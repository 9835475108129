export const environmentIssueTypes = {
  sandbox: {
    SERVICE_REQUEST: "10015", // equivalent of CYBER_INCIDENT_LEGACY
    THREAT_HUNT: "10021",
    CYBER_INCIDENT: "10010",
    CYBER_SERVICE_REQUEST: "10011",
    XDR_RECOMMENDATION: "10023",
    HEALTH_ALERT: "10018",
    XDR_SERVICE_CHECK: "10024",
  },
  production: {
    SERVICE_REQUEST: "10005", // equivalent of CYBER_INCIDENT_LEGACY
    THREAT_HUNT: "10012",
    CYBER_INCIDENT: "10013",
    CYBER_SERVICE_REQUEST: "10024",
    XDR_RECOMMENDATION: "10089",
    HEALTH_ALERT: "10083",
    XDR_SERVICE_CHECK: "10090",
  },
};

export const mapIssueTypes = () => {
  if (process.env.REACT_APP_JIRA_ENV?.toString().toLowerCase() === "production") {
    return environmentIssueTypes.production;
  }
  return environmentIssueTypes.sandbox;
};

export const ISSUE_TYPE_IDS = mapIssueTypes();
export const ISSUE_TYPES = {
  "service request": ISSUE_TYPE_IDS.SERVICE_REQUEST,
  "threat hunt": ISSUE_TYPE_IDS.THREAT_HUNT,
  "cyber incident": ISSUE_TYPE_IDS.CYBER_INCIDENT,
  "health alert": ISSUE_TYPE_IDS.HEALTH_ALERT,
  "soc change notification": ISSUE_TYPE_IDS.SOC_CHANGE_NOTIFICATION,
  "security engineering": ISSUE_TYPE_IDS.SECURITY_ENGINEERING,
  "service management": ISSUE_TYPE_IDS.SERVICE_MANAGEMENT,
  "cyber service request": ISSUE_TYPE_IDS.CYBER_SERVICE_REQUEST,
  "xdr recommendation": ISSUE_TYPE_IDS.XDR_RECOMMENDATION,
  "xdr service check": ISSUE_TYPE_IDS.XDR_SERVICE_CHECK,
};
