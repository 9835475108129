import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";

import { Table } from "lib/components/Table";
import { SortableHeader } from "lib/components/SortableHeader";
import { LinkRow } from "lib/components/LinkRow";
import { trimWord } from "lib/helpers/trimWord";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Searchbar } from "lib/components/Searchbar";
import { useDebouncedCallback } from "use-debounce";
import { InfiniteScroll, useInfiniteScroll } from "lib/components/InfiniteScroll";

const StyledSearchbar = styled(Searchbar)`
  @media (max-width: ${(props) => props.theme.mobileWidth}) {
    margin: 1.5rem 1rem 0.5rem;
    width: calc(100% - 2rem);
    & + .table {
      margin-left: 2rem;
      width: calc(100% - 2rem);
    }
  }
`;

export const RelatedTickets = ({ id }) => {
  const [data, setData] = useState({ result: [], paging: {} });
  const [params, setParams] = useState({ search: "", orderBy: "fid", reverse: true, status: "open" });
  const url = `${process.env.REACT_APP_PHISH_API}/artifacts/${id}/related`;

  const { loadItems, hasMore, isLoading } = useInfiniteScroll({ url, params, data, setData });

  const [searchCallback] = useDebouncedCallback(search => {
    setParams(p => ({ ...p, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: search => searchCallback(search)
  };

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: sort => setParams(p => ({ ...p, ...sort }))
  };

  const filters = <StyledSearchbar {...search} />;

  return (
    <InfiniteScroll filters={filters} loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data}>
      <Table style={{ border: "none", marginBottom: "-1.8rem", width: "calc(100% + 3.6rem)" }}>
        <thead>
          <tr>
            <th></th>
            <SortableHeader field="fid" {...sort}>
              ID
            </SortableHeader>
            <SortableHeader field="mail['from']['value'][0].address" {...sort}>
              Mail From
            </SortableHeader>
            <SortableHeader field="customer.code" {...sort}>
              Company
            </SortableHeader>
            <SortableHeader field="created" {...sort}>
              Received
            </SortableHeader>
            <SortableHeader field="mail.subject" {...sort}>
              E-mail Subject
            </SortableHeader>
            <th>Tags</th>
            <SortableHeader field="severity.text" style={{ justifyContent: "  flex-end" }} {...sort}>
              Severity
            </SortableHeader>
          </tr>
        </thead>
        <tbody>
          {data.result.map(ticket => (
            <LinkRow path={`/phish/tickets/${ticket.customer.id}/${ticket.id}`} key={ticket.id}>
              <td style={{ maxWidth: "20px", paddingRight: "0" }}>{ticket.hasAttachments && <FontAwesomeIcon icon={faPaperclip} />}</td>
              <td style={{ maxWidth: "100px", paddingRight: "0", textDecoration: !ticket.analyst && "underline" }}>{ticket.fid}</td>
              <td style={{ maxWidth: "100px" }}>{trimWord(ticket.from?.value[0]?.address?.split("@")?.[0], 40)}</td>
              <td style={{ maxWidth: "50px" }}>{ticket.customer?.code}</td>
              <td style={{ maxWidth: "100px", overflow: "visible" }}>{moment(ticket.created).format("D/MM - HH:mm")}</td>
              <td style={{ maxWidth: "300px" }}>{ticket.subject}</td>
              <td style={{ maxWidth: "100px" }}>{ticket.tags?.map(tag => tag.name)?.join(", ")}</td>
              <td
                style={{
                  maxWidth: "160px",
                  textAlign: "right",
                  textTransform: "capitalize",
                  color: getColorFromSeverity(ticket.severity)
                }}
              >
                {`${ticket.severity?.text} ${ticket.severity?.score > 0 ? `(${ticket.confidence?.text})` : ""} `}
              </td>
            </LinkRow>
          ))}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
};
