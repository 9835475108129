import React from "react";
import styled from "styled-components";
import { SidebarFilter } from "lib/components/SidebarFilters";
import { SidebarFilterContainer } from "lib/components/SidebarFilters";
import { PRIORITY_NAMES } from "../components/constants";

const StyledContainer = styled.div`
  display: flex;
`;

export const ProjectFilters = ({ filtersData, filters, setFilter, getClient, getProjectManager }) => {
  const handleOverallRagCallback = ([value]) => {
    if (filters.overallPriority === value) {
      setFilter({ overallPriority: "" });
    } else {
      setFilter({ overallPriority: value });
    }
  };

  const handleScopeRagCallback = ([value]) => {
    if (filters.scopePriority === value) {
      setFilter({ scopePriority: "" });
    } else {
      setFilter({ scopePriority: value });
    }
  };

  const handleTimeRagCallback = ([value]) => {
    if (filters.timePriority === value) {
      setFilter({ timePriority: "" });
    } else {
      setFilter({ timePriority: value });
    }
  };

  const handleBudgetRagCallback = ([value]) => {
    if (filters.budgetPriority === value) {
      setFilter({ budgetPriority: "" });
    } else {
      setFilter({ budgetPriority: value });
    }
  };

  const handleIsArchivedCallback = ([value]) => {
    value = value.toString().toLowerCase() === "true";
    if (filters.isArchived === value) {
      setFilter({ isArchived: "" });
    } else {
      setFilter({ isArchived: value });
    }
  };

  const handleIsBillableCallback = ([value]) => {
    value = value.toString().toLowerCase() === "true";
    if (filters.isBillable === value) {
      setFilter({ isBillable: "" });
    } else {
      setFilter({ isBillable: value });
    }
  };

  const handleIsTentativeCallback = ([value]) => {
    value = value.toString().toLowerCase() === "true";
    if (filters.isTentative === value) {
      setFilter({ isTentative: "" });
    } else {
      setFilter({ isTentative: value });
    }
  };

  const handleTagCallback = ([value]) => {
    if (filters.tag === value) {
      setFilter({ tag: "" });
    } else {
      setFilter({ tag: value });
    }
  };

  const handleClientCallback = ([value]) => {
    if (filters.clientId === value) {
      setFilter({ clientId: "" });
    } else {
      setFilter({ clientId: value });
    }
  };

  const handleProjectManagerCallback = ([value]) => {
    if (filters.projectManagerId === value) {
      setFilter({ projectManagerId: "" });
    } else {
      setFilter({ projectManagerId: value });
    }
  };

  const overallRagIsActive = ([overallRag]) => filters.overallPriority === overallRag;
  const scopeRagIsActive = ([scopeRag]) => filters.scopePriority === scopeRag;
  const timeRagIsActive = ([timeRag]) => filters.timePriority === timeRag;
  const budgetRagIsActive = ([budgetRag]) => filters.budgetPriority === budgetRag;
  const isArchivedIsActive = ([isArchived]) => `${filters.isArchived}` === isArchived;
  const isBillableIsActive = ([isBillable]) => `${filters.isBillable}` === isBillable;
  const isTentativeIsActive = ([isTentative]) => `${filters.isTentative}` === isTentative;
  const tagIsActive = ([tag]) => filters.tag === tag;
  const clientIsActive = ([clientId]) => filters.clientId === clientId;
  const projectManagerIsActive = ([projectManagerId]) => filters.projectManagerId === projectManagerId;

  const clearOverallRag = () => setFilter({ overallPriority: "" });
  const clearScopeRag = () => setFilter({ scopePriority: "" });
  const clearBudgetRag = () => setFilter({ budgetPriority: "" });
  const clearTimeRag = () => setFilter({ timePriority: "" });
  const clearIsArchived = () => setFilter({ isArchived: "" });
  const clearIsBillable = () => setFilter({ isBillable: "" });
  const clearIsTentative = () => setFilter({ isTentative: "" });
  const clearTag = () => setFilter({ tag: "" });
  const clearClient = () => setFilter({ clientId: "" });
  const clearProjectManager = () => setFilter({ projectManagerId: "" });

  const getPriorityName = (e) => PRIORITY_NAMES[e[0]];
  const getIsArchivedName = (e) => (e[0] === "true" ? "Archived" : "Not archived");
  const getIsTentativeName = (e) => (e[0] === "true" ? "Tentative" : "Definite");
  const getIsBillableName = (e) => (e[0] === "true" ? "Billable" : "Non-billable");
  const getClientName = (e) => getClient(e[0])?.name;
  const getProjectManagerName = (e) => getProjectManager(e[0])?.name;

  const getName = (e) => e[0];
  const getCount = (e) => e[1];

  const clientSort = (a, b) => (getClientName(a).toUpperCase() < getClientName(b).toUpperCase() ? -1 : 1);

  const commonProps = {
    ...filtersData,
    getName: getName,
    getCount: getCount,
    isObject: true,
  };

  return (
    <StyledContainer>
      <SidebarFilterContainer>
        <SidebarFilter
          name="Clients"
          clear={clearClient}
          callback={handleClientCallback}
          isActive={clientIsActive}
          resultField="result.clients"
          {...commonProps}
          getName={getClientName}
          sortFn={clientSort}
        />
        <SidebarFilter
          name="Overall RAG"
          clear={clearOverallRag}
          callback={handleOverallRagCallback}
          isActive={overallRagIsActive}
          resultField="result.overallPriorities"
          {...commonProps}
          getName={getPriorityName}
        />
        <SidebarFilter
          name="Time RAG"
          clear={clearTimeRag}
          callback={handleTimeRagCallback}
          isActive={timeRagIsActive}
          resultField="result.timePriorities"
          {...commonProps}
          getName={getPriorityName}
        />
        <SidebarFilter
          name="Scope RAG"
          clear={clearScopeRag}
          callback={handleScopeRagCallback}
          isActive={scopeRagIsActive}
          resultField="result.scopePriorities"
          {...commonProps}
          getName={getPriorityName}
        />
        <SidebarFilter
          name="Budget RAG"
          clear={clearBudgetRag}
          callback={handleBudgetRagCallback}
          isActive={budgetRagIsActive}
          resultField="result.budgetPriorities"
          {...commonProps}
          getName={getPriorityName}
        />
        <SidebarFilter
          name="Archived Status"
          clear={clearIsArchived}
          callback={handleIsArchivedCallback}
          isActive={isArchivedIsActive}
          resultField="result.isArchived"
          {...commonProps}
          getName={getIsArchivedName}
        />
        <SidebarFilter
          name="Billable Status"
          clear={clearIsBillable}
          callback={handleIsBillableCallback}
          isActive={isBillableIsActive}
          resultField="result.isBillable"
          {...commonProps}
          getName={getIsBillableName}
        />
        <SidebarFilter
          name="Tentative Status"
          clear={clearIsTentative}
          callback={handleIsTentativeCallback}
          isActive={isTentativeIsActive}
          resultField="result.isTentative"
          {...commonProps}
          getName={getIsTentativeName}
        />
        <SidebarFilter
          name="Tags"
          clear={clearTag}
          callback={handleTagCallback}
          isActive={tagIsActive}
          resultField="result.tags"
          {...commonProps}
        />
        <SidebarFilter
          name="Project Managers"
          clear={clearProjectManager}
          callback={handleProjectManagerCallback}
          isActive={projectManagerIsActive}
          resultField="result.projectManagers"
          {...commonProps}
          getName={getProjectManagerName}
        />
      </SidebarFilterContainer>
    </StyledContainer>
  );
};
