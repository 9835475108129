import { makeStyles } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";

export const ClarityDarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#01a981",
    },
    secondary: {
      main: "#01a981",
      contrastText: "#ffffff",
    },
    text: {
      secondary: "#01a981",
    },
    background: {
      paper: "#2b2e34",
      default: "#1f2228",
    },
    root: {
      color: "#ffffff",
    },
  },
});

export const PaginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
      "background-color": "#1b1e23",
    },
    "& .Mui-selected": {
      color: "#01a981",
    },
  },
}));


export const FilterStyles = makeStyles((theme) => ({
  customFilter: {
    color: "#01a981",
    borderColor: "#01a981",
    button: {
      color: "#01a981",
      borderColor: "#01a981",
    },
    deleteIcon: {
      color: "#01a981",
    },
    "& .MuiChip-deleteIcon": {
      color: "#737373"
    },
    "& .MuiChip-root": {
      color: "#fff",
      backgroundColor: "#1b1e23",
    },
  },
}));

export const ButtonTheme = {
  primaryButtonColor: "#01a981",
  errorText: "#ff0000",
};
