import { useState, useEffect } from "react";

export const MATCHED_ELEMENT_CLASSNAME = "match";

export const useSearchResults = (search, results) => {
  const [index, setIndex] = useState(-1);
  const [fixedOffset, setFixedOffset] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const searchEl = document.getElementById("searchbar");
      const bodyEl = searchEl.parentElement;
      if (fixedOffset) {
        if (window.scrollY < bodyEl.offsetTop + searchEl.offsetHeight) {
          setFixedOffset(null);
        }
      } else {
        if (window.scrollY > searchEl.offsetTop) {
          searchEl.style.maxWidth = bodyEl.offsetWidth + "px";
          setFixedOffset(searchEl.offsetHeight);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fixedOffset]);

  // Effect to handle incrementing index on key press
  useEffect(() => {
    const handleKeyPress = ({ key }) => {
      if (key === "Enter" && results.length) {
        if (index < results.length - 1) {
          setIndex(index + 1);
        } else {
          setIndex(0);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [index, results]);

  // Effect to handle resetting index on search change
  useEffect(() => {
    setIndex(-1);
  }, [search]);

  // Effect to handle changing scroll position on index change
  useEffect(() => {
    const matchedEls = document.getElementsByClassName(MATCHED_ELEMENT_CLASSNAME);
    // Reset any existing styles on previous indexes
    [...matchedEls].forEach(el => el.classList.remove("current"));
    // Highlight and scroll to matched el at current index
    if (matchedEls.length > 0 && matchedEls[index]) {
      matchedEls[index].classList.add("current");
      matchedEls[index].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }, [index, search]);

  return [index, fixedOffset];
};
