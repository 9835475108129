import React from "react";

import { Input } from "lib/components/Input";
import { TextArea } from "lib/components/TextArea";
import { Tooltip } from "lib/components/Tooltip";
import { ScrollContainer } from "lib/components/ScrollContainer";

export const Targets = ({ targets, setTargets, readOnly }) => {
  const handleChange = (e) => {
    let targets = e.target.value
      .split(/[\r\n\s,]+/)
      .map((t) => t.trim())
      .filter(Boolean);
    setTargets(targets);
  };

  return (
    <>
      <Input label={`Target Emails (${targets.length ? `${targets.length} user${targets.length > 1 ? "s" : ""}` : "CSV"})`}>
        <ScrollContainer style={{ width: "100%", maxHeight: readOnly ? "30rem" : "unset" }}>
          <TextArea name="targets" rows="10" onChange={handleChange} defaultValue={targets.join(",\n")} readOnly={readOnly} />
        </ScrollContainer>
      </Input>
      {!readOnly && (
        <Tooltip
          xHoverPos="right"
          hoverText={<p>{`Make sure you've filled in targets correctly first,\n (separated any way: space, comma, tab, new-line)\n as the form will validate the stats against it later on.`}</p>}
        />
      )}
    </>
  );
};
