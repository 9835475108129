import { ScrollContainer } from "lib/components/ScrollContainer";
import styled from "styled-components";

const StyledContainer = styled(ScrollContainer)`
  display: flex;
  flex: 1;
  gap: 1rem;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0.35rem;
`;

const StyledTag = styled.div`
  position: relative;
  z-index: 10;
  font-size: 1.1rem;
  color: ${(props) => props.theme.mediumText};
  padding: 0.4rem 0.5rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.35rem;

  & > p {
    margin: 0;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:after {
    position: absolute;
    content: "";
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.cardBackground};
    border-radius: 0.35rem;
    border: 1px solid ${(props) => props.theme.headerOutline};
    opacity: 0.6;
  }
`;

const EXCLUDED_TAGS = ["RECURRING"];

export const Tags = ({ tags = [], className = "" }) => {
  const filteredTags = tags.filter((t) => !EXCLUDED_TAGS.includes(t.toUpperCase()));

  return (
    <StyledContainer scrollX="auto" scrollY="hidden" className={`${className} tags`}>
      {filteredTags.map((tag) => (
        <StyledTag key={tag} title={tag}>
          <p>{tag}</p>
        </StyledTag>
      ))}
    </StyledContainer>
  );
};
