import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Dashboard } from "./dashboard";
import { Projects } from "./projects";
import { Clients } from "./clients";

export const ServiceDeliveryPortfolio = () => {
  const routes = [
    {
      name: "Dashboard",
      link: "/service-delivery-portfolio/",
      isHidden: true,
    },
    {
      name: "Clients",
      link: "/service-delivery-portfolio/clients",
      isWide: true,
    },
    {
      name: "Projects",
      link: "/service-delivery-portfolio/projects",
      isWide: true,
    },
  ];

  return (
    <PageContainer name="Service Delivery Portfolio" link="/service-delivery-portfolio" routes={routes}>
      <ErrorHandledRoute path="/service-delivery-portfolio/clients" component={Clients} />
      <ErrorHandledRoute path="/service-delivery-portfolio/projects" component={Projects} />
      <ErrorHandledRoute path="/service-delivery-portfolio" component={Dashboard} />
    </PageContainer>
  );
};
