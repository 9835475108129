import React from "react";

import { Table } from "lib/components/Table";

export const Headers = ({ data }) => {
  const items = Object.entries(data.mail.headers || {});
  return (
    <Table isFixed minWidth="700px" style={{ margin: "-1.8rem", width: "unset" }}>
      <thead>
        <tr>
          <th style={{ width: "200px" }}>Key</th>
          <th style={{ textAlign: "left" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {items.map(([key, value], i) => (
          <tr key={i}>
            <td style={{ maxWidth: "300px", whiteSpace: "inherit" }}>{key}</td>
            <td style={{ textAlign: "left", padding: "10px" }}>
              <p style={{ margin: "0px", whiteSpace: "pre-wrap" }}>
                {typeof value === "string" ? value : JSON.stringify(value, null, 2)}
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
