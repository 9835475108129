import React, { useState, useRef } from "react";
import { fetchData } from "lib/helpers/fetchData";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledFilters } from "lib/styles/general";
import { StyledForm } from "lib/styles/general";

import { Modal, ConfirmModal } from "lib/components/Modal";
import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { Table } from "lib/components/Table";
import { Input } from "lib/components/Input";
import { MarkdownEditor } from "lib/components/MarkdownEditor";

export const Hosts = ({ hosts = [], customerId, reload }) => {
  const [search, setSearch] = useState("");
  const [addHostModal, setAddHostModal] = useState({ showModal: false, callback: null });
  const [hostModal, setHostModal] = useState({ showModal: false, host: {} });

  const handleAddHost = async (host) => {
    setAddHostModal({ showModal: true, isLoading: true });
    const newHosts = [...hosts, host];
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/hosts`, JSON.stringify(newHosts), "PUT");
    setAddHostModal({ showModal: false, isLoading: false });
    reload();
  };

  const handleShowAddHost = () => setAddHostModal({ showModal: true, callback: handleAddHost });

  const list = hosts?.filter((c) => [c.name.toLowerCase(), c.ip.toLowerCase()].join("").includes(search.toLowerCase()));

  return (
    <div>
      <StyledFilters style={{ display: "flex" }}>
        <Searchbar style={{ margin: 0 }} setSearch={setSearch}>{`${list.length} hosts`}</Searchbar>
        <hr />
        <CTAButton onClick={handleShowAddHost}>Add Host</CTAButton>
      </StyledFilters>
      {list.length ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>IP</th>
              <th>OS</th>
              <th style={{ textAlign: "left" }}>Role</th>
            </tr>
          </thead>
          <tbody>
            {list.map((host, i) => (
              <Host key={i} host={host} setModal={setHostModal} />
            ))}
          </tbody>
        </Table>
      ) : (
        <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No hosts</p>
      )}
      <HostModal
        key={hostModal.host?.name}
        {...hostModal}
        hide={() => setHostModal({ showModal: false })}
        reload={reload}
        customerId={customerId}
        hosts={hosts}
      />
      <AddHostModal {...addHostModal} hide={() => setAddHostModal({ showModal: false })} />
    </div>
  );
};

const Host = ({ host, setModal }) => {
  const handleHostClick = () => {
    setModal({ showModal: true, host });
  };

  return (
    <tr style={{ cursor: "pointer" }} onClick={handleHostClick}>
      <td>{host.name}</td>
      <td>{host.ip}</td>
      <td>{host.os}</td>
      <td style={{ textAlign: "left" }}>{host.role}</td>
    </tr>
  );
};

const HostModal = ({ showModal, hide, host = {}, hosts = [], customerId, reload }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    const newUser = getFormValues(formRef.current);
    const otherHosts = hosts.filter((c) => c.name !== (isEditMode ? host : newUser).name);
    const newHosts = [...otherHosts, newUser];
    setIsLoading(true);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/hosts`, JSON.stringify(newHosts), "PUT");
    setIsLoading(false);
    reload();
    hide();
  };

  const handleDelete = async () => {
    const newHosts = hosts.filter((c) => c.name !== host.name);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/hosts`, JSON.stringify(newHosts), "PUT");
    setConfirmModal({ showModal: false, callback: null });
    reload();
    hide();
  };

  const handleDeletePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDelete() });
  };

  return (
    <>
      <Modal showModal={showModal} hide={hide} style={{ width: "1000px" }}>
        <div style={{ position: "relative", marginTop: "1rem" }}>
          {!isEditMode && setIsEditMode && (
            <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
              Edit
            </CTAButton>
          )}
          <StyledForm ref={formRef}>
            <section>
              <Input name="name" label="Name" defaultValue={host.name} readOnly={!isEditMode} />
              <Input name="ip" label="IP" defaultValue={host.ip} readOnly={!isEditMode} />
              <Input name="os" label="OS" defaultValue={host.os} readOnly={!isEditMode} />
              <Input name="role" label="Role" defaultValue={host.role} readOnly={!isEditMode} />
              <Input label="Notes">
                <MarkdownEditor name="notes" style={{ marginTop: "0.3rem" }} defaultValue={host.notes || "No notes yet"} readOnly={!isEditMode} subtle />
              </Input>
            </section>
            {!isEditMode && (
              <section>
                {/* eslint-disable-next-line */}
                <h3>Danger Zone 🚨🚨</h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid rgba(255, 0, 0, 0.6)",
                    borderRadius: "0.8rem",
                    padding: "1rem",
                  }}
                >
                  <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this host permanently</p>
                  <CTAButton onClick={handleDeletePrompt} style={{ color: "rgba(255, 0, 0, 0.7)", fontWeight: "bold" }} isSecondary>
                    Delete
                  </CTAButton>
                </div>
              </section>
            )}
          </StyledForm>
          {isEditMode && (
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem" }}>
              <CTAButton isSecondary onClick={() => setIsEditMode(false)}>
                Cancel
              </CTAButton>
              <CTAButton onClick={handleSubmit} isLoading={isLoading}>
                {setIsEditMode ? "Save" : "Create"}
              </CTAButton>
            </div>
          )}
        </div>
      </Modal>
      <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
    </>
  );
};

const AddHostModal = ({ showModal, callback, isLoading, hide }) => {
  const formRef = useRef(null);

  const handleAdd = () => {
    const values = getFormValues(formRef.current);
    callback(values);
  };

  return (
    <Modal
      style={{ width: "1000px" }}
      title="Add Host"
      showModal={showModal}
      isLoading={isLoading}
      hide={hide}
      actionText="Create"
      actionCommand={handleAdd}
    >
      <StyledForm ref={formRef}>
        <Input name="name" label="Name" />
        <Input name="ip" label="IP" />
        <Input name="os" label="OS" />
        <Input name="role" label="Role" />
        <Input label="Notes">
          <MarkdownEditor name="notes" style={{ marginTop: "0.3rem" }} subtle />
        </Input>
      </StyledForm>
    </Modal>
  );
};
