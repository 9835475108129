import React from "react";
import { Switch } from "react-router-dom";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { CreateCampaign } from "./create-campaign";
import { ListCampaigns } from "./list-campaigns";
import { SingleCampaign } from "./single-campaign";

export const Campaigns = () => {
  return (
    <Switch>
      <ErrorHandledRoute path="/phish-sim/campaigns/new" component={CreateCampaign} />
      <ErrorHandledRoute path="/phish-sim/campaigns/single/:id" component={SingleCampaign} />
      <ErrorHandledRoute path="/phish-sim/campaigns" component={ListCampaigns} />
    </Switch>
  );
};
