export const COLORS = {
  GREEN: "rgba(3, 255, 122, 0.5)",
  AMBER: "rgba(255, 206, 46, 0.85)",
  RED: "rgba(255, 63, 48, 0.68)",
};

export const COLOR_VALUES = {
  [COLORS.WHITE]: -1,
  [COLORS.RED]: 0,
  [COLORS.AMBER]: 1,
  [COLORS.GREEN]: 2,
};

export const VALUE_COLORS = {
  "-1": COLORS.WHITE,
  0: COLORS.RED,
  1: COLORS.AMBER,
  2: COLORS.GREEN,
};

export const PRIORITY_NAMES = {
  "-1": "Unset",
  0: "Red",
  1: "Amber",
  2: "Green",
};

export const TIER_NAMES = {
  "-1": "Unset",
  1: "Very high",
  2: "High",
  3: "Medium",
  4: "Low",
  5: "Very low"
};