import React, { useLayoutEffect, useContext, useEffect, useState, useCallback } from "react";
import { Chart } from "chart.js";
import { ThemeContext } from "./ThemeProvider";
import { getRemValue } from "lib/helpers/getRemValue";

export const StandardChart = ({
  axisLabels = [],
  dataPoints = [],
  dataLabels = [],
  colors = [],
  xLabel,
  yLabel,
  padding,
  tooltipLabelFn = (items, data) => data.datasets[items.datasetIndex].label + ": " + items.yLabel,
  tooltipTitleFn = (items) => items[0].xLabel,
  yAxisTicksFn = (value) => value,
  xAxisTicksFn = (value) => value,
  displayTooltip = true,
  displayLegend = true,
  legendPosition = "top",
  thickness = "flex",
  animation = true,
  id = "myChart",
  chartType = "bar",
  xAxisType = "time",
  yAxisType = "linear",
  yPrecision = null,
  stacked = false,
  transparentBackground = false,
  handleClick,
  skipDulicates = false,
  allowLabelRotation = true,
  xAxisUnit = "day",
  disabledDatasetIndexes = [],
  tension = 0.4,
  drawBoxIndex,
  width,
  height,
  beginAtZero = true,
  offsetXAxis = false,
  xStepSize,
}) => {
  const { theme } = useContext(ThemeContext);
  const [ctx, setCtx] = useState(null);

  const [index, setIndex] = useState(drawBoxIndex);
  const [prevIndex, setPrevIndex] = useState(drawBoxIndex);

  useEffect(() => {
    setIndex((i) => {
      setPrevIndex(i);
      return drawBoxIndex;
    });
  }, [drawBoxIndex]);

  const renderBoxes = useCallback(
    (chartInstance) => {
      const getIndexDimesions = (index) => {
        const dataset = chartInstance?.config?.data?.datasets?.[0];
        const model = dataset?._meta[Object.keys(dataset?._meta)?.[0]]?.data?.[index]?._model;
        const { x, width } = model || {};
        return {
          x: x - width / 2 - 3,
          y: chartInstance.chartArea.top - 3,
          width: width + 6,
          height: chartInstance.chartArea.bottom - chartInstance.chartArea.top + 6,
        };
      };

      const clearIndex = (index) => {
        const dimensions = getIndexDimesions(index);
        if (!dimensions) return;
        const { x, y, width, height } = dimensions;
        ctx.clearRect(x - 6, y - 6, width + 12, height + 12);
      };

      const drawBoxAroundIndex = (index) => {
        const dimensions = getIndexDimesions(index);
        if (!dimensions) return;
        const { x, y, width, height } = dimensions;
        ctx.beginPath();
        ctx.globalAlpha = 0.6;
        ctx.fillStyle = theme.secondaryButtonColor;
        ctx.fillRect(x, y, width, height);
        ctx.globalAlpha = 1;
      };

      if (drawBoxIndex !== undefined && ctx && chartInstance.canvas.id === id) {
        if (prevIndex !== null) {
          clearIndex(prevIndex);
        }
        drawBoxAroundIndex(index);
      }
    },
    [index, prevIndex, drawBoxIndex, ctx, theme.secondaryButtonColor, id]
  );

  useLayoutEffect(() => {
    if (!ctx) {
      setCtx(document.getElementById(id).getContext("2d"));
    }

    const drawNumbers = (chartInstance) => {
      const ctx = chartInstance.chart.ctx;
      ctx.textAlign = "left";
      ctx.textBaseline = "bottom";
      ctx.font = `12.6px Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif`;
      chartInstance.data.datasets.forEach(function (dataset) {
        for (let i = 0; i < dataset.data.length; i++) {
          const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
          const scaleWidth = dataset._meta[Object.keys(dataset._meta)[0]].data[i]?._xScale?.maxWidth;
          const xPos = model.x === 0 ? 0 : scaleWidth - model.x > 0 ? model.x + 10 : model.x - dataset?.data?.[i]?.toString()?.length * 10;
          ctx.fillStyle = scaleWidth - model.x > 0 ? theme.text : theme.cardBackground;
          ctx.fillText(Number(dataset.data[i]).toLocaleString(), xPos, model.y + model.height / 4);
        }
      });
    };

    const drawSmallHeights = (chartInstance) => {
      const datasets = chartInstance.config.data.datasets;
      for (let i = 0; i < datasets.length; i++) {
        const meta = datasets[i]._meta;
        const metaData = meta[Object.keys(meta)[0]];
        const bars = metaData.data;
        for (var j = 0; j < bars.length; j++) {
          const model = bars[j]._model;
          if (metaData.type === "horizontalBar" && model.x - 3 < model.base && model.x !== model.base) {
            model.x = model.base + 3;
          }
        }
      }
    };

    Chart.plugins.register({
      beforeTooltipDraw: drawNumbers,
      beforeRender: drawSmallHeights,
      beforeDraw: renderBoxes,
    });

    let chart;
    if (ctx) {
      chart = new Chart(ctx, {
        type: chartType,
        data: {
          labels: axisLabels,
          datasets: dataPoints.map((_, i) => ({
            data: dataPoints[i],
            label: dataLabels[i],
            fill: !transparentBackground,
            backgroundColor: transparentBackground ? "transparent" : colors[i],
            borderColor: colors[i],
            barThickness: thickness,
            hidden: disabledDatasetIndexes.includes(i),
            pointHitRadius: 10,
            radius: 2,
            lineTension: tension,
          })),
        },
        options: {
          responsive: true,
          devicePixelRatio: window.devicePixelRatio * 2,
          maintainAspectRatio: false,
          hover: { animationDuration: 0 },
          animation: {
            duration: 0,
          },
          layout: {
            padding: padding,
          },
          onClick: (e, el) => handleClick && handleClick(e, el, chart),
          legend: {
            position: legendPosition,
            display: displayLegend,
            onHover: (e) => (e.target.style.cursor = "pointer"),
            labels: {
              usePointStyle: true,
              boxWidth: 30,
            },
          },
          tooltips: {
            enabled: displayTooltip,
            callbacks: {
              label: tooltipLabelFn,
              title: tooltipTitleFn,
              labelColor: function (tooltipItem, chart) {
                let dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                let color = transparentBackground ? dataset.borderColor : dataset.backgroundColor;
                if (Array.isArray(colors[tooltipItem.datasetIndex])) {
                  color = colors[tooltipItem.datasetIndex][tooltipItem.index];
                }
                return {
                  backgroundColor: color,
                  borderColor: color,
                };
              },
            },
          },
          onHover: (e) => handleClick && (e.target.style.cursor = "pointer"),
          scaleShowValues: true,
          scales: {
            xAxes: [
              {
                offset: chartType === "line" ? false : true,
                beginAtZero: true,
                stacked: stacked,
                scaleLabel: {
                  display: xLabel,
                  labelString: xLabel,
                  fontColor: theme.lightText,
                  fontSize: getRemValue(1.2),
                  fontFamily: theme.fontFamily,
                  fontStyle: "bold",
                },
                ticks: {
                  callback: xAxisTicksFn,
                  fontColor: theme.lightText,
                  fontSize: getRemValue(1.2),
                  fontFamily: theme.fontFamily,
                  fontStyle: "normal",
                  autoSkip: true,
                  stepSize: xStepSize,
                  min: 0,
                },
                type: xAxisType,
                distribution: "linear",
                time: {
                  unit: xAxisUnit,
                },
              },
            ],
            yAxes: [
              {
                stacked: stacked,
                scaleLabel: {
                  display: yLabel,
                  labelString: yLabel,
                  fontColor: theme.lightText,
                  fontSize: getRemValue(1.2),
                  fontFamily: theme.fontFamily,
                  fontStyle: "bold",
                },
                type: yAxisType,
                ticks: {
                  min: 0,
                  beginAtZero: true,
                  autoSkip: false,
                  callback: yAxisTicksFn,
                  fontColor: theme.lightText,
                  fontSize: getRemValue(1.2),
                  fontFamily: theme.fontFamily,
                  fontStyle: "normal",
                  precision: yPrecision !== null && yPrecision,
                },
              },
            ],
          },
        },
      });
    }
    return () => chart?.destroy();
  }, [
    chartType,
    offsetXAxis,
    animation,
    transparentBackground,
    axisLabels,
    colors,
    dataLabels,
    dataPoints,
    displayLegend,
    handleClick,
    legendPosition,
    padding,
    xAxisUnit,
    tooltipLabelFn,
    tooltipTitleFn,
    yAxisTicksFn,
    xLabel,
    yLabel,
    stacked,
    tension,
    beginAtZero,
    xAxisTicksFn,
    id,
    xAxisType,
    yAxisType,
    yPrecision,
    thickness,
    theme,
    disabledDatasetIndexes,
    allowLabelRotation,
    skipDulicates,
    renderBoxes,
    ctx,
    displayTooltip,
    xStepSize,
  ]);

  return <canvas id={id} width={width} height={height} />;
};
