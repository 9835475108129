export const calculteWorstPriority = ({ project, metrics }) => {
  const { budgetPriority, timePriority, scopePriority } = project;

  // reset metrics if archived
  if (project.isArchived) metrics = {};

  let priorities = [];
  if (metrics.budget) priorities.push(budgetPriority);
  if (metrics.time) priorities.push(timePriority);
  if (metrics.scope) priorities.push(scopePriority);

  const worstPriority = Math.min(...priorities);
  return worstPriority;
};
