import React, { useState, useRef } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { TableComponent } from "./TableComponent";

export const Rules = ({ data }) => {
  if (!data) return null;
  return (
    <StyledRulesContainer scrollX="auto" scrollY="unset">
      <header>
        <div className="small" />
        <div className="large">Customer</div>
        <div className="smaller center">Successful</div>
        <div className="smaller center">Found data</div>
        <div className="smaller center">Row count</div>
      </header>
      {data.length > 0 ? (
        data.map((result, i) => (
          <Rule key={result.item.customer.name} {...result} isSuccessful={result.item.result.tables[0].columns.length <= 0 ? false : true} />
        ))
      ) : (
        <p>No rules ran against this object</p>
      )}
    </StyledRulesContainer>
  );
};

const Rule = ({ item, isSuccessful }) => {
  let hasData;
  if (isSuccessful) {
    hasData = item.result.tables[0].rows[0].length > 0 && item.result.tables[0].columns.length > 0 ? true : false;
  } else {
    hasData = false;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  const handleToggle = () => {
    setHeight(isOpen ? 0 : contentRef.current.clientHeight);
    setIsOpen(!isOpen);
  };

  let parsedData = item;
  try {
    parsedData = JSON.parse(item);
  } catch (error) {}
  return (
    <StyledRule open={isOpen} height={height}>
      <rule-header onClick={handleToggle}>
        <div className="small">
          <FontAwesomeIcon icon={faChevronDown} className="arrow" />
        </div>
        <div className="large">{parsedData.customer.name}</div>
        <div className="smaller">
          <Ball color={isSuccessful ? "green" : "red"} />
        </div>
        <div className="smaller">
          <Ball color={hasData ? "green" : "red"} />
        </div>
        <div className="smaller">{isSuccessful && hasData ? item.originalLength : "N/A"}</div>
      </rule-header>
      <rule-body>
        <rule-content ref={contentRef}>
          <TableComponent data={parsedData} />
        </rule-content>
      </rule-body>
    </StyledRule>
  );
};

const Ball = ({ color }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
      <div style={{ height: "10px", width: "10px", borderRadius: "5px", background: color }} />
    </div>
  );
};

const StyledRulesContainer = styled(ScrollContainer)`
  margin: -1.8rem;
  margin-top: 3%;
  width: calc(100% + 3.6rem);
  p {
    font-size: 1.5rem;
    color: ${(props) => props.theme.text};
    white-space: pre-wrap;
  }
  & > p {
    text-align: center;
  }
  header {
    font-size: 1.25rem;
    display: flex;
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      padding: 1.25rem 0.8rem;
      color: ${(props) => props.theme.mediumText};
    }
  }
  .small {
    min-width: 20px;
    max-width: 20px;
    display: flex;
    justify-content: center;
  }
  .smaller {
    min-width: 85px;
    max-width: 85px;
    overflow: unset;
  }
  .medium {
    min-width: 20%;
    max-width: 120px;
    padding: 0 0.8rem;
    display: flex;
    align-items: center;
    min-height: 4rem;
    max-height: 4rem;
    overflow: unset;
    .tooltip {
      margin-left: 1rem;
    }
  }
  .large {
    flex: 1;
    min-width: 40%;
  }
  .center {
    text-align: center;
  }
`;

const StyledRule = styled.div`
  &:nth-child(even) rule-header > div {
    background: ${(props) => props.theme.accentCardBackground};
  }
  rule-header {
    cursor: pointer;
    display: flex;
    align-items: stretch;
    color: ${(props) => props.theme.mediumText};
    font-size: 1.45rem;
    & > div {
      padding: 1.25rem 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  rule-body {
    transition: height 0.3s;
    height: ${(props) => `${props.height}px`};
    overflow: hidden;
    display: block;
    box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.2);
  }

  rule-content {
    display: block;
    margin-left: calc(20px + (3 * 0.8rem) - 1.25rem);
    padding: 1.25rem;
  }

  .arrow {
    transition: transform 0.3s;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(360deg)")};
  }
`;
