import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const StyledExpandButton = styled.div`
  cursor: pointer;
  background: ${props => props.theme.accentCardBackground};
  color: ${props => props.theme.mediumText};
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 7rem;
  height: 2rem;
  top: 0.5rem;
  border-radius: 0.25rem;
  left: calc(50% - 3.5rem);
  transform: translateY(-6rem);
  transition: transform 0.3s;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);

  &:hover {
    transform: translateY(0);
  }

  svg {
    font-size: 1rem;
    margin-left: 0.4rem;
  }
`;

export const ExpandButton = ({ isExpanded }) => {
  return (
    <StyledExpandButton className="expand-button">
      {isExpanded ? "Collapse" : "Expand"}
      <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
    </StyledExpandButton>
  );
};
