import * as React from "react";

function dispatchStorageEvent(key, newValue) {
  window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
}

const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

const setLocalStorageItem = (key, value) => {
  const stringifiedValue = typeof value !== "string" ? JSON.stringify(value) : value;
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const getLocalStorageItem = (key) => {
  return window.localStorage.getItem(key);
};

export const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = React.useState(getLocalStorageItem(key));

  const setState = React.useCallback(
    (v) => {
      try {
        const nextState = typeof v === "function" ? v(value) : v;

        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key);
        } else {
          setLocalStorageItem(key, nextState);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, value]
  );

  React.useEffect(() => {
    function callback() {
      const item = getLocalStorageItem(key);

      if (item) {
        setValue(item);
      }
    }

    window.addEventListener("storage", callback);

    return () => {
      window.removeEventListener("storage", callback);
    };
  }, [key]);

  React.useEffect(() => {
    if (getLocalStorageItem(key) === null && typeof initialValue !== "undefined") {
      setValue(initialValue);
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [value, setState];
};
