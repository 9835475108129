import React from "react";
import styled from "styled-components";
import { Spinner } from "./Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const StyledDropdown = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  border-radius: 0.25rem;
  border: ${(props) => props.secondary && `1px solid ${props.theme.headerOutline}`};
  color: ${(props) => props.theme.text};
  font-size: 1.33rem;
  box-sizing: border-box;
  height: 2.7rem;
  width: 10rem;
  min-width: 10rem;
  background: ${(props) => !props.secondary && props.theme.secondaryButtonColor};
  transition: all 0.3s;
  &:hover {
    background: ${(props) => !props.secondary && props.theme.secondaryButtonHover};
    border: ${(props) => props.secondary && `1px solid ${props.theme.secondaryButtonColor}`};
  }
  select {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background: transparent !important;
    outline: none;
    border: none;
    appearance: none;
    color: transparent;
    margin: 0;
    & > option {
      background: ${(props) => props.theme.cardBackground};
      color: ${(props) => props.theme.text};
      font-size: 1.33rem;
      height: 30px;
      padding: 10px;
    }
  }
  label {
    background: transparent;
    font-weight: normal;
    margin: 0;
    line-height: 1.2;
    border: none;
    color: ${(props) => props.color || (props.secondary ? props.theme.text : props.theme.buttonText || props.theme.text)};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 0.8rem;
    }
  }
  .spinner {
    transform: scale(0.4);
    top: 0;
    height: 0;
    position: absolute;
    svg {
      margin: 1.2rem;
      circle {
        stroke-opacity: 1;
      }
    }
  }
`;

export const Dropdown = ({
  ariaLabel = "Dropdown",
  children,
  label,
  callback,
  isLoading,
  defaultValue = "----",
  className,
  secondary,
  style,
  showDrop = true,
}) => {
  return (
    <StyledDropdown className={className} secondary={secondary} style={style} color={style?.color}>
      {isLoading && <Spinner />}
      {!isLoading && (
        <label>
          {label}
          {showDrop && <FontAwesomeIcon icon={faChevronDown} />}
          <select onChange={(e) => callback(e.target.value)} aria-label={ariaLabel}>
            {defaultValue && <option value="">{defaultValue}</option>}
            {children}
          </select>
        </label>
      )}
    </StyledDropdown>
  );
};
