import React, { useEffect, useState } from "react";
import { Spinner, Error } from "lib/helpers/fetchData";
import { useSearchResults, MATCHED_ELEMENT_CLASSNAME } from "lib/helpers/useSearchResults";
import { StyledBody, StyledPart } from "apps/phish/tickets/single-ticket/components/Body";
import { Searchbar } from "lib/components/Searchbar";
import { Tooltip } from "lib/components/Tooltip";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { ScrollContainer } from "lib/components/ScrollContainer";

export const Preview = ({ data: artifact }) => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!data) {
      const headers = {
        "Content-Type": "text/plain",
      };

      fetch(artifact?.properties?.location?.url, { headers })
        .then((d) => d.text())
        .then(setData);
    }
  }, [artifact, data]);

  let parts = [],
    results = [];
  if (data) {
    parts = search.length > 1 ? data.split(new RegExp(`(${search})`, "gi")) : [data];
    results = parts.filter((part) => part.toLowerCase() === search.toLowerCase());
  }

  const [index, offset] = useSearchResults(search, results);

  if (!data) return <Spinner />;
  if (data.error) return <Error />;

  return (
    <StyledBody offset={offset}>
      <Searchbar setSearch={(s) => setSearch(s)} style={{ overflow: "unset" }}>
        <p style={{ margin: "0 0.5rem" }}>{results.length ? `${index + 1}/${results.length}` : ""}</p>
        <a href={artifact?.properties?.location?.url || "/oh-dear"} target="_blank" rel="noopener noreferrer">
          <Tooltip xHoverPos="left" yHoverPos="bottom" hoverText="Open in new tab" icon={faExternalLinkAlt} />
        </a>
      </Searchbar>
      <div>
        <ScrollContainer scrollX="auto" style={{ padding: "1.5rem" }}>
          <p style={{ whiteSpace: "pre-wrap" }}>
            {parts.map((part, i) => {
              const isMatch = part.toLowerCase() === search.toLowerCase();
              return (
                <StyledPart key={i} className={isMatch && MATCHED_ELEMENT_CLASSNAME}>
                  {part}
                </StyledPart>
              );
            })}
          </p>
        </ScrollContainer>
      </div>
    </StyledBody>
  );
};
