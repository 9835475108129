import React from "react";
import styled from "styled-components";
import moment from "moment";

import { Table } from "lib/components/Table";
import { Tooltip } from "lib/components/Tooltip";

import { getEventKey } from "./Events";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ScrollContainer } from "lib/components/ScrollContainer";

const StyledContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.cardOutline};
  overflow: hidden;
`;

export const ListEvents = ({ events, setEvents, readOnly }) => {
  const removeEvent = (eventId) => {
    const newEvents = events.filter((e) => e.id !== eventId);
    setEvents(newEvents);
  };

  if (!events.length) return <p style={{ fontSize: "1.33rem", margin: 0, width: "100%", textAlign: "center" }}>No detailed events have been added</p>;

  return (
    <StyledContainer>
      <ScrollContainer style={{ maxHeight: "40rem", width: "100%" }}>
        <Table style={{ border: "none", borderRadius: 0 }}>
          <thead>
            <tr>
              <th>User</th>
              <th>Event Type</th>
              <th>Timestamp</th>
              {!readOnly && <th />}
            </tr>
          </thead>
          <tbody>
            {events
              .sort((a, b) => (moment(a.date).unix() - moment(b.date).unix() > 0 ? 1 : -1))
              .map((e) => (
                <tr key={getEventKey(e)}>
                  <td>{e.user}</td>
                  <td>{e.type}</td>
                  <td>{moment(e.date).format("HH:mm YYYY-MM-DD")}</td>
                  {!readOnly && (
                    <td style={{ overflow: "visible" }}>
                      <Tooltip hoverText="Delete event" xHoverPos="left" icon={faTrash} clickAction={() => removeEvent(e.id)} />
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </ScrollContainer>
    </StyledContainer>
  );
};
