import React from "react";
import styled from "styled-components";

const StyledHistoryContainer = styled.div`
  & > div {
    display: flex;
  }
  & > .no-results {
    font-size: 1.5rem;
    color: ${props => props.theme.text};
    text-align: center;
  }
`;

export const History = ({ data }) => (
  <StyledHistoryContainer>
    {data.history.length ? (
      data.history.map(history => <div key={history.id}>{history.text}</div>)
    ) : (
      <p className="no-results">No historical mutations detected</p>
    )}
  </StyledHistoryContainer>
);
