import React, { useState } from "react";
import moment from "moment";
import { faHome, faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebouncedCallback } from "use-debounce/lib";
import { ErrorModal } from "lib/components/Modal";

import { StyledFilters } from "lib/styles/general";

import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { Table } from "lib/components/Table";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { useInfiniteScroll } from "lib/components/InfiniteScroll";

import { AddUserModal } from "./AddUser";
import { UserModal } from "./SingleUser";

export const Users = ({ customerId, group, isUserManagementEnabled }) => {
  const [data, setData] = useState({ result: [] });
  const [params, setParams] = useState({ search: "", pageSize: 40 });
  const [showAddUsersModal, setShowAddUserModal] = useState(false);
  const [usersModal, setUserModal] = useState({ showModal: false, user: {} });
  const [error, setError] = useState(null);

  const url = `${process.env.REACT_APP_ADMIN_API}/users/${customerId}`;
  const { loadItems, hasMore, isLoading, reload } = useInfiniteScroll({ url, params, data, setData });

  const handleShowAddUser = () => setShowAddUserModal(true);
  const handleHideAddUser = () => setShowAddUserModal(false);

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  const filters = (
    <StyledFilters style={{ display: "flex" }}>
      <Searchbar style={{ margin: 0 }} {...search} />
      <hr />
      <CTAButton onClick={handleShowAddUser}>Add User</CTAButton>
    </StyledFilters>
  );

  if (isUserManagementEnabled) {
    const clarityUrl = process.env.REACT_APP_CLARITY_URL;
    const encodedGroup = encodeURIComponent(group);
    return (
      <div>
        <h1>User management is enabled for this customer. Users should be managed from Clarity.</h1>
        <CTAButton onClick={() => window.open(`${clarityUrl}/user-management?group=${encodedGroup}`, "_blank", "noopener")}>
          Clarity App
          <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faExternalLinkSquareAlt} />
        </CTAButton>
      </div>
    );
  }

  return (
    <div>
      <InfiniteScroll filters={filters} loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data} useWindow={false}>
        {data.result.length ? (
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Last Login</th>
              </tr>
            </thead>
            <tbody>
              {data.result.map((user, i) => (
                <User key={i} user={user} setModal={setUserModal} customerId={customerId} />
              ))}
            </tbody>
          </Table>
        ) : (
          <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No users</p>
        )}
      </InfiniteScroll>
      <UserModal
        key={usersModal.user?.id}
        {...usersModal}
        hide={() => setUserModal({ showModal: false })}
        reload={reload}
        customerId={customerId}
      />
      {showAddUsersModal && (
        <AddUserModal
          hide={handleHideAddUser}
          key={data.result.length}
          existingUsers={data.result}
          reload={reload}
          customerId={customerId}
          showModal={showAddUsersModal}
        />
      )}
      {error !== null && <ErrorModal error={error} hide={() => setError(null)} />}
    </div>
  );
};

const User = ({ user, setModal, customerId }) => {
  const handleUserClick = () => {
    setModal({ showModal: true, user });
  };

  return (
    <tr style={{ cursor: "pointer" }} onClick={handleUserClick}>
      <td style={{ fontSize: "1.2rem" }}>{customerId === user?.customer?.id && <FontAwesomeIcon icon={faHome} />}</td>
      <td>{user.displayName}</td>
      <td>{user.email}</td>
      <td>{user.lastLogin ? moment(user.lastLogin * 1000).fromNow() : "Never"}</td>
    </tr>
  );
};
