import React from "react";
import { useParams } from "react-router-dom";
import { fetchData, useFetch } from "lib/helpers/fetchData";

import { Navbar } from "lib/components/Navbar";
import { ArtifactContent } from "./components/Content";

const loadArtifact = async ({ artifactId }) => {
  const artifactPromise = fetchData(`${process.env.REACT_APP_PHISH_API}/artifacts/${artifactId}`);
  const relatedTicketsPromise = fetchData(`${process.env.REACT_APP_PHISH_API}/artifacts/${artifactId}/related/stats`);
  return await Promise.all([artifactPromise, relatedTicketsPromise]);
};

export const SingleArtifact = ({ isTicket = true }) => {
  const { ticketId, customerId, artifactId } = useParams();
  const state = useFetch(loadArtifact, { artifactId });

  // Path used when accessing artifact from ticket
  const ticketPaths = [
    { text: "Tickets", link: "/phish/tickets" },
    { text: "Ticket " + (sessionStorage.getItem("last_ticket") || ""), link: "#", goBack: true },
  ];

  // Path used when accessing artifact from ioc list
  const iocPaths = [{ text: "Artifacts", link: "/phish/artifacts" }];

  // Path always used to show current artifact
  const artifactPath = [{ text: "Artifact " + (state.data?.[0]?.result?.fid || ""), link: "#" }];

  // Combine the appropriate paths together based on whether this is a ticket artifact
  const paths = [...(isTicket ? ticketPaths : iocPaths), ...artifactPath];

  return (
    <>
      <Navbar paths={paths} />
      <ArtifactContent {...state} ticketId={ticketId} customerId={customerId} artifactId={artifactId} />
    </>
  );
};
