import { useState, useRef } from "react";
import styled, { css, keyframes } from "styled-components";

import { Editor } from "./components/Editor";

import { ButtonTheme } from "./components/theme";
import { TimeRangeFilter, WorkspaceFilter } from "./components/Filter";
import { fetchData } from "lib/helpers/fetchData";
import { Rules } from "./components/StyledTableComponents";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 25rem;
  justify-content: flex-start;
`;

const ErrorContainer = styled.div`
  margin-top: 1rem;
`;

const ErrorItem = styled.div`
  color: ${ButtonTheme.errorText};
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const Spinner = styled.div`
  cursor: not-allowed;
  pointer-events: none;
  color: ${(props) => props.theme.mediumText};
  &:after {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
    vertical-align: -0.15em;
    border-radius: 50%;
    border: 2px solid ${ButtonTheme.mediumText};
    border-top-color: transparent;
    border-right-color: transparent;
    animation: ${spin} 0.6s linear infinite;
  }
`;

const StyledFilter = styled.div`
  position: relative;
  justify-content: flex-start;
  margin-right: 10rem;
  width: auto;

  .workspace-filter {
    margin-bottom: 3rem;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 2vh;
`;

const CancelButton = styled.a`
  position: absolute;
  width: 65px;
  left: 0; /* Align to the left edge */
  box-sizing: border-box;
  font-size: 1.25rem;
  padding: 0.8rem 1rem;
  margin: 0;
  background: ${(props) => props.theme.primaryButtonColor};
  border: 1px solid ${(props) => props.theme.headerOutline};
  color: ${(props) => props.theme.mediumText};
  border-radius: 0.45rem;
  text-decoration: none;
  transition: background 0.3s;
  z-index: 1; /* Place the CancelButton above the SubmitButton */
  &:hover {
    background: ${ButtonTheme.primaryButtonColor};
  }
  cursor: pointer;
  ${(props) =>
    props.loading &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      &:after {
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-left: 0.5em;
        vertical-align: -0.15em;
        border-radius: 50%;
        border: 2px solid ${ButtonTheme.mediumText};
        border-top-color: transparent;
        border-right-color: transparent;
        animation: ${spin} 0.6s linear infinite;
      }
    `}
`;

const SubmitButton = styled.a`
  box-sizing: border-box;
  font-size: 1.25rem;
  padding: 0.8rem 1rem;
  margin-right: 10rem;
  margin: 0;
  background: ${(props) => props.theme.primaryButtonColor};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.45rem;
  color: ${(props) => props.theme.mediumText};
  text-decoration: none;
  transition: background 0.3s;
  position: relative; /* Position relative to allow for proper overlapping */
  z-index: 0; /* Lower z-index to be behind the CancelButton */
  &:hover {
    background: ${ButtonTheme.primaryButtonColor};
  }
  cursor: pointer;
  ${(props) =>
    props.loading &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      &:after {
        content: "";
        display: inline-block;
        height: 1em;
        margin-left: 0.5em;
        vertical-align: -0.15em;
        border-radius: 50%;
        border: 2px solid ${ButtonTheme.mediumText};
        border-top-color: transparent;
        border-right-color: transparent;
        animation: ${spin} 0.6s linear infinite;
      }
    `}
`;

const submitQuery = async (body) => {
  const response = await fetchData(`${process.env.REACT_APP_SOC_API}/query`, JSON.stringify(body), "POST");
  return response;
};

const handleSubmitClick = async (
  editorRef,
  selectedWorkspaces,
  selectedTimeRange,
  setLoading,
  setErrorMessages,
  setTableData,
  setSubmitClicked,
  isCancelledRef,
  setCancellationCompleted,
  setQueryCompleted
) => {
  setQueryCompleted(false);
  isCancelledRef.current = false;
  setCancellationCompleted(false);
  const query = editorRef;
  const customerIds = selectedWorkspaces.map((option) => option.value); // Update this line
  const selectedTimeSpan = selectedTimeRange ? selectedTimeRange.value : null;

  // Clear previous error messages and table data
  setErrorMessages([]);
  setTableData([]);

  setSubmitClicked(true);
  setLoading(true);

  const batchSize = 10;

  const processBatch = async (batchCustomerIds) => {
    const batchPromises = batchCustomerIds.map(async (customerId) => {
      const requestBody = {
        query: query,
        customers: [customerId],
        timeSpan: selectedTimeSpan,
        parse: false,
      };

      try {
        const response = await submitQuery(requestBody);
        const customerResults = response.result.map((item) => ({ item }));
        let rows = [];
        let columns = [];
        const splitRows = [];
        if (!customerResults[0].item.result) {
          rows = [customerResults[0].item.message];
        } else {
          rows = customerResults[0].item.result.tables[0].rows; // Extract rows
          columns = customerResults[0].item.result.tables[0].columns; // Extract columns
        }
        // split rows into pages of 25
        const pages = Math.ceil(rows.length / 50);
        for (let i = 0; i < pages; i++) {
          const chunk = rows.slice(i * 50, (i + 1) * 50);
          splitRows.push(chunk);
        }
        const originalLength = rows.length;
        const customer = customerResults[0].item.customer; // Extract customer
        const newItem = {
          item: {
            customer: customer,
            result: {
              tables: [{ rows: [splitRows], columns: columns }],
            },
            originalLength,
            pages,
          },
        };
        setTableData((prevTableData) => [...prevTableData, newItem]);
        return rows.length; // Return the original response row count
      } catch (error) {
        setErrorMessages((prevErrors) => [...prevErrors, error.message]);
        return 0; // Return 0 for failed responses
      }
    });

    // Execute promises in the current batch and update originalLength
    await Promise.allSettled(batchPromises);
    setSubmitClicked(true);
  };

  // Process customers in batches
  for (let i = 0; i < customerIds.length; i += batchSize) {
    if (isCancelledRef.current) {
      setCancellationCompleted(true);
      break; // Stop processing batches if cancelled
    }

    const batchCustomerIds = customerIds.slice(i, i + batchSize);
    await processBatch(batchCustomerIds);

    if (i + batchSize < customerIds.length) {
      // Wait 1 second between batches
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  setLoading(false);
  setSubmitClicked(false);
  setQueryCompleted(true);
};

const handleCancelClick = async (isCancelledRef, setSubmitClicked, setQueryCompleted) => {
  isCancelledRef.current = true;
  setSubmitClicked(false); // Reset submitClicked to hide the Cancel button
};

export const QueryEngine = () => {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const isCancelledRef = useRef(false);
  const [queryCompleted, setQueryCompleted] = useState(false);
  const [cancellationCompleted, setCancellationCompleted] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  const [selectedTimeRange, setSelectedTimeRange] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <div>
      <Container>
        <StyledFilter>
          <WorkspaceFilter selectedWorkspaces={selectedWorkspaces} setSelectedWorkspaces={setSelectedWorkspaces} />
          <TimeRangeFilter selectedTimeRange={selectedTimeRange} setSelectedTimeRange={setSelectedTimeRange} />
        </StyledFilter>
        <div>
          <Editor ref={editorRef} />
          <StyledContainer>
            <SubmitButton
              onClick={() =>
                handleSubmitClick(
                  editorRef.current.getValue(),
                  selectedWorkspaces,
                  selectedTimeRange,
                  setLoading,
                  setErrorMessages,
                  setTableData,
                  setSubmitClicked,
                  isCancelledRef,
                  setCancellationCompleted,
                  setQueryCompleted
                )
              }
              className="submit"
            >
              Submit
            </SubmitButton>
            {submitClicked && (
              <CancelButton onClick={() => handleCancelClick(isCancelledRef, setSubmitClicked, setQueryCompleted)} className="cancel">
                Cancel
              </CancelButton>
            )}
            {loading && (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
            {queryCompleted && !cancellationCompleted && <div style={{ marginLeft: "1rem", color: "#ffffff" }}>Query Completed!</div>}
            {cancellationCompleted && <div style={{ marginLeft: "1rem", color: "#ffffff" }}>Cancellation Completed!</div>}
            {errorMessages.length > 0 && (
              <ErrorContainer>
                {errorMessages.map((message, index) => (
                  <ErrorItem key={index}>{message}</ErrorItem>
                ))}
              </ErrorContainer>
            )}
          </StyledContainer>
        </div>
      </Container>
      <Rules name="Query" data={tableData} />
    </div>
  );
};
