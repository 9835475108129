export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const toBinaryString = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const imageURLToBase64 = async (url, outputFormat = "image/png") => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL(outputFormat).split("base64,")[1];
      resolve(dataURL);
      canvas = null;
    };
    img.src = url;
  });
};

export const toBase64URI = ({ data, contentType }) => {
  return `data:${contentType};base64,${data}`;
};
