import styled from "styled-components";

import { CTAButton } from "lib/components/CTAButton";
import { KeyValue } from "lib/components/KeyValue";
import { Modal } from "lib/components/Modal";
import { Input } from "lib/components/Input";
import { TextArea } from "lib/components/TextArea";
import { useRef, useState } from "react";
import { StyledForm } from "lib/styles/general";
import { getFormValues } from "lib/helpers/getFormValues";
import { ErrorModal } from "lib/components/Modal";
import { Select } from "lib/components/Select";
import { PRIORITY_NAMES } from "../components/constants";
import { fetchData } from "lib/helpers/fetchData";

const updateScope = ({ scope, id }) => {
  const scopePriority = parseInt(scope);
  if (isNaN(scope)) throw new Error("Not a valid number");

  const body = JSON.stringify({ scopePriority });
  return fetchData(`${process.env.REACT_APP_SERVICE_DELIVERY_API}/projects/${id}`, body, "PUT");
};

const updateStatus = ({ status, id }) => {
  if (!status) throw new Error("Not a valid status");

  const body = JSON.stringify({ status });
  return fetchData(`${process.env.REACT_APP_SERVICE_DELIVERY_API}/projects/${id}`, body, "PUT");
};

const StyledContainer = styled.div`
  display: flex;
  margin: 0 0.5rem 1.5rem;
  gap: 1rem;

  & > .key-value {
    flex: 1;
    margin: 0;
    z-index: 100;
    align-items: flex-start;

    .text {
      -webkit-line-clamp: unset;
      white-space: pre-wrap;
      margin: 0.3rem 0 0 0;
      word-break: break-word;
    }
  }

  & > .actions {
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    flex-flow: column nowrap;
    border-left: 1px solid ${(props) => props.theme.headerOutline};
    filter: ${(props) =>
      `drop-shadow(-5rem 2rem 5rem ${props.theme.pageBackground}) drop-shadow(5rem -2rem 3rem ${props.theme.pageBackground})`};
    z-index: 100;
    min-height: 6rem;
    min-width: 15rem;

    & > button {
      width: 15rem;
      margin: 0;
    }

    &::after {
      content: "ARCHIVED";
      color: ${(props) => props.theme.mediumText};
      font-size: 2rem;
      font-weight: bold;
      transform: rotate(-20deg);
      text-shadow: 0 0 1rem ${(props) => props.theme.pageBackground};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      display: ${(props) => (props.archived ? "flex" : "none")};
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Evaluate = ({ project, refresh }) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showScopeModal, setShowScopeModal] = useState(false);

  return (
    <StyledContainer archived={project.isArchived}>
      <KeyValue text="Status" value={project.status || "No status provided yet."} />
      <div className="actions">
        {!project.isArchived && (
          <>
            <CTAButton isSecondary onClick={() => setShowStatusModal(true)}>
              Change status
            </CTAButton>
            <CTAButton isSecondary onClick={() => setShowScopeModal(true)}>
              Change scope RAG
            </CTAButton>
          </>
        )}
      </div>
      <StatusModal
        refresh={refresh}
        project={project}
        callback={updateStatus}
        showModal={showStatusModal}
        setShowModal={setShowStatusModal}
      />
      <ScopeModal refresh={refresh} project={project} callback={updateScope} showModal={showScopeModal} setShowModal={setShowScopeModal} />
    </StyledContainer>
  );
};

const StatusModal = ({ project, showModal, setShowModal, callback, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const formRef = useRef(null);

  const hide = () => setShowModal(false);

  const handleCallback = async () => {
    setIsLoading(true);
    const { status } = getFormValues(formRef.current);

    try {
      await callback({ status, id: project.id });
      refresh();
      hide();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal width="40rem" title="Change Status" isLoading={isLoading} showModal={showModal} actionCommand={handleCallback} hide={hide}>
      <StyledForm ref={formRef}>
        <Input label="Status">
          <TextArea name="status" defaultValue={project.status} />
        </Input>
      </StyledForm>
      {error && <ErrorModal error={error} hide={() => setError(false)} />}
    </Modal>
  );
};

const ScopeModal = ({ project, showModal, setShowModal, callback, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const formRef = useRef(null);

  const hide = () => setShowModal(false);

  const handleCallback = async () => {
    setIsLoading(true);
    const { scope } = getFormValues(formRef.current);

    try {
      await callback({ scope, id: project.id });
      refresh();
      hide();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal width="40rem" title="Change Scope" isLoading={isLoading} showModal={showModal} actionCommand={handleCallback} hide={hide}>
      <StyledForm ref={formRef}>
        <Input label="Scope">
          <Select name="scope" defaultValue={project.scopePriority}>
            <option value="">Select priority</option>
            <option value="0">{PRIORITY_NAMES[0]}</option>
            <option value="1">{PRIORITY_NAMES[1]}</option>
            <option value="2">{PRIORITY_NAMES[2]}</option>
          </Select>
        </Input>
      </StyledForm>
      {error && <ErrorModal error={error} hide={() => setError(false)} />}
    </Modal>
  );
};
