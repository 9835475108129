import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Card } from "./Card";
import { CTAButton } from "./CTAButton";
import { ScrollContainer } from "./ScrollContainer";
import { Spinner, Error } from "lib/helpers/fetchData";
import { Searchbar } from "./Searchbar";

export const ListPane = ({
  listState,
  listKey = "name",
  listName,
  listWidth,
  isInside = false,
  hasSearch = false,
  sort = false,
  childrenProps = {},
  listLeftComponent,
  listRightComponent,
  upsertPane: UpsertPane,
  singleItemPane: SingleItemPane,
}) => {
  const [selectedId, setSelectedId] = useState(null);
  const [isCreation, setIsCreation] = useState(false);
  const [editData, setEditData] = useState(null);

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!listState.isLoading && listState.data) {
      if (sort) {
        setList(
          [...(listState.data?.result || [])].sort((a, b) => {
            var _a = a?.[listKey]?.toUpperCase();
            var _b = b?.[listKey]?.toUpperCase();
            return _a < _b ? -1 : _a > _b ? 1 : 0;
          })
        );
      } else {
        setList([...(listState.data?.result || [])]);
      }
    }
  }, [listState.data, listState.isLoading, listKey, sort]);

  const selectCustomer = (id) => {
    setSelectedId(id);
    setIsCreation(false);
    const listItemNode = document.getElementById(id);
    if (listItemNode) listItemNode.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
  };

  const startCreation = () => {
    setSelectedId(-1);
    setEdit(null);
  };

  const endCreation = (id) => {
    const firstItem = list[0].id !== -1 ? list[0] : list[1];
    selectCustomer(id || firstItem.id);
  };

  const setEdit = (data) => {
    setEditData(data);
    setIsCreation(true);
  };

  return (
    <StyledContainer className={isInside && "inside"}>
      <List
        {...listState}
        list={list}
        listWidth={listWidth}
        listKey={listKey}
        listName={listName}
        listLeftComponent={listLeftComponent}
        listRightComponent={listRightComponent}
        selectedId={selectedId}
        handleSelect={selectCustomer}
        handleCreate={startCreation}
        hasSearch={hasSearch}
        sort={sort}
      />
      {isCreation ? (
        <UpsertPane reload={listState.reload} data={editData} endCreation={endCreation} />
      ) : selectedId === null ? (
        <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
          <Spinner />
        </div>
      ) : (
        <SingleItemPane
          name={listState.data.result.find((d) => d.id === selectedId)?.name}
          data={listState.data.result.find((d) => d.id === selectedId)}
          selectedId={selectedId}
          reload={listState.reload}
          endCreation={endCreation}
          setEdit={setEdit}
          key={selectedId}
          {...childrenProps}
        />
      )}
    </StyledContainer>
  );
};

const List = ({
  data,
  isLoading,
  error,
  list,
  hasSearch,
  listLeftComponent,
  listRightComponent,
  listKey,
  listName,
  listWidth,
  selectedId,
  handleSelect,
  handleCreate,
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (list.length && selectedId === null) {
      const firstItem = list[0]?.id;
      firstItem && handleSelect(firstItem);
    }
  }, [list, selectedId, handleSelect]);

  const filteredList = list?.filter((d) => d[listKey]?.toLowerCase()?.includes(search.toLowerCase()));

  const renderList = () => {
    if (isLoading && !data) return <Spinner />;
    if (error || data?.success === false) return <Error message={error} />;

    return (
      <>
        {hasSearch && <Searchbar setSearch={setSearch} />}
        <ScrollContainer style={{ width: "100%" }}>
          {filteredList?.length > 0 ? (
            filteredList.map((item) => (
              <StyledListItem id={item.id} key={item.id} active={item.id === selectedId} onClick={() => handleSelect(item.id)}>
                {listLeftComponent && listLeftComponent(item)}
                <p className="title">{item[listKey]}</p>
                {listRightComponent && listRightComponent(item)}
              </StyledListItem>
            ))
          ) : (
            <p className="no-results">No results</p>
          )}
        </ScrollContainer>
        {listName && (
          <>
            <span />
            <CTAButton onClick={handleCreate}>
              <FontAwesomeIcon icon={faPlus} />
              {listName}
            </CTAButton>
          </>
        )}
      </>
    );
  };

  return <StyledList width={listWidth}>{renderList()}</StyledList>;
};

const StyledContainer = styled(Card)`
  display: flex;
  padding: 0;
  height: calc(100vh - 43px - 4rem);
  margin-bottom: -24rem;
  overflow: hidden;
  &.inside {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }
  & > div {
    flex-flow: row nowrap;
  }
`;

const StyledList = styled.div`
  min-width: ${(props) => (props.width ? `${props.width}px` : "320px")};
  max-width: ${(props) => (props.width ? `${props.width}px` : "320px")};
  border-right: 1px solid ${(props) => props.theme.headerOutline};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: calc((100vh - 43px) - 4rem);

  #searchbar {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.headerOutline};
    margin: 0;
  }
  .no-results {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
  }
  span {
    flex: 1;
  }
  button {
    margin: 1.2rem 1rem;
    width: calc(100% - 2rem);
    text-transform: capitalize;
    svg {
      margin: 0 5px;
    }
  }
`;

const StyledListItem = styled.div`
  cursor: pointer;
  padding: 1rem 1.25rem;
  transition: background 0.3;
  background: ${(props) => props.theme.cardBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > .title {
    flex: 1;
    font-size: 1.33rem;
    font-weight: ${(props) => props.active && "bold"};
    color: ${(props) => props.active && props.theme.text};
    margin: 0;
    user-select: none;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.headerOutline};
  }
  &:hover {
    background: ${(props) => props.theme.accentCardBackground};
  }
`;

export const StyledContentContainer = styled(ScrollContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  & > .body {
    flex: 1;
    width: 100%;
    margin: 0 auto;
    padding: 4rem 3rem 2rem;
    box-sizing: border-box;
    &.small {
      max-width: 65rem;
    }
    &.medium {
      max-width: 100rem;
    }
    &.large {
      max-width: 135rem;
    }
  }
  & > .footer,
  & > .header {
    position: sticky;
    background: ${(props) => props.theme.cardBackground};
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    & > .title {
      margin: auto 0;
    }
    & > .actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
  & > .header {
    top: 0;
    border-bottom: 1px solid ${(props) => props.theme.headerOutline};
  }
  & > .footer {
    bottom: 0;
    border-top: 1px solid ${(props) => props.theme.headerOutline};
  }
`;
