import styled from "styled-components";

import { CheckboxButton } from "lib/components/Checkbox";
import { Searchbar } from "lib/components/Searchbar";
import { toSentenceCase } from "lib/helpers/toSentenceCase";
import { StyledFilters } from "lib/styles/general";
import { useDebouncedCallback } from "use-debounce/lib";
import { ProjectBox } from "./ProjectBox";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { Route } from "react-router-dom";
import { SingleProject } from "../single-project";
import { useState } from "react";

const StyledProjectsGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
  grid-auto-rows: 14rem;
  grid-auto-flow: row;
`;

const StyledButtonFilters = styled.div`
  display: flex;
  gap: 1rem;
  & > label {
    padding: 0 0.75rem;
    border-color: ${(props) => props.theme.cardOutline};
  }
`;

const MetricsButton = ({ name, metrics, setMetrics }) => {
  return (
    <CheckboxButton
      onChange={() => setMetrics((m) => ({ ...m, [name]: !m[name] }))}
      defaultChecked={metrics[name]}
      label={toSentenceCase(name)}
    />
  );
};

export const ProjectsList = ({ projectsData, refresh, filters, setFilter, getClient, getProjectManager }) => {
  const [metrics, setMetrics] = useState({ time: true, budget: true, scope: true });

  const [searchCallback] = useDebouncedCallback((search) => setFilter({ search }), 500);

  const search = {
    search: filters.search,
    setSearch: (search) => searchCallback(search),
  };

  const filterComponents = (
    <StyledFilters>
      <Searchbar {...search} placeholder="Search projects" />
      <hr />
      <StyledButtonFilters>
        <MetricsButton name="time" metrics={metrics} setMetrics={setMetrics} />
        <MetricsButton name="budget" metrics={metrics} setMetrics={setMetrics} />
        <MetricsButton name="scope" metrics={metrics} setMetrics={setMetrics} />
      </StyledButtonFilters>
    </StyledFilters>
  );

  return (
    <>
      <InfiniteScroll filters={filterComponents} {...projectsData}>
        <StyledProjectsGrid>
          {projectsData?.data?.result?.map((project) => (
            <ProjectBox key={project.id} project={project} metrics={metrics} getClient={getClient} getProjectManager={getProjectManager} />
          ))}
        </StyledProjectsGrid>
      </InfiniteScroll>
      <Route path="/service-delivery-portfolio/projects/:projectId">
        <SingleProject getClient={getClient} getProjectManager={getProjectManager} metrics={metrics} refresh={refresh} />
      </Route>
    </>
  );
};
