import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { UserProfileContext } from "auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt, faLockOpen, faUsers, faUserNinja, faComments, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} 0.6s ease-out forwards;
  margin-top: 30vh; // Adjust the top margin to center vertically

  a {
    width: 80%;
    box-sizing: border-box;
    font-size: 1.25rem;
    padding: 0.8rem 1rem;
    margin: 0;
    background: ${(props) => props.theme.primaryButtonColor};
    border: 1px solid ${(props) => props.theme.headerOutline};
    border-radius: 0.45rem;
    color: ${(props) => props.theme.mediumText};
    text-decoration: none;
    transition: background 0.3s;
    &:hover {
      background: ${(props) => props.theme.primaryButtonHover};
    }
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 60%;
  margin-bottom: 1rem;
`;

const StyledGreeting = styled.div`
  width: 100%;
  margin: -100px 0 20px 0;
  h1 {
    margin: 0;
    font-size: 3rem;
    color: ${(props) => props.theme.text};
  }
  h2 {
    margin: 0;
    font-size: 1.33rem;
    color: ${(props) => props.theme.accentText};
  }
`;

const margin = {
  margin: "0.5rem 0",
};

export const Home = () => {
  const { user } = useContext(UserProfileContext);

  return (
    <StyledContainer>
      <StyledGreeting>
        <h2>Welcome</h2>
        <h1>{user.displayName}</h1>
      </StyledGreeting>
      <RowContainer>
        <Link to="/customers">
          <FontAwesomeIcon icon={faUsers} size="3x" />
          <br style={margin} />
          Customers
        </Link>
        <Link to="/soc">
          <FontAwesomeIcon icon={faShieldAlt} size="3x" />
          <br style={margin} />
          Security Operations Center
        </Link>
        <Link to="/pentest">
          <FontAwesomeIcon icon={faLockOpen} size="3x" />
          <br style={margin} />
          Pen Testing
        </Link>
      </RowContainer>
      <RowContainer>
        <Link to="/phish">
          <FontAwesomeIcon icon={faEnvelope} size="3x" />
          <br style={margin} />
          Phish
        </Link>
        <Link to="/phish-sim">
          <FontAwesomeIcon icon={faUserNinja} size="3x" />
          <br style={margin} />
          Phishing Simulation
        </Link>
        <Link to="/release-publishing">
          <FontAwesomeIcon icon={faComments} size="3x" />
          <br style={margin} />
          Release Publishing
        </Link>
      </RowContainer>
    </StyledContainer>
  );
};
