import React from "react";

import { Table } from "lib/components/Table";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { RadioIcon } from "lib/styles/general";
import { faBan, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "lib/components/Tooltip";

export const ListTemplates = ({ selected, setSelected, templates = [], customer }) => {
  const isBanned = (t = {}) => t.isPrivate && t.customer?.id !== customer;

  const publicTemplates = templates?.filter(t => !t?.isPrivate).sort((a, b) => a?.customer?.id === customer ? -1 : 1);
  const privateToCurrentCustomer = templates?.filter(t => t?.isPrivate && !isBanned(t));
  const privateToOtherCustomer = templates?.filter(t => isBanned(t));

  const sortedTemplates = [...privateToCurrentCustomer, ...publicTemplates, ...privateToOtherCustomer];
  
  return (
    <ScrollContainer className="scroll">
      <Table style={{ border: "none", borderRadius: 0 }} tableStyle={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th style={{ width: "1rem" }}></th>
            <th>Template Name</th>
            <th style={{ width: "1rem" }}></th>
            <th style={{ width: "5rem", textAlign: "right" }}>Difficulty</th>
            <th style={{ width: "8rem" }}>Owner</th>
          </tr>
        </thead>
        <tbody>
          <tr onClick={() => setSelected({})}>
            <td>
              <RadioIcon active={!selected.id} />
            </td>
            <td>Create New</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {sortedTemplates.map((t = {}, i) => {
              return (
                <tr key={i} onClick={() => setSelected(t)}>
                  <td>
                    <RadioIcon active={selected.id === t.id} />
                  </td>
                  <td title={t.name}>{t.name}</td>
                  <td style={{ overflow: "visible" }}>{t.isPrivate && <InfoTooltip template={t} customer={customer} />}</td>
                  <td style={{ textAlign: "right" }}>{t.difficulty}/5</td>
                  <td title={t.customer?.name}>{t.customer?.code}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </ScrollContainer>
  );
};

const InfoTooltip = ({ template = {}, customer = {} }) => {
  if (customer === template.customer?.id)
    return (
      <Tooltip
        icon={faUserLock}
        xHoverPos="left"
        hoverText={
          <p>
            {`This template is privately owned by the currently\nselected campaign customer, `}
            <i>{template.customer?.name}.</i>
          </p>
        }
      />
    );

  return (
    <Tooltip
      icon={faBan}
      xHoverPos="left"
      hoverText={
        <p>
          {`This template is privately owned\nby `}
          <i>{template.customer?.name}</i>
        </p>
      }
    />
  );
};
