import React from "react";
import styled, { keyframes } from "styled-components";
import { Error as ErrorSvg } from "../assets/Error";
import { useAsync } from "react-async";

export const getRequestOptions = (
  method = "GET",
  body,
  contentType = "application/json"
) => ({
  headers: {
    "Content-Type": contentType,
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
  method,
  body,
});

export const clearStorage = () => {
  const theme = localStorage.getItem("theme");
  const ui_scale = localStorage.getItem("ui_scale");
  const token = localStorage.getItem("access_token");
  const name = localStorage.getItem("user_name");
  const picture = localStorage.getItem("user_picture");
  const email = localStorage.getItem("user_email");

  localStorage.clear();

  theme && localStorage.setItem("theme", theme);
  ui_scale && localStorage.setItem("ui_scale", ui_scale);
  token && localStorage.setItem("access_token", token);
  name && localStorage.setItem("user_name", name);
  picture && localStorage.setItem("user_picture", picture);
  email && localStorage.setItem("user_email", email);
};

export const useFetch = (...args) => {
  const state = useAsync(...args);
  return state;
};

export const fetchData = async (endpoint, body, method, contentType) => {
  return new Promise(async (resolve, reject) => {
    let response;
    try {
      response = await fetch(
        endpoint,
        getRequestOptions(method, body, contentType)
      );
    } catch (error) {
      return reject(error);
    }
    let json = {};
    if (response.status === 401) {
      localStorage.setItem("redirect", window.location.pathname);
      window.location.href = `${window.location.origin}/logout`;
    }
    if (response.status !== 204) {
      json = await response.json();
    }
    if (!response.ok || (json.hasOwnProperty("success") && !json.success)) {
      return reject(json);
    }
    return resolve(json);
  });
};

const StyledMessage = styled.p`
  color: ${(props) => props.theme.text};
  text-align: center;
  line-height: 1.5;
  font-size: 1.5rem;
  white-space: pre;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: ${fadeIn} 0.6s 0.6s ease-out forwards;
  color: ${(props) => props.theme.lightText};
`;

export const Error = ({ message }) => {
  if (message) console.error(message);
  return (
    <StyledError>
      <ErrorSvg style={{ padding: "2rem 2rem 0", margin: "0 auto" }} />
      {message && (
        <StyledMessage>
          {message?.error?.message ||
            message?.message ||
            JSON.stringify(message)}
          <br />
          <br />
          {`If you've got a second maybe you'd like to report \nthe above issue in the feedback channel by clicking `}
          <a
            href="slack://channel?team=T65FAQK5E&id=CJ9828JG0"
            style={{ color: "#4078c0" }}
          >
            here.
          </a>
        </StyledMessage>
      )}
    </StyledError>
  );
};

export { Spinner } from "../components/Spinner";
