import React, { useState, useContext } from "react";
import styled from "styled-components";

import { Card } from "./Card";
import { ScrollContainer } from "./ScrollContainer";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { getSeverityScoreFromText } from "lib/helpers/getColorFromSeverity";
import { ThemeContext } from "./ThemeProvider";

const StyledContainer = styled.div`
  margin-top: ${(props) => props.space && "2rem"};
  width: 100%;
  box-sizing: border-box;
`;

const StyledTabsContainer = styled(ScrollContainer)`
  display: flex;
  margin: 0 0.8rem;
  border-radius: 0 0.5rem;
`;

const StyledTabItem = styled.div`
  display: flex;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0.66rem 1.16rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-bottom: none;
  color: ${(props) => (props.active ? props.theme.accentText : props.theme.mediumText)};
  font-weight: ${(props) => props.active && "bold"};
  font-size: 1.33rem;
  transition: background 0.3s;
  margin: 0 0.33rem;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-left: 10px;
    padding: 0 0.6rem;
    height: 16px;
    border-radius: 8px;
    background: ${(props) => props.theme.secondaryButtonColor};
    color: ${(props) => props.theme.buttonText || props.theme.text};
    font-weight: bold;
  }
`;

const getTabIndexFromCache = (id) => {
  const tab = sessionStorage.getItem(`tab-${id}`);
  if (id && tab) {
    return parseInt(tab);
  }
  return null;
};

const Counts = ({ count }) => {
  const { theme } = useContext(ThemeContext);

  if (typeof count === "number" || typeof count === "string") return <div>{count}</div>;
  const types = Object.entries(count);
  return (
    <>
      {types.map(([type, number], i) => {
        const color = getColorFromSeverity({ score: getSeverityScoreFromText(type) });
        return (
          <React.Fragment key={i}>
            <span title={`${number} ${type}`} style={{ color: color || theme.lightText, marginLeft: i === 0 ? "1rem" : "0.3rem" }}>
              {number}
            </span>
            {i < types.length - 1 && <span style={{ color: theme.headerOutline, marginLeft: "0.3rem" }}>|</span>}
          </React.Fragment>
        );
      })}
    </>
  );
};

export const Tabs = ({ children, id, includeTopSpacing = true, counts = [], style, subtle, noPad }) => {
  const [tab, setTab] = useState(getTabIndexFromCache(id) || 0);

  const updateTab = (i) => {
    sessionStorage.setItem(`tab-${id}`, i);
    setTab(i);
  };

  const kids = children.flat();

  return (
    <StyledContainer space={includeTopSpacing} style={style}>
      <StyledTabsContainer scrollX="auto">
        {kids.map(
          (item, i) =>
            item?.props?.name && (
              <StyledTabItem key={i} active={tab === i} onClick={() => updateTab(i)}>
                {item.props.name}
                {counts.length > i && <Counts count={counts[i]} />}
              </StyledTabItem>
            )
        )}
      </StyledTabsContainer>
      <Card key={tab} style={{ overflow: "visible", boxShadow: subtle && "none", padding: noPad && 0, border: noPad && "none" }}>
        {kids[tab]}
      </Card>
    </StyledContainer>
  );
};
