export const toSentenceCase = (string = "", separator = " ") => {
  return string
    ?.split(separator)
    ?.map((s) => (s?.[0]?.toString()?.toUpperCase() || "") + (s?.substring(1)?.toString()?.toLowerCase() || ""))
    ?.join(separator) || "";
};

export const toLowerCase = (string = "") => {
  return string?.toString()?.toLowerCase();
};
