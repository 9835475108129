export const getArtifactContent = ({ type, properties }) => {
  switch (type) {
    case "url":
      return properties?.url || "Unspecified";
    case "file":
      return properties?.filename || properties?.name || "Unspecified";
    default:
      return "";
  }
};
