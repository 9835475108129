import { fetchData } from "lib/helpers/fetchData";
import React, { useState, useEffect, useContext } from "react";
import { Switch, useHistory, useLocation } from "react-router-dom";
import { AUTH_STATE } from "./config";
import {
  checkIsAuthenticated,
  createTokenRequestParams,
  decodeToken,
  getAccessToken,
  getErrorFromMessage,
  getUserData,
  handleError,
} from "./helpers";
import { Loading } from "./Loading";
import { Login } from "./Login";
import { useLocalStorage } from "lib/helpers/useLocalStorage";

// Context to access the current user's data (from /me)
export const UserProfileContext = React.createContext(null);
// Context to access an admin user's chosen customer
export const CustomerContext = React.createContext(null);

export const useUser = () => {
  return useContext(UserProfileContext);
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};

export const AuthLayer = ({ children }) => {
  const [, setUserId] = useLocalStorage("user_id");
  const [userData, setUserData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [token, setToken] = useState(getAccessToken() || null);
  const [authState, setAuthState] = useState(checkIsAuthenticated() ? AUTH_STATE.AUTHENTICATED : AUTH_STATE.UNAUTHENTICATED);
  const location = useLocation();
  const history = useHistory();

  // Run authentication checks on initial load
  useEffect(() => {
    // Check if the user is authenticated
    const checkAuthentication = () => {
      const isAuthenticated = checkIsAuthenticated();
      setAuthState(isAuthenticated ? AUTH_STATE.AUTHENTICATED : AUTH_STATE.UNAUTHENTICATED);
    };

    // Check if the user is at a callback location
    const checkForCallback = async () => {
      if (location.pathname === "/callback" && location.search) {
        setAuthState(AUTH_STATE.UNDETERMINED);

        const queryString = location.search.substring(1); // remove '#' from beginning of hash
        const params = Object.fromEntries(new URLSearchParams(queryString)); // convert query string to object

        if (params.error) {
          const error = getErrorFromMessage(params.error_description);
          return handleError(error);
        }

        const { path, body } = createTokenRequestParams({ code: params.code });

        let data = await fetch(path, {
          method: "POST",
          body,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        data = await data.json();
        if (!data.id_token) return false;
        setToken(data.id_token);
        localStorage.setItem("access_token", data.id_token);
        localStorage.removeItem("code_verifier");

        const decodedToken = decodeToken(data.id_token);
        const { emails } = decodedToken;

        const email = emails[0];
        localStorage.setItem("email", email);
        fetchData(`${process.env.REACT_APP_ADMIN_API}/track-login`, JSON.stringify({ email }), "POST");

        setAuthState(AUTH_STATE.AUTHENTICATED);
        return true;
      }
      return false;
    };

    const checkForLogout = () => {
      if (location.pathname === "/logout") {
        localStorage.removeItem("access_token");
        setAuthState(AUTH_STATE.UNAUTHENTICATED);
      }
    };

    const checkState = async () => {
      if (checkAuthentication()) return;
      if (checkForLogout()) return;
      if (await checkForCallback()) return;
    };

    checkState();
  }, [location, history]);

  useEffect(() => {
    const path = window.location.pathname;
    const search = window.location.search;

    if (path === "/callback") {
      const newPath = localStorage.getItem("redirect") || "/";
      localStorage.removeItem("redirect");
      return history.push(newPath);
    }
    if (path === "/logout" && authState === AUTH_STATE.UNAUTHENTICATED) {
      return history.push("/");
    }
    if (path === "/login") {
      return history.push("/");
    }
    if (authState === AUTH_STATE.UNAUTHENTICATED) {
      localStorage.setItem("redirect", path + search);
      return history.push("/");
    }
  }, [authState, history]);

  useEffect(() => {
    const getMe = async () => {
      const data = await getUserData();
      const { customers, user } = data;
      setUserData(data);
      setUserId(data.user.id);
      let homeCustomer;

      const existingCustomerCode = localStorage.getItem("customer_code");
      if (existingCustomerCode) {
        homeCustomer = customers.find((c) => c.group === existingCustomerCode);
      } else {
        homeCustomer = customers.find((c) => c.id === user.customer.id);
      }

      localStorage.setItem("customer_code", homeCustomer.group);
      setCustomer(homeCustomer);
    };
    token && getMe();
  }, [token, setUserId]);

  if (authState === AUTH_STATE.UNAUTHENTICATED && window.location.pathname === "/") {
    return <Login />;
  }

  if (authState === AUTH_STATE.UNDETERMINED) {
    return null;
  }

  if (authState === AUTH_STATE.AUTHENTICATED) {
    return (
      <CustomerContext.Provider value={{ customer, setCustomer }}>
        <UserProfileContext.Provider value={{ ...userData }}>
          {userData && customer ? <Switch>{children}</Switch> : <Loading />}
        </UserProfileContext.Provider>
      </CustomerContext.Provider>
    );
  }

  return null;
};
