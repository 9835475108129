import moment from "moment";
import { AUTH_ERRORS, LOGOUT_URI, REDIRECT_URI, SIGN_IN_POLICY_NAME } from "./config";
import { encode as base64encode } from "base64-arraybuffer";
import { fetchData } from "lib/helpers/fetchData";

export const getAccessToken = () => localStorage.getItem("access_token");
export const decodeToken = (token = "") => JSON.parse(atob(token.split(".")[1]));

const generateRandomString = (length = 10) => {
  const arr = new Uint8Array(Math.floor(length / 2));
  window.crypto.getRandomValues(arr);
  return Array.from(arr, (dec) => dec.toString(16).padStart(2, "0")).join("");
};

export const checkIsAuthenticated = () => {
  const token = getAccessToken();
  if (!token) return false;
  const decodedToken = decodeToken(token);
  if (typeof decodedToken !== "object") return false;
  const { exp } = decodedToken; // Not before and expiration are given as seconds since epoch
  const currentTimestamp = moment().unix();
  if (currentTimestamp > exp) return false;
  return true;
};

export const getCodeVerifier = () => {
  if (!localStorage.getItem("code_verifier")) {
    localStorage.setItem("code_verifier", generateRandomString(128));
  }
  return localStorage.getItem("code_verifier");
};

export const generateCodeChallenge = async () => {
  const encoder = new TextEncoder();
  const data = encoder.encode(getCodeVerifier());
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const base64Digest = base64encode(digest);
  return base64Digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};

export const createTokenRequestParams = ({
  tenant = process.env.REACT_APP_TENANT,
  policy = "b2c_1_sign_on_sign_in",
  clientId = process.env.REACT_APP_CLIENT_ID,
  grantType = "authorization_code",
  scope = "openid profile offline_access",
  redirectURI = REDIRECT_URI,
  code,
}) => {
  const path = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policy}/oauth2/v2.0/token`;
  const url = new URL(path);
  const addParam = (key, value) => key && value && url.searchParams.append(key, value);
  addParam("client_id", clientId);
  addParam("grant_type", grantType);
  addParam("scope", scope);
  addParam("redirect_uri", redirectURI);
  addParam("code_verifier", getCodeVerifier());
  addParam("code", code);
  const body = url.search.substring(1);
  return { path, body };
};

export const createSignInSignUpURL = async ({
  tenant = process.env.REACT_APP_TENANT,
  policy = SIGN_IN_POLICY_NAME,
  clientId = process.env.REACT_APP_CLIENT_ID,
  redirectURI = REDIRECT_URI,
  prompt = "",
  scope = "openid profile",
  nonce = "defaultNonce",
  responseType = "code",
  responseMode = "query",
  domainHint = "",
  loginHint = "",
  signUp = false,
  forgotPassword = false,
  path = "/authorize",
} = {}) => {
  const codeChallenge = await generateCodeChallenge();

  const url = new URL(`https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/oauth2/v2.0${path}`);
  const addParam = (key, value) => key && value && url.searchParams.append(key, value);
  addParam("forgot_password", forgotPassword);
  addParam("sign_up", signUp);
  addParam("p", policy);
  addParam("client_id", clientId);
  addParam("nonce", nonce);
  addParam("redirect_uri", redirectURI);
  addParam("scope", scope);
  addParam("response_type", responseType);
  addParam("response_mode", responseMode);
  addParam("prompt", prompt);
  addParam("domain_hint", domainHint);
  addParam("login_hint", loginHint);
  addParam("code_challenge", codeChallenge);
  addParam("code_challenge_method", "S256");
  return url.href;
};

export const redirectToLogin = (props) => {
  createSignInSignUpURL({ ...props }).then((url) => (window.location.href = url));
};

export const redirectToSignUp = (props) => {
  createSignInSignUpURL({ ...props, signUp: true }).then((url) => (window.location.href = url));
};

export const redirectToForgotPassword = (props) => {
  createSignInSignUpURL({ ...props, forgotPassword: true }).then((url) => (window.location.href = url));
};

export const redirectToLogout = (props) => {
  createSignInSignUpURL({ ...props, path: "/logout", redirectURI: LOGOUT_URI }).then((url) => (window.location.href = url));
};

export const redirectToEntry = () => {
  window.location = window.location.origin;
};

export const getErrorFromMessage = (message = "") => {
  const code = message.split(":")[0]; // description format is '[error_code]: full message goes here'
  if (!code) return;
  return AUTH_ERRORS.find((error) => error.code === code);
};

export const handleError = ({
  code = "Unknown exception",
  message = "An unknown error occurred",
  handler = redirectToEntry,
  skipModal = true,
} = {}) => {
  let response;
  if (!skipModal) response = window.confirm(`An error occurred (${code})\n\n${message}\n\nClick OK to try again`);
  if (response || skipModal) handler();
};

// export const setSession = authSessionResult => {
//   // Destructure auth data from the session result
//   const { accessToken, idTokenPayload } = authSessionResult;

//   const { namespace } = AUTH_CONFIG;
//   const name = idTokenPayload[namespace + "name"];
//   const picture = idTokenPayload[namespace + "picture"];
//   const email = idTokenPayload[namespace + "email"];
//   const defaultCustomer = idTokenPayload[namespace + "group"][0];

//   // Set auth data into session storage
//   localStorage.setItem("access_token", accessToken);
//   localStorage.setItem("customer_code", defaultCustomer);
//   if (name) localStorage.setItem("user_name", name);
//   localStorage.setItem("user_picture", picture);
//   localStorage.setItem("user_email", email);
//   localStorage.setItem("last_login", new Date().getTime());
// };

export const getUserData = async () => {
  const customerCode = localStorage.getItem("customer_code");
  localStorage.removeItem("customer_code");
  const data = await fetchData(`${process.env.REACT_APP_CLARITY_API}/me`);
  if (customerCode) {
    localStorage.setItem("customer_code", customerCode);
  }
  return data.result;
};
