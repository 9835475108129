const isFieldSafe = (fieldText) => {
  if (!fieldText) return true;
  //accounting for \r and \t
  fieldText = typeof fieldText === "string" ? fieldText.trim() : fieldText;
  if (Array.isArray(fieldText)) {
    const updatedFieldText = [];
    for (const text of fieldText) {
      updatedFieldText.push(text.trim());
    }
    fieldText = updatedFieldText;
  }
  const suspiciousCharacters = ["=", "+", "-", "@"];
  const startsWithSuspiciousChar = (text) => {
    return typeof text === "string" && suspiciousCharacters.some((char) => text.startsWith(char));
  };
  if (startsWithSuspiciousChar(fieldText)) {
    return false;
  }
  if (Array.isArray(fieldText)) {
    if (fieldText.some((text) => startsWithSuspiciousChar(text))) {
      return false;
    }
  }

  return true;
};

export const csvInjectionCheck = (textBlocks, newErrors) => {
  for (const key in textBlocks) {
    if (textBlocks.hasOwnProperty(key)) {
      const fieldText = textBlocks[key];
      if (!isFieldSafe(fieldText)) {
        newErrors[key] = "Invalid leading character";
      }
    }
  }

  return newErrors;
};
