import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700|Open+Sans&display=swap');

  #root {
    width: 100%;
    height: 100%;
  }

  html {
    box-sizing: border-box;
    font-family: ${props => props.theme.fontFamily} !important;
    font-size: ${props => props.theme.uiScale};
    font-weight: 400;
    font-style: normal;
    line-height: 1.1;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (max-width: ${props => props.theme.mobileWidth}) {
      font-size: calc(${props => props.theme.uiScale} - 1.5px);
    }

    @media (min-width: ${props => props.theme.mobileWidth}) {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-corner {
        background: ${props => props.theme.pageBackground};
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: ${props => props.theme.scrollbarColor};
        transition: background 0.3s;
        &:hover {
          background: ${props => props.theme.scrollbarHover};
        }
      }
    }
  }

  body {
    margin: 0;
    height: 100vh;
    overflow: auto scroll;
    background: ${props => props.theme.pageBackground};
    transition: background 0.3s;
  }

  a {
    color: inherit;
  }
`;
