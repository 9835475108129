import React, { useState, useLayoutEffect, useCallback, useEffect } from "react";
import styled from "styled-components";
import { ExpandButton } from "lib/components/ExpandButton";

const StyledPreview = styled.section`
  cursor: ${props => (props.childheight > props.maxheight || props.expanded) && "pointer"};
  margin-top: 0.3rem;
  border: 1px solid ${props => props.theme.headerOutline};
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: ${props => (!props.expanded && props.childheight > props.maxheight ? `${props.maxheight}px` : `${props.childheight}px`)};
  transition: max-height ${props => props.animation}s;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3rem;
    background: ${props =>
      !props.expanded &&
      props.shadow &&
      props.childheight > props.maxheight &&
      `linear-gradient(to top, ${props.theme.pageBackground}, transparent)`};
  }
  & > div {
    width: 100%;
    height: 100%;
    margin-bottom: -0.4rem;
    box-sizing: border-box;
  }
  &:hover .expand-button {
    transform: translateY(0);
  }
`;

export const PreviewBox = ({ children, maxHeight = 150, showShadow = true, className: _className, id, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [originalY, setOriginalY] = useState(0);
  const [childrenHeight, setChildrenHeight] = useState(null);
  const [animationLength, setAnimationLength] = useState(0);

  const sizeCallback = useCallback(() => {
    const el = document.getElementById(id);
    if (el) setChildrenHeight(el.clientHeight);
  }, [id])

  useLayoutEffect(() => {
    sizeCallback();
  }, [id, sizeCallback]);

  useEffect(() => {
    const animationTimeOut = window.setTimeout(() => setAnimationLength(0.3), 500);
    return () => window.clearTimeout(animationTimeOut);
  }, [])

  const handlePreviewClick = () => {
    if (isExpanded) {
      window.scrollTo({ top: originalY, behavior: "smooth" });
    } else {
      setOriginalY(window.scrollY);
    }
    setIsExpanded(!isExpanded);
  };

  const className = [isExpanded ? "expanded" : "", _className ? _className : ""].filter(Boolean).join(" ");

  return (
    <StyledPreview
      {...props}
      onClick={handlePreviewClick}
      shadow={showShadow}
      expanded={isExpanded}
      maxheight={maxHeight}
      childheight={childrenHeight}
      animation={animationLength}
    >
      <div id={id} className={className}>
        {React.Children.map(children, child => React.cloneElement(child, { onLoad: sizeCallback }))}
        {childrenHeight > maxHeight && <ExpandButton isExpanded={isExpanded} />}
      </div>
    </StyledPreview>
  );
};
