import React from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
  width: 100% !important;
  box-sizing: border-box;
  display: block;
  padding: 0.5rem 0.75rem;
  margin: 0.33rem 0 1rem 0;
  font: inherit;
  font-size: 1.3rem;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.accentCardBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.25rem;
  outline: none;
  transition: all 0.3s;
  &:focus-within {
    border-color: ${(props) => props.theme.secondaryButtonColor};
  }

  @media (min-width: ${(props) => props.theme.mobileWidth}) {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-corner {
      background: ${(props) => props.theme.cardBackground};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: ${(props) => props.theme.scrollbarColor};
      transition: background 0.3s;
      &:hover {
        cursor: default;
        background: ${(props) => props.theme.scrollbarHover};
      }
    }
  }
`;

const StyledParagraph = styled.p`
  width: 100% !important;
  margin: 0.33rem 0 1rem 0;
  line-height: 1.3;
  font-size: 1.5rem;
  color: ${(props) => props.theme.text};
  white-space: pre-wrap;
`;

export const TextArea = React.forwardRef((props, ref) => {
  const handleChange = (e) => {
    props.onChange && props.onChange(e);
  };

  if (props.readOnly) return <StyledParagraph ref={ref}>{props.defaultValue || "Not yet given"}</StyledParagraph>;
  return <StyledTextArea {...props} ref={ref} onChange={handleChange} />;
});
