import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";
import { SingleRelease } from "./SingleRelease";
import { CreateRelease } from "./CreateRelease";
import { CTAButton } from "lib/components/CTAButton";

const fetchReleases = async () => {
  return await fetchData(`${process.env.REACT_APP_CLARITY_API}/releases`);
};

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 5rem;
  & > button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ListReleases = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { data, isLoading, error, reload } = useFetch(fetchReleases);

  const showCreate = () => setShowCreateModal(true);
  const hideCreate = () => setShowCreateModal(false);

  const refresh = () => {
    hideCreate();
    reload();
  };

  if (isLoading) return <Spinner />;
  if (error) return <Error message={error} />;

  return (
    <StyledContainer>
      <CreateRelease show={showCreateModal} hide={hideCreate} refresh={refresh} key={data?.result?.length || 0} />
      <CTAButton onClick={showCreate}>Create New</CTAButton>
      {data?.result
        ?.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
        ?.map((release) => (
          <SingleRelease key={release.id} release={release} reload={reload} />
        ))}
    </StyledContainer>
  );
};
