import React from "react";
import styled from "styled-components";

const StyledTextSvg = styled.svg`
  path {
    fill: ${(props) => props.theme.logoTextColor || "#3f454b"};
  }
`;

export const LogoText = () => {
  return (
    <StyledTextSvg id="svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="147, 25.5, 191, 93.5" version="1.1" className="text">
      <g>
        <path
          d="M179.4183044,43.1181831c0,10.1396484-5.1083984,15.4223633-11.6367188,17.0097656l2.2685547,3.4897461
			c1.3203125,2.0307579,1.6494141,2.8745079-0.9433594,2.8745079h-1.6972656c-1.2724609,0-2.3330078-0.3754883-2.8740234-1.5078125
			l-2.0742188-4.336422l-0.0058594-0.0117188c-8.1962891-0.3125-15.2294922-5.5092773-15.2294922-17.5185547
			c0-12.1606464,7.5888672-17.4868183,16.1201172-17.4868183c8.3896484,0,16.0722656,5.3261719,16.0722656,17.4868183V43.1181831z
			 M173.950531,43.1181831c0-7.871582-4.1474609-12.5375996-10.6054688-12.5375996
			c-6.5986328,0-10.7460938,4.6191425-10.7460938,12.5375996c0,8.0600586,4.0058594,12.5849609,10.6523438,12.5849609
			C169.8499451,55.7031441,173.950531,51.1782417,173.950531,43.1181831z"
        />
        <path
          d="M207.0794373,33.1728706c1.5078125,0,2.3564453,0.8486328,2.3564453,2.3569336v22.2470703
			c0,1.5083008-0.8486328,2.3569336-2.3564453,2.3569336h-0.5654297c-1.5087891,0-2.3574219-0.8486328-2.3574219-2.3569336
			v-2.6806641c-1.2255859,3.1264648-4.2871094,5.5083008-8.8125,5.5083008c-7.4472656,0-10.8886719-4.2890625-10.8886719-12.8203125
			V35.5293159c0-1.5083008,0.8486328-2.3569336,2.3574219-2.3569336h0.5654297c1.5078125,0,2.3564453,0.8486328,2.3564453,2.3569336
			v11.8305664c0,5.8916016,2.2626953,8.6728516,7.2587891,8.6728516c5.0908203,0,7.1640625-3.3466797,7.1640625-9.2856445
			V35.5293159c0-1.5083008,0.8486328-2.3569336,2.3574219-2.3569336h0.5654297L207.0794373,33.1728706z"
        />
        <path
          d="M214.9466248,46.6528511c0-9.7094727,6.7402344-13.9990234,13.6220703-13.9990234
			s13.6689453,4.2895508,13.6689453,13.9990234c0,9.8041992-6.7402344,14.0932617-13.6220703,14.0932617
			C221.6868591,60.7461128,214.9466248,56.4570503,214.9466248,46.6528511z M236.9583435,46.6528511
			c0-5.8916016-3.3935547-9.5209961-8.3896484-9.5209961s-8.3427734,3.5820312-8.3427734,9.5209961
			c0,5.9863281,3.3466797,9.5683594,8.3896484,9.5683594C233.6116638,56.2212105,236.9583435,52.5918159,236.9583435,46.6528511z"
        />
        <path
          d="M259.7669373,32.8418159c1.6025391,0,2.4042969,1.0371094,2.2158203,2.5454102l-0.0478516,0.3769531
			c-0.1416016,1.5083008-1.0371094,2.0742188-2.5917969,2.0742188c-4.4306641,0.0942383-6.5517578,3.2993164-6.5517578,8.9555664
			V57.775898c0,1.5083008-0.8486328,2.3569336-2.3574219,2.3569336h-0.5654297
			c-1.5078125,0-2.3564453-0.8486328-2.3564453-2.3569336V35.5288277c0-1.5083008,0.8486328-2.3569336,2.3564453-2.3569336
			h0.5654297c1.5087891,0,2.3574219,0.8012695,2.3574219,2.2626953v2.2617188
			c0.8496094-2.4042969,3.0175781-4.8540039,6.9755859-4.8540039V32.8418159z"
        />
        <path
          d="M287.8548279,33.1728706c1.5078125,0,2.3564453,0.8486328,2.3564453,2.3569336v22.2470703
			c0,1.5083008-0.8486328,2.3569336-2.3564453,2.3569336h-0.5654297c-1.5087891,0-2.3574219-0.8486328-2.3574219-2.3569336
			v-2.6806641c-1.2255859,3.1264648-4.2871094,5.5083008-8.8125,5.5083008c-7.4472656,0-10.8886719-4.2890625-10.8886719-12.8203125
			V35.5293159c0-1.5083008,0.8486328-2.3569336,2.3574219-2.3569336h0.5654297c1.5078125,0,2.3564453,0.8486328,2.3564453,2.3569336
			v11.8305664c0,5.8916016,2.2626953,8.6728516,7.2587891,8.6728516c5.0908203,0,7.1640625-3.3466797,7.1640625-9.2856445
			V35.5293159c0-1.5083008,0.8486328-2.3569336,2.3574219-2.3569336h0.5654297L287.8548279,33.1728706z"
        />
        <path
          d="M328.2454529,32.6533394c6.7880859,0,9.6162109,4.0537109,9.6162109,13.7631836v11.359375
			c0,1.5083008-0.8486328,2.3569336-2.3574219,2.3569336h-0.5654297c-1.5078125,0-2.3564453-0.8486328-2.3564453-2.3569336
			V46.8408394c0-6.8813477-1.7910156-9.6152344-6.2685547-9.6152344c-4.6191406,0-6.5517578,2.828125-6.5517578,9.5209961V57.775898
			c0,1.5083008-0.8486328,2.3569336-2.3574219,2.3569336h-0.5654297c-1.5078125,0-2.3564453-0.8486328-2.3564453-2.3569336
			V46.8882027c0-7.0703125-1.6025391-9.6157227-5.9863281-9.6157227c-4.5722656,0-6.5517578,3.0639648-6.5517578,9.9926758
			V57.775898c0,1.5083008-0.8486328,2.3569336-2.3564453,2.3569336h-0.5654297
			c-1.5087891,0-2.3564453-0.8486328-2.3564453-2.3569336V35.5288277c0-1.5083008,0.8476562-2.3569336,2.3564453-2.3569336
			h0.5654297c1.5078125,0,2.3564453,0.8486328,2.3564453,2.3569336v2.7338867
			c0.9443359-3.0166016,4.0048828-5.5615234,8.2001953-5.5615234c4.5253906,0,6.7402344,1.6967773,8.2021484,6.4101562
			c1.2724609-3.6293945,4.8544922-6.4575195,9.8974609-6.4575195L328.2454529,32.6533394z"
        />
        <path
          d="M171.6614685,93.3056793c0-12.1132812,7.9189453-17.5810547,16.0732422-17.5810547
			c5.5615234,0,11.6416016,2.4511719,14.328125,9.2382812c0.6132812,1.5556641-0.1884766,2.6396484-1.7431641,2.9228516
			l-0.7548828,0.1416016c-1.4609375,0.2822266-2.1679688-0.3300781-2.875-1.6972656
			c-1.9326172-3.6767578-4.9960938-5.5615234-8.9550781-5.5615234c-6.2695312,0-10.4638672,4.8076172-10.4638672,12.5371094
			c0,7.7773438,4.1005859,12.4433594,10.4638672,12.4433594c4.0527344,0,7.1640625-1.9316406,9.0498047-5.796875
			c0.6591797-1.3671875,1.4609375-1.9794922,2.875-1.6972656l0.7539062,0.1416016
			c1.5556641,0.2832031,2.2626953,1.6494141,1.4140625,3.6767578c-2.7333984,6.4101562-8.6728516,8.7666016-14.0927734,8.7666016
			C179.5804138,110.839859,171.6614685,105.7490387,171.6614685,93.3056793z"
        />
        <path
          d="M213.9261169,116.2129059l2.7333984-6.1269531l-10.4638672-24.0390625
			c-0.7070312-1.6025391,0.0478516-2.7333984,1.7441406-2.7333984h0.8955078c1.3203125,0,2.2158203,0.6132812,2.734375,1.8378906
			l7.9179688,19.2314453l7.9189453-19.2314453c0.5185547-1.2246094,1.4140625-1.8378906,2.7333984-1.8378906h0.8017578
			c1.7910156,0,2.5449219,1.1308594,1.8378906,2.7333984l-13.4804688,31.15625
			c-0.5185547,1.2724609-1.4609375,1.7910156-2.78125,1.7910156h-0.8955078
			C213.9261169,118.9941559,213.2190857,117.8632965,213.9261169,116.2129059z"
        />
        <path
          d="M242.4944763,105.136734v2.7333984c0,1.5087891-0.8476562,2.3574219-2.3564453,2.3574219h-0.5654297
			c-1.5087891,0-2.3564453-0.8486328-2.3564453-2.3574219V76.9033356c0-1.5087891,0.8476562-2.3564453,2.3564453-2.3564453
			h0.5654297c1.5087891,0,2.3564453,0.8476562,2.3564453,2.3564453v11.6425781
			c1.5087891-3.6767578,5.2792969-5.7509766,9.5214844-5.7509766c6.6455078,0,12.3964844,4.7138672,12.3964844,13.9990234
			c0,9.3330078-5.7509766,14.0927734-12.3496094,14.0927734C247.8206482,110.886734,244.0032654,108.7666168,242.4944763,105.136734
			z M259.1331482,96.7939606c0-5.8916016-3.3935547-9.5214844-8.4375-9.5214844
			c-4.9482422,0-8.3427734,3.5830078-8.3427734,9.5214844c0,5.9863281,3.3945312,9.5683594,8.4375,9.5683594
			C255.7864685,106.3623199,259.1331482,102.7334137,259.1331482,96.7939606z"
        />
        <path
          d="M268.7425232,96.7939606c0-8.2958984,5.5625-13.9990234,13.6220703-13.9990234
			c7.8242188,0,12.8203125,5.2324219,13.1503906,13.0087891c0.046875,1.5556641-0.8486328,2.3574219-2.3564453,2.3574219
			h-19.1367188c0.1884766,5.2314453,3.4404297,8.2011719,8.6728516,8.2011719c2.921875,0,4.7607422-0.7539062,6.4570312-2.2626953
			c1.0371094-0.8955078,1.6972656-1.6494141,3.0166016-1.3193359l0.5654297,0.1416016
			c1.6503906,0.4238281,1.9804688,1.3193359,1.0849609,2.6386719c-2.3574219,3.4882812-6.1748047,5.2324219-11.21875,5.2324219
			C274.2102966,110.792984,268.7425232,105.2314606,268.7425232,96.7939606z M290.5188904,94.0127106
			c-0.3769531-4.2890625-3.2998047-7.0224609-8.0595703-7.0224609c-4.5253906,0-7.6835938,2.3564453-8.2958984,7.0224609
			H290.5188904z"
        />
        <path
          d="M315.2601013,85.5283356l-0.0478516,0.3769531
			c-0.1416016,1.5087891-1.0371094,2.0742188-2.5917969,2.0742188c-4.4306641,0.09375-6.5517578,3.2988281-6.5517578,8.9550781
			v10.9824219c0,1.5087891-0.8486328,2.3574219-2.3574219,2.3574219h-0.5654297
			c-1.5078125,0-2.3564453-0.8486328-2.3564453-2.3574219V85.6699371c0-1.5087891,0.8486328-2.3564453,2.3564453-2.3564453
			h0.5654297c1.5087891,0,2.3574219,0.8007812,2.3574219,2.2617188v2.2626953
			c0.8496094-2.4042969,3.0175781-4.8544922,6.9755859-4.8544922
			C314.6468201,82.9834137,315.4485779,84.0205231,315.2601013,85.5283356z"
        />
      </g>
    </StyledTextSvg>
  );
};
