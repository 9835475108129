import React from "react";
import styled from "styled-components";

export const StyledCheckboxesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 3fr));
  width: 100%;
  grid-gap: 10px;
  margin: 5px 0;
`;

export const StyledCheckbox = styled.label`
  padding: 10px;
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s;
  &,
  & * {
    cursor: pointer;
  }
  &:hover,
  &:focus-within {
    background: ${(props) => props.theme.accentCardBackground};
  }
`;

export const StyledFilters = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  box-sizing: border-box;
  margin-bottom: 1rem;

  @media (max-width: ${(props) => props.theme.mobileWidth}) {
    margin: 1.5rem 1rem;
    width: calc(100% - 2rem);
    & + .table {
      width: calc(100% - 2rem);
    }
  }

  #searchbar {
    margin: 0;
    p {
      color: ${(props) => props.theme.lightText};
      margin: 0 1rem;
      font-size: 1rem;
    }
  }
  & > hr {
    border: none;
    outline: none;
    width: 1px;
    height: 3rem;
    margin: 0 1rem;
    background: ${(props) => props.theme.headerOutline};
  }
  & > p {
    color: ${(props) => props.theme.lightText};
    font-size: 1.15rem;
    white-space: nowrap;
    margin-right: 1rem;
  }
  & > select {
    width: 17rem;
    height: 3rem;
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

export const StyledForm = styled.form`
  section {
    padding: ${props => props.tightSpacing ? "1rem 0" : "2rem 0"};
    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.headerOutline};
    }
    span {
      display: flex;
      &:first-child:last-child {
        margin-top: 0.3rem;
        margin-bottom: 1.25rem;
      }
      & > * {
        flex: 1;
        margin: 0;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
    .contents > span {
      margin-top: 0.3rem;
    }
  }
  hr {
    background: ${(props) => props.theme.headerOutline};
    height: 1px;
    border: none;
    outline: none;
  }
  h1,
  h3 {
    margin-top: 0;
    color: ${(props) => props.theme.text};
  }
  select {
    display: block;
    margin: 0.33rem 0 1rem 0;
    background: ${(props) => props.theme.accentCardBackground};
    width: 100%;
  }
  .box-container {
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.headerOutline};
    background: ${(props) => props.theme.cardBackground};
    border-radius: 0.8rem;
    padding: 1rem;
    transition: all 0.3s;
    &.interactive {
      cursor: pointer;
      &:hover {
        background: ${(props) => props.theme.accentCardBackground};
      }
    }
  }
`;

export const StyledRadioIcon = styled.div`
  padding: 0.75rem 1rem;
  margin: 0 -1rem;
  width: 100%;
  & > .radio-icon {
    transition: all 0.2s;
    width: 1.45rem;
    height: 1.45rem;
    border-radius: 1.33rem;
    border: 1px solid ${(props) => (props.active ? props.theme.secondaryButtonColor : props.theme.headerOutline)};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      transition: all 0.2s;
      position: absolute;
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 1.2rem;
      opacity: ${(props) => (props.active ? 1 : 0)};
      background: ${(props) => props.theme.secondaryButtonColor};
      &:hover {
        background: ${(props) => props.theme.secondaryButtonHover};
      }
    }
  }
`;

export const RadioIcon = ({ active }) => (
  <StyledRadioIcon active={active}>
    <div className="radio-icon" />
  </StyledRadioIcon>
);
