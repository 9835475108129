import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorkerRegistration";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register({
  onUpdate: (registration) => {
    const response = window.confirm("A new version of Clarity is available! Would you like to update now?");
    if (!response) return;

    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      registration.waiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload(true);
        }
      });
    }
  },
});
