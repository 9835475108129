import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Dashboard } from "./Dashboard";
import { Groupings } from "./Groupings";

export const Vuln = () => {
  const routes = [
    { name: "Solution Groupings", link: "/vuln/solution-groupings", isWide: true },
  ];

  return (
    <PageContainer name="Vulnerability Management" link="/vuln" routes={routes}>
      <ErrorHandledRoute path="/vuln/solution-groupings/" component={Groupings} />
      <ErrorHandledRoute path="/vuln/" component={Dashboard} />
    </PageContainer>
  );
};
