import React from "react";
import { useParams } from "react-router-dom";
import { fetchData, useFetch, Spinner, Error } from "lib/helpers/fetchData";

import { KeyValue } from "lib/components/KeyValue";
import { Card } from "lib/components/Card";

const loadRule = async ({ id }) => {
  return await fetchData(
    `${process.env.REACT_APP_PHISH_API}/rules/${id}`
  );
};

export const SingleRule = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useFetch(loadRule, { id });
  if (isLoading) return <Spinner />;
  if (error || data?.success === false) return <Error message={error} />;

  return (
    <div style={{ width: "100%" }}>
      <Card>
        <KeyValue text="Confidence" value={data.result?.confidence} />
        <KeyValue text="Description" value={data.result?.description} />
        <KeyValue text="Enabled" value={`${data.result?.enabled}`} />
        <KeyValue text="Function" value={data.result?.["function"]} />
        <KeyValue text="Id" value={data.result?.id} />
        <KeyValue text="Resultant Severity" value={data.result?.on_success?.[0]?.set_severity} />
        <KeyValue text="Tags" value={data.result?.tags?.join(", ")} />
        <KeyValue text="Targets" value={data.result?.targets?.join(", ")} />
      </Card>
    </div>
  );
};
