import styled from "styled-components";
import { ScrollContainer } from "lib/components/ScrollContainer";

import { AttachmentsBlock } from "./AttachmentsBlock";
import { useEffect, useState } from "react";
import { Searchbar } from "lib/components/Searchbar";
import { useDebouncedCallback } from "use-debounce";
import { CTAButton } from "lib/components/CTAButton";
import { StyledFilters } from "lib/styles/general";
import { useDataList } from "lib/helpers/useDataList";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { generateUUID } from "lib/helpers/generateUUID";

export const AttachmentsList = ({ targetId, setAttachments }) => {
  const [file, setFile] = useState(null);

  const url = `${process.env.REACT_APP_PENTEST_API}/attachments/${targetId}`;
  const { params, setParams, ...attachments } = useDataList({ url, orderBy: "createdAt", reverse: true, pageSize: 60 });

  useEffect(() => {
    const attachmentsList = attachments?.data?.result || [];
    if (attachmentsList.length && setAttachments) setAttachments(attachmentsList);
  }, [attachments, setAttachments]);

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const placeholderFile = { filename: file.name, id: generateUUID(), isPlaceholder: true };
    setFile(placeholderFile);

    let formData = new FormData();
    formData.append("file", file);
    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      method: "POST",
      body: formData,
    };
    await fetch(`${process.env.REACT_APP_PENTEST_API}/attachments/create/${targetId}`, headers);
    attachments.reload();
  };

  // Whenever a new file is added, the count will increase, and we can remove our placeholder file
  const count = attachments.data?.result?.length || 0;
  useEffect(() => {
    setFile(null);
  }, [count]);

  const attachmentsList = [file, ...attachments.data?.result].filter(Boolean);
  const data = { ...attachments.data, result: attachmentsList };

  return (
    <StyledContainer>
      <StyledFilters>
        <Searchbar {...search} placeholder="Search engagement attachments" small />
        <hr />
        <CTAButton as="label" isSecondary>
          <input key={file?.id} disabled={!!file} type="file" onChange={handleUpload} style={{ display: "none" }} />
          Upload file
        </CTAButton>
      </StyledFilters>
      <ScrollContainer className="scroll">
        <InfiniteScroll {...attachments} data={data} useWindow={false} noDataText="No attachments added yet">
          <AttachmentsBlock attachments={attachmentsList} placeholderFile={file} reload={attachments.reload} />
        </InfiniteScroll>
      </ScrollContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100%;
  position: relative;
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem;
    height: 3.6rem;
    h3 {
      margin: 0;
      color: ${(props) => props.theme.text};
      font-size: 1.6rem;
    }
  }
  & > .scroll {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 4rem;
    & > div {
      justify-content: flex-start;
    }
  }
`;
