import React, { useEffect, useRef, useState } from "react";

export const CachedImage = ({ src, alt, ...props }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const imageRef = useRef(null);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => setImageSrc(src);
      img.src = src;
    }
  }, [src]);

  return <img ref={imageRef} {...props} src={imageSrc} alt={alt} />;
};
