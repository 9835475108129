import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Card } from "lib/components/Card";
import ReactMde, { getDefaultToolbarCommands } from "react-mde";
import { Converter } from "showdown";

import "lib/styles/editor.css";
import { getBorderActiveColor } from "./CTAButton";

const converter = new Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  emoji: true,
  openLinksInNewWindow: true,
});

const StyledEditor = styled(Card)`
  padding: 0;
  overflow: hidden;
  border-color: ${(props) => props.theme.headerOutline};
  border-radius: 0.5rem;

  && {
    .react-mde {
      border: none;
      width: 100%;

      .invisible {
        display: none;
      }

      .mde-textarea-wrapper {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 3px;
          height: 3px;
          pointer-events: none;
          border-width: 3px;
          border-style: solid;
          border-color: transparent ${(props) => props.theme.scrollbarHover} ${(props) => props.theme.scrollbarHover} transparent;
        }
      }

      .mde-text {
        background: ${(props) => props.theme.cardBackground};
        outline: none;
        color: ${(props) => props.theme.text};
        font: 1.5rem ${(props) => props.theme.fontFamily};
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-corner {
          background: ${(props) => props.theme.pageBackground};
        }

        &::-webkit-resizer {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: ${(props) => props.theme.scrollbarColor};

          transition: background 0.3s;
          &:hover {
            background: ${(props) => props.theme.scrollbarHover};
          }
        }
      }
      .mde-tabs {
        button {
          cursor: pointer;
          border: none !important;
          outline-color: ${getBorderActiveColor};
          border-radius: 0.25rem;
          height: 2.8rem;
          min-height: 2.8rem;
          width: 9rem;
          min-width: 9rem;
          font: inherit;
          font-size: 1.33rem;
          color: ${(props) => props.theme.buttonText || props.theme.text};
          background: ${(props) => props.theme.secondaryButtonColor};
          transition: background 0.3s;
          &:not(.selected) {
            color: ${(props) => props.theme.mediumText};
            border: 1px solid ${(props) => props.theme.cardOutline} !important;
            background: ${(props) => props.theme.cardBackground};
            outline-color: ${(props) => getBorderActiveColor({ ...props, secondary: true })};
          }
        }
      }
      .mde-header-item {
        button {
          color: ${(props) => props.theme.mediumText} !important;
          outline: none;
          font: icon;
        }
        .react-mde-dropdown {
          background-color: ${(props) => props.theme.cardBackground} !important;
          border-color: ${(props) => props.theme.cardOutline} !important;
          border-radius: 0.25rem;
          &::before {
            border-bottom-color: none;
          }
          &::after {
            border-bottom-color: ${(props) => props.theme.cardOutline} !important;
          }
          button {
            font-family: ${(props) => props.theme.fontFamily};
            p:hover {
              color: ${(props) => props.theme.accentText} !important;
            }
          }
        }
      }
      .grip,
      .mde-header {
        display: ${(props) => props.hideheader && "none"};
        background: ${(props) => props.theme.accentCardBackground};
        border-color: ${(props) => props.theme.headerOutline};
        color: ${(props) => props.theme.mediumText};
        height: unset;
        svg {
          height: 1.5rem;
        }
      }
      .mde-preview-content {
        padding: 10px;
        white-space: pre-wrap;
        * {
          white-space: pre-wrap;
          font-size: 1.5rem;
          border-bottom-color: ${(props) => props.theme.headerOutline};
          border-bottom-width: 2px;
          line-height: 0.9;
        }
        hr {
          border: none;
          height: 1px;
          width: 100%;
          background: ${(props) => props.theme.lightText};
          opacity: 0.7;
        }
        p {
          color: ${(props) => props.theme.mediumText};
        }
        code {
          background: ${(props) => props.theme.accentCardBackground};
          margin: 2px;
        }
        blockquote {
          border-left-color: ${(props) => props.theme.lightText};
          * {
            color: ${(props) => props.theme.text};
          }
          opacity: 0.5;
          blockquote {
            opacity: 1 !important;
          }
        }
        pre {
          background: ${(props) => props.theme.accentCardBackground};
          border: 1px solid ${(props) => props.theme.cardOutline};
        }
        ol {
          color: ${(props) => props.theme.mediumText};
        }
        h2 {
          color: ${(props) => props.theme.mediumText};
        }
        h1 {
          font-size: 2rem;
        }
        img {
          max-width: 40rem;
          max-height: 30rem;
        }
      }
    }
  }
`;

export const MarkdownEditor = ({ name, tab = "write", style, readOnly, defaultValue, hideButtons = false, subtle, onChange, commands = [] }) => {
  const [value, setValue] = useState(defaultValue);
  const [selectedTab, setSelectedTab] = useState(readOnly ? "preview" : tab);
  const ref = useRef(null);
  const handleChange = (e) => {
    onChange && onChange(e);
  }

  useEffect(() => {
    if (!readOnly) setSelectedTab("write");
  }, [readOnly]);

  useEffect(() => {
    setValue(defaultValue);
  }, [readOnly, defaultValue]);

  const handleDrop = (e) => {
    const eventData = e.dataTransfer.getData("data") || "";
    let { image, filename, link, id } = JSON.parse(eventData) || {};
    image = image && image.replaceAll(" ", "%20");
    link = link.replaceAll(" ", "%20");
    insertAtCursor(`${image ? "\n\n!" : value.endsWith(" ") ? "" : " "}[${filename}](${link} "${id}")${image ? "\n\n" : " "}`);
  };

  const insertAtCursor = (text) => {
    var startPos = ref.current.selectionStart;
    var endPos = ref.current.selectionEnd;
    ref.current.value = ref.current.value.substring(0, startPos) + text + ref.current.value.substring(endPos, ref.current.value.length);
    setValue(ref.current.value);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const defaultToolbarCommands = getDefaultToolbarCommands();

  const commandsObject = {};
  commands.forEach((command) => (commandsObject[command.name] = command));

  const toolbarCommands = [...defaultToolbarCommands, [...commands.map((c) => c.name)]];

  return (
    <StyledEditor style={{ ...style, boxShadow: subtle && "none" }} className="editor-container" hideheader={readOnly} onChange={handleChange}>
      <ReactMde
        commands={commandsObject}
        toolbarCommands={toolbarCommands}
        value={value}
        onChange={setValue}
        selectedTab={!readOnly && selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
        childProps={{
          textArea: { name, placeholder: "Not given", onDrop: handleDrop, onDragOver: handleDragOver },
          writeButton: { style: { display: hideButtons && "none" } },
          previewButton: { style: { display: hideButtons && "none" } },
        }}
        refs={{
          textarea: ref,
        }}
      />
    </StyledEditor>
  );
};
