import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { useRef, useState } from "react";
import styled from "styled-components";

const createComment = async ({ targetId, text }) => {
  return await fetchData(`${process.env.REACT_APP_PENTEST_API}/comments/create`, JSON.stringify({ targetId, text }), "POST");
};

export const CreateComment = ({ targetId, reload }) => {
  const textRef = useRef(null);
  const defaultValue = "Enter comment";
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    e.target.style.height = `${e.target.scrollHeight || 5}px`;
  };

  const handleFocus = () => {
    if (textRef.current.value === defaultValue) {
      textRef.current.value = "";
    } else if (textRef.current.value === "") {
      textRef.current.value = defaultValue;
    }
  };

  const handleSubmit = async () => {
    const text = textRef.current.value;
    if (text === defaultValue) return;
    setIsLoading(true);
    await createComment({ targetId, text });
    setIsLoading(false);
    reload();
    textRef.current.value = defaultValue;
  };

  return (
    <StyledCreateComment create={true} style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}>
      <textarea ref={textRef} defaultValue={defaultValue} onChange={handleChange} onFocus={handleFocus} onBlur={handleFocus} />
      <CTAButton onClick={handleSubmit} isLoading={isLoading}>
        Post
      </CTAButton>
    </StyledCreateComment>
  );
};

export const StyledCreateComment = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  margin: auto;

  & > div:first-child {
    flex: 1;
    & > p {
      word-break: break-word;
      margin-left: 0.5rem;
      padding-left: 0.75rem;
      border-left: 2px solid ${(props) => props.theme.headerOutline};
    }
  }
  textarea {
    width: 100% !important;
    height: ${(props) => (props.create ? "7.5rem" : "5rem")};
    min-height: ${(props) => (props.create ? "7.5rem" : "5rem")};
    padding: 0.5rem;
    background: transparent;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.headerOutline};
    color: inherit;
    font: inherit;
    font-size: 1.5rem;
    border-radius: 0.5rem;
    outline: none;
    resize: none;
    margin-right: 0.5rem;
    transition: border 0.3s;
    &:hover,
    &:focus-within {
      border: 1px solid ${(props) => props.theme.secondaryButtonColor};
      resize: auto;
    }
  }
  p {
    margin: 4px 0;
    font-size: 1.5rem;
  }
`;
