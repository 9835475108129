import styled from "styled-components";

import { faEdit, faMoneyBillWave, faStopwatch, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TIER_NAMES, VALUE_COLORS } from "../components/constants";
import { EditClient } from "./EditClient";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const StyledClientBox = styled.div`
  border-radius: 0.5rem;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: inset 0 1rem 3rem 0rem rgba(0, 0, 0, 0.25), 0 0.5rem 1.5rem rgba(0, 0, 0, 0.45);
  overflow: hidden;
  cursor: default;
  transition: all 0.3s;

  & > .main {
    flex: 1;
    color: ${(props) => props.theme.text};
    font-size: 1.75rem;
    font-weight: bolder;
    text-shadow: 0 0 10px ${(props) => props.theme.pageBackground};
    border-radius: 0.25rem;
    padding: 0.75rem 0.5rem 0;
    position: relative;

    & > .name {
      overflow: hidden;
      padding: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    & > .tier {
      font-size: 1.35rem;
      padding: 0 0.5rem;
    }
    & > svg {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      padding: 0.75rem;
      color: ${(props) => props.theme.text};
      font-size: 1.25rem;
      opacity: 0;
      transition: all 0.3s;
      background: ${(props) => props.theme.cardBackground};
      box-shadow: 0 0 1rem 0.75rem ${(props) => props.theme.cardBackground};
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        opacity: 1 !important;
      }
    }
    &:hover > svg {
      opacity: 0.65;
    }
  }

  & > .statuses {
    display: flex;
    flex-flow: row nowrap;
    height: 6rem;
    margin: 0.75rem;
    background: ${(props) => props.theme.pageBackground};
    border: 1px solid ${(props) => props.theme.headerOutline};
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 3rem 5rem 4rem rgba(0, 0, 0, 0.5);
    opacity: 0.9;
  }
`;

const StyledStatus = styled.div`
  background-color: ${(props) => props.color};
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${(props) => props.theme.text};
  font-weight: bolder;
  text-shadow: 0 0 10px ${(props) => props.theme.pageBackground};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  transition: all 0.3s;
  position: relative;
  cursor: pointer;

  :not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.headerOutline};
  }

  & > svg {
    filter: drop-shadow(0 0 0.5rem ${(props) => props.theme.pageBackground});
    font-size: 1.75rem;
  }

  & > div {
    margin: 0 0 -0.25rem 0.65rem;
    font-size: 1.35rem;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover:after {
    opacity: 1;
  }
`;

export const SingleClient = ({ client, metrics, reload }) => {
  const [showEditClient, setShowEditClient] = useState(false);

  const history = useHistory();

  const budget = client.leastPriorityValues?.worstBudgetPriority || {};
  const time = client.leastPriorityValues?.worstTimePriority || {};
  const scope = client.leastPriorityValues?.worstScopePriority || {};

  let { priority: budgetPriority, count: budgetCount } = budget;
  let { priority: timePriority, count: timeCount } = time;
  let { priority: scopePriority, count: scopeCount } = scope;

  if (budgetPriority === null) budgetPriority = -1;
  if (timePriority === null) timePriority = -1;
  if (scopePriority === null) scopePriority = -1;

  let priorities = [];
  if (metrics.budget) priorities.push(budgetPriority);
  if (metrics.time) priorities.push(timePriority);
  if (metrics.scope) priorities.push(scopePriority);

  const worstPriority = Math.min(...priorities);

  const handleClientNavigation = () => history.push(`/service-delivery-portfolio/projects?clientId=${client.id}`);

  return (
    <>
      <EditClient client={client} showModal={showEditClient} hide={() => setShowEditClient(false)} reload={reload} />
      <StyledClientBox style={{ background: VALUE_COLORS[worstPriority] }}>
        <div className="main">
          <div className="name" title={client.name} onClick={handleClientNavigation}>
            {client.name}
          </div>
          <div className="tier" title={`Tier: ${client.tier}`}>
            {TIER_NAMES[client.tier]} priority
          </div>
          <FontAwesomeIcon title="Edit" icon={faEdit} onClick={() => setShowEditClient(true)} />
        </div>
        <div className="statuses">
          <StyledStatus color={VALUE_COLORS[timePriority]} disabled={!metrics.time}>
            <FontAwesomeIcon icon={faStopwatch} />
            <div>x{timeCount || 0}</div>
          </StyledStatus>
          <StyledStatus color={VALUE_COLORS[budgetPriority]} disabled={!metrics.budget}>
            <FontAwesomeIcon icon={faMoneyBillWave} />
            <div>x{budgetCount || 0}</div>
          </StyledStatus>
          <StyledStatus color={VALUE_COLORS[scopePriority]} disabled={!metrics.scope}>
            <FontAwesomeIcon icon={faTasks} />
            <div>x{scopeCount || 0}</div>
          </StyledStatus>
        </div>
      </StyledClientBox>
    </>
  );
};
