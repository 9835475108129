import styled, { css, keyframes } from "styled-components";
import { ButtonTheme } from "./query-engine/components/theme";

export const timeRangeValues = [
  { label: "Set in query", value: "0" },
  { label: "Last 30 minutes", value: "PT30M" },
  { label: "Last 1 hour", value: "PT1H" },
  { label: "Last 4 hours", value: "PT4H" },
  { label: "Last 12 hours", value: "PT12H" },
  { label: "Last 24 hours", value: "P1D" },
  { label: "Last 48 hours", value: "P2D" },
  { label: "Last 3 days", value: "P3D" },
  { label: "Last 7 days", value: "P7D" },
];

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 25rem;
  justify-content: flex-start;
`;

export const StyledFilter = styled.div`
  position: relative;
  justify-content: flex-start;
  margin-right: 10rem;
  width: auto;

  .workspace-filter {
    margin-bottom: 3rem; /* Adjust the margin value as needed */
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
`;

export const SubmitButton = styled.a`
  width: auto;
  box-sizing: border-box;
  font-size: 1.25rem;
  padding: 0.8rem 1rem;
  margin: 0;
  background: ${(props) => props.theme.primaryButtonColor};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.45rem;
  color: ${(props) => props.theme.mediumText};
  text-decoration: none;
  transition: background 0.3s;
  &:hover {
    background: ${ButtonTheme.primaryButtonColor};
  }
  cursor: pointer;
  ${(props) =>
    props.loading &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      &:after {
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-left: 0.5em;
        vertical-align: -0.15em;
        border-radius: 50%;
        border: 2px solid ${ButtonTheme.mediumText};
        border-top-color: transparent;
        border-right-color: transparent;
        animation: ${spin} 0.6s linear infinite;
      }
    `}
`;

export const ErrorText = styled.span`
  color: ${ButtonTheme.errorText};
  font-size: 1.1rem;
  margin-left: 0.5rem;
  align-self: center;
`;
