import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Input } from "lib/components/Input";
import { IconPopup } from "lib/components/PopupMenu";
import { CheckboxButton } from "lib/components/Checkbox";

import { ListEvents } from "./ListEvents";
import { CreateEvent } from "./CreateEvent";

export const getEventKey = (e) => {
  const { user, type, date } = e;
  return [user, type, date].join("-");
};

export const Events = ({ events, setEvents, eventTotals, targets, readOnly }) => {
  const [includeEvents, setIncludeEvents] = useState(!!events.length);

  useEffect(() => {
    setIncludeEvents(!!events.length);
  }, [events.length]);

  const handleIncludeEventsChange = (e) => {
    setIncludeEvents(e.target.checked);
  };

  return (
    <>
      {!readOnly && <CheckboxButton key={includeEvents} defaultChecked={includeEvents} label="Include detailed event data?" subtle onChange={handleIncludeEventsChange} />}
      {includeEvents ? <DetailedEvents events={events} setEvents={setEvents} targets={targets} readOnly={readOnly} /> : null}
      <EventTotals events={events} eventTotals={eventTotals} readOnly={readOnly} includeEvents={includeEvents} targets={targets} />
    </>
  );
};

const DetailedEvents = ({ events, setEvents, readOnly, targets }) => {
  return (
    <div style={{ display: "flex", flexFlow: "column nowrap", alignItems: "flex-end" }}>
      <ListEvents events={events} setEvents={setEvents} readOnly={readOnly} />
      {!readOnly && (
        <IconPopup key={events.length} icon={faPlus} text="Event" buttonStyle={{ margin: 0, padding: "0.75rem 1rem" }}>
          <CreateEvent setEvents={setEvents} targets={targets} />
        </IconPopup>
      )}
    </div>
  );
};

const EventTotals = ({ events, eventTotals, readOnly, includeEvents, targets }) => {
  const renderStat = ({ name, label }) => {
    const existingTotal = eventTotals?.[name];
    const eventTotal = events.filter((event) => event.type === name).length;
    const hasTotalChanged = existingTotal !== eventTotal;

    let total = eventTotal && existingTotal ? (hasTotalChanged ? eventTotal : existingTotal) : existingTotal || eventTotal;
    total = (total || 0).toString();

    return <Input name={name} key={`${name}-${total}`} label={label} type="number" min={0} max={targets.length} defaultValue={total} readOnly={readOnly || includeEvents} />;
  };

  return (
    <section style={{ paddingBottom: 0 }}>
      {renderStat({ name: "opened", label: "No. of people opened email" })}
      {renderStat({ name: "clicked", label: "No. of people clicked link" })}
      {renderStat({ name: "submitted", label: "No. of people submitted data" })}
      {renderStat({ name: "reported", label: "No. of people reported email" })}
    </section>
  );
};
