import React, { useState, useContext } from "react";
import styled from "styled-components";

import { KeyValue } from "lib/components/KeyValue";
import { Card } from "lib/components/Card";
import { RadioButton } from "lib/components/RadioButton";
import { ThemeContext } from "lib/components/ThemeProvider";
import { getValueOrDefault } from "lib/helpers/getValueOrDefault";
import { CTAButton } from "./CTAButton";
import { useHistory } from "react-router-dom";

const StyledOptionsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 10rem;
  grid-gap: 1rem;
  margin-top: 0.3rem;
`;

export const Settings = () => {
  const [scale, setScale] = useState(getValueOrDefault("ui_scale", "10.5px"));
  const history = useHistory();

  const { themeKey, themes, modifyTheme, setThemeByKey } = useContext(ThemeContext);

  const handleScaleChange = (scale) => {
    const modification = { uiScale: scale };
    modifyTheme(modification);
    setScale(scale);
    localStorage.setItem("ui_scale", scale);
  };

  const handleThemeChange = (_key) => {
    const key = _key.toLowerCase();
    setThemeByKey(key);
    localStorage.setItem("theme", key);
  };

  const uiScaleSelections = [
    { key: "Small", value: "9px" },
    { key: "Medium", value: "10.5px" },
    { key: "Large", value: "12px" },
  ];
  const themeSelections = [
    { key: "Light", value: themes.light },
    { key: "Dark", value: themes.dark },
    { key: "Midnight", value: themes.midnight },
  ];

  return (
    <Card>
      <CTAButton isSecondary onClick={() => history.goBack()} style={{ marginBottom: "2rem", width: "5rem", minWidth: "unset" }}>
        Back
      </CTAButton>
      <KeyValue
        text="UI Scale"
        wrap={false}
        value={
          <StyledOptionsContainer>
            {uiScaleSelections.map(({ key, value }) => (
              <RadioButton key={key} label={key} onChange={() => handleScaleChange(value)} checked={value === scale} />
            ))}
          </StyledOptionsContainer>
        }
      />
      <KeyValue
        text="Theme"
        wrap={false}
        value={
          <StyledOptionsContainer>
            {themeSelections.map(({ key }) => (
              <RadioButton key={key} label={key} onChange={() => handleThemeChange(key)} checked={key.toLowerCase() === themeKey.toLowerCase()} />
            ))}
          </StyledOptionsContainer>
        }
      />
    </Card>
  );
};
