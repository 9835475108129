import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ErrorBoundary } from "./ErrorBoundary";

export const LinkRow = ({ path, children, className, style = {}, exclusionAreaLeft = 0, exclusionAreaRight = 0, ...props }) => {
  const rowRef = useRef(null);
  const history = useHistory();

  const redirect = (e) => {
    // Prevent click-through when user has text selected
    const selection = window.getSelection().toString();
    if (selection !== "") return;

    // Test if click is outwith exclusion area
    const rect = rowRef.current.getBoundingClientRect();
    if (e.clientX - rect.left > exclusionAreaLeft && e.clientX < rect.right - exclusionAreaRight) {
      history.push(path);
    }
  };

  return (
    <StyledLinkRow onClick={redirect} className={className} style={{ cursor: "pointer", ...style }} ref={rowRef} {...props}>
      <ErrorBoundary fallback={<p>Bad data</p>}>{children}</ErrorBoundary>
    </StyledLinkRow>
  );
};

const StyledLinkRow = styled.tr`
  color: ${(props) => (props.active ? props.theme.accentText : props.theme.mediumText)} !important;
  font-weight: ${(props) => (props.active ? "bold" : "normal")} !important;
`;
