import React from "react";
import { faUser, faRobot, faSkull } from "@fortawesome/free-solid-svg-icons";
import { getAnalystName } from "./getAnalystName";

export const getEvaluationOriginIcon = ({ type } = {}) => {
  const typeMap = {
    Analyst: faUser,
    Rule: faRobot,
    "Phish V1": faSkull
  };
  return typeMap[type];
};

export const getEvaluationText = ({ type, target, user, result } = {}, { fid: ticketFid, severity }) => {
  const typeMap = {
    Analyst: <p><b>{getAnalystName(user)}</b> marked {target?.type} {target?.fid} as <b>{severity?.text?.toLowerCase()}</b></p>,
    Rule: <p>Rule <b>{result?.rule?.type} ({result?.rule?.description})</b> {"\n"} {result?.message && <>returned <b>{result?.message?.toLowerCase()}</b> and hence</>} marked {"\n"} {(!target?.type || target?.type?.toLowerCase() === "mail") ? `Ticket ${ticketFid}` : `${target?.type} ${target?.fid}`} as <b>{severity.text?.toLowerCase()}</b></p>,
    "Phish V1": <p><b>Phish V1</b> marked this ticket or a child artifact as <b>{severity?.text?.toLowerCase()}</b></p>
  }
  return typeMap[type];
}

export const hasEvaluationOrigin = ({ evaluationOrigin }) => {
  if (!evaluationOrigin?.type) return false;
  return evaluationOrigin?.type?.toLowerCase() !== "unknown"
}