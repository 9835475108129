import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getSeverityText } from "lib/helpers/getSeverityText";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { PreviewBox } from "lib/components/PreviewBox";
import { getRemValue } from "lib/helpers/getRemValue";

const StyledRedirectsContainer = styled(PreviewBox)`
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
`;

const StyledRedirect = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
  font-size: 1rem;
  text-decoration: unset;
  & > div {
    position: relative;
    height: fit-content;
    min-height: 5rem;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 0.3rem;
    border: 1px solid ${props => props.theme.headerOutline};
    padding: 1rem;
    padding-right: 2.5rem;
    margin-right: 0.35rem;
    max-width: 40rem;
    background: ${props => props.theme.cardBackground};
    transition: all 0.3s;
    &:hover {
      background: ${props => props.theme.accentCardBackground};
    }
    &::before {
      content: "${props => props.index}";
      position: absolute;
      display: ${props => (props.index ? "flex" : "none")};
      align-items: center;
      justify-content: center;
      color: ${props => props.theme.lightText};
      top: 0;
      right: 0;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.3rem;
      border: 1px solid ${props => props.theme.headerOutline};
      border-radius: 50%;
    }
    & > .tags {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 0.3rem;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.mediumText};
        border: 1px solid ${props => props.theme.headerOutline};
        border-radius: 0.3rem;
        padding: 0.3rem;
        &:not(:last-of-type) {
          margin-right: 0.3rem;
        }
      }
    }
    & > p {
      color: ${props => props.theme.text};
      font-size: 1.2rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    & > h3 {
      color: ${props => props.theme.lightText};
      margin: 0 0 0.3rem 0;
      &.first {
        transform: rotate(-90deg);
        transform-origin: left top 0px;
        position: absolute;
        bottom: -0.7rem;
        left: 0.4rem;
      }
    }
  }
  & > svg {
    margin-right: 0.35rem;
  }
`;

export const Redirects = ({ data, height = 10 }) => {
  return (
    <StyledRedirectsContainer maxHeight={height && getRemValue(height)} id={`redirect-${data.id}`}>
      <StyledRedirect to={`/phish/artifacts/${data?.id}`}>
        <div style={{ minHeight: "6rem", paddingRight: "1rem" }}>
          <h3 className="first">{`#${data.fid}`}</h3>
        </div>
      </StyledRedirect>
        {data?.properties?.redirects?.map((artifact, i) => (
          <StyledRedirect key={i} index={i + 1} title={artifact?.properties?.url} to={`/phish/artifacts/${artifact?.id}`}>
            <FontAwesomeIcon icon={faArrowRight} />
            <div>
              <h3 style={{ color: getColorFromSeverity(artifact?.severity) }}>{getSeverityText(artifact)}</h3>
              {artifact.tags.length ? (
                <div className="tags">
                  {artifact.tags.map(tag => (
                    <div key={tag.id}>{tag.name}</div>
                  ))}
                </div>
              ) : null}
              <p>{artifact?.properties?.url}</p>
            </div>
          </StyledRedirect>
        ))}
    </StyledRedirectsContainer>
  );
};
