export const getAnalystName = (analyst = {}) => {
  if (analyst && typeof analyst === "object") {
    if (analyst.displayName) return analyst.displayName;
    if (analyst.firstName) return analyst.firstName;
    if (analyst.email) {
      const firstPart = analyst.email.toLowerCase().split("@")[0];
      return firstPart.split(".").join(" ");
    }
  }
  return "n/a";
};
