import React from "react";
import { useFetch, fetchData } from "lib/helpers/fetchData";
import { SingleCustomer } from "./components/SingleCustomer";
import { ListPane } from "lib/components/ListPane";

const loadCustomersList = async () => {
    var allCustomers = await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers`);
    allCustomers.result = allCustomers.result.filter(function (customer) {
        return customer.apps.soc.enabled === true;
    })
    return allCustomers;
};

export const Brain = () => {
    const state = useFetch(loadCustomersList);
    return (
        <ListPane
            sort
            hasSearch
            listState={state}
            listWidth={400}
            singleItemPane={SingleCustomer}
        />
    );
};
