import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { Loading } from "auth/Loading";
import { useLocalStorage } from "lib/helpers/useLocalStorage";

const generateSDKConfig = (key) => {
  return {
    core: {
      authorizationKey: process.env.REACT_APP_FEATURE_FLAG_AUTH_KEY,
      key: key ?? "anonymous",
    },
    urls: {
      sdk: `${process.env.REACT_APP_SPLIT_IO_HOST}/sdk/api`,
      auth: `${process.env.REACT_APP_SPLIT_IO_HOST}/auth/api`,
      events: `${process.env.REACT_APP_SPLIT_IO_HOST}/events/api`,
      streaming: `${process.env.REACT_APP_SPLIT_IO_HOST}/streaming`,
      telemetry: `${process.env.REACT_APP_SPLIT_IO_HOST}/telemetry`,
    },
  };
};

/**
 * This Factory creates a non-user specific client for use before authentication. It uses
 * a standard "anonymous" key and does not contain any user or customer.
 */
export const FeatureFlagProvider = ({ children }) => {
  const [userId] = useLocalStorage("user_id");
  const config = generateSDKConfig(userId);

  return <SplitFactoryProvider config={config}>{({ isReady }) => (isReady ? children : <Loading />)}</SplitFactoryProvider>;
};
