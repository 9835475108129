import React from "react";
import { useHistory } from "react-router-dom";

import { Card } from "lib/components/Card";
import { Navbar } from "lib/components/Navbar";
import { CampaignForm } from "../CampaignForm";

export const CreateCampaign = ({ reload }) => {
  const history = useHistory();

  let paths = [
    { text: "Campaigns", link: "/phish-sim/campaigns" },
    { text: "New Campaign", link: "#" },
  ];

  const refresh = (d) => {
    history.push(`/phish-sim/campaigns/single/${d.result.id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
    reload();
  };

  return (
    <>
      <Navbar paths={paths} />
      <Card>
        <CampaignForm refresh={refresh} />
      </Card>
    </>
  );
};
