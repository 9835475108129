import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave, faStopwatch, faTasks } from "@fortawesome/free-solid-svg-icons";

import { VALUE_COLORS } from "../components/constants";

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 30rem;
  box-sizing: border-box;
  background: ${(props) => props.theme.pageBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 3rem 5rem 4rem rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 5rem;
  z-index: 100;
`;

const StyledStatus = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.text};
  font-weight: bolder;
  text-shadow: 0 0 10px ${(props) => props.theme.pageBackground};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  transition: all 0.3s;
  box-sizing: border-box;
  background-color: ${(props) => props.color};
  overflow: hidden;

  :not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.headerOutline};
  }

  svg {
    filter: ${(props) => `drop-shadow(0 0 0.5rem ${props.theme.pageBackground})`};
    font-size: 1.75rem;
    flex: 1;
  }
`;

export const Statuses = ({ project }) => {
  const { budgetPriority, timePriority, scopePriority } = project;

  return (
    <StyledContainer>
      <StyledStatus color={VALUE_COLORS[timePriority]} title="Time RAG">
        <FontAwesomeIcon icon={faStopwatch} />
      </StyledStatus>
      <StyledStatus color={VALUE_COLORS[budgetPriority]} title="Budget RAG">
        <FontAwesomeIcon icon={faMoneyBillWave} />
      </StyledStatus>
      <StyledStatus color={VALUE_COLORS[scopePriority]} title="Scope RAG">
        <FontAwesomeIcon icon={faTasks} />
      </StyledStatus>
    </StyledContainer>
  );
};
