import React from "react";
import styled from "styled-components";
import { ErrorBoundary } from "./ErrorBoundary";
import { ScrollContainer } from "./ScrollContainer";

export const StyledTable = styled.div`
  width: 100%;
  min-width: min-content;
  background: ${(props) => props.theme.cardBackground};
  border-radius: ${(props) => (props.small ? "0.3rem" : "0.8rem")};
  border: 1px solid ${(props) => props.theme.cardOutline};
  box-sizing: border-box;
  grid-area: ${(props) => props.area};
  table {
    width: 100%;
    border-spacing: 0;
  }
  th {
    height: ${(props) => (props.small ? "2.75rem" : "3.5rem")};
    font-weight: normal;
    text-transform: capitalize;
    font-size: ${(props) => (props.small ? "1rem" : "1.25rem")};
    color: ${(props) => props.theme.lightText};
  }
  tbody tr {
    .no-results-text, .no-results-text p {
      text-align: center;
    }
    height: ${(props) => (props.small ? "3.5rem" : "4rem")};
    font-size: ${(props) => (props.small ? "1.25rem" : "1.45rem")};
    color: ${(props) => props.theme.mediumText};
    background: ${(props) => (props.flip ? props.theme.accentCardBackground : props.theme.cardBackground)};
    &:nth-child(odd) {
      background: ${(props) => (props.flip ? props.theme.cardBackground : props.theme.accentCardBackground)};
    }
  }
  tr {
    p {
      margin: 0;
    }
    & > * {
      padding: ${(props) => (props.small ? "0 0.75rem" : "0 1rem")};
      text-align: left;
      &:last-child {
        text-align: right;
      }
    }
    &:hover {
      .radio-icon {
        border-color: ${(props) => props.theme.secondaryButtonColor};
      }
    }
  }
  td {
    white-space: ${(props) => (props.fix && !props.clamp ? "pre-line" : "nowrap")};
    overflow-wrap: ${(props) => props.fix && "break-word"};
    overflow: ${(props) => (!props.fix || props.clamp) && "hidden"};
    text-overflow: ${(props) => (!props.fix || props.clamp) && "ellipsis"};
    user-select: ${(props) => (props.select ? "auto" : "none")};
    p + p {
      color: #757575;
      font-size: 1rem;
    }
  }
  path {
    color: #888;
  }
  input {
    cursor: pointer;
  }
`;

export const Table = React.forwardRef(
  (
    {
      children,
      area,
      className,
      style,
      tableStyle = {},
      flip = false,
      disableSelect = false,
      isFixed,
      minWidth,
      singleLine,
      small = false,
    },
    ref
  ) => (
    <StyledTable
      ref={ref}
      className={`${className || ""} table`}
      small={small}
      area={area}
      style={style}
      fix={isFixed}
      flip={flip}
      select={!disableSelect}
      clamp={singleLine}
    >
      <ErrorBoundary>
        <ScrollContainer scrollX="auto" scrollY="hidden" style={{ width: "100%" }}>
          <table style={{ tableLayout: isFixed ? "fixed" : "unset", minWidth, overflowX: minWidth && "auto", ...tableStyle }}>
            {children}
          </table>
        </ScrollContainer>
      </ErrorBoundary>
    </StyledTable>
  )
);
