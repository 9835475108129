import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { fetchData, useFetch, Spinner } from "lib/helpers/fetchData";

import { Navbar } from "lib/components/Navbar";
import { FullWidthContainer } from "lib/components/FullWidthContainer";
import { ScrollContainer } from "lib/components/ScrollContainer";

import { UpsertEngagement } from "./UpsertEngagement";
import { AttachmentsList } from "../attachments/AttachmentsList";
import { FindingsList } from "./findings/FindingsList";
import { SingleFinding } from "./findings/SingleFinding";
import { CTAButton } from "lib/components/CTAButton";
import { useQuery } from "lib/helpers/useQuery";
import { useDataList } from "lib/helpers/useDataList";
import { CreateFinding } from "./findings/CreateFinding";

const loadEngagement = async ({ id, customerId }) => fetchData(`${process.env.REACT_APP_PENTEST_API}/engagement/${customerId}/${id}`);

const defaultPath = { text: "Engagements", link: "/pentest/engagements" };

export const SingleEngagement = ({ reload: reloadList }) => {
  const history = useHistory();
  const { customerId, engagementId } = useParams();
  const { findingId, isCreateFinding } = useQuery();
  const [ newCustomerId, setNewCustomerId ] = useState(customerId);
  const [attachments, setAttachments] = useState([]);
  const { data, reload } = useFetch(loadEngagement, { id: engagementId, customerId: newCustomerId });
  const engagement = data?.result || {};
  const [attachmentsPreference, setAttachmentsPreference] = useState(null);
  const [showAttachments, setShowAttachments] = useState(null);
  const [paths, setPaths] = useState([defaultPath]);

  const url = `${process.env.REACT_APP_PENTEST_API}/findings/${engagementId}`;
  const findingsData = useDataList({ url, orderBy: "createdAt", reverse: true });

  const findings = findingsData?.data?.result || [];
  const finding = findings?.find((f) => f.id === findingId) || {};

  useEffect(() => {
    let newPaths = [defaultPath];
    if (engagement.title) {
      newPaths.push({ text: engagement.title, link: `/pentest/engagements/engagement/${newCustomerId}/${engagementId}` });
      reload();
    }
    if (finding.title && findingId) {
      newPaths.push({ text: finding.title, link: `/pentest/engagements/engagement/${customerId}/${engagementId}?findingId=${findingId}` });
    }
    setPaths(newPaths);
  }, [engagement.title, finding.title, engagementId, findingId, customerId, newCustomerId, reload]);

  const handleHide = () => {
    history.push("/pentest/engagements");
    reloadList();
  };

  useEffect(() => {
    setShowAttachments(!findingId);
  }, [findingId]);

  const showAttachmentsPanel = attachmentsPreference === null ? showAttachments : attachmentsPreference;

  return (
    <FullWidthContainer style={{ paddingBottom: 0 }}>
      <Navbar
        paths={paths}
        action={
          <CTAButton isSecondary style={{ width: "15rem" }} onClick={() => setAttachmentsPreference(!showAttachmentsPanel)}>
            {showAttachmentsPanel ? "Hide" : "Show"} attachments
          </CTAButton>
        }
      />
      <StyledContainer id="scrolling-element" scrollX="auto" scrollY="unset">
        <div className="side-list left">
          <FindingsList engagementId={engagementId} findingsData={findingsData} customerId={customerId} />
        </div>
        <div className="main-body" style={{ minWidth: !showAttachmentsPanel && "calc(100% - 44.5rem)" }}>
          {isCreateFinding ? (
            <CreateFinding reload={findingsData.reload} engagementId={engagementId} customerId={customerId} />
          ) : findingId ? (
            <SingleFinding
              key={findingId}
              finding={finding}
              findingId={findingId}
              attachments={attachments}
              engagementId={engagementId}
              reload={findingsData.reload}
              customerId={customerId}
            />
          ) : engagement.id ? (
            <UpsertEngagement data={engagement} endCreation={reload} hide={handleHide} findings={findings} attachments={attachments} setNewCustomerId={setNewCustomerId}/>
          ) : (
            <Spinner />
          )}
        </div>
        <div className="side-list right">
          <AttachmentsList targetId={engagementId} setAttachments={setAttachments} />
        </div>
      </StyledContainer>
    </FullWidthContainer>
  );
};

const StyledContainer = styled(ScrollContainer)`
  display: flex;
  flex: 1;
  & > .side-list {
    min-width: 40rem;
    max-width: 40rem;
    margin-top: 1px;
    padding-bottom: 1.5rem;
    width: 40rem;
    display: flex;
    flex-flow: column nowrap;
    &.left {
      padding-right: 1.5rem;
      border-right: 1px solid ${(props) => props.theme.headerOutline};
      margin-right: 1.5rem;
    }
    &.right {
      padding-left: 1.5rem;
      border-left: 1px solid ${(props) => props.theme.headerOutline};
      margin-left: 1.5rem;
    }
  }
  & > .main-body {
    flex: 1;
    margin-top: 0.7rem;
    display: flex;
    padding-bottom: 3rem;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s;
    & > div:not(.spinner) {
      width: 100%;
      box-sizing: border-box;
    }
    & > .spinner {
      flex: 1;
    }
  }
`;
