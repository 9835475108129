import React, { useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";

import { Modal } from "lib/components/Modal";
import { fetchData } from "lib/helpers/fetchData";
import { getFormValues } from "lib/helpers/getFormValues";
import { ReleaseForm } from "./ReleaseForm";

const StyledModal = styled(Modal)`
  && {
    width: 80rem;
  }
`;

export const EditRelease = ({ show, hide, refresh, release }) => {
  const [errors, setErrors] = useState([]);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    let editedRelease = getFormValues(formRef.current);
    let errors = [];

    if (!editedRelease.title) errors.push("Missing title");
    if (!editedRelease.version) errors.push("Missing version");
    if (!editedRelease.date) errors.push("Missing date");
    if (!moment(editedRelease.date).isValid()) errors.push("Invalid date");

    setErrors(errors);
    if (errors.length) return;

    editedRelease.date = moment(editedRelease.date).toISOString();

    fetchData(`${process.env.REACT_APP_ADMIN_API}/releases/${release.id}`, JSON.stringify(editedRelease), "PUT")
      .then((d) => refresh(d))
      .catch((e) => {
        console.log(e?.error?.message || e?.error || e);
        setErrors([e?.error?.message || e?.error || e]);
      });
  };

  return (
    <StyledModal showModal={show} hide={hide} title="Create Release">
      <ReleaseForm release={release} hide={hide} handleSubmit={handleSubmit} isEditMode errors={errors} ref={formRef} />
    </StyledModal>
  );
};
