import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Activities as ActivitiesPage } from "./activities";
import { Customers as CustomersPage } from "./Customers";
import { Users as UsersPage } from "./Users";
import { useIsFeatureEnabled, flags } from "lib/feature-flagging/treatments";

export const Customers = () => {
  const legacyModeEnabled = useIsFeatureEnabled(flags.CLARITY_ADMIN_LEGACY_USER_MANAGEMENT);

  const routes = [
    { name: "Customers", link: "/customers/", disableBottomMargin: true },
    ...(legacyModeEnabled ? [{ name: "Users", link: "/customers/users" }] : []),
    { name: "Activities", link: "/customers/activities" },
  ];

  return (
    <PageContainer name="Customers" link="/customers" routes={routes}>
      {legacyModeEnabled && <ErrorHandledRoute path="/customers/users" component={UsersPage} />}
      <ErrorHandledRoute path="/customers/activities" component={ActivitiesPage} />
      <ErrorHandledRoute path="/customers/" component={CustomersPage} />
    </PageContainer>
  );
};
