import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { StyledFilters } from "lib/styles/general";
import { Searchbar } from "lib/components/Searchbar";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { Table } from "lib/components/Table";
import { SortableHeader } from "lib/components/SortableHeader";
import { LinkRow } from "lib/components/LinkRow";
import { ReviewBox } from "../../reviews";
import { useQuery } from "lib/helpers/useQuery";
import { CTAButton } from "lib/components/CTAButton";

export const FindingsList = ({ engagementId, findingsData, customerId }) => {
  const history = useHistory();
  // We may have an optional finding id param to represent the current finding
  const { findingId } = useQuery();
  const { params, setParams, ...findings } = findingsData;

  const handleSubmitNew = () => {
    history.push(`/pentest/engagements/engagement/${customerId}/${engagementId}?isCreateFinding=true`);
  };

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: (sort) => setParams((p) => ({ ...p, ...sort })),
  };

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  return (
    <>
      <div>
        <StyledFilters>
          <Searchbar {...search} placeholder="Filter engagement findings" small />
          <hr />
          <CTAButton onClick={handleSubmitNew} isSecondary>
            Add Finding
          </CTAButton>
        </StyledFilters>
        <ScrollContainer className="scroll">
          <InfiniteScroll {...findings} useWindow={false} noDataText="No findings added yet">
            <Table tableStyle={{ tableLayout: "fixed" }} small>
              <thead>
                <tr>
                  <th style={{ width: "8rem" }}></th>
                  <SortableHeader field="title" {...sort} headerStyle={{ paddingLeft: "0.3rem" }}>
                    Finding Title
                  </SortableHeader>
                  <SortableHeader field="severity" {...sort} style={{ justifyContent: "flex-end" }} headerStyle={{ width: "3.5rem" }}>
                    Severity
                  </SortableHeader>
                  <SortableHeader field="cvssScore" {...sort} style={{ justifyContent: "flex-end" }} headerStyle={{ width: "2.5rem" }}>
                    CVSS
                  </SortableHeader>
                </tr>
              </thead>
              <tbody>
                {findings.data.result.map((finding) => (
                  <LinkRow
                    key={finding.id}
                    active={finding.id === findingId}
                    path={`/pentest/engagements/engagement/${customerId}/${engagementId}?findingId=${finding.id}`}
                  >
                    <td style={{ padding: 0, paddingLeft: "0.2rem" }}>{<ReviewBox status={finding.status} />}</td>
                    <td style={{ padding: 0, paddingLeft: "0.2rem" }}>{finding.title}</td>
                    <td style={{ paddingLeft: 0, textAlign: "right" }}>{finding.severity}</td>
                    <td style={{ paddingLeft: 0, textAlign: "right" }}>{finding.cvssScore}</td>
                  </LinkRow>
                ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </ScrollContainer>
      </div>
    </>
  );
};
