import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Consulting as ConsultingPage } from "./Consulting";

export const Consulting = () => {
  const routes = [{ name: "Consulting", link: "/consulting/" }];

  return (
    <PageContainer name="Consulting" link="/consulting" routes={routes}>
      <ErrorHandledRoute path="/consulting/" component={ConsultingPage} />
    </PageContainer>
  );
};
