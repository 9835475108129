import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchData, useFetch, Spinner, Error } from "lib/helpers/fetchData";

import { Navbar } from "lib/components/Navbar";
import { Tabs } from "lib/components/Tabs";

import { Artifacts } from "./components/Artifacts";
import { Text, Html, Raw } from "./components/Body";
import { Headers } from "./components/Headers";
import { Preview } from "./components/Preview";
import { Evaluate } from "./components/Evaluate";
import { Comments } from "../../artifacts/single-artifact/components/Comments";
import { Rules } from "../../artifacts/single-artifact/components/Rules";
import { Details } from "./components/Details";
import { Card } from "lib/components/Card";
import { TagsContext } from "apps/phish";

const loadTicket = async ({ customerId, ticketId }) => {
  return await fetchData(`${process.env.REACT_APP_PHISH_API}/tickets/${customerId}/${ticketId}?deep=true`);
};

export const SingleTicket = () => {
  const { customerId, ticketId } = useParams();
  const state = useFetch(loadTicket, { customerId, ticketId });
  const tagsState = useContext(TagsContext);

  useEffect(() => {
    sessionStorage.setItem("last_ticket", state.data?.result?.fid);
  }, [state]);

  const paths = [
    { text: "Tickets", link: "/phish/tickets" },
    { text: "Ticket " + (state.data?.result?.fid || ""), link: "#" },
  ];

  return (
    <>
      <Navbar paths={paths} />
      <SingleTicketContent {...state} tags={tagsState} />
    </>
  );
};

const SingleTicketContent = ({ data, error, isLoading, reload, setData, tags }) => {
  if (error || data?.success === false) return <Error message={error} />;
  if (isLoading && !data) return <Spinner />;

  const optimiseChanges = (changedData) => {
    let newData = data;
    newData.result = changedData;
    setData(newData);
  };

  const types = ["url", "file"];

  const artifacts = types.map((type) => (
    <Artifacts name={`${type}s`} key={type} data={data.result} type={type} tagsState={tags} refresh={reload} />
  ));
  const artifactsCounts = types.map(
    (type) =>
      data.result.mail.children.filter((a) => a.type === type && a.tags.every((t) => t.name.toLowerCase() !== "recurring benignity")).length
  );

  const artifactComments = data.result.mail.children
    .map((artifact) => {
      return artifact.comments.map((_comment) => {
        let comment = _comment;
        if (typeof comment === "string") comment = JSON.parse(comment);
        comment.target = {
          id: artifact.id,
          fid: artifact.fid,
          severity: artifact.severity,
          confidence: artifact.confidence,
          type: artifact.type,
          properties: artifact.properties,
          customer: data.result.customer,
          ticketId: data.result.id,
          isRecBen: artifact.tags.some((t) => t.name.toLowerCase() === "recurring benignity"),
        };
        comment.isFromArtifact = true;
        return comment;
      });
    })
    .flat();

  const comments = [...data.result.comments, ...artifactComments];

  const nonRecBenArtifactCount = artifactComments.filter((comment) => !comment.target.isRecBen)?.length;

  const counts = [
    ...artifactsCounts,
    data?.result?.comments.length + nonRecBenArtifactCount,
    `${data?.result?.rules?.filter((r) => r?.didFindEvil)?.length}/${data?.result?.rules?.length}`,
  ];

  const commentsData = {
    ...{ ...data.result, comments: comments },
    id: data.result.mail.id,
  };

  return (
    <>
      <Card childrenStyle={{ display: "flex", flexFlow: "wrap-reverse", alignItems: "flex-end" }}>
        <Details data={data.result} />
        <Evaluate data={data.result} refresh={reload} setData={optimiseChanges} tagsState={tags} />
      </Card>
      <Tabs counts={counts} id="ticket">
        {artifacts}
        <Comments name="Comments" data={commentsData} refresh={reload} />
        <Rules name="Detection" data={data.result} />
        <Headers name="Headers" data={data.result} />
        <Text name="Text" data={data.result} />
        <Html name="Html" data={data.result} />
        {data.result?.mail?.location?.url && <Raw name="Raw" data={data.result?.mail?.location?.url} />}
        <Preview name="Preview" data={data.result} />
      </Tabs>
    </>
  );
};
