import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const StyledNavbar = styled.div`
  height: 3.5rem;
  width: 100%;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.cardOutline};
  border-radius: 0.8rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: stretch;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.mobileWidth}) {
    display: none;
  }

  & > span {
    flex: 1;
  }

  & > .children {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.cardBackground};
  color: ${(props) => props.theme.lightText};
  border-right: 1px solid ${(props) => props.theme.headerOutline};
  width: calc(68px - 2rem);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    background: ${(props) => props.theme.accentCardBackground};
    color: ${(props) => props.theme.accentText};
  }
`;

const StyledPath = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.lightText};
  text-decoration: none;
  font-size: 1.33rem;
  padding: 0 15px;
  background: ${(props) => props.theme.cardBackground};
  transition: all 0.3s;
  &:hover {
    background: ${(props) => props.theme.accentCardBackground};
  }
  border-right: 1px solid transparent;
  &:not(:last-of-type) {
    border-right: 1px solid ${(props) => props.theme.headerOutline};
  }
`;

export const Navbar = ({ paths, action }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledNavbar className="navbar">
      <StyledLink onClick={() => history.goBack()}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </StyledLink>
      {paths.map((path, i) => (
        <StyledPath
          key={i}
          to={path.link}
          onClick={(e) => {
            if (path.goBack) {
              e.preventDefault();
              e.stopPropagation();
              history.goBack();
            }
          }}
        >
          {path.text}
        </StyledPath>
      ))}
      <span />
      <div className="children">{action}</div>
    </StyledNavbar>
  );
};
