import { CTAButton } from "lib/components/CTAButton";
import { Input } from "lib/components/Input";
import { Modal } from "lib/components/Modal";
import { TextArea } from "lib/components/TextArea";
import { fetchData } from "lib/helpers/fetchData";
import { useState } from "react";
import styled from "styled-components";
import { REVIEW_STATUSES_TEXT, REVIEW_STATUSES } from "./index";

const updateEntity = (entity, updateUrl) => fetchData(updateUrl, JSON.stringify(entity), "PUT");

export const Review = ({ entity, entityId, updateUrl, reload, readOnly, ...props }) => {
  const [justify, setJustify] = useState({ callback: null, actionText: "", isRequired: false });
  const showJustifyModal = !!justify.callback;

  const hideJustifyModal = () => setJustify({ callback: null });

  const renderReviewActions = () => {
    switch (entity.status) {
      case REVIEW_STATUSES.DRAFT:
        return (
          <StyledActions>
            <CTAButton style={{ width: "15rem" }} onClick={() => updateStatus(REVIEW_STATUSES.PENDING_REVIEW)}>
              Publish for Review
            </CTAButton>
          </StyledActions>
        );
      case REVIEW_STATUSES.PENDING_REVIEW:
        const callbackApprove = () => updateStatus(REVIEW_STATUSES.APPROVED);
        const callbackReject = () => updateStatus(REVIEW_STATUSES.REQUIRES_CHANGES);
        return (
          <StyledActions>
            <CTAButton isSecondary onClick={() => setJustify({ callback: callbackReject, actionText: "Reject", isRequired: true })}>
              Reject
            </CTAButton>
            <CTAButton onClick={() => setJustify({ callback: callbackApprove, actionText: "Approve" })}>Approve</CTAButton>
          </StyledActions>
        );
      case REVIEW_STATUSES.REQUIRES_CHANGES:
        const callback = () => updateStatus(REVIEW_STATUSES.PENDING_REVIEW);
        return (
          <StyledActions>
            <CTAButton style={{ width: "15rem" }} onClick={() => setJustify({ callback, actionText: "Resubmit" })}>
              Resubmit for Review
            </CTAButton>
          </StyledActions>
        );
      default:
        return null;
    }
  };

  const updateStatus = async (status) => {
    const updatedEntity = { ...entity, status };
    await updateEntity(updatedEntity, updateUrl);
    reload();
  };

  return (
    <StyledContainer {...props}>
      <div className="status-text">
        <div className="status-text-title">Status:</div>
        <div className="status-text-value">{REVIEW_STATUSES_TEXT[entity.status]}</div>
      </div>
      {readOnly && renderReviewActions()}
      {showJustifyModal && <JustifyModal {...justify} isRequired={false} hide={hideJustifyModal} entityId={entityId} />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.5rem;
  align-self: flex-start;
  height: 4.9rem;
  padding: 0 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .status-text {
    display: flex;
    align-items: center;
    gap: 2rem;
    & > .status-text-title {
      font-weight: bold;
      font-size: 1.3rem;
    }
    & > .status-text-value {
      text-transform: uppercase;
      font-size: 1.5rem;
    }
  }
`;

const StyledActions = styled.div`
  display: flex;
  gap: 1rem;
`;

const createComment = async ({ targetId, text }) => {
  return await fetchData(`${process.env.REACT_APP_PENTEST_API}/comments/create`, JSON.stringify({ targetId, text, isApproval: true }), "POST");
};

const JustifyModal = ({ callback, actionText, isRequired, hide, entityId }) => {
  const [error, setError] = useState(null);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (isRequired && !text) {
      return setError("Please provide a justification");
    }
    const parsedJustificationText = text ? `Justification for approval: ${text}` : "No justification provided during approval";
    setIsLoading(true);
    await createComment({ targetId: entityId, text: parsedJustificationText });
    await callback();
    setIsLoading(false);
    hide();
  };

  return (
    <Modal
      hide={hide}
      actionText={actionText}
      actionCommand={handleSubmit}
      isLoading={isLoading}
      width="80rem"
      subtitle={`Please provide ${isRequired ? "" : "optional"} justification`}
    >
      <Input label="Justification" error={error} onChange={(e) => setText(e.target.value)}>
        <TextArea />
      </Input>
    </Modal>
  );
};
