import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { KeyValue } from "lib/components/KeyValue";
import { useFetch } from "lib/helpers/fetchData";
import { Spinner } from "lib/components/Spinner";
import { Error } from "lib/helpers/fetchData";
import { Table } from "lib/components/Table";
import { Tooltip } from "lib/components/Tooltip";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export const Auth = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    const values = getFormValues(formRef.current);
    await fetchData(`${process.env.REACT_APP_CLARITY_API}/idp?group=${data.groupName}`, JSON.stringify({ ...values }), "POST");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };

  const handleDisableSSO = async () => {
    setIsLoading(true);
    await fetchData(`${process.env.REACT_APP_CLARITY_API}/idp?group=${data.groupName}`, null, "DELETE");
    setIsLoading(false);
    reload();
  };

  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <div style={{ position: "absolute", right: 0, top: 0, zIndex: 99, display: "flex", alignItems: "center" }}>
          {data?.auth?.sso?.enabled ? (
            <CTAButton isLoading={isLoading} onClick={handleDisableSSO}>
              Disable SSO
            </CTAButton>
          ) : (
            <CTAButton isLoading={isLoading} onClick={() => setIsEditMode(true)}>
              Enable SSO
            </CTAButton>
          )}
        </div>
      )}
      <StyledForm ref={formRef}>
        <section>
          <KeyValue text="SSO" value={data?.auth?.sso?.enabled || isEditMode ? "Enabled" : "Disabled"} />
          <Input name="domainHint" label="Domain Hint" defaultValue={data?.auth?.sso?.domain_hint} readOnly={!isEditMode} />
          <Input name="clientId" label="Client ID" defaultValue={data?.auth?.sso?.client_id} readOnly={!isEditMode} />
          {isEditMode && <Input name="clientSecret" label="Client Secret" type="password" />}
        </section>
        {data?.auth?.sso?.enabled && (
          <section>
            <h1>Identity Provider</h1>
            <IdentityProvider customer={data} refresh={reload} />
            <h1 style={{ marginTop: "2rem" }}>User Flows</h1>
            <UserFlows customer={data} />
          </section>
        )}
      </StyledForm>
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
      )}
    </div>
  );
};

const loadIdentityProviders = async () => {
  return await fetchData(`${process.env.REACT_APP_ADMIN_API}/idp`);
};

const IdentityProvider = ({ customer }) => {
  const { data, isLoading, error } = useFetch(loadIdentityProviders, { key: "identityProviders" });

  if (isLoading) return <Spinner />;
  if (error) return <Error />;

  const idp = data.result?.find((idp) => idp.name.toLowerCase() === customer.code.toLowerCase()) || {};

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Client Id</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{idp.name}</td>
          <td>{idp.type}</td>
          <td>{idp.clientId}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const loadUserFlows = async () => {
  let userflows = await fetchData(`${process.env.REACT_APP_ADMIN_API}/userflows`);
  for (let i = 0; i < userflows.result.length; i++) {
    const identities = await fetchData(`${process.env.REACT_APP_ADMIN_API}/userflow/${userflows.result[i].id}/identities`);
    userflows.result[i].identities = identities.result;
  }
  return userflows;
};

const UserFlows = ({ customer }) => {
  const { data, isLoading, error, reload } = useFetch(loadUserFlows, { key: "userflows" });

  if (isLoading) return <Spinner />;
  if (error) return <Error />;

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Identities</th>
          <th>Is the IdP present?</th>
        </tr>
      </thead>
      <tbody>
        {data.result.map((userflow, i) => (
          <tr key={i}>
            <td>{userflow.id}</td>
            <td>{userflow.identities.length}</td>
            <td style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", height: "4rem" }}>
              <>
                {!!userflow.identities.find((i) => i.name.toLowerCase() === customer.code.toLowerCase()) ? (
                  "Yes"
                ) : (
                  <>
                    {"Missing! Please add in "}
                    <a
                      style={{ margin: "0 0.4rem" }}
                      href="https://portal.azure.com/#blade/Microsoft_AAD_B2CAdmin/TenantManagementMenuBlade/userJourneys"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Azure Portal
                    </a>
                  </>
                )}
                <Tooltip style={{ marginLeft: "1rem" }} icon={faSync} hoverText="reload" xHoverPos="left" yHoverPos="center" clickAction={reload} />
              </>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
