import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";
import { Card } from "lib/components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFolder, faChevronRight, faArrowUp, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const loadBlobs = async () => {
  return await fetchData(`${process.env.REACT_APP_ADMIN_API}/blob/file-system`);
};

const loadContainers = async () => {
  return fetchData(`${process.env.REACT_APP_ADMIN_API}/blob/containers`);
};

const StyledExplorer = styled(Card)`
  width: 100%;
  max-width: 1000px;
  padding: 1rem;
`;

const StyledAddressBar = styled.div`
  & > .path {
    background: ${(props) => props.theme.disabledCardBackground};
    border: 1px solid ${(props) => props.theme.headerOutline};
    border-radius: 0.3rem;
    padding: 0.3rem 0.5rem;
    display: flex;
    align-items: center;
    & > .directional-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.5rem 0.5rem;
      border-radius: 0.3rem;
      &:hover {
        background: ${(props) => props.theme.accentCardBackground};
      }
      &.up {
        margin-left: 0.5rem;
      }
      &.disabled {
        opacity: 0.5;
        background: ${(props) => props.theme.disabledCardBackground} !important;
        cursor: default;
      }
    }
    & > hr {
      width: 1px;
      height: 2rem;
      background: ${(props) => props.theme.lightText};
      border: none;
      margin: 0 0.5rem;
      opacity: 0.3;
    }
    & > .path-item {
      cursor: pointer;
      font-size: 1.2rem;
      color: ${(props) => props.theme.mediumText};
      padding: 0.7rem;
      border-radius: 0.3rem;
      background: ${(props) => props.theme.disabledCardBackground};
      transition: all 0.3s;
      &:hover {
        background: ${(props) => props.theme.accentCardBackground};
      }
    }
    & > .path-icon {
      margin: 0 0.35rem;
      color: ${(props) => props.theme.iconBackground};
    }
  }
`;

const StyledContentPanel = styled.div`
  margin-top: 1rem;
  background: ${(props) => props.theme.disabledCardBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.3rem;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
`;

const StyledContentItem = styled.div`
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.headerOutline};
  min-width: 10rem;
  max-width: 10rem;
  height: 10rem;
  margin: 0.5rem;
  flex: 1;
  border-radius: 0.3rem;
  padding: 0.5rem 0.35rem;
  display: flex;
  flex-flow: column nowrap;
  background: ${(props) => props.theme.disabledCardBackground};
  transition: all 0.3s;
  &:hover {
    background: ${(props) => props.theme.cardBackground};
  }
  & > .icon {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    color: ${(props) => props.theme.iconBackground};
  }
  & > .content {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const StyledContainerPanel = styled.div``;

export const Explorer = () => {
  const [container, setContainer] = useState(null);
  const { data, isLoading, error } = useFetch(loadContainers);

  useEffect(() => {
    if (!container && data) {
      setContainer(data.result[0].name);
    }
  }, [container, data]);

  if (isLoading || !container) return <Spinner />;
  if (error) return <Error />;

  return <ExplorerPanel key={container} container={container} containers={data?.result} setContainer={setContainer} />;
};

export const ContainerPanel = ({ containers, setContainer }) => {
  return (
    <StyledContainerPanel>
      {containers.map((c, i) => (
        <div key={i} onClick={() => setContainer(c.name)}>
          {c.name}
        </div>
      ))}
    </StyledContainerPanel>
  );
};

export const ExplorerPanel = ({ container, containers, setContainer }) => {
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [history, setHistory] = useState([]);
  const { data, isLoading, error } = useFetch(loadBlobs, { container });

  const push = useCallback((item) => {
    setHistoryIndex((i) => {
      setHistory((h) => [...h.slice(0, i + 1), item]);
      return i + 1;
    });
  }, []);

  const location = history[historyIndex];

  const goForward = () => setHistoryIndex((i) => i + 1);
  const goBack = () => setHistoryIndex((i) => i - 1);

  const canGoForward = useCallback(() => {
    return historyIndex < history.length - 1;
  }, [history, historyIndex]);

  const canGoBack = useCallback(() => {
    return historyIndex > 0;
  }, [historyIndex]);

  const getParentLocation = useCallback(() => {
    const findParent = (all, current) => {
      if (!all || !all.children) return null;
      if (all?.children?.some((child) => child.id === current.id)) return all;
      return all.children.map((child) => findParent(child, current)).flat(Infinity);
    };
    const parent = findParent(data.result, location);
    if (parent.length === 1) return parent[0];
    return Array.isArray(parent) ? null : parent;
  }, [data, location]);

  const goToParent = useCallback(() => {
    const parent = getParentLocation();
    if (parent) push(parent);
  }, [getParentLocation, push]);

  const hasParent = () => !!getParentLocation();

  const getPathLocation = useCallback(
    (path) => {
      const findPath = (all, path) => {
        if ((all.fullPath || all.path) === path) return all;
        return all.children.map((child) => findPath(child, path)).flat(Infinity);
      };
      const parent = findPath(data.result, path);
      if (parent.length === 1) return parent[0];
      return Array.isArray(parent) ? null : parent;
    },
    [data]
  );

  const goToPath = useCallback(
    (path) => {
      const pathLocation = getPathLocation(path);
      if (pathLocation) push(pathLocation);
    },
    [getPathLocation, push]
  );

  useEffect(() => {
    if (data) push(data.result);
  }, [data, push]);

  if (error) return <Error message={error} />;
  if (isLoading || !location) return <Spinner />;

  return (
    <StyledExplorer>
      <AddressBar
        goUp={goToParent}
        canGoUp={hasParent}
        goBack={goBack}
        canGoBack={canGoBack}
        goForward={goForward}
        canGoForward={canGoForward}
        goToPath={goToPath}
        data={data.result}
        location={location}
      />
      <div style={{ display: "flex" }}>
        <ContainerPanel containers={containers} setContainer={setContainer} />
        <ContentPanel content={location.children} push={push} />
      </div>
    </StyledExplorer>
  );
};

const ContentItem = ({ name, type, handleClick }) => {
  const renderIcon = () => {
    switch (type) {
      case "file":
        return <FontAwesomeIcon icon={faFile} />;
      case "folder":
        return <FontAwesomeIcon icon={faFolder} />;
      case "container":
        return <FontAwesomeIcon icon={faFolder} />;
      default:
        return null;
    }
  };
  return (
    <StyledContentItem onClick={handleClick}>
      <div className="icon">{renderIcon()}</div>
      <div className="content">{name}</div>
    </StyledContentItem>
  );
};

const ContentPanel = ({ content = [], push }) => {
  return (
    <StyledContentPanel>
      {content.map((content, i) => {
        const handleClick = () => push(content);
        return <ContentItem key={i} {...content} handleClick={handleClick} />;
      })}
    </StyledContentPanel>
  );
};

const DirectionalIcon = ({ direction, disabled, onClick }) => {
  const icon = direction === "left" ? faArrowLeft : direction === "right" ? faArrowRight : faArrowUp;
  const className = ["directional-button", direction, disabled && "disabled"].filter(Boolean).join(" ");
  return <FontAwesomeIcon icon={icon} className={className} onClick={() => !disabled && onClick()} />;
};

const AddressBar = ({ location, goUp, canGoUp, goBack, canGoBack, goForward, canGoForward, goToPath }) => {
  console.log(location);
  return (
    <StyledAddressBar>
      <div className="path">
        <DirectionalIcon direction="left" onClick={goBack} disabled={!canGoBack()} />
        <DirectionalIcon direction="right" onClick={goForward} disabled={!canGoForward()} />
        <DirectionalIcon direction="up" onClick={goUp} disabled={!canGoUp()} />
        <hr />
        {(location.fullPath || location.path)
          .split("/")
          .map((item, i) => {
            const currentPath = location.fullPath || location.path
              .split("/")
              .slice(0, i + 1)
              .join("/");
            console.log(currentPath, location);
            return (
              <React.Fragment key={i}>
                {i > 0 && <FontAwesomeIcon className="path-icon" icon={faChevronRight} />}
                <div className="path-item" onClick={() => goToPath(currentPath)}>
                  {item}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </StyledAddressBar>
  );
};
