import React from "react";
import styled from "styled-components";

const StyledScroll = styled.div`
  overflow-y: ${props => props.scrolly};
  overflow-x: ${props => props.scrollx};
  transform: ${props => props.flipy && "scaleY(-1)"};
  & > * {
    transform: ${props => props.flipy && "scaleY(-1)"};
  }

  @media (min-width: ${props => props.theme.mobileWidth}) {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      display: ${props => props.hide && "none"};
    }

    &::-webkit-scrollbar-corner {
      background: ${props => props.theme.cardBackground};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: ${props => props.theme.scrollbarColor};
      transition: background 0.3s;
      &:hover {
        background: ${props => props.theme.scrollbarHover};
      }
    }
  }
`;

export const ScrollContainer = ({ scrollX = "unset", scrollY = "auto", children, hide, flipY, ...props }) => {
  return (
    <StyledScroll scrollx={scrollX} scrolly={scrollY} hide={hide} flipy={flipY} {...props}>
      {children}
    </StyledScroll>
  );
};
