import React, { useCallback, useState } from "react";
import { themes } from "../styles/themes";
import { ThemeProvider as Theme } from "styled-components";
import { getValueOrDefault } from "lib/helpers/getValueOrDefault";

export const DEFAULT_THEME = "midnight";

export const ThemeContext = React.createContext();

const getThemeKey = () => {
  const themeKey = localStorage.getItem("theme");
  if (!themeKey || themeKey === "null") return null;
  return themeKey;
};

const getTheme = (key) => {
  let theme = themes[key];
  if (!theme || typeof theme !== "object") theme = themes[DEFAULT_THEME];

  theme.blur = getValueOrDefault("blur_effects", "true") === "true";
  theme.uiScale = getValueOrDefault("ui_scale", "10.5px");

  return theme;
};

export const ThemeProvider = ({ children }) => {
  const [themeKey, setThemeKey] = useState(getThemeKey() || DEFAULT_THEME);
  const [theme, setTheme] = useState(getTheme(themeKey));

  const modifyTheme = useCallback((modification) => {
    setTheme((t) => ({ ...t, ...modification }));
  }, []);

  const setThemeByKey = (key) => {
    setThemeKey(key);
    setTheme(getTheme(key));
  };

  return (
    <Theme theme={theme}>
      <ThemeContext.Provider value={{ theme, themeKey, themes, modifyTheme, setThemeByKey }}>{children}</ThemeContext.Provider>
    </Theme>
  );
};
