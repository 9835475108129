import React from "react";
import styled from "styled-components";

const StyledRadioButton = styled.label`
  user-select: none;
  cursor: ${(props) => !props.readOnly && "pointer"};
  min-height: ${(props) => !props.readOnly && "3rem"};
  color: ${(props) => props.theme.text};
  display: flex;
  align-items: center;
  padding: ${(props) => !props.readOnly && "0 1rem"};
  border: ${(props) => !props.readOnly && `1px solid ${props.theme.headerOutline}`};
  border-radius: 0.8rem;
  background: ${(props) => props.theme.cardBackground};
  outline-color: ${(props) => props.theme.secondaryButtonColor};
  font-size: ${(props) => (props.readOnly ? "1.5rem" : "1.33rem")};
  font-weight: ${(props) => props.readOnly && "bold"};
  transition: all 0.3s;
  &:hover {
    background: ${(props) => !props.readOnly && props.theme.accentCardBackground};
  }
`;

const StyledIconLabel = styled.label`
  margin-right: 0.75rem;
  cursor: pointer;

  & > input {
    display: none;
    transition: all 0.2s;

    &:checked + .radio > .icon {
      border-color: ${(props) => props.theme.secondaryButtonColor};
      &::after {
        opacity: 1 !important;
      }
    }
  }

  & > .radio {
    padding: 0.75rem 1rem;
    margin: 0 -1rem;
    width: 100%;
    transition: all 0.2s;

    &:hover {
      & > .icon {
        border-color: ${(props) => props.theme.secondaryButtonColor};
      }
    }

    & > .icon {
      width: 1.33rem;
      height: 1.33rem;
      border-radius: 1.33rem;
      border: 1px solid ${(props) => props.theme.headerOutline};
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;

      &::after {
        position: absolute;
        content: "";
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 0.75rem;
        opacity: 0;
        background: ${(props) => props.theme.secondaryButtonColor};
        transition: all 0.2s;

        &:hover {
          background: ${(props) => props.theme.secondaryButtonHover};
        }
      }
    }
  }
`;

export const RadioButton = ({ name, label, field, labelStyle = {}, readOnly, onChange, ...props }) => {
  const value = field || label.toLowerCase();
  const _key = props.defaultChecked;

  const handleClick = () => {
    if (!onChange) return;
    onChange(value);
  };
  if (readOnly && !props.defaultChecked) return null;

  return (
    <StyledRadioButton style={{ ...labelStyle }} tabIndex={0} readOnly={readOnly} onClick={handleClick} key={_key}>
      {!readOnly && (
        <StyledIconLabel>
          <input type="radio" name={name} data-value={value} {...props} />
          <div className="radio">
            <div className="icon" />
          </div>
        </StyledIconLabel>
      )}
      {label}
    </StyledRadioButton>
  );
};

const StyledRadioButtonGroup = styled.span`
  margin-top: 0.3rem;
  & > label:first-child:last-child {
    margin: 0.33rem 0.1rem;
  }
`;

export const RadioButtonGroup = ({ onChange, readOnly, children, ...props }) => {
  return (
    <StyledRadioButtonGroup {...props}>
      {React.Children.map(children, (child) => React.cloneElement(child, { onChange, readOnly }))}
    </StyledRadioButtonGroup>
  );
};
