import React from "react";
import styled from "styled-components";

const StyledSelect = styled.select`
  cursor: pointer;
  outline: none;
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.3rem;
  background: ${(props) => props.theme.cardBackground};
  font: inherit;
  font-size: 1.33rem;
  color: ${(props) => props.theme.text};
  height: 2.7rem;
  min-height: 2.7rem;
  width: 9rem;
  min-width: 9rem;
  padding: 0.2rem 0.4rem;
  transition: all 0.3s;
  &:focus {
    background: ${(props) => props.theme.accentCardBackground};
    border-color: ${(props) => props.theme.secondaryButtonColor};
  }
`;

const StyledParagraph = styled.p`
  width: 100% !important;
  margin: 0.33rem 0 1rem 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.text};
  white-space: pre-wrap;
`;

export const Select = React.forwardRef(({ children, className, childrenLookup = true, ...props }, ref) => {
  const getTextFromValue = (value) => {
    if (!childrenLookup || !props.readOnly) return value;
    const kids = children.length === 2 ? children[1] : children;
    const selected = Array.isArray(kids) ? kids?.find((k) => k.props.value === value) : kids;
    return selected && selected.props.children;
  };


  if (props.readOnly) return <StyledParagraph ref={ref} {...props} value={props.defaultValue}>{getTextFromValue(props.defaultValue)}</StyledParagraph>;
  return (
    <StyledSelect {...props} className={`${className || ""} select`} ref={ref}>
      {children}
    </StyledSelect>
  );
});
