import styled from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;

  .ring {
    fill: none;
    stroke: none;
    transform: scaleX(-1);

    .full-ring {
      stroke-width: 10px;
      stroke: ${(props) => props.color};
      opacity: 0.3;
    }

    .remaining-ring {
      stroke: ${(props) => props.color};
      stroke-width: 10px;
      stroke-linecap: round;

      transform: rotate(90deg);
      transform-origin: center;
    }

    .ball-container {
      width: 10px;
      height: calc(100% - 10px);
      transform-origin: center;
    }

    .ball {
      filter: drop-shadow(-1px 0 1.75px black);
      fill: ${(props) => props.color};
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CircleIndicator = ({ percentageComplete, label, color = "red", children, className = "" }) => {
  const rotation = 360 * percentageComplete;
  return (
    <StyledContainer className={`circle-indicator ${className}`} color={color}>
      <svg className="ring" viewBox="0 0 100 100">
        <circle className="full-ring" cx="50" cy="50" r="45" />

        <path
          className="remaining-ring"
          strokeDasharray={`${percentageComplete * 283} 283`}
          d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
        ></path>
        <g className="ball-container" style={{ transform: `rotate(-${rotation}deg)` }}>
          <clipPath id="cut-off">
            <rect x="40" y="0" width="10" height="10" />
          </clipPath>
          <circle className="ball" cx="50" cy="5" r="5" clipPath="url(#cut-off)" />
        </g>
      </svg>
      <span className="label">{children}</span>
    </StyledContainer>
  );
};
