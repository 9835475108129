import { useState } from "react";
import styled from "styled-components";
import { ClientFilters } from "./ClientFilters";
import { ClientsList } from "./ClientsList";

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Clients = () => {
  const [filters, setFilters] = useState({
    overallPriority: "",
    scopePriority: "",
    timePriority: "",
    budgetPriority: "",
    clientTier: "",
    search: "",
    pageSize: 60
  });

  const filtersKey = JSON.stringify(filters);

  const setFilter = (filter) => setFilters((f) => ({ ...f, ...filter }));

  return (
    <StyledContainer>
      <ClientFilters filters={filters} setFilter={setFilter} />
      <ClientsList key={filtersKey} params={filters} setFilter={setFilter} />
    </StyledContainer>
  )
  
}