import React from "react";
import styled from "styled-components";
import { Switch, useLocation } from "react-router-dom";

import { Header } from "./Header";
import { Settings } from "./Settings";
import { ErrorHandledRoute } from "./ErrorHandledRoute";

const StyledPageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 4rem);
  max-width: ${(props) => (props.wide ? "100%" : "1600px")};
  min-height: calc(100% - 6rem - 1px);
  margin: 2rem auto 0;
  padding-bottom: ${(props) => !props.fit && "10rem"};
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.mobileWidth}) {
    margin: 0 0 2rem 0;
    width: 100%;
    padding-bottom: 0;
  }
`;

export const PageContainer = ({ children, name = "Admin", link = "/", routes = [], showRouteLinks = true }) => {
  const { pathname } = useLocation();
  const [, app, path] = pathname.split("/");
  const currentPath = `/${[app, path].join("/")}`;

  // Sort the routes so depth is consistent, i.e. most specific (or long) route first
  const sortedRoutes = routes.sort((a, b) => (b.link.toLowerCase() > a.link.toLowerCase() ? 1 : -1));
  const currentRoute = sortedRoutes.find((r) => currentPath === r.link);

  return (
    <>
      <Header appLink={link} appName={name} routes={routes} showRouteLinks={showRouteLinks} />
      <StyledPageContainer wide={currentRoute?.isWide} fit={currentRoute?.disableBottomMargin}>
        <Switch>
          <ErrorHandledRoute path={link + "/settings"} component={Settings} />
          {children}
        </Switch>
      </StyledPageContainer>
    </>
  );
};
