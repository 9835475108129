import React, { useState } from "react";
import styled from "styled-components";
import { ConfirmModal } from "lib/components/Modal"; 
import { SubmitIoCToSentinel } from "./settings/MISPEventID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 
  faDatabase
} from "@fortawesome/free-solid-svg-icons";

import { ScrollContainer } from "lib/components/ScrollContainer";


const StyledContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledHeaderContainer = styled.div`
  padding: 2.2rem 1.5rem;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.disabledCardBackground};
  border-bottom: 1px solid ${(props) => props.theme.headerOutline};
  & > h1 {
    color: ${(props) => props.theme.text};
    margin: 0;
    flex: 1;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  & > section {
    display: flex;
    flex-flow: column;
    width: 300px;
    border-right: 1px solid ${(props) => props.theme.headerOutline};
    background: ${(props) => props.theme.disabledCardBackground};
    & > h1 {
      margin: 1.5rem 1.5rem;
      color: ${(props) => props.theme.text};
    }
  }
`;

const StyledTab = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
  transition: all 0.2s;
  background: ${(props) => (props.active ? props.theme.accentCardBackground : props.theme.disabledCardBackground)};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) => (props.active ? props.theme.text : "inherit")};
  & > svg {
    margin-right: 1rem;
    color: ${(props) => props.theme.text};
  }
  &:hover {
    background: ${(props) => props.theme.cardBackground};
  }
  &:first-of-type {
    margin-left: 0;
  }
  .status {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > div {
      width: 0.65rem;
      height: 0.65rem;
      border-radius: 50%;
      background: red;
      &.enabled {
        background: green;
      }
    }
  }
  user-select: none;
`;

const StyledPaneContainer = styled(ScrollContainer)`
  flex: 1;
  padding: 3rem;
  height: calc(100% - 6rem);
  box-sizing: border-box;
  & > *:not(.spinner) {
    flex: 1;
  }
`;

const TABS = {
  ICOS: "IoCs",
};

export const SingleCustomer = ({ data = {}, reload }) => {
  const [tab, setTab] = useState(TABS.GENERAL);
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });

  const renderTab = () => {
    switch (tab) {
      case TABS.GENERAL:
      case TABS.IOCS:
        return <SubmitIoCToSentinel customerId={data.id} reload/>;
      default:
        return null;
    }
  };

  return (
    <StyledContentContainer key={JSON.stringify(data)}>
      <StyledHeaderContainer>
        <h1>{data.name}</h1>
      </StyledHeaderContainer>
      <StyledContainer>
        <section>
          <StyledTab active={tab === TABS.IOCS} onClick={() => setTab(TABS.IOCS)}>
            <FontAwesomeIcon icon={faDatabase} /> Send IoC's from MISP
          </StyledTab>
        </section>
        <StyledPaneContainer>{renderTab()}</StyledPaneContainer>
      </StyledContainer>
      <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
    </StyledContentContainer>
  );
};
