import React, { useRef, useState } from "react";
import moment from "moment";
import { fetchData } from "lib/helpers/fetchData";
import { ErrorModal } from "lib/components/Modal";

import { StyledFilters } from "lib/styles/general";
import { UserModal } from "./components/settings/users";

import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { Table } from "lib/components/Table";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { useInfiniteScroll } from "lib/components/InfiniteScroll";
import { useDebouncedCallback } from "use-debounce/lib";
import { loadCustomersList } from "./Customers";
import { useFetch } from "lib/helpers/fetchData";
import { getFormValues } from "lib/helpers/getFormValues";
import { Modal } from "lib/components/Modal";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { Select } from "lib/components/Select";
import { toSentenceCase } from "lib/helpers/toSentenceCase";
import { CheckboxButton } from "lib/components/Checkbox";

export const Users = () => {
  const [data, setData] = useState({ result: [] });
  const [params, setParams] = useState({ search: "", pageSize: 40 });
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [usersModal, setUserModal] = useState({ showModal: false, user: {} });

  const url = `${process.env.REACT_APP_ADMIN_API}/users/`;
  const { loadItems, hasMore, isLoading, reload } = useInfiniteScroll({ url, params, data, setData });

  const handleShowCreateUser = () => setShowCreateUserModal(true);
  const handleHideCreateUser = () => setShowCreateUserModal(false);

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  const filters = (
    <StyledFilters style={{ display: "flex" }}>
      <Searchbar style={{ margin: 0 }} {...search} />
      <hr />
      <CTAButton onClick={handleShowCreateUser}>Create User</CTAButton>
    </StyledFilters>
  );

  return (
    <>
      <InfiniteScroll filters={filters} loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data}>
        {data.result.length ? (
          <Table tableStyle={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Last Login</th>
              </tr>
            </thead>
            <tbody>
              {data.result.map((user, i) => (
                <User key={i} user={user} setModal={setUserModal} />
              ))}
            </tbody>
          </Table>
        ) : (
          <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No users</p>
        )}
      </InfiniteScroll>
      {usersModal.showModal && (
        <UserModal
          key={usersModal.user?.id}
          {...usersModal}
          hide={() => setUserModal({ showModal: false })}
          reload={reload}
          customerId={usersModal.user?.customer?.id}
        />
      )}
      {showCreateUserModal && <CreateUserModal hide={handleHideCreateUser} reload={reload} key={data.result.length} />}
    </>
  );
};

const User = ({ user, setModal }) => {
  const handleUserClick = () => {
    setModal({ showModal: true, user });
  };

  return (
    <tr style={{ cursor: "pointer" }} onClick={handleUserClick}>
      <td>{user.displayName}</td>
      <td style={{ textAlign: "left" }}>{user.email}</td>
      <td>{user.lastLogin ? moment(user.lastLogin * 1000).fromNow() : "Never"}</td>
    </tr>
  );
};

const CreateUserModal = ({ hide, reload }) => {
  const { data } = useFetch(loadCustomersList, { key: "customers" });
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleAdd = async () => {
    const { customer: customerId, ...user } = getFormValues(formRef.current);
    setIsLoading(true);
    try {
      await fetchData(`${process.env.REACT_APP_ADMIN_API}/users/${customerId}`, JSON.stringify(user), "POST");
      reload();
      hide();
    } catch (e) {
      setError(e?.error?.message);
    }
    setIsLoading(false);
  };

  const getDefaultName = () => {
    if (!email.includes("@")) return "";
    return toSentenceCase(email.split("@")[0].split(".").join(" "));
  };

  return (
    <Modal title="Create User" hide={hide} isLoading={isLoading} actionText="Create" actionCommand={handleAdd} width="35vw">
      <StyledForm ref={formRef}>
        <section>
          <Input label="Customer">
            <Select name="customer" label="Customer" style={{ margin: "0.3rem 0" }}>
              <option>Select customer</option>
              {data?.result
                ?.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                ?.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
            </Select>
          </Input>
        </section>
        <section>
          <Input name="email" label="Email" onChange={(e) => setEmail(e.target.value)} />
          <Input name="displayName" label="Display Name" key={email} defaultValue={getDefaultName()} />
          <CheckboxButton name="sendInvite" subtle label="Send Invitation Email" defaultChecked={true} />
        </section>
      </StyledForm>
      {error && <ErrorModal error={error} hide={() => setError(null)} />}
    </Modal>
  );
};
