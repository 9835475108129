import styled from "styled-components";
import { color } from "d3";

export const getBorderColor = (props) => props.borderColor || props.color ? color(props.color).brighter(0.3) : props.theme.headerOutline;
export const getColor = (props) => props.color || props.theme.lightText;

const StyledWordBox = styled.div`
  font-size: 1rem;
  border: 1px solid ${getBorderColor};
  font-weight: bold;
  border-radius: 0.3rem;
  margin: 0.3rem;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getColor};
`;

export const WordBox = ({ color, borderColor, children }) => {
  return <StyledWordBox color={color} border={borderColor}>{children}</StyledWordBox>;
};
