import React from "react";
import styled from "styled-components";
import { useFetch, fetchData } from "lib/helpers/fetchData";
import { SidebarFilter } from "lib/components/SidebarFilters";
import { SidebarFilterContainer } from "lib/components/SidebarFilters";
import { PRIORITY_NAMES, TIER_NAMES } from "../components/constants";

const StyledContainer = styled.div`
  display: flex;
`;

const loadClientStats = async () => {
  return await fetchData(`${process.env.REACT_APP_SERVICE_DELIVERY_API}/client-stats`);
};

export const ClientFilters = ({ filters, setFilter }) => {
  const priorityData = useFetch(loadClientStats);

  const handleOverallRagCallback = ([value]) => {
    if (filters.overallPriority === value) {
      setFilter({ overallPriority: "" });
    } else {
      setFilter({ overallPriority: value });
    }
  };

  const handleScopeRagCallback = ([value]) => {
    if (filters.scopePriority === value) {
      setFilter({ scopePriority: "" });
    } else {
      setFilter({ scopePriority: value });
    }
  };

  const handleTimeRagCallback = ([value]) => {
    if (filters.timePriority === value) {
      setFilter({ timePriority: "" });
    } else {
      setFilter({ timePriority: value });
    }
  };

  const handleBudgetRagCallback = ([value]) => {
    if (filters.budgetPriority === value) {
      setFilter({ budgetPriority: "" });
    } else {
      setFilter({ budgetPriority: value });
    }
  };

  const handleClientTierCallback = ([value]) => {
    if (filters.clientTier === value) {
      setFilter({ clientTier: "" });
    } else {
      setFilter({ clientTier: value });
    }
  };

  const overallRagIsActive = ([overallRag]) => filters.overallPriority === overallRag;
  const scopeRagIsActive = ([scopeRag]) => filters.scopePriority === scopeRag;
  const timeRagIsActive = ([timeRag]) => filters.timePriority === timeRag;
  const budgetRagIsActive = ([budgetRag]) => filters.budgetPriority === budgetRag;
  const clientTierIsActive = ([clientTier]) => filters.clientTier === clientTier;

  const clearOverallRag = () => setFilter({ overallPriority: "" });
  const clearScopeRag = () => setFilter({ scopePriority: "" });
  const clearBudgetRag = () => setFilter({ budgetPriority: "" });
  const clearTimeRag = () => setFilter({ timePriority: "" });
  const clearClientTier = () => setFilter({ clientTier: "" });

  const getPriorityName = (e) => {
    return PRIORITY_NAMES[e[0]];
  };

  const getTierName = e => {
    return TIER_NAMES[e[0]]
  }

  const getPriorityCount = (e) => {
    return e[1];
  };

  const commonProps = {
    ...priorityData,
    getName: getPriorityName,
    getCount: getPriorityCount,
    isObject: true,
  };

  return (
    <StyledContainer>
      <SidebarFilterContainer>
        <SidebarFilter
          name="Overall RAG"
          clear={clearOverallRag}
          callback={handleOverallRagCallback}
          isActive={overallRagIsActive}
          resultField="result.priorities.overallPriorities"
          label="Overall RAG filter"
          {...commonProps}
        />
        <SidebarFilter
          name="Time RAG"
          clear={clearTimeRag}
          callback={handleTimeRagCallback}
          isActive={timeRagIsActive}
          resultField="result.priorities.timePriorities"
          label="Time RAG filter"
          {...commonProps}
        />
        <SidebarFilter
          name="Scope RAG"
          clear={clearScopeRag}
          callback={handleScopeRagCallback}
          isActive={scopeRagIsActive}
          resultField="result.priorities.scopePriorities"
          label="Scope RAG filter"
          {...commonProps}
        />
        <SidebarFilter
          name="Budget RAG"
          clear={clearBudgetRag}
          callback={handleBudgetRagCallback}
          isActive={budgetRagIsActive}
          resultField="result.priorities.budgetPriorities"
          label="Budget RAG filter"
          {...commonProps}
        />
        <SidebarFilter
          name="Client Tier"
          clear={clearClientTier}
          callback={handleClientTierCallback}
          isActive={clientTierIsActive}
          resultField="result.tiers"
          label="Client Tier filter"
          {...commonProps}
          getName={getTierName}
        />
      </SidebarFilterContainer>
    </StyledContainer>
  );
};
