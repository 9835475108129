import React from "react";
import styled from "styled-components";
import { fetchData, useFetch, Spinner, Error } from "lib/helpers/fetchData";

import { ListTemplates } from "./ListTemplates";
import { SingleTemplate } from "./SingleTemplate";
import { fetchCustomers } from "../CustomerPicker";

const fetchTemplates = async () => {
  return await fetchData(`${process.env.REACT_APP_REPORTING_API}/phish-sim/templates`);
};

const fetchTemplateData = async () => {
  const data = await Promise.all([fetchTemplates(), fetchCustomers()]);
  return {
    templates: data[0].result,
    customers: data[1],
  };
};

const StyledContainer = styled.div`
  display: flex;
  height: ${props => !props.small && "49rem"};
  min-height: ${props => !props.small && "49rem"};
  max-height: ${props => !props.small && "49rem"};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.8rem;
  overflow: hidden;

  & > .scroll {
    flex: 1.1;
    height: unset;
    border-right: 1px solid ${(props) => props.theme.headerOutline};
    tr.banned:hover {
      cursor: not-allowed;
    }
  }
  & > .preview {
    flex: 1;
    box-sizing: border-box;
    position: relative;
    .form {
      padding: 2rem 1rem;
      width: 100%;
      max-width: 40rem;
      margin: auto;
      box-sizing: border-box;
      section {
        padding: 1rem 0;
      }
    }
  }
  .header,
  .footer {
    z-index: 99;
    position: absolute;
    left: 0;
    right: 0;
    display: ${(props) => (props.small ? "none" : "flex")};
    background: ${(props) => props.theme.cardBackground};
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    box-sizing: border-box;
    & + div {
      height: ${props => props.small ? "100%" : "calc(100% - 4.9rem)"};
      margin-top: ${props => props.small && "0 !important"};
      box-shadow: ${props => props.small && "none !important"};
    }
  }
  .header {
    top: 0;
    border-bottom: 1px solid ${(props) => props.theme.headerOutline};
    & + div {
      margin-top: 4.9rem;
      box-shadow: inset 0 12px 12px -8.5px rgba(0, 0, 0, 0.125);
    }
  }
  .footer {
    bottom: 0;
    border-top: 1px solid ${(props) => props.theme.headerOutline};
    & + div {
      box-shadow: inset 0 -12px 12px -8.5px rgba(0, 0, 0, 0.125);
    }
  }
  tr {
    cursor: pointer;
  }
  section {
    padding: 1rem 0;
  }
`;

export const Templates = ({ template, setTemplate, customer, readOnly }) => {
  const { data, isLoading, error, reload } = useFetch(fetchTemplateData);

  const refresh = (template = {}) => {
    if (template.id) {
      setTemplate(template);
    } else {
      setTemplate({});
    }
    reload();
  };

  if (isLoading) return <Spinner />;
  if (error) return <Error message={error} />;

  return (
    <StyledContainer small={readOnly}>
      {!readOnly && <ListTemplates selected={template} setSelected={setTemplate} templates={data.templates} customer={customer} />}
      <SingleTemplate selected={template} customers={data.customers} customer={customer} refresh={refresh} templateReadOnly={readOnly} />
    </StyledContainer>
  );
};
