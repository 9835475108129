import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { Table } from "lib/components/Table";
import { Tooltip } from "lib/components/Tooltip";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

export const Azure = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const existingTags = data.connectors.azure.workflowTags || [];
  const [tags, setTags] = useState(existingTags);

  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    const values = getFormValues(formRef.current);
    Object.keys(values).forEach((key) => (values[key] = values[key].trim()));
    values.workflowTags = tags;
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${data.id}/connectors/azure`, JSON.stringify({ ...values }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };
  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
      )}
      <StyledForm ref={formRef}>
        <section>
          <Input name="resourceGroup" label="Resource Group" defaultValue={data.connectors.azure.resourceGroup} readOnly={!isEditMode} />
          <Input name="location" label="Location" defaultValue={data.connectors.azure.location} readOnly={!isEditMode} />
          <Input name="workspaceName" label="Workspace Name" defaultValue={data.connectors.azure.workspaceName} readOnly={!isEditMode} />
          <Input name="workspaceId" label="Workspace Id" defaultValue={data.connectors.azure.workspaceId} readOnly={!isEditMode} />
          <Input name="subscriptionId" label="Subscription Id" defaultValue={data.connectors.azure.subscriptionId} readOnly={!isEditMode} />
        </section>
        <section>
          <h1>Sentinel</h1>
          <Input name="appId" label="App Id" defaultValue={data.connectors.azure.appId} readOnly={!isEditMode} />
          <Input
            name="appPrincipalId"
            label="App Principal Id"
            defaultValue={data.connectors.azure.appPrincipalId}
            readOnly={!isEditMode}
          />
          <Input
            name="securityInsightsId"
            label="Azure Security Insights Principal Id"
            defaultValue={data.connectors.azure.securityInsightsId}
            readOnly={!isEditMode}
          />
          <Input name="tenantId" label="Tenant Id" defaultValue={data.connectors.azure.tenantId} readOnly={!isEditMode} />
          <Input name="keyVault" label="Key Vault" defaultValue={data.connectors.azure.keyVault} readOnly={!isEditMode} />
        </section>
        <section>
          <h1>Workflows (Logic Apps)</h1>
          <Input
            name="workflowResourceGroup"
            label="Workflow Resource Group"
            defaultValue={data.connectors.azure.workflowResourceGroup}
            readOnly={!isEditMode}
          />
          <Input label="Workflow Tags">
            <Tags tags={isEditMode ? tags : existingTags} setTags={setTags} readOnly={!isEditMode} />
          </Input>
        </section>
        <section>
          <h1>EASM</h1>
          <Input
            name="easmResourceName"
            label="Resource Name (EASM)"
            defaultValue={data.connectors.azure.easmResourceName}
            readOnly={!isEditMode}
          />
          <Input
            name="easmRegionPrefix"
            label="Region Prefix"
            defaultValue={data.connectors.azure.easmRegionPrefix}
            readOnly={!isEditMode}
          />
        </section>
      </StyledForm>
    </div>
  );
};

export const Tags = ({ tags, setTags, readOnly }) => {
  const newTagName = useRef(null);
  const newTagValue = useRef(null);

  return (
    <Table style={{ marginTop: "0.5rem" }}>
      <thead>
        <tr>
          <th>Tag Name</th>
          <th>Tag Value</th>
          {!readOnly && <th />}
        </tr>
      </thead>
      <tbody>
        {tags.length
          ? tags.map((tag, index) => (
              <tr key={index}>
                <td>{tag.key}</td>
                <td>{tag.value}</td>
                {!readOnly && (
                  <td style={{ overflow: "visible" }}>
                    <Tooltip
                      icon={faTrash}
                      hoverText="Delete Tag"
                      xHoverPos="left"
                      clickAction={() => setTags(tags.filter((_, i) => i !== index))}
                    />
                  </td>
                )}
              </tr>
            ))
          : readOnly && (
              <tr className="no-results-row">
                <td colSpan={4} className="no-results-text">
                  No tags added yet
                </td>
              </tr>
            )}
        {!readOnly && (
          <tr>
            <td>
              <Input ref={newTagName} placeholder="Name" style={{ margin: 0 }} />
            </td>
            <td>
              <Input ref={newTagValue} placeholder="Tag Value" style={{ margin: 0 }} />
            </td>
            <td style={{ overflow: "visible" }}>
              <Tooltip
                icon={faPlus}
                hoverText="Add Tag"
                xHoverPos="left"
                clickAction={() => {
                  setTags([...tags, { key: newTagName.current.value, value: newTagValue.current.value }]);
                  newTagName.current.value = "";
                  newTagValue.current.value = "";
                }}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
