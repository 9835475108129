import React, { useEffect, useRef } from "react";
import { KeyValue } from "./KeyValue";

export const ErrorsList = ({ errors = [], style = {}, label = "Please address the following fields that contain errors:", mapper = (e) => e, ...props }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (errors.length) {
      containerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errors.length])

  if (!errors.length) return null;

  return (
    <KeyValue
      text={label}
      wrap={false}
      style={{ margin: "2rem 0 0", ...style }}
      {...props}
      value={
        <ul style={{ fontSize: "1.5rem" }} ref={containerRef}>
          {errors.map(mapper).map((e, i) => (
            <li key={i}>{`${e}`}</li>
          ))}
        </ul>
      }
    />
  );
};
