import React from "react";
import { Redirect } from "react-router-dom";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Campaigns } from "./campaigns";

export const PhishSim = () => {
  const routes = [{ name: "Campaigns", link: "/phish-sim/campaigns" }];

  return (
    <PageContainer name="Phishing Simulation" link="/phish-sim" routes={routes}>
      <ErrorHandledRoute path="/phish-sim/campaigns" component={Campaigns} />
      <ErrorHandledRoute path="/phish-sim" render={() => <Redirect to="/phish-sim/campaigns" />} />
    </PageContainer>
  );
};
