import React, { useState, useRef } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";

import { Modal, ErrorModal } from "lib/components/Modal";
import { Searchbar } from "lib/components/Searchbar";
import { Table } from "lib/components/Table";
import { Input } from "lib/components/Input";
import { toSentenceCase } from "lib/helpers/toSentenceCase";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { useInfiniteScroll } from "lib/components/InfiniteScroll";
import { useDebouncedCallback } from "use-debounce/lib";
import { RadioButton } from "lib/components/RadioButton";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { CheckboxButton } from "lib/components/Checkbox";
import { fetchData } from "lib/helpers/fetchData";

export const AddUserModal = ({ hide, existingUsers, showModal, reload, customerId, defaultError }) => {
  const [email, setEmail] = useState("");
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [error, setError] = useState(defaultError);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  const handleHide = () => {
    setEmail("");
    hide();
  };

  const handleAddNew = async () => {
    setIsLoading(true);
    const userData  = getFormValues(formRef.current);
    try {
      await fetchData(`${process.env.REACT_APP_ADMIN_API}/users/${customerId}`, JSON.stringify(userData), "POST");
      reload();
      handleHide();
    } catch(e) {
      setError(e?.error?.message);
    }
    setIsLoading(false);
  };

  const handleAddExisting = async (userId) => {
    setIsLoading(true);
    const userData = { id: userId };
    try {
      await fetchData(`${process.env.REACT_APP_ADMIN_API}/user/${customerId}/customers`, JSON.stringify(userData), "POST");
      reload();
      handleHide();
    } catch(e) {
      setError(e?.error?.message);
    }
    setIsLoading(false);
  };

  const getDefaultName = () => {
    if (!email.includes("@")) return "";
    return toSentenceCase(email.split("@")[0].split(".").join(" "));
  };

  return (
    <Modal title="Add User" showModal={showModal} isLoading={isLoading} hide={handleHide} actionText="Create" actionCommand={isCreateMode && handleAddNew} width="40rem">
      <StyledForm ref={formRef}>
        <section style={{ padding: 0 }}>
          <span>
            <RadioButton name="create" label="New User" defaultChecked={isCreateMode === true} onChange={() => setIsCreateMode(true)} labelStyle={{ flex: 1 }} />
            <RadioButton name="create" label="Existing User" defaultChecked={isCreateMode === false} onChange={() => setIsCreateMode(false)} labelStyle={{ flex: 1 }} />
          </span>
        </section>
        {isCreateMode ? (
          <section>
            <Input name="email" label="Email" data-testid="email" onChange={(e) => setEmail(e.target.value)} />
            <Input name="displayName" label="Display Name" data-testid="displayName" key={email} defaultValue={getDefaultName()} />
            <CheckboxButton name="sendInvite" subtle label="Send Invitation Email" defaultChecked={true} />
          </section>
        ) : (
          <section>
            <UsersSelect selectUser={handleAddExisting} existingUsers={existingUsers} />
          </section>
        )}
      </StyledForm>
      {error && <ErrorModal data-testid="error" error={error} hide={() => setError(null)} />}
    </Modal>
  );
};

export const UsersSelect = ({ selectUser, existingUsers }) => {
  const [data, setData] = useState({ result: [] });
  const [params, setParams] = useState({ search: "", pageSize: 40 });

  const url = `${process.env.REACT_APP_ADMIN_API}/users/`;
  const { loadItems, hasMore, isLoading } = useInfiniteScroll({ url, params, data, setData });

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  return (
    <>
      <Searchbar {...search} />
      <ScrollContainer style={{ maxHeight: "30rem", marginRight: "-1rem", paddingRight: "0.5rem" }}>
        <InfiniteScroll loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data} useWindow={false}>
          <Table>
            <tbody>
              {data.result
                .filter((user) => !existingUsers.find((u) => u.id === user.id))
                .map((user) => (
                  <tr key={user.id} style={{ cursor: "pointer" }} onClick={() => selectUser(user.id)}>
                    <td style={{ textAlign: "left" }}>{user.email}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </ScrollContainer>
    </>
  );
};