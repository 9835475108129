import React from "react";

import { toBinaryString } from "lib/helpers/toBase64";
import { parseCSV } from "lib/helpers/parseCSV";

import { Input } from "lib/components/Input";
import { KeyValue } from "lib/components/KeyValue";

const typeMap = {
  "Opened Email?": "opened",
  "Clicked Link?": "clicked",
  "Submitted Form": "submitted",
  "Reported Phish?": "reported",
};

export const UploadGoPhish = ({ setTargets, setEvents }) => {
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const content = await toBinaryString(file);
    const data = parseCSV(content);
    const newTargets = [...new Set([...data.map((d) => d.Email).filter(Boolean)])];
    let events = [];

    data.forEach((dl) => {
      const user = dl["Email"];
      const details = dl;

      if (dl["Opened Email?"] === "Yes") events.push({ type: typeMap["Opened Email?"], date: dl["Opened Email Timestamp"], user, details });
      if (dl["Clicked Link?"] === "Yes") events.push({ type: typeMap["Clicked Link?"], date: dl["Clicked Link Timestamp"], user, details });
      if (dl["Submitted Form"] === "Yes") events.push({ type: typeMap["Submitted Form"], date: dl["Submitted Form Timestamp"], user, details });
      if (dl["Reported Phish?"] === "Yes") events.push({ type: typeMap["Reported Phish?"], date: dl["Reported Phish Timestamp"], user, details });

    });
 
    setTargets(newTargets);
    setEvents(events);
  };
  return <KeyValue text="Populate form using GoPhish Event Data" wrap={false} value={<Input type="file" onChange={handleUpload} accept=".csv" />} />;
};
