import { faBoxOpen, faMoneyBillWave, faQuestion, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  margin-right: 1rem;
  gap: 1rem;

  & > svg {
    font-size: 1.3rem;
    color: ${props => props.theme.text};
    filter: drop-shadow(0 0 0.5rem ${(props) => props.theme.pageBackground});
  }
`;

const isProjectRecurring = project => project.tags.includes("RECURRING");

export const Flags = ({ project, className = "" }) => {
  const isRecurring = isProjectRecurring(project);
  const { isBillable, isTentative, isArchived } = project;
  
  return (
    <StyledContainer className={`${className} flags`}>
      {isRecurring && <FontAwesomeIcon title="Recurring" icon={faSyncAlt} />}
      {isBillable && <FontAwesomeIcon title="Billable" icon={faMoneyBillWave} />}
      {isTentative && <FontAwesomeIcon title="Tentative" icon={faQuestion} />}
      {isArchived && <FontAwesomeIcon title="Archived" icon={faBoxOpen} />}
    </StyledContainer>
  )
}