import moment from "moment";

export const getTimeTillDate = (startTime, endTime = moment().valueOf(), forceShort) => {
  if (!startTime) return "Unset";

  if (startTime - endTime <= 0) return "Past";

  const isSameDay = moment(endTime).isSame(startTime, "day");
  const difference = moment.duration(startTime - endTime);

  if (isSameDay || forceShort) {
    if (difference.asSeconds() < 100) return difference.asSeconds().toFixed(0) + " secs";
    if (difference.asMinutes() < 120) return difference.asMinutes().toFixed(0) + " mins";
    if (difference.asHours() < 24) return difference.asHours().toFixed(0) + " hrs";
    return difference.asDays().toFixed(0) + " days";
  } else {
    return moment(startTime).format("HH:mm ddd");
  }
};
