import moment from "moment";

export const displayDataAmount = (_data, isBytes) => {
  const data = isBytes ? _data / 1000000000 : _data;
  // Display in Gb if more than 1Gb
  if (data >= 1) return `${Math.round(data * 100) / 100} Gb`;
  // Display in Mb if more than 1Mb
  if (data >= 0.001) return `${Math.round(data * 100000) / 100} Mb`;
  // Display in Kb if more than 1Kb
  if (data >= 0.000001) return `${Math.round(data * 100000000) / 100} Kb`;
  // Display in bytes if more than 0
  if (data > 0) return `${Math.round(data * 100000000000) / 100} b`;
  return "None";
};

export const displayNumberAmount = number => {
  // Display in millions if more than 1m
  if (number >= 1000000) return `${parseFloat(number / 1000000).toPrecision(4)}M`;
  // Display in thousands if more than 1k
  if (number >= 1000) return `${parseFloat(number / 1000).toPrecision(4)}K`;
  return parseFloat(number).toPrecision(4);
}

export const displayDate = date => moment(date).format("YYYY-MM-DD");