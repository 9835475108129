import { useEffect, useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { fetchData, useFetch, Spinner, Error } from "lib/helpers/fetchData";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faLock, faShieldAlt, faUser, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { ListPane } from "lib/components/ListPane";

import { ActivitiesChart } from "./ActivitiesChart";
import { SingleDayActivities } from "./SingleDayActivities";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { addParamsToUrl } from "lib/helpers/addParamsToUrl";
import { CheckboxBorderedButton } from "lib/components/Checkbox";
import { StyledForm } from "lib/styles/general";
import { getFormValues } from "lib/helpers/getFormValues";
import { Select } from "lib/components/Select";
import { loadCustomersList } from "../Customers";
import { CTAButton } from "lib/components/CTAButton";
import { escapeText } from "lib/helpers/escapeText";

const loadActivitiesData = async ({ days, type, excludeQC, removeDuplicates, customerId, isDash = true }) => {
  const startDate = moment().subtract(days, "days").startOf("day").utc(true).toISOString();
  const endDate = moment().startOf("day").utc(true).toISOString();
  const params = {
    startDate,
    endDate,
    customerId,
    types: [type],
    excludeCustomerId: excludeQC ? process.env.REACT_APP_QC_ID : undefined,
    removeDuplicates: removeDuplicates ? true : undefined,
  };

  const url = new URL(`${process.env.REACT_APP_ADMIN_API}/activities${isDash ? "/dash" : ""}`);
  addParamsToUrl(url, params);
  return fetchData(url.toString());
};

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  margin-left: 0.5rem;
  color: ${(props) => props.color};
  opacity: 0.8;
`;
const types = [
  { id: "login-success", name: "Clarity Logins", icon: faUser, color: "#67ec90" },
  { id: "admin-login-success", name: "Admin Logins", icon: faUserLock, color: "#00deec", options: { excludeQC: false } },
  {
    id: "contact-update-success",
    name: "Contact Updates",
    icon: faAddressBook,
    color: "#7700ec",
    options: { excludeQC: false, removeDuplicates: false },
  },
  {
    id: "passphrase-update-success",
    name: "Passphrase Updates",
    icon: faLock,
    color: "#ec007e",
    options: { excludeQC: false, removeDuplicates: false },
  },
  {
    id: "soc-deletion-success",
    name: "SOC Deletions",
    icon: faShieldAlt,
    color: "#CC8800",
    options: { excludeQC: false, removeDuplicates: false },
  },
];

const defaultOptions = { excludeQC: true, removeDuplicates: true, days: 30, customerId: null };

export const Activities = () => {
  const { data, isLoading, error } = useFetch(loadCustomersList);
  const [options, setOptions] = useState(defaultOptions);

  const icon = (t) => <StyledIcon title={t.name} icon={t.icon} color={t.color} />;

  if (isLoading) return <Spinner />;
  if (error) return <Error message={error.message} />;

  return (
    <ListPane
      listRightComponent={icon}
      listState={{ data: { result: types } }}
      listWidth={300}
      singleItemPane={ActivitesContainer}
      childrenProps={{ customers: data?.result, options, setOptions }}
    />
  );
};

const StyledContainer = styled.div`
  width: 100%;
  & > .header {
    display: flex;
    align-items: center;
    padding: 2.2rem 1.5rem;
    background: ${(props) => props.theme.disabledCardBackground};
    border-bottom: 1px solid ${(props) => props.theme.headerOutline};
    & > h1 {
      color: ${(props) => props.theme.text};
      margin: 0;
    }
  }
`;

const ActivitesContainer = ({ data: type, customers, options, setOptions }) => {
  const [index, setIndex] = useState(options.days);

  useEffect(() => {
    setIndex(options.days);
  }, [options.days]);

  useEffect(() => {
    setOptions((options) => ({ ...defaultOptions, ...(type.options || {}), customerId: options.customerId, days: options.days }));
  }, [type.options, type.id, setOptions]);

  const handleOptionsChange = (values) => {
    values = getFormValues(values);
    setOptions(values);
  };

  return (
    <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
      <ActivitiesComponent
        key={JSON.stringify(options)}
        type={type}
        options={options}
        handleOptionsChange={handleOptionsChange}
        index={index}
        setIndex={setIndex}
        customers={customers}
      />
    </div>
  );
};

const ActivitiesComponent = ({ type, options, handleOptionsChange, index, setIndex, customers }) => {
  const { data, isLoading, error } = useFetch(loadActivitiesData, { type: type.id, ...options });
  const formRef = useRef(null);
  const [downloading, setDownloading] = useState(false);

  const renderComponent = () => {
    if (isLoading) return <Spinner />;
    if (error) return <Error message={error.message} />;
    return (
      <div style={{ width: "100%" }}>
        <div style={{ height: "27rem", width: "100%" }}>
          <ActivitiesChart type={type} data={data.result.activities} index={index} setIndex={setIndex} />
        </div>
        <ScrollContainer style={{ maxHeight: "calc(100vh - 27rem - 15.5rem)", width: "100%" }}>
          <SingleDayActivities type={type} options={options} data={data.result.activities} index={index} setIndex={setIndex} />
        </ScrollContainer>
      </div>
    );
  };

  const handleCSVDownload = async () => {
    setDownloading(true);

    const activitiesData = await loadActivitiesData({ type: type.id, isDash: false, ...options});

    const lines = [];
    let i = 0;
    for (const activity of activitiesData.result) {
      const obj = {
        "Customer Name": activity.customer.name,
        "Username": activity.user.displayName,
        "User Email": activity.user.email,
        "Created": moment.unix(activity.created).toISOString(),
        "Type": activity.type,
      };
      const keys = Object.keys(obj);

      // CSV Header
      if (i === 0) lines.push(keys.join(","));

      const line = keys.map((key) => escapeText(obj[key])).join(",");
      lines.push(line);
      i++;
    }

    const content = lines.join("\n");

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(content));
    element.setAttribute("download", `${type.id}-export.csv`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    setDownloading(false);
  };

  return (
    <StyledContainer>
      <div className="header">
        <h1>{type.name}</h1>
        <StyledForm
          ref={formRef}
          style={{ display: "flex", gap: "1rem", flex: 1, justifyContent: "flex-end" }}
          onChange={() => handleOptionsChange(formRef.current)}
        >
          <CheckboxBorderedButton name="excludeQC" label="Exclude QC activities" defaultChecked={options.excludeQC} />
          <CheckboxBorderedButton name="removeDuplicates" label="Remove duplicates" defaultChecked={options.removeDuplicates} />
          <Select name="days" style={{ margin: 0, width: "12rem" }} defaultValue={options.days}>
            <option value="30">Past month</option>
            <option value="90">Past 3 months</option>
            <option value="180">Past 6 months</option>
            <option value="360">Past 12 months</option>
          </Select>
          <Select name="customerId" style={{ margin: 0, width: "12rem" }} defaultValue={options.customerId}>
            <option value="">Filter customer</option>
            {customers.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.name}
              </option>
            ))}
          </Select>
          <CTAButton isLoading={downloading} style={{ width: "12rem" }} onClick={handleCSVDownload}>
            Export CSV
          </CTAButton>
        </StyledForm>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>{renderComponent()}</div>
    </StyledContainer>
  );
};
