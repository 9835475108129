import React from "react";
import styled from "styled-components";
import { ErrorBoundary } from "./ErrorBoundary";

const StyledCard = styled.div`
  width: 100%;
  overflow: hidden;
  background: ${(props) => props.theme.cardBackground};
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.cardOutline};
  color: ${(props) => props.theme.lightText};
  padding: ${(props) => props.pad && "1.8rem"};
  box-shadow: 0 10px 22px rgba(0, 0, 0, 0.06), 0 7px 15px 8px rgba(0, 0, 0, 0.06), 0 5px 10px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  grid-area: ${(props) => props.area};
  /* display: ${(props) => props.pad && "flex"}; */
  /* flex-flow: ${(props) => props.pad && "column nowrap"}; */
  display: flex;
  flex-flow: column nowrap;

  @media (max-width: ${(props) => props.theme.mobileWidth}) {
    border: none;
    border-radius: 0;
    background: ${(props) => props.theme.headerBackground};
  }
  &.main {
    border: ${(props) => props.padding && "none"};
    border-radius: ${(props) => props.padding && "0"};
  }
`;

const StyledContainerHeader = styled.div`
  color: ${(props) => props.theme.text};
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.p`
  margin: ${(props) => (props.pad ? "0" : "1.8rem 1.8rem 0.8rem 1.8rem")};
  font-size: 1.33rem;
  flex-grow: 1;
  color: ${(props) => props.theme.text};
  text-transform: uppercase;
  font-weight: bolder;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSubtitle = styled.p`
  font-size: 1.33rem;
  font-weight: lighter;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledChildren = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  height: ${(props) => props.pad && "100%"};
  margin: auto;
`;

const StyledActionButton = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 0.33rem;
  outline: none;
  background: ${(props) => props.theme.secondaryButtonColor};
  transition: all 0.3s;
  &:hover {
    background: ${(props) => props.theme.secondaryButtonHover};
  }
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  font-size: 1.33rem;
  transition: all 0.3s;
  color: ${(props) => props.theme.buttonText || props.theme.text};
`;

export const Card = ({ title, subtitle, children, action, actionText = "View All", childrenStyle, pad = true, ...props }) => {
  const Title = title && <StyledTitle pad={pad}>{title}</StyledTitle>;

  const Subtitle = subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>;

  const ActionButton = action && <StyledActionButton onClick={action}>{actionText}</StyledActionButton>;

  const Header = title && (
    <StyledContainerHeader>
      {Title}
      {ActionButton}
      {Subtitle}
    </StyledContainerHeader>
  );

  return (
    <StyledCard {...props} pad={pad}>
      {Header}
      <StyledChildren style={childrenStyle} pad={pad}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </StyledChildren>
    </StyledCard>
  );
};
