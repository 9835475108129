import React from "react";
import styled from "styled-components";
import { Spinner } from "./Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const StyledSelectButton = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  color: ${props => props.theme.text};
  font-size: 1.33rem;
  box-sizing: border-box;
  height: 2.7rem;
  width: 10rem;
  min-width: 10rem;
  background: ${props => props.theme.secondaryButtonColor};
  select {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: transparent !important;
    outline: none;
    border: none;
    appearance: none;
    color: transparent;
    margin: 0;
    & > option {
      position: absolute;
      width: 100%;
      background: ${props => props.theme.cardBackground};
      color: ${props => props.theme.text};
      font-size: 1.33rem;
      height: 30px;
      padding: 10px;
    }
  }
  label {
    display: ${props => (props.single ? "none" : "flex")};
    justify-content: flex-end;
    align-items: center;
    z-index: 99;
    cursor: pointer;
    font-weight: normal;
    font-size: 0.8rem;
    color: ${props => props.theme.buttonText || props.theme.text};
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    &:hover {
      background: transparent;
    }
    svg {
      margin: 0.7rem;
    }
  }
  button {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    border: none;
    outline: none;
    font: inherit;
    color: ${props => props.theme.buttonText || props.theme.text};
    background: transparent;
    left: 0;
    bottom: 0;
    top: 0;
    width: ${props => (props.single ? "100%" : "calc(100% - 2rem)")};
    border-right: ${props => !props.single && "2px solid rgba(0, 0, 0, 0.2)"};
    background: ${props => props.theme.secondaryButtonColor};
    transition: all 0.3s;
    &:hover {
      background: ${props => props.theme.secondaryButtonHover};
    }
  }
  .spinner {
    transform: scale(0.4);
    top: 0;
    height: 0;
    position: absolute;
    svg {
      margin: 1.2rem;
      circle {
        stroke-opacity: 1;
      }
    }
  }
`;

export const SelectButton = ({ children, callback, isLoading }) => {
  const [defaultAction, ...actions] = children;
  if (!defaultAction) return null;

  return (
    <StyledSelectButton single={actions.length === 0}>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <button onClick={() => callback(defaultAction.props.value)}>{defaultAction.props.children}</button>
          <label>
            <FontAwesomeIcon icon={faChevronDown} />
            <select onChange={e => callback(e.target.value)}>
              <option value="">----</option>
              {actions}
            </select>
          </label>
        </>
      )}
    </StyledSelectButton>
  );
};
