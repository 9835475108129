import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

const StyledBlock = styled.div`
  height: 1rem;
  width: 1rem;
  background: ${(props) => (props.active ? props.color : props.theme.pageBackground)};
  margin-right: 0.25rem;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const QuantifierBoxes = ({ value: _value, max = 5, count = 5, title = "Severity" }) => {
  const value = (_value > -1 && _value !== null) ? _value : 1
  const normalisedValue = Math.ceil(value / (max / count));
  const colors = ["#ffde35", "#f67325", "#d72020", "#ad062c", "#960075"];

  return (
    <StyledContainer title={`${title}: ${value}/${max}`}>
      {Array(5)
        .fill(null)
        .map((_, i) => (
          <StyledBlock key={i} active={i < normalisedValue} color={colors[i]} />
        ))}
    </StyledContainer>
  );
};
