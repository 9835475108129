import React, { useState } from "react";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";
import { Switch } from "react-router";
import { ListTickets } from "./list-tickets";
import { SingleTicket } from "./single-ticket";
import { TicketArtifact } from "../artifacts";

const ticketsDefaultFilters = {
  search: "",
  orderBy: "fid",
  reverse: true,
  status: "open",
  pageSize: 40,
};

export const TicketsContext = React.createContext();

export const Tickets = () => {
  const [filters, setFilters] = useState(ticketsDefaultFilters);

  const setFilter = (filter) => setFilters((filters) => ({ ...filters, ...filter }));

  return (
    <TicketsContext.Provider value={{ filters, setFilter }}>
      <Switch>
        <ErrorHandledRoute path="/phish/tickets/:customerId/:ticketId/artifacts/:artifactId" component={TicketArtifact} />
        <ErrorHandledRoute path="/phish/tickets/:customerId/:ticketId" component={SingleTicket} />
        <ErrorHandledRoute path="/phish/tickets" component={ListTickets} />
      </Switch>
    </TicketsContext.Provider>
  );
};
