import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { Spinner } from "lib/helpers/fetchData";
import { addParamsToUrl } from "lib/helpers/addParamsToUrl";
import { fetchData } from "lib/helpers/fetchData";

const loadData = async ({ page = 0, pageSize, url, params, data }) => {
  const _url = new URL(url);
  _url.searchParams.set("page", page);
  addParamsToUrl(_url, params);

  if (pageSize) _url.searchParams.set("pageSize", pageSize);

  const newData = await fetchData(_url);
  newData.paging.itemCount = newData?.result?.length;
  const result = page > 0 ? [...data.result, ...newData.result] : newData.result;
  return { ...newData, result };
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  & > section {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
`;

const StyledNoResults = styled.p`
  color: ${(props) => props.theme.mediumText};
  font-size: 1.5rem;
`;

export const useInfiniteScroll = ({ url, params: _params, data, setData }) => {
  const [isLoading, setIsLoading] = useState(null);

  let params = _params;
  if (params.sortBy) {
    params.orderBy = params.sortBy;
  }

  // loadItems callback for infinite scroll component
  const loadItems = (forcePage = null) => {
    const page = forcePage !== null ? forcePage : data?.paging?.page || 0;
    setIsLoading("page");
    loadData({ page, url, params, data }).then((data) => {
      setIsLoading(null);
      setData(data);
    });
  };

  const reload = async () => {
    const previousPage = data.params?.page || 0;
    const previousPageSize = params?.pageSize || 40;
    let refreshedData = { ...data, result: [] };

    const loadPageRecursive = async ({ page = 0, pageSize = previousPageSize } = {}) => {
      refreshedData = await loadData({ page, pageSize, url, params, data: refreshedData });
      const newItems = refreshedData?.paging?.itemCount;
      if (page < previousPage && newItems === pageSize) {
        return loadPageRecursive({ page: page + 1, pageSize });
      } else {
        return refreshedData;
      }
    };

    const newData = await loadPageRecursive();
    setData(newData);
  };

  const isInitial = useRef(true);
  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
      setIsLoading("initial");
    } else {
      setIsLoading("param");
    }

    // Load page with new params and reset page to 0
    loadData({ url, params }).then((data) => {
      setIsLoading(null);
      setData(data);
    });
  }, [params, url, setData]);

  // Boolean representing if there is more data or not
  const hasMore = data.paging?.page > 0 && data.result.length > 0 && data.paging.itemCount === data.paging.pageSize;

  return {
    isLoading,
    hasMore,
    data,
    loadItems,
    reload,
  };
};

export const InfiniteScroll = ({ filters, data, children, noDataText, disableNoResults, ...props }) => (
  <StyledContainer>
    {filters}
    <InfiniteScrollBetter data={data} {...props}>
      {data.result.length > 0 || disableNoResults
        ? children
        : data.success > 0 && <StyledNoResults>{noDataText || "We do not have any results matching this criteria"}</StyledNoResults>}
    </InfiniteScrollBetter>
  </StyledContainer>
);

const InfiniteScrollBetter = ({ loadItems, hasMore, isLoading, data, children, offset = 400, useWindow = true, debugSpinner = false }) => {
  const maxScrollTop = useRef(0);
  const lastScrollTop = useRef(0);
  const lastFetchTime = useRef(Date.now());
  const childRef = useRef(null);
  const dataCount = data?.result?.length;

  useEffect(() => {
    const node = useWindow ? document.scrollingElement : childRef?.current?.parentElement?.parentElement;

    const hasReachedBottom = () => {
      const windowHeight = window.innerHeight;
      const scrollHeight = node.scrollHeight;
      const scrollPosition = node.scrollTop;
      const screenTop = scrollPosition + windowHeight;
      return screenTop > scrollHeight - offset;
    };

    const handleScroll = () => {
      if (node.scrollTop > maxScrollTop.current) {
        maxScrollTop.current = node.scrollTop;
      }

      if (hasReachedBottom() && hasMore && !isLoading && node.scrollTop >= maxScrollTop.current && node.scrollTop > lastScrollTop.current) {
        if (Date.now() > lastFetchTime.current + 1000) {
          lastFetchTime.current = Date.now();
          loadItems();
        }
      }
      lastScrollTop.current = node.scrollTop;
    };

    if (node && !useWindow) {
      node.addEventListener("scroll", handleScroll);
      return () => node.removeEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [loadItems, hasMore, isLoading, offset, useWindow]);

  useEffect(() => {
    maxScrollTop.current = 0;
  }, [dataCount]);

  if (debugSpinner) return <Spinner />;

  return (
    <>
      {(isLoading === "param" || (isLoading === "initial" && !data.success)) && <Spinner />}
      {(isLoading === "page" || !isLoading || (isLoading === "initial" && data.success)) &&
        children &&
        React.Children.map(children, (child) => React.cloneElement(child, { ref: childRef }))}
      {isLoading === "page" && <Spinner />}
    </>
  );
};
