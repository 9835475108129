import { getValueOrDefault } from "lib/helpers/getValueOrDefault";

const commonTheme = {
  uiScale: () => getValueOrDefault("ui_scale", "10.5px"),
  mobileWidth: "600px",
  fontFamily: `"Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Open Sans", "Helvetica Neue", sans-serif`,
};

const lightTheme = {
  pageBackground: "#e7ebf1",
  cardBackground: "#ffffff",
  cardOutline: "#d6d6d6",
  accentCardBackground: "#f1f3f8",
  disabledCardBackground: "#f3f5f6",
  headerBackground: "#ffffff",
  headerOutline: "#d6d6d6",
  secondaryButtonColor: "#ff008c",
  secondaryButtonHover: "#ff0f93",
  primaryButtonColor: "#f7f7f7",
  primaryButtonHover: "#f1f6f9",
  lightGray: "#c7cedb",
  mediumGray: "#a4abb7",
  text: "#49494a",
  mediumText: "#606060",
  lightText: "#666666",
  accentText: "#e1007c",
  logoCircleColor: "#e1007c",
  logoTextColor: "#3f454b",
  buttonText: "rgba(255,255,255,0.875)",
  scrollbarColor: "#aeb0b6",
  scrollbarHover: "#959ba6",
  iconBackground: "#9ea7b3",
  modalBackground: "rgba(0, 0, 0, 0.5)",
};

const midnightTheme = {
  pageBackground: "#1b1e23",
  cardBackground: "#22252b",
  cardOutline: "#191c1f",
  accentCardBackground: "#262a31",
  disabledCardBackground: "#1f2228",
  headerBackground: "#1f2228",
  headerOutline: "#2d3239",
  secondaryButtonColor: "#00ad85",
  secondaryButtonHover: "#00cc9c",
  primaryButtonColor: "#282c34",
  primaryButtonHover: "#23272f",
  lightGray: "#c7cedb",
  mediumGray: "#a4abb7",
  text: "rgba(255,255,255,0.8)",
  mediumText: "rgba(255,255,255,0.675)",
  lightText: "rgba(255,255,255,0.6)",
  accentText: "#00ad85",
  logoTextColor: "#888888",
  scrollbarColor: "#2f333d",
  scrollbarHover: "#414753",
  iconBackground: "#79808a",
  modalBackground: "rgba(0, 0, 0, 0.7)",
};

const darkTheme = {
  pageBackground: "#21252b",
  cardBackground: "#282c34",
  cardOutline: "#1f2328",
  accentCardBackground: "#2c313a",
  disabledCardBackground: "#252a31",
  headerBackground: "#282c34",
  headerOutline: "#343942",
  secondaryButtonColor: "#f24159",
  secondaryButtonHover: "#f46679",
  primaryButtonColor: "#282c34",
  primaryButtonHover: "#2c313a",
  lightGray: "#a4abb7",
  mediumGray: "#c7cedb",
  text: "rgba(255,255,255,0.75)",
  mediumText: "rgba(255,255,255,0.625)",
  lightText: "rgba(255,255,255,0.5)",
  accentText: "#f24159",
  logoTextColor: "#888888",
  scrollbarColor: "#383e49",
  scrollbarHover: "#494f5c",
  iconBackground: "#7b818a",
  modalBackground: "rgba(0, 0, 0, 0.6)",
};

export const themes = {
  light: { ...commonTheme, ...lightTheme },
  dark: { ...commonTheme, ...darkTheme },
  midnight: { ...commonTheme, ...midnightTheme },
};
