import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

import { Card } from "./Card";
import { CTAButton } from "./CTAButton";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const StyledPopupMenu = styled(Card)`
  position: absolute;
  z-index: 999;
  top: ${(props) => props.offset && `${props.offset}px`};
  right: -0.25rem;
  margin-top: 1rem;
  width: auto !important;
  padding: 1rem;
  box-shadow: 0 5px 12px 1px rgba(0, 0, 0, 0.3);
  animation: ${fadeIn} 0.3s ease-out forwards;
  .select {
    width: 11rem;
  }
`;

export const PopupMenu = ({ buttonComponent: Button, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClose = () => setIsOpen(false);
    window.addEventListener("click", handleClose);
    return () => window.removeEventListener("click", handleClose);
  }, []);

  const containerHeight = containerRef?.current?.clientHeight;

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };

  return (
    <StyledContainer ref={containerRef}>
      <Button onClick={handleButtonClick} />
      {isOpen && (
        <StyledPopupMenu offset={containerHeight} onClick={handlePopupClick}>
          {children}
        </StyledPopupMenu>
      )}
    </StyledContainer>
  );
};

export const IconPopup = ({ text, icon, children, buttonStyle, ...props }) => {
  const Button = (props) => (
    <CTAButton isSecondary style={{ width: "100%", minWidth: "100%", padding: "0 1rem", ...buttonStyle }} {...props}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: "0.5rem" }} />
      {text}
    </CTAButton>
  );

  return (
    <PopupMenu buttonComponent={Button} {...props}>
      {children}
    </PopupMenu>
  );
};
