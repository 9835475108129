export const getColorFromSeverity = severity => {
  switch (severity?.score) {
    case 1:
      return "green";
    case 2:
      return "#da9e00";
    case 3:
      return "red";
    default:
      return null;
  }
};

export const getSeverityScoreFromText = _text => {
  let text = _text.toLowerCase();
  switch (text) {
    case "open":
      return 0;
    case "benign":
      return 1;
    case "suspicious":
      return 2;
    case "malicious":
      return 3;
    default:
      return null;
  }
};

export const getSeverityTextFromScore = score => {
  switch (score) {
    case 0:
      return "Unknown";
    case 1:
      return "Benign";
    case 2:
      return "Suspicious";
    case 3:
      return "Malicious";
    default:
      return null;
  }
};
