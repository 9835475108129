import { Review } from "./Review";
import { ReviewBox } from "./ReviewBox";

export { Review, ReviewBox };

export const REVIEW_STATUSES = {
  DRAFT: 1,
  APPROVED: 2,
  REQUIRES_CHANGES: 3,
  PENDING_REVIEW: 4,
};

export const REVIEW_STATUSES_TEXT = {
  [REVIEW_STATUSES.DRAFT]: "Draft",
  [REVIEW_STATUSES.APPROVED]: "Approved",
  [REVIEW_STATUSES.REQUIRES_CHANGES]: "Requires Changes",
  [REVIEW_STATUSES.PENDING_REVIEW]: "Pending Review",
};

export const REVIEW_STATUSES_COLOR = {
  [REVIEW_STATUSES.DRAFT]: "#8a8a8f", // gray
  [REVIEW_STATUSES.APPROVED]: "#48a148", // green
  [REVIEW_STATUSES.REQUIRES_CHANGES]: "#b98f23", // amber
  [REVIEW_STATUSES.PENDING_REVIEW]: "#cf3b3b", // red
};
