import { useState } from "react";
import { UpsertKBIssue } from "./UpsertKBIssue";
import { useHistory } from "react-router-dom";

export const CreateKBIssue = ({ reload: reloadAll }) => {
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(true);

  const handleHide = (kbIssueId) => {
    reloadAll();
    if (kbIssueId) {
      history.push(`/pentest/kb-issues?kbIssueId=${kbIssueId}&fromCreate=true`);
    } else {
      history.goBack();
    }
  };

  return <UpsertKBIssue isEditMode={isEditMode} setIsEditMode={setIsEditMode} reload={handleHide} />;
};
