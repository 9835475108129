import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract, faHome, faLockOpen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faShieldAlt, faEnvelope, faBiohazard, faUserNinja, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "lib/components/Tooltip";

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  margin-left: 0.5rem;
  color: ${(props) => props.theme.text};
  opacity: ${(props) => (props.active === "true" ? 0.7 : 0.1)};
`;

export const CustomerRow = ({ customer, customerUser, setEditPermissionsModal, customerId, handleRemovePrompt }) => {
  const handleCustomerClick = () => {
    setEditPermissionsModal({ showModal: true, customer: customer, customerUser: customerUser });
  };

  return (
    <tr onClick={handleCustomerClick} style={{ cursor: "pointer" }}>
      <td style={{ fontSize: "1.2rem", width: "10px" }}>
        {customer?.id === customerId && <FontAwesomeIcon icon={faHome} aria-label={"Home customer"} />}
      </td>
      <td style={{ textAlign: "left" }}>{customer?.name}</td>
      <td>
        <StyledIcon
          aria-label="is customer admin?"
          title="Customer Admin"
          icon={faUserCog}
          active={`${customerUser?.roles?.includes("customer-admin")}`}
        />
        {" |"}
        <StyledIcon aria-label="access to SOC" title="Soc" icon={faShieldAlt} active={`${customerUser?.apps?.["soc"]?.enabled}`} />
        <StyledIcon aria-label="access to Vuln" title="Vuln" icon={faBiohazard} active={`${customerUser?.apps?.["vuln"]?.enabled}`} />
        <StyledIcon aria-label="access to Phish" title="Phish" icon={faEnvelope} active={`${customerUser?.apps?.["phish"]?.enabled}`} />
        <StyledIcon
          aria-label="access to Phishing Simulation"
          title="Phishing Simulation"
          icon={faUserNinja}
          active={`${customerUser?.apps?.["phish-sim"]?.enabled}`}
        />
        <StyledIcon aria-label="access to Pentest" title="Pentest" icon={faLockOpen} active={`${customerUser?.apps?.["pentest"]?.enabled}`} />
        <StyledIcon aria-label="access to MDS" title="MDS" icon={faFileContract} active={`${customerUser?.apps?.["mds"]?.enabled}`} />
      </td>
      <td style={{ overflow: "visible" }}>
        <Tooltip
          xHoverPos="left"
          yHoverPos="center"
          icon={faTrash}
          hoverText="Remove"
          clickAction={() => handleRemovePrompt(customer.id)}
        />
      </td>
    </tr>
  );
};
