import { UserProfileContext } from "auth";
import { useContext } from "react";
import { QueryEngine } from "../QueryEngine";


export const RenderQueryEngine = () => {
  const { customerUsers: user } = useContext(UserProfileContext);
  if (user[0].apps?.soc["query-engine"] && user[0].apps?.soc["query-engine"]?.enabled === true) {
    return <QueryEngine />;
  }
};
