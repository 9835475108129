import React, { useState } from "react";
import moment from "moment";
import { Input } from "lib/components/Input";
import { CheckboxButton } from "lib/components/Checkbox";
import { getFormValues } from "lib/helpers/getFormValues";

export const DateTimeWindows = React.forwardRef(({ defaultValue, readOnly, hideFields }, formRef) => {
  const [mins, setMins] = useState({});
  const [maxs, setMaxs] = useState({});
  const [includeSending, setIncludeSending] = useState(defaultValue.sendingStartDate);

  const handleIncludeSendingChange = (e) => {
    setIncludeSending(e.target.checked);
  };

  const parseDate = (date, format = "YYYY-MM-DD") => {
    if (!date) return null;
    return moment(date).format(format);
  };

  const onChange = () => {
    const { startDate, startTime, endDate, endTime, sendingStartDate, sendingEndDate } = getFormValues(formRef.current);
    let mins = {};
    let maxs = {};

    // End date must be at least the start date
    mins.endDate = startDate;
    // If the end date is the same day, the time has to be at least the same time
    if (startDate === endDate) mins.endTime = startTime;
    // Sending start date is at least overall start date
    mins.sendingStartDate = startDate;
    // Sending end date is at max overall end data
    maxs.sendingStartDate = endDate;
    // If the start date is the same day, the time has to be at least the same time
    if (sendingStartDate === startDate) mins.sendingStartTime = startTime;
    // Sending end date is at least the overall start date
    mins.sendingEndDate = startDate;
    // Sending end date can't exceed overall end date
    maxs.sendingEndDate = endDate;
    // If the end date is the same day, the time has to be at max the same time
    if (sendingEndDate === endDate) maxs.sendingEndTime = endTime;

    setMins(mins);
    setMaxs(maxs);
  };

  const renderDateTime = ({ dateName, dateLabel, timeName, timeLabel }) => {
    const props = { readOnly, onChange };
    if (readOnly) {
      return <Input {...props} name={dateName} label={dateLabel} defaultValue={parseDate(defaultValue[dateName], "HH:mm YYYY-MM-DD")} />;
    }
    return (
      <>
        <Input {...props} name={dateName} label={dateLabel} type="date" min={mins[dateName]} max={maxs[dateName]} defaultValue={parseDate(defaultValue[dateName])} />
        <Input {...props} name={timeName} label={timeLabel} type="time" min={mins[timeName]} max={maxs[timeName]} defaultValue={parseDate(defaultValue[dateName], "HH:mm")} />
      </>
    );
  };

  const shouldRenderSendingTimes = !hideFields && (includeSending || (readOnly && (defaultValue.sendingStartDate || defaultValue.sendingEndDate)));

  return (
    <>
      {!readOnly && !hideFields && <CheckboxButton subtle label="Include email sending window times?" key={includeSending} defaultChecked={includeSending} onChange={handleIncludeSendingChange} />}
      <span style={{ flexWrap: "wrap", gap: "0 3rem" }}>
        <div style={{ display: "flex", flex: "1 0 0", gap: "1rem", margin: 0 }}>
          {renderDateTime({ dateName: "startDate", dateLabel: "Campaign Start Date", timeName: "startTime", timeLabel: "Campaign Start Time" })}
        </div>
        <div style={{ display: "flex", flex: "1 0 0", gap: "1rem" }}>
          {renderDateTime({ dateName: "endDate", dateLabel: "Campaign End Date", timeName: "endTime", timeLabel: "Campaign End Time" })}
        </div>
      </span>
      {shouldRenderSendingTimes && (
        <span style={{ flexWrap: "wrap", gap: "0 3rem" }}>
          <div style={{ display: "flex", flex: "1 0 0", gap: "1rem", margin: 0 }}>
            {renderDateTime({ dateName: "sendingStartDate", dateLabel: "Email Sending Start Date", timeName: "sendingStartTime", timeLabel: "Email Sending Start Time" })}
          </div>
          <div style={{ display: "flex", flex: "1 0 0", gap: "1rem" }}>
            {renderDateTime({ dateName: "sendingEndDate", dateLabel: "Email Sending End Date", timeName: "sendingEndTime", timeLabel: "Email Sending End Time" })}
          </div>
        </span>
      )}
    </>
  );
});
