import React, { createContext } from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Dashboard } from "./dashboard";
import { Engagements } from "./engagements";
import { PendingItems } from "./pending-items";
import { KBIssues } from "./kb-issues";
import { useFetch } from "lib/helpers/fetchData";
import { fetchData } from "lib/helpers/fetchData";

export const CustomersContext = createContext({});
export const TestersContext = createContext({});

const loadCustomers = () => fetchData(`${process.env.REACT_APP_ADMIN_API}/customers`);
const loadTesters = () => fetchData(`${process.env.REACT_APP_PENTEST_API}/testers`);

export const PenTest = () => {
  const routes = [
    {
      name: "Dashboard",
      link: "/pentest/",
      isHidden: true,
    },
    {
      name: "Engagements",
      link: "/pentest/engagements",
      isWide: true,
    },
    {
      name: "KB Issues",
      link: "/pentest/kb-issues",
      isWide: true,
    },
    {
      name: "Pending items",
      link: "/pentest/pending-items",
      isWide: true,
    },
  ];

  const customers = useFetch(loadCustomers);
  const testers = useFetch(loadTesters);

  return (
    <CustomersContext.Provider value={customers}>
      <TestersContext.Provider value={testers}>
        <PageContainer name="Pentest" link="/pentest" routes={routes}>
          <ErrorHandledRoute path="/pentest/engagements" component={Engagements} />
          <ErrorHandledRoute path="/pentest/kb-issues" component={KBIssues} />
          <ErrorHandledRoute path="/pentest/pending-items" component={PendingItems} />
          <ErrorHandledRoute path="/pentest" component={Dashboard} />
        </PageContainer>
      </TestersContext.Provider>
    </CustomersContext.Provider>
  );
};
