import { UpsertEngagement } from "./UpsertEngagement";
import { Modal } from "lib/components/Modal";
import { useHistory } from "react-router-dom";

export const CreateEngagement = () => {
  const history = useHistory();

  const handleHide = (id, customerId) => {
    if (id) {
      history.push(`/pentest/engagements/engagement/${customerId}/${id}`);
    } else {
      history.goBack();
    }
  };

  return (
    <Modal hide={() => handleHide()} width="70rem">
      <UpsertEngagement endCreation={handleHide} />
    </Modal>
  );
};
