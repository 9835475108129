import React, { useEffect, useRef, useState } from "react";

import { fetchData } from "lib/helpers/fetchData";
import { ErrorsList } from "lib/components/ErrorsList";
import { getNamedValues } from "lib/helpers/getFormValues";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { Input } from "lib/components/Input";
import { CheckboxButton } from "lib/components/Checkbox";
import { StyledForm } from "lib/styles/general";
import { CTAButton } from "lib/components/CTAButton";
import { Tooltip } from "lib/components/Tooltip";

import { Previews } from "./Previews";
import { DeleteTemplate } from "./DeleteTemplate";
import { CustomerPicker } from "../CustomerPicker";
import { faBan } from "@fortawesome/free-solid-svg-icons";

export const SingleTemplate = ({ selected, customers, customer, refresh, templateReadOnly }) => {
  const [emailPreview, setEmailPreview] = useState(null);
  const [landingPreview, setLandingPreview] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const templateForm = useRef(null);

  useEffect(() => {
    setIsEditMode(!!!selected.id);
  }, [selected.id]);

  const handleSubmit = ({ duplicate = false }) => {
    const values = getNamedValues(templateForm.current);

    let errors = [];
    let errorWhenEmptyFields = { ...values };
    delete errorWhenEmptyFields.isPrivate;

    Object.entries(errorWhenEmptyFields).forEach(([key, value]) => !value && errors.push(key));

    values.customer = values.templateCustomer;
    values.emailPreview = emailPreview;
    values.landingPreview = landingPreview;

    if (!values.emailPreview) errors.push("emailPreview");

    if (duplicate) values.name = `${values.name} - Copy`; 

    setErrors(errors);
    if (errors.length) return;

    delete values.templateCustomer;
    delete values.emailPreview?.uri;
    delete values.landingPreview?.uri;

    if (selected.id && selected.fid && !duplicate) {
      values.id = selected.id;
      values.fid = selected.fid;
    }

    fetchData(`${process.env.REACT_APP_REPORTING_API}/phish-sim/manage/templates/upload`, JSON.stringify(values), "POST")
      .then((d) => {
        setIsEditMode(false);
        refresh(d.result);
      })
      .catch((e) => {
        setErrors([e?.error?.message || e?.error || e]);
      });
  };

  const isCreate = !selected.id;
  const defaultCustomer = selected.customer || { id: customer };

  return (
    <div className="preview">
      {!isEditMode && !isCreate ? (
        <div className="header">
          <CTAButton isSecondary onClick={() => handleSubmit({ duplicate: true })}>Duplicate</CTAButton>
          <CTAButton onClick={() => setIsEditMode(true)}>Edit</CTAButton>
        </div>
      ) : (
        <div className="footer">
          {!isCreate && (
            <CTAButton isSecondary onClick={() => setIsEditMode(false)}>
              Cancel
            </CTAButton>
          )}
          <CTAButton onClick={handleSubmit}>{isCreate ? "Create New" : "Update"}</CTAButton>
        </div>
      )}
      <ScrollContainer scrollX="hidden">
        <StyledForm ref={templateForm} style={{ position: "relative" }} className="form">
          <section>
            <Input name="name" label="Template Name" defaultValue={selected.name} readOnly={!isEditMode} />
            <Input name="difficulty" label="Difficulty" type="number" min={0} max={5} defaultValue={selected.difficulty} readOnly={!isEditMode} />
          </section>
          <section>
            <CustomerPicker defaultValue={defaultCustomer} key={defaultCustomer.id} name="templateCustomer" label="Owner" readOnly={!isEditMode} data={customers} style={{ marginBottom: 0 }} />
            {isEditMode && (
              <Tooltip
                xHoverPos="right"
                yHoverPos="bottom"
                hoverText={<p>{`"Owner" only takes affect when\n"Only for use by this customer" is ticked,\nin which case it is made private to them\n(for example: a bespoke template).`}</p>}
              />
            )}
            <CheckboxButton subtle name="isPrivate" label="Only for use by this customer" readOnly={!isEditMode} defaultChecked={selected.isPrivate} showWhenNull={false} smallWhenNull />
            {!isEditMode && selected.isPrivate && selected.customer.id !== customer && (
              <Tooltip style={{ marginTop: "1rem" }} icon={faBan} xHoverPos="right" yHoverPos="bottom" hoverText={<p>{`This customer doesn't\nhave access to this template.`}</p>} />
            )}
          </section>
          <section>
            <Input name="description" label="Template Description" defaultValue={selected.description} readOnly={!isEditMode} />
          </section>
          <Previews isEditMode={isEditMode} selected={selected} emailPreview={emailPreview} setEmailPreview={setEmailPreview} landingPreview={landingPreview} setLandingPreview={setLandingPreview} />
          <ErrorsList errors={errors} />
          {!templateReadOnly && !isCreate && !isEditMode && <DeleteTemplate template={selected} refresh={refresh} />}
        </StyledForm>
      </ScrollContainer>
    </div>
  );
};
