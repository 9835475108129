import styled from "styled-components";
import { Budget } from "./Budget";
import { Time } from "./Time";

const StyledContainer = styled.div`
  z-index: 101;
  padding: 1rem 0.75rem;
  border: 1px solid ${(props) => props.theme.headerOutline};
  background: ${props => props.theme.cardBackground};
  box-shadow: 0 3rem 5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 0.75rem;
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Details = ({ project }) => {
  return (
    <StyledContainer>
      <Time project={project} />
      <Budget project={project} />
    </StyledContainer>
  );
};
