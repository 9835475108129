import React, { useRef, useState } from "react";
import { fetchData, useFetch, Spinner, Error } from "lib/helpers/fetchData";
import { ListPane, StyledContentContainer } from "lib/components/ListPane";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { KeyValue } from "lib/components/KeyValue";
import { Table } from "lib/components/Table";
import { faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "lib/components/Tooltip";
import { Modal } from "lib/components/Modal";
import { QuantifierBoxes } from "lib/components/QuantifierBoxes";
import { useInfiniteScroll } from "lib/components/InfiniteScroll";
import { useDebouncedCallback } from "use-debounce";
import { Searchbar } from "lib/components/Searchbar";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { Checkbox } from "lib/components/Checkbox";

const loadGroupings = () => {
  return fetchData(`${process.env.REACT_APP_VULN_API}/groups`);
};

const loadSingleGroup = ({ id }) => {
  return fetchData(`${process.env.REACT_APP_VULN_API}/groups/item/${id}`);
};

const upsertGroup = async (title, id) => {
  const method = id ? "PUT" : "POST";
  const path = id ? `/groups/item/${id}` : "/groups";
  return fetchData(`${process.env.REACT_APP_VULN_API}${path}`, JSON.stringify({ title }), method);
};

const deleteGroup = async (id) => {
  return fetchData(`${process.env.REACT_APP_VULN_API}/groups/item/${id}`, null, "DELETE");
};

const removeQidFromGroup = async (groupId, qid) => {
  return fetchData(`${process.env.REACT_APP_VULN_API}/groups/qids/remove/${groupId}`, JSON.stringify({ qids: [qid] }), "PUT");
};

const addQidsToGroup = async (groupId, qids) => {
  return fetchData(`${process.env.REACT_APP_VULN_API}/groups/qids/add/${groupId}`, JSON.stringify({ qids }), "PUT");
};

export const Groupings = () => {
  const state = useFetch(loadGroupings);
  return <ListPane listState={state} listKey={"title"} listName="Group" singleItemPane={SingleGroup} upsertPane={UpsertGroup} />;
};

const SingleGroup = ({ data = {}, setEdit, endCreation, reload }) => {
  const [isDeletingGroup, setIsDeletingGroup] = useState(false);
  const [isUpdatingQid, setIsUpdatingQid] = useState(null);
  const [isVulnModalShown, setIsVulnModalShown] = useState(false);

  const handleDeleteGroup = async () => {
    setIsDeletingGroup(true);
    await deleteGroup(data.id);
    reload();
    setIsDeletingGroup(false);
    endCreation();
  };

  const handleRemoveQid = async (qid) => {
    setIsUpdatingQid(qid);
    await removeQidFromGroup(data.id, qid);
    reload();
    setIsUpdatingQid(null);
  };

  const handleAddQids = async (qids) => {
    setIsUpdatingQid(true);
    await addQidsToGroup(data.id, qids);
    setIsUpdatingQid(null);
    reload();
    setIsVulnModalShown(false);
  };

  const handleEditGroupName = () => {
    setEdit(data);
  };

  const handleShowQidModal = () => {
    setIsVulnModalShown(true);
  };

  return (
    <StyledContentContainer>
      <div className="header">
        <h1 className="title">{data.title}</h1>
        <div className="actions">
          <CTAButton isSecondary onClick={handleEditGroupName}>
            Change Title
          </CTAButton>
          <CTAButton isSecondary onClick={handleShowQidModal}>
            Add Qids
          </CTAButton>
          <CTAButton isLoading={isDeletingGroup} onClick={handleDeleteGroup}>
            Delete
          </CTAButton>
        </div>
      </div>
      <div className="body">
        <KeyValue text="Title" value={data.title} />
        <KeyValue text="Members" value={data.count} />
        <QidsTable id={data.id} key={data.count} removeQid={handleRemoveQid} isUpdatingQid={isUpdatingQid} />
        <VulnModal isShown={isVulnModalShown} hide={() => setIsVulnModalShown(false)} addQids={handleAddQids} existingQids={data.qids} />
      </div>
    </StyledContentContainer>
  );
};

const QidsTable = ({ id, removeQid, isUpdatingQid }) => {
  const { data, isLoading, error } = useFetch(loadSingleGroup, { id });

  if (isLoading || isUpdatingQid === true) return <Spinner />;
  if (error) return <Error message={error} />;
  if (data?.result?.vulns?.length === 0) return null;

  return (
    <Table tableStyle={{ tableLayout: data?.result?.vulns?.length ? "fixed" : "auto" }}>
      <thead>
        <tr>
          <th style={{ width: "7.5rem" }}>ID</th>
          <th>Title</th>
          <th style={{ width: "15rem" }}>Category</th>
          <th style={{ width: "6rem" }}>Severity</th>
          <th style={{ width: "2.5rem" }}></th>
        </tr>
      </thead>
      <tbody>
        {data?.result?.vulns?.map((vuln, i) => (
          <tr key={i}>
            <td>{`vuln-${vuln.fid}`}</td>
            <td title={vuln.title}>{vuln.title}</td>
            <td title={vuln.category}>{vuln.category}</td>
            <td>
              <QuantifierBoxes value={vuln.severity} />
            </td>
            <td>
              <Tooltip
                icon={isUpdatingQid === vuln.qid ? faSpinner : faTrash}
                iconProps={isUpdatingQid === vuln.qid && { rotate: "true" }}
                clickAction={() => removeQid(vuln.qid)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const VulnModal = ({ hide, isShown, addQids, existingQids }) => {
  const [data, setData] = useState({ result: [] });
  const [params, setParams] = useState({ search: "" });
  const [checks, setChecks] = useState([]);

  const { loadItems, hasMore, isLoading } = useInfiniteScroll({
    url: `https://api.dev.quorumcyber.com/api/vuln/clarity/vulns?group=DEV-Quorum+Cyber`,
    data,
    setData,
    params,
  });

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const handleCheck = (qid) => {
    if (checks.includes(qid)) {
      setChecks((checks) => checks.filter((c) => c !== qid));
    } else {
      setChecks((checks) => [...checks, qid]);
    }
  };

  const handleSubmit = () => {
    const qids = [...checks];
    setChecks([]);
    addQids(qids);
  };

  const handleHide = () => {
    setChecks([]);
    hide();
  };

  const filters = (
    <div style={{ display: "flex", width: "calc(100% + 0.6rem)", marginBottom: "1rem", position: "sticky", top: 0 }}>
      <Searchbar setSearch={searchCallback} />
    </div>
  );

  return (
    <Modal
      showModal={isShown}
      hide={handleHide}
      style={{ width: "95vw", marginBottom: 0 }}
      actionText="Add Selected"
      actionCommand={handleSubmit}
    >
      <ScrollContainer style={{ maxHeight: "calc(100vh - 25rem)", margin: "0.7rem -1.5rem 0", padding: "0 1rem 1rem" }} scrollX="hidden">
        <InfiniteScroll filters={filters} useWindow={false} loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data}>
          <Table tableStyle={{ tableLayout: data.result.length ? "fixed" : "auto" }}>
            <thead>
              <tr>
                <th style={{ width: "1rem" }}></th>
                <th style={{ width: "7.5rem" }}>ID</th>
                <th>Title</th>
                <th>Solution</th>
                <th style={{ width: "15rem" }}>Category</th>
                <th style={{ width: "6rem" }}>Severity</th>
              </tr>
            </thead>
            <tbody>
              {data.result
                .filter((vuln) => !existingQids.includes(vuln.qid))
                .map((vuln, i) => (
                  <tr key={i}>
                    <td>
                      <Checkbox readOnly checked={checks.includes(vuln.qid)} onClick={() => handleCheck(vuln.qid)} />
                    </td>
                    <td>{`vuln-${vuln.fid}`}</td>
                    <td title={vuln.title}>{vuln.title}</td>
                    <td title={vuln.solution}>{vuln.solution}</td>
                    <td title={vuln.category}>{vuln.category}</td>
                    <td>
                      <QuantifierBoxes value={vuln.severity} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </ScrollContainer>
    </Modal>
  );
};

const UpsertGroup = ({ reload, data, endCreation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const nameRef = useRef(data?.title);

  const handleUpsertGroup = async () => {
    const groupName = nameRef.current.value;
    const groupId = data?.id;
    setIsLoading(true);
    await upsertGroup(groupName, groupId);
    setIsLoading(false);
    reload();
    endCreation(data?.id);
  };

  const verb = !!data ? "Edit" : "Create";

  return (
    <StyledContentContainer>
      <div className="body constrain">
        <h1>{verb} Group</h1>
        <Input ref={nameRef} defaultValue={nameRef.current} label="Name" />
      </div>
      <div className="footer">
        <div className="actions">
          <CTAButton isSecondary onClick={() => endCreation(data?.id)}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleUpsertGroup}>
            {verb}
          </CTAButton>
        </div>
      </div>
    </StyledContentContainer>
  );
};
