import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchData, useFetch } from "lib/helpers/fetchData";

import { UpsertKBIssue } from "./UpsertKBIssue";
import { CommentsList } from "../comments/CommentsList";
import { useQuery } from "lib/helpers/useQuery";

const loadComments = async ({ kbIssueId }) => {
  return await fetchData(`${process.env.REACT_APP_PENTEST_API}/comments/${kbIssueId}`);
};

export const SingleKBIssue = ({ reload: reloadList, kbIssue, kbIssueId, attachments }) => {
  const { data, isLoading, error, reload: reloadComments } = useFetch(loadComments, { kbIssueId });
  const { fromCreate } = useQuery();
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(!!fromCreate);

  useEffect(() => {
    const scrollingElement = document.getElementById("scrolling-element");
    if (scrollingElement) {
      const left = isEditMode ? scrollingElement.clientWidth : 0;
      scrollingElement.scrollTo({ behavior: "smooth", left });
    }
  }, [isEditMode]);

  const handleHide = () => {
    history.push(`/pentest/kb-issues`);
    reloadList();
  };

  const reloadAll = () => {
    reloadList();
    reloadComments();
  };

  return (
    <>
      <UpsertKBIssue
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        data={kbIssue}
        reload={reloadAll}
        hide={handleHide}
        attachments={attachments}
      />
      {!isEditMode && <CommentsList targetId={kbIssueId} data={data} isLoading={isLoading} error={error} reload={reloadComments} />}
    </>
  );
};
