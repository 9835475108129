import moment from "moment";
import styled from "styled-components";
import { toPrecision } from "lib/helpers/toPrecision";
import { CircleIndicator } from "../../components/CircleIndicator";
import { VALUE_COLORS } from "../../components/constants";

const StyledCircle = styled(CircleIndicator)`
  && {
    width: 8.5rem;
    height: 8.5rem;

    .text {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.mediumText};

      & > h2 {
        font-size: 1.75rem;
        margin: 0;
        text-align: center;
      }

      & > h3 {
        font-size: 1.2rem;
        margin: 0 0 0.5rem;
        white-space: pre-wrap;
        text-align: center;
      }
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  padding-right: 2rem;
  padding-bottom: 0;
  border-right: 1px solid ${props => props.theme.headerOutline};

  & > .details {
    & > h2 {
      margin: 0;
      color: ${(props) => props.theme.text};
      font-size: 1.75rem;
    }
  }
`;

export const Time = ({ project }) => {
  const { startDate, endDate } = project;

  const formatDate = (date) => {
    if (!date) return "NA";
    if (!moment().isSame(date, "year")) return moment(date).format("D MMM 'YY");
    return moment(date).format("D MMM");
  };

  return (
    <StyledContainer>
      <div className="details">
        <h2>
          {formatDate(startDate)} - {formatDate(endDate)}
        </h2>
      </div>
      <TimeCircle project={project} />
    </StyledContainer>
  );
};

const TimeCircle = ({ project }) => {
  let { startDate, endDate, timePriority } = project;

  if (!startDate || !endDate) return null;

  startDate = moment(startDate).startOf("day");
  endDate = moment(endDate).endOf("day");

  // Calc in hours here for better granularity
  const projectLengthInHours = endDate.diff(startDate, "hours") || 1;
  const hoursThroughProject = moment().diff(startDate, "hours");

  const percentageComplete = toPrecision(Math.max(0, hoursThroughProject) / projectLengthInHours);

  // Convert back to days with rounding
  const daysLeft = Math.round((projectLengthInHours - hoursThroughProject) / 24);
  const timeTillStart = -hoursThroughProject;
  const daysTillStart = Math.round(timeTillStart / 24);

  const days = timeTillStart > 0 ? daysTillStart : daysLeft;
  const daysWordage = Math.abs(days) === 1 ? "day" : "days";
  const label = daysTillStart > 0 ? "'til\nstart" : daysLeft < 0 ? "over" : "left";

  const color = daysTillStart > 0 ? "gray" : VALUE_COLORS[timePriority];

  return (
    <StyledCircle percentageComplete={percentageComplete} color={color}>
      <span className="text">
        <h2>{`${Math.abs(days)}`}</h2>
        <h3>{`${daysWordage} ${label}`}</h3>
      </span>
    </StyledCircle>
  );
};
