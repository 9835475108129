const VALID_IMG_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "bmp"];

export const getImageSource = ({ properties = {} }) => {
  if (properties.contentType?.toString()?.split("/")?.[0] === "image") {
    return properties.location?.url
  }
  if (properties.url) {
    const url = properties.url.toLowerCase();
    let ext = url.substr(url.lastIndexOf(".") + 1);
    if (VALID_IMG_EXTENSIONS.includes(ext)) {
      return properties.url;
    }
  }
  return null;
}