import React, { useState, useContext } from "react";
import moment from "moment";

import { Table } from "lib/components/Table";
import { SortableHeader } from "lib/components/SortableHeader";
import { LinkRow } from "lib/components/LinkRow";
import { trimWord } from "lib/helpers/trimWord";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faFilter } from "@fortawesome/free-solid-svg-icons";
import { getTimeTillDate } from "lib/helpers/getTimeTillDate";
import { IconPopup } from "lib/components/PopupMenu";
import { Select } from "lib/components/Select";
import { KeyValue } from "lib/components/KeyValue";
import { useDebouncedCallback } from "use-debounce";
import { useInfiniteScroll, InfiniteScroll } from "lib/components/InfiniteScroll";
import { Searchbar } from "lib/components/Searchbar";
import { TicketsContext } from "..";
import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { AutoHideModal } from "lib/components/Modal";
import { StyledFilters } from "lib/styles/general";
import { getAnalystName } from "lib/helpers/getAnalystName";

const FilterPopup = ({ status, setStatus }) => {
  return (
    <IconPopup text="Filter" icon={faFilter} key={status} buttonStyle={{ margin: 0 }}>
      <KeyValue
        text="Status"
        disableTitle
        value={
          <Select defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">All</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
            <option value="suspended">Suspended</option>
            <option value="dismissed">Dismissed</option>
          </Select>
        }
      />
    </IconPopup>
  );
};

export const ListTickets = () => {
  const url = `${process.env.REACT_APP_PHISH_API}/tickets`;
  const { filters, setFilter } = useContext(TicketsContext);
  return <TicketsTable url={url} params={filters} setParam={setFilter} />;
};

const TicketsTable = ({ url, params, setParam }) => {
  const [data, setData] = useState({ result: [] });
  const [evaluationStatus, setEvaluationStatus] = useState(null);
  const { loadItems, hasMore, isLoading } = useInfiniteScroll({ url, params, data, setData });

  const [searchCallback] = useDebouncedCallback((search) => {
    setParam({ search });
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: (sort) => setParam({ ...sort }),
  };

  const status = {
    status: params.status,
    setStatus: (status) => setParam({ status }),
  };

  const handleCancel = async () => {
    const data = await fetchData(`${process.env.REACT_APP_PHISH_API}/tickets/evaluations/clear`, null, "POST");
    setEvaluationStatus(`Successfully dequeued ${data.result.numberOfDequeuedTickets} tickets`);
  };

  const handleEvaluate = async () => {
    const data = await fetchData(`${process.env.REACT_APP_PHISH_API}/tickets/evaluate`, null, "POST");
    if (data.result.success) {
      setEvaluationStatus(`Successfully enqueued ${data.result.numberOfQueuedTickets} tickets`);
    } else {
      setEvaluationStatus(data.result.message);
    }
  };

  const filters = (
    <StyledFilters>
      {evaluationStatus && <AutoHideModal actionCommand={() => setEvaluationStatus(null)}>{evaluationStatus}</AutoHideModal>}
      <Searchbar {...search} />
      <hr />
      <CTAButton onClick={handleEvaluate} isSecondary>
        Evaluate open
      </CTAButton>
      <CTAButton style={{ margin: 0 }} onClick={handleCancel} isSecondary>
        Clear queue
      </CTAButton>
      <hr />
      <FilterPopup {...status} />
    </StyledFilters>
  );

  return (
    <InfiniteScroll filters={filters} loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data}>
      <Table>
        <thead>
          <tr>
            <th />
            <SortableHeader field="fid" {...sort}>
              ID
            </SortableHeader>
            <SortableHeader field="mail['from']['value'][0].address" {...sort}>
              Mail From
            </SortableHeader>
            <SortableHeader field="customer.code" {...sort}>
              Company
            </SortableHeader>
            <SortableHeader field="created" {...sort}>
              Received
            </SortableHeader>
            <SortableHeader field="due" {...sort}>
              Due In
            </SortableHeader>
            <SortableHeader field="mail.subject" {...sort}>
              E-mail Subject
            </SortableHeader>
            <th>Tags</th>
            <SortableHeader field="status" {...sort}>
              Status
            </SortableHeader>
            <SortableHeader field="severity.text" style={{ justifyContent: "  flex-end" }} {...sort}>
              Severity
            </SortableHeader>
            <SortableHeader field="analyst.displayName" style={{ justifyContent: "flex-end" }} {...sort}>
              Analyst
            </SortableHeader>
          </tr>
        </thead>
        <tbody>
          {data.result.map((ticket, i) => (
            <LinkRow key={i} path={`/phish/tickets/${ticket.customer.id}/${ticket.id}`} style={!ticket.analyst && { fontWeight: "bold" }}>
              <td style={{ maxWidth: "20px", paddingRight: "0" }}>{ticket.hasAttachments && <FontAwesomeIcon icon={faPaperclip} />}</td>
              <td style={{ maxWidth: "100px", paddingRight: "0", textDecoration: !ticket.analyst && "underline" }}>{ticket.fid}</td>
              <td style={{ maxWidth: "100px" }}>{trimWord(ticket.from?.value[0]?.address?.split("@")?.[0], 40)}</td>
              <td style={{ maxWidth: "50px" }}>{ticket.customer?.code}</td>
              <td style={{ maxWidth: "100px", overflow: "visible" }}>{moment(ticket.created).format("D/MM - HH:mm")}</td>
              <td style={{ maxWidth: "80px" }}>{getTimeTillDate(ticket.due)}</td>
              <td style={{ maxWidth: "300px" }}>{ticket.subject}</td>
              <td style={{ maxWidth: "100px" }}>{ticket.tags?.map((tag) => tag.name)?.join(", ")}</td>
              <td style={{ maxWidth: "300px" }}>{ticket.status}</td>
              <td
                style={{
                  maxWidth: "160px",
                  textAlign: "right",
                  textTransform: "capitalize",
                  color: getColorFromSeverity(ticket.severity),
                }}
              >
                {`${ticket.severity?.text} ${ticket.severity?.score > 0 ? `(${ticket.confidence?.text})` : ""} `}
              </td>
              <td style={{ maxWidth: "125px" }}>{getAnalystName(ticket?.analyst)}</td>
            </LinkRow>
          ))}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
};
