import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { prettifyHTML } from "lib/helpers/prettifyHTML";
import { useSearchResults, MATCHED_ELEMENT_CLASSNAME } from "lib/helpers/useSearchResults";
import { Searchbar } from "lib/components/Searchbar";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "lib/components/Tooltip";

export const StyledBody = styled.div`
  width: 100%;
  padding-top: ${props => `calc(${props.offset || 0}px + ${props.offset ? "1rem" : "0px"})`};
  & > div {
    border: 1px solid ${props => props.theme.headerOutline};
    border-radius: 0.5rem;
    overflow: hidden;
    &#searchbar {
      position: ${props => props.offset && "fixed"};
      box-shadow: ${props => props.offset && `0 10px 12px -5px rgba(0, 0, 0, 0.3), 0 -2rem 0 0 ${props.theme.cardBackground}`};
      top: 1rem;
      z-index: 99;
    }
  }
  p {
    font-size: 1.25rem;
    color: ${props => props.theme.text};
  }
`;

export const StyledPart = styled.span`
  &.match {
    font-weight: bold;
    border-radius: 0.25rem;
    color: ${props => props.theme.buttonText || props.theme.text};
    background: ${props => props.theme.secondaryButtonColor};
    opacity: 0.5;
    transition: opacity 0.3;
    &.current {
      opacity: 1;
    }
  }
`;

export const Text = ({ data }) => {
  const [search, setSearch] = useState("");

  let parts = search.length > 1 ? data.mail.text.split(new RegExp(`(${search})`, "gi")) : [data.mail.text];
  let results = parts.filter(part => part.toLowerCase() === search.toLowerCase());

  const [index, offset] = useSearchResults(search, results);

  return (
    <StyledBody offset={offset}>
      <Searchbar setSearch={s => setSearch(s)}>
        <p style={{ margin: "0 0.5rem" }}>{results.length ? `${index + 1}/${results.length}` : ""}</p>
      </Searchbar>
      <div>
        <ScrollContainer scrollX="auto" style={{ padding: "1.5rem" }}>
          <p style={{ whiteSpace: "pre" }}>
            {parts.map((part, i) => {
              const isMatch = part.toLowerCase() === search.toLowerCase();
              return (
                <StyledPart key={i} className={isMatch && MATCHED_ELEMENT_CLASSNAME}>
                  {part}
                </StyledPart>
              );
            })}
          </p>
        </ScrollContainer>
      </div>
    </StyledBody>
  );
};

export const Html = ({ data }) => {
  const [search, setSearch] = useState("");

  let parts = search.length > 1 ? data.mail.html.split(new RegExp(`(${search})`, "gi")) : [data.mail.html];
  let results = parts.filter(part => part.toLowerCase() === search.toLowerCase());

  const [index, offset] = useSearchResults(search, results);

  return (
    <StyledBody offset={offset}>
      <Searchbar setSearch={s => setSearch(s)}>
        <p style={{ margin: "0 0.5rem" }}>{results.length ? `${index + 1}/${results.length}` : ""}</p>
      </Searchbar>
      <div>
        <ScrollContainer style={{ padding: "1.5rem" }}>
          <p style={{ whiteSpace: "pre-wrap" }}>
            {prettifyHTML(parts.join("SEPARATOR"))
              .split("SEPARATOR")
              .map((part, i) => {
                const isMatch = part.toLowerCase() === search.toLowerCase();
                return (
                  <StyledPart key={i} className={isMatch && MATCHED_ELEMENT_CLASSNAME}>
                    {part}
                  </StyledPart>
                );
              })}
          </p>
        </ScrollContainer>
      </div>
    </StyledBody>
  );
};

export const Raw = ({ data: url }) => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const headers = {
      "Content-Type": "text/plain"
    };

    fetch(url, { headers })
      .then(d => d.text())
      .then(d => setData(d));
  }, [url]);

  let parts = [],
    results = [];
  if (data) {
    parts = search.length > 1 ? data.split(new RegExp(`(${search})`, "gi")) : [data];
    results = parts.filter(part => part.toLowerCase() === search.toLowerCase());
  }

  const [index, offset] = useSearchResults(search, results);

  return (
    <StyledBody offset={offset}>
      <Searchbar setSearch={s => setSearch(s)} style={{ overflow: "unset" }}>
        <p style={{ margin: "0 0.5rem" }}>{results.length ? `${index + 1}/${results.length}` : ""}</p>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Tooltip xHoverPos="left" yHoverPos="bottom" hoverText="Open in new tab" icon={faExternalLinkAlt} />
        </a>
      </Searchbar>
      <div>
        <ScrollContainer scrollX="auto" style={{ padding: "1.5rem" }}>
          <p style={{ whiteSpace: "pre-wrap" }}>
            {parts.map((part, i) => {
              const isMatch = part.toLowerCase() === search.toLowerCase();
              return (
                <StyledPart key={i} className={isMatch && MATCHED_ELEMENT_CLASSNAME}>
                  {part}
                </StyledPart>
              );
            })}
          </p>
        </ScrollContainer>
      </div>
    </StyledBody>
  );
};
