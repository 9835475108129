import React, { useEffect, useRef } from "react";

import { Input } from "lib/components/Input";

import { CTAButton } from "lib/components/CTAButton";
import { PreviewBox } from "lib/components/PreviewBox";
import { KeyValue } from "lib/components/KeyValue";
import { imageURLToBase64, toBase64, toBase64URI } from "lib/helpers/toBase64";
import { CachedImage } from "lib/components/CachedImage";

export const Previews = ({ isEditMode, selected, emailPreview, setEmailPreview, landingPreview, setLandingPreview }) => {
  const emailPreviewRef = useRef(null);
  const landingPreviewRef = useRef(null);

  useEffect(() => {
    const convertExistingToBase64 = async () => {
      if (selected.emailPreview?.url) {
        const emailData = await imageURLToBase64(selected.emailPreview.url);
        const emailPreview = { data: emailData, ...selected.emailPreview };
        setEmailPreview({ ...emailPreview, uri: toBase64URI(emailPreview) });
      } else {
        setEmailPreview(null);
      }

      if (selected.landingPreview?.url) {
        const landingData = await imageURLToBase64(selected.landingPreview.url);
        const landingPreview = { data: landingData, ...selected.landingPreview };
        setLandingPreview({ ...landingPreview, uri: toBase64URI(landingPreview) });
      } else {
        setLandingPreview(null);
      }
    };
    convertExistingToBase64();
  }, [selected, setEmailPreview, setLandingPreview]);

  const handleChange = async (e, setPreview) => {
    const file = e.target.files[0];
    const blob = await toBase64(file);
    const data = blob.split("base64,")[1];
    const contentType = blob.split("data:")[1].split(";")[0];
    const uri = toBase64URI({ data, contentType });
    setPreview({ data, contentType, uri });
  };

  return (
    <section>
      {emailPreview ? (
        <KeyValue
          text="Email Preview"
          wrap={false}
          value={
            <>
              <PreviewBox maxHeight={150} id="email-preview" style={{ padding: 0, marginBottom: "0.5rem" }}>
                <CachedImage src={emailPreview?.uri} alt="Email Preview" style={{ width: "100%", height: "auto" }} />
              </PreviewBox>
              {isEditMode && (
                <CTAButton
                  isSecondary
                  onClick={() => {
                    setEmailPreview(null);
                    setTimeout(() => emailPreviewRef.current.click(), 50);
                  }}
                >
                  Change
                </CTAButton>
              )}
            </>
          }
        />
      ) : (
        <Input ref={emailPreviewRef} accept="image/*" onChange={(e) => handleChange(e, setEmailPreview)} type="file" label="Email Preview" readOnly={!isEditMode} />
      )}
      {landingPreview ? (
        <KeyValue
          text={`Landing Page Preview ${isEditMode ? "(Optional)" : ""}`}
          wrap={false}
          value={
            <>
              <PreviewBox maxHeight={150} id="landing-preview" style={{ padding: 0, marginBottom: "0.5rem" }}>
                <CachedImage src={landingPreview?.uri} alt="Landing Preview" style={{ width: "100%", height: "auto" }} />
              </PreviewBox>
              {isEditMode && (
                <CTAButton
                  isSecondary
                  onClick={() => {
                    setLandingPreview(null);
                    setTimeout(() => landingPreviewRef.current.click(), 50);
                  }}
                >
                  Change
                </CTAButton>
              )}
            </>
          }
        />
      ) : (
        <Input ref={landingPreviewRef} accept="image/*" onChange={(e) => handleChange(e, setLandingPreview)} type="file" label="Landing Page Preview (Optional)" readOnly={!isEditMode} />
      )}
    </section>
  );
};