import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { timeRangeValues } from "../../utils";
import { ClarityDarkTheme, FilterStyles } from "./theme";
import { loadCustomersList } from "apps/customers/Customers";
import { useFetch } from "lib/helpers/fetchData";
import { Chip } from "@material-ui/core";

const fetchOptions = async () => {
  const response = await loadCustomersList();
  const filteredOptions = response.result
    .filter((customer) => customer.connectors.azure.workspaceName !== "" && customer.connectors.azure.workspaceId !== "")
    .map((customer) => ({
      label: `${customer.name} - ${customer.connectors.azure.workspaceName}`,
      value: customer.id,
    }));
  return filteredOptions;
};

export const TimeRangeFilter = ({ selectedTimeRange, setSelectedTimeRange }) => {
  useEffect(() => {
    setSelectedTimeRange(timeRangeValues.find((range) => range.value === "PT30M"));
  }, [setSelectedTimeRange]); // Set the default value when the component mounts

  return (
    <ThemeProvider theme={ClarityDarkTheme}>
      <Autocomplete
        options={timeRangeValues}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={selectedTimeRange}
        onChange={(event, value) => setSelectedTimeRange(value)}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Time Range" variant="outlined" />}
      />
    </ThemeProvider>
  );
};

export const WorkspaceFilter = ({ selectedWorkspaces, setSelectedWorkspaces }) => {
  const filterClass = FilterStyles();
  const [selectAll, setSelectAll] = useState(true);
  const { data: workspaces, isLoading } = useFetch(fetchOptions);

  useEffect(() => {
    if (workspaces && workspaces.length > 0 && selectAll) {
      setSelectedWorkspaces(workspaces);
    }
  }, [workspaces, selectAll, setSelectedWorkspaces]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedWorkspaces(workspaces);
      setSelectAll(true);
    } else {
      setSelectedWorkspaces([]);
      setSelectAll(false);
    }
  };

  const handleSelectOption = (event, value) => {
    setSelectedWorkspaces(value);
    setSelectAll(value.length === workspaces.length);
  };

  const MAX_VISIBLE_TAGS = 10;

  const renderTags = (value, getTagProps) => {
    if (value.length <= MAX_VISIBLE_TAGS) {
      return value.map((option, index) => <Chip key={index} label={option.label} {...getTagProps({ index })} />);
    } else {
      const visibleTags = value.slice(0, MAX_VISIBLE_TAGS);
      const remainingCount = value.length - visibleTags.length;

      return (
        <>
          {visibleTags.map((option, index) => (
            <Chip key={index} label={option.label} {...getTagProps({ index })} style={{ fontSize: "10px" }} />
          ))}
          <Chip label={`& ${remainingCount} others`} style={{ fontSize: "10.5px", color: "#01a981" }} />
        </>
      );
    }
  };

  return (
    <div className="workspace-filter" style={{ width: "400px" }}>
      <ThemeProvider theme={ClarityDarkTheme}>
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: ClarityDarkTheme.palette.primary.main }}
              checked={selectAll}
              onChange={handleSelectAll}
              indeterminate={selectedWorkspaces.length > 0 && selectedWorkspaces.length < workspaces.length}
            />
          }
          label="Select All"
          style={{ color: ClarityDarkTheme.palette.secondary.contrastText }}
        />
        <Autocomplete
          theme={ClarityDarkTheme}
          multiple
          options={workspaces || []}
          loading={isLoading}
          disableCloseOnSelect
          value={selectedWorkspaces}
          onChange={handleSelectOption}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <li {...props}>
              <Checkbox checked={selectedWorkspaces.includes(option)} style={{ color: ClarityDarkTheme.palette.primary.main }} />
              {option.label}
            </li>
          )}
          renderTags={renderTags}
          renderInput={(params) => <TextField {...params} label="Workspaces" variant="outlined" />}
          className={filterClass.customFilter}
        />
      </ThemeProvider>
    </div>
  );
};
