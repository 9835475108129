import React, { useState, useRef } from "react";

import { loadCustomersList } from "../../../Customers";
import { EditPermissionsModal } from "./EditPermissionsModal";
import { CustomerRow } from "./CustomerRow";

import { getFormValues } from "lib/helpers/getFormValues";
import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";

import { StyledFilters } from "lib/styles/general";
import { StyledForm } from "lib/styles/general";

import { Modal, ConfirmModal } from "lib/components/Modal";
import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { Table } from "lib/components/Table";
import { Select } from "lib/components/Select";

const loadUserCustomers = async ({ userId }) => {
  return await fetchData(`${process.env.REACT_APP_ADMIN_API}/user/${userId}/customers`);
};

const loadCustomerUserList = async ({ userId }) => {
  return await fetchData(`${process.env.REACT_APP_ADMIN_API}/user/${userId}/customers/permissions`);
};

export const UserCustomers = ({ user, reload: reloadUsers, customerId }) => {
  const [search, setSearch] = useState("");
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [addCustomerModal, setAddCustomerModal] = useState({ showModal: false, callback: null });
  const [editPermissionsModal, setEditPermissionsModal] = useState({ showModal: false, callback: null, customer: {}, customerUser: {} });
  const { data, isLoading, error, reload: reloadUserCustomers } = useFetch(loadUserCustomers, { userId: user.id, key: "userCustomers" });
  const [isRemoving, setIsRemoving] = useState(false);
  const customerUsers = useFetch(loadCustomerUserList, { userId: user.id });

  if (isLoading) return <Spinner />;
  if (error) return <Error message={error} />;

  const reload = () => {
    reloadUsers();
    reloadUserCustomers();
    customerUsers.reload();
  };

  const handleAddCustomer = async (customer) => {
    setAddCustomerModal({ showModal: true, isLoading: true });
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/user/${user.id}/customers`, JSON.stringify(customer), "POST");
    setAddCustomerModal({ showModal: false, isLoading: false });
    reload();
  };

  const handleRemoveCustomer = async (customerId) => {
    setIsRemoving(true);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/user/${user.id}/customers`, JSON.stringify({ id: customerId }), "DELETE");
    setIsRemoving(false);
    setConfirmModal({ showModal: false, callback: null });
    reload();
  };

  const handleRemovePrompt = (customerId) => {
    setConfirmModal({ showModal: true, callback: () => handleRemoveCustomer(customerId) });
  };

  const handleShowAddCustomer = () => setAddCustomerModal({ showModal: true, callback: handleAddCustomer });

  const customers = data?.result?.filter((u) => [u.name.toLowerCase()].join("").includes(search.toLowerCase()));
  return (
    <>
      <div>
        <StyledFilters style={{ display: "flex" }}>
          <Searchbar style={{ margin: 0 }} setSearch={setSearch}>{`${customers.length} customers`}</Searchbar>
          <hr />
          <CTAButton onClick={handleShowAddCustomer}>+ To Customer</CTAButton>
        </StyledFilters>
        {customers.length ? (
          <Table>
            <tbody>
              {customers.map((customer, i) => {
                return (
                  <CustomerRow
                    customer={customer}
                    customerUser={customerUsers.data?.result.find((x) => x.customer.id === customer.id)}
                    setEditPermissionsModal={setEditPermissionsModal}
                    customerId={customerId}
                    handleRemovePrompt={handleRemovePrompt}
                    key={i}
                  />
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No customers</p>
        )}
      </div>
      <AddCustomerModal
        {...addCustomerModal}
        hide={() => setAddCustomerModal({ showModal: false })}
        user={user}
        existingCustomers={data.result}
      />
      <ConfirmModal {...confirmModal} isLoading={isRemoving} hide={() => setConfirmModal({ showModal: false })} />
      <EditPermissionsModal {...editPermissionsModal} hide={() => setEditPermissionsModal({ showModal: false })} reload={reload} />
    </>
  );
};

const AddCustomerModal = ({ showModal, callback, hide, user, existingCustomers }) => {
  const { data, isLoading } = useFetch(loadCustomersList, { key: "customers" });
  const formRef = useRef(null);

  const handleAdd = () => {
    const values = getFormValues(formRef.current);
    const customer = { id: values.customer };
    callback(customer);
  };

  return (
    <Modal
      title={`Add ${user.displayName} to...`}
      showModal={showModal}
      hide={hide}
      isLoading={isLoading}
      actionText="Add"
      actionCommand={handleAdd}
    >
      <StyledForm ref={formRef}>
        <p>Note that the user will be added with minimal access, and the desired level of access will need to be set separately.</p>
        <Select name="customer" label="Customer">
          <option>Select customer</option>
          {data?.result
            ?.filter((c) => !existingCustomers.find((existingCustomer) => existingCustomer.id === c.id))
            ?.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
            ?.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
        </Select>
      </StyledForm>
    </Modal>
  );
};
