import React from "react";
import { Redirect } from "react-router-dom";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Explorer } from "./Explorer";

export const DocumentHub = () => {
  const routes = [{ name: "Explorer", link: "/document-hub/explorer" }];

  return (
    <PageContainer name="Document Hub" link="/document-hub" routes={routes}>
      <ErrorHandledRoute path="/document-hub/explorer" component={Explorer} />
      <ErrorHandledRoute path="/document-hub/" component={() => <Redirect to="/document-hub/explorer" />} />
    </PageContainer>
  );
};
