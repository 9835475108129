import { Pagination } from "@mui/material";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { Table } from "lib/components/Table";
import { truncate } from "lib/helpers/truncate";
import { useEffect, useState } from "react";
import { PaginationStyles } from "./theme";

const handlePageChange = (event, value, rows, setPaginatedRows) => {
  const index = value - 1;
  console.log(rows[0][index]);
  setPaginatedRows(rows[0][index]);
};

export const TableComponent = ({ data }) => {
  const [paginatedRows, setPaginatedRows] = useState([]);
  useEffect(() => {
    if (data.result) {
      setPaginatedRows(data.result.tables[0].rows[0][0]);
    }
  }, [data]);
  let table = {};
  try {
    table = data.result.tables[0];
  } catch {
    table.rows = [[data.message]];
    table.columns = [{ name: "Error", type: "string" }];
  }
  if (!table) return null;
  const classes = PaginationStyles();
  const { columns } = table;
  return (
    <ScrollContainer scrollX="auto" scrollY="unset" style={{ maxWidth: "90vw" }}>
      <Pagination
        count={data.pages}
        shape="rounded"
        onChange={(event, value) => handlePageChange(event, value, table.rows, setPaginatedRows)}
        classes={{ ul: classes.ul }}
      />
      <Table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedRows && paginatedRows.length > 0 ? (
            paginatedRows.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                {Array.isArray(row) ? (
                  row.map((cell, cellIndex) => (
                    <td key={`cell-${cellIndex}`} title={cell}>
                      {truncate(cell || "")}
                    </td>
                  ))
                ) : (
                  <td colSpan={columns.length}>{truncate(row || "")}</td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>No data</td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollContainer>
  );
};
