import React, { useState, useRef } from "react";
import { fetchData } from "lib/helpers/fetchData";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledFilters } from "lib/styles/general";
import { StyledForm } from "lib/styles/general";

import { Modal, ConfirmModal } from "lib/components/Modal";
import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { Table } from "lib/components/Table";
import { Input } from "lib/components/Input";
import { MarkdownEditor } from "lib/components/MarkdownEditor";

export const Network = ({ data, customerId, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [addApplianceModal, setAddApplianceModal] = useState({ showModal: false, callback: null });
  const [applianceModal, setApplianceModal] = useState({ showModal: false, appliance: {} });

  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    let values = getFormValues(formRef.current);
    values.appliances = data.wiki.network.appliances;
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${data.id}/wiki/network`, JSON.stringify({ ...values }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };
  const handleExit = () => setIsEditMode(false);

  const handleAddAppliance = async (appliance) => {
    setAddApplianceModal({ showModal: true, isLoading: true });
    const appliances = [...data.wiki.network.appliances, appliance];
    console.log(appliances);
    await fetchData(
      `${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/network`,
      JSON.stringify({ ...data.wiki.network, appliances }),
      "PUT"
    );
    setAddApplianceModal({ showModal: false, isLoading: false });
    reload();
  };

  const handleShowAddAppliance = () => setAddApplianceModal({ showModal: true, callback: handleAddAppliance });

  const list = data.wiki.network.appliances?.filter((c) =>
    [c.name.toLowerCase(), c.ip.toLowerCase(), c.version.toLowerCase(), c.os.toLowerCase()].join("").includes(search.toLowerCase())
  );

  return (
    <div>
      <div style={{ position: "relative" }}>
        {!isEditMode && (
          <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
            Edit
          </CTAButton>
        )}
        <StyledForm ref={formRef}>
          <section>
            <MarkdownEditor name="notes" style={{ margin: "2rem 0" }} defaultValue={data?.wiki?.network?.notes || "No notes yet"} readOnly={!isEditMode} subtle />
            {isEditMode ? (
              <Input name="diagram" label="Diagram URL" defaultValue={data.wiki.network.diagram} readOnly={!isEditMode} />
            ) : data.wiki.network.diagram ? (
              <img style={{ width: "100%" }} src={data.wiki.network.diagram} alt="Network Diagram" />
            ) : (
              <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No network diagram provided</p>
            )}
          </section>
        </StyledForm>
        {isEditMode && (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
            <CTAButton isSecondary onClick={handleExit}>
              Cancel
            </CTAButton>
            <CTAButton isLoading={isLoading} onClick={handleSubmit}>
              Save
            </CTAButton>
          </div>
        )}
      </div>
      {!isEditMode && (
        <>
          <StyledFilters style={{ display: "flex" }}>
            <Searchbar style={{ margin: 0 }} setSearch={setSearch}>{`${list.length} appliances`}</Searchbar>
            <hr />
            <CTAButton onClick={handleShowAddAppliance}>Add Appliance</CTAButton>
          </StyledFilters>
          {list.length ? (
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>IP</th>
                  <th>OS</th>
                  <th>Version</th>
                  <th style={{ textAlign: "left" }}>Role</th>
                </tr>
              </thead>
              <tbody>
                {list.map((appliance, i) => (
                  <Appliance key={i} appliance={appliance} setModal={setApplianceModal} />
                ))}
              </tbody>
            </Table>
          ) : (
            <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No appliances</p>
          )}
        </>
      )}

      <ApplianceModal
        key={applianceModal.appliance?.name}
        {...applianceModal}
        hide={() => setApplianceModal({ showModal: false })}
        reload={reload}
        customerId={customerId}
        appliances={data.wiki.network.appliances}
        data={data}
      />
      <AddApplianceModal {...addApplianceModal} hide={() => setAddApplianceModal({ showModal: false })} />
    </div>
  );
};

const Appliance = ({ appliance, setModal }) => {
  const handleApplianceClick = () => {
    setModal({ showModal: true, appliance });
  };

  return (
    <tr style={{ cursor: "pointer" }} onClick={handleApplianceClick}>
      <td>{appliance.name}</td>
      <td>{appliance.ip}</td>
      <td>{appliance.os}</td>
      <td>{appliance.version}</td>
      <td>{appliance.role}</td>
    </tr>
  );
};

const ApplianceModal = ({ data, showModal, hide, appliance = {}, appliances = [], customerId, reload }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    const newAppliance = getFormValues(formRef.current);
    const otherAppliances = appliances.filter((c) => c.name !== (isEditMode ? appliance : newAppliance).name);
    const newAppliances = [...otherAppliances, newAppliance];
    setIsLoading(true);
    console.log(newAppliances);
    await fetchData(
      `${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/network`,
      JSON.stringify({ ...data.wiki.network, appliances: newAppliances }),
      "PUT"
    );
    setIsLoading(false);
    reload();
    hide();
  };

  const handleDelete = async () => {
    const newAppliances = appliances.filter((c) => c.name !== appliance.name);
    console.log(newAppliances);
    await fetchData(
      `${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/network`,
      JSON.stringify({ ...data.wiki.network, appliances: newAppliances }),
      "PUT"
    );
    setConfirmModal({ showModal: false, callback: null });
    reload();
    hide();
  };

  const handleDeletePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDelete() });
  };

  return (
    <>
      <Modal showModal={showModal} hide={hide} style={{ width: "1000px" }}>
        <div style={{ position: "relative", marginTop: "1rem" }}>
          {!isEditMode && setIsEditMode && (
            <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
              Edit
            </CTAButton>
          )}
          <StyledForm ref={formRef}>
            <section>
              <Input name="name" label="Name" defaultValue={appliance.name} readOnly={!isEditMode} />
              <Input name="ip" label="IP" defaultValue={appliance.ip} readOnly={!isEditMode} />
              <Input name="os" label="OS" defaultValue={appliance.os} readOnly={!isEditMode} />
              <Input name="version" label="Version" defaultValue={appliance.version} readOnly={!isEditMode} />
              <Input name="role" label="Role" defaultValue={appliance.role} readOnly={!isEditMode} />
              <Input label="Notes">
                <MarkdownEditor name="notes" style={{ marginTop: "0.3rem" }} defaultValue={appliance.notes} readOnly={!isEditMode} />
              </Input>
            </section>
            {!isEditMode && (
              <section>
                {/* eslint-disable-next-line */}
                <h3>Danger Zone 🚨🚨</h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid rgba(255, 0, 0, 0.6)",
                    borderRadius: "0.8rem",
                    padding: "1rem",
                  }}
                >
                  <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this appliance permanently</p>
                  <CTAButton onClick={handleDeletePrompt} style={{ color: "rgba(255, 0, 0, 0.7)", fontWeight: "bold" }} isSecondary>
                    Delete
                  </CTAButton>
                </div>
              </section>
            )}
          </StyledForm>
          {isEditMode && (
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem" }}>
              <CTAButton isSecondary onClick={() => setIsEditMode(false)}>
                Cancel
              </CTAButton>
              <CTAButton onClick={handleSubmit} isLoading={isLoading}>
                {setIsEditMode ? "Save" : "Create"}
              </CTAButton>
            </div>
          )}
        </div>
      </Modal>
      <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
    </>
  );
};

const AddApplianceModal = ({ showModal, callback, isLoading, hide }) => {
  const formRef = useRef(null);

  const handleAdd = () => {
    const values = getFormValues(formRef.current);
    callback(values);
  };

  return (
    <Modal
      style={{ width: "1000px" }}
      title="Add Appliance"
      showModal={showModal}
      isLoading={isLoading}
      hide={hide}
      actionText="Create"
      actionCommand={handleAdd}
    >
      <StyledForm ref={formRef}>
        <Input name="name" label="Name" />
        <Input name="ip" label="IP" />
        <Input name="os" label="OS" />
        <Input name="version" label="Version" />
        <Input name="role" label="Role" />
        <Input label="Notes">
          <MarkdownEditor name="notes" style={{ marginTop: "0.3rem" }} />
        </Input>
      </StyledForm>
    </Modal>
  );
};
