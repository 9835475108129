import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Releases } from "./Releases";

export const ReleasePublishing = () => {
  const routes = [{ name: "Releases", link: "/release-publishing/" }];

  return (
    <PageContainer name="Release Publishing" link="/release-publishing" routes={routes}>
      <ErrorHandledRoute path="/release-publishing/" component={Releases} />
    </PageContainer>
  );
};
