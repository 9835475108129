import React from "react";
import styled from "styled-components";
import { copyText } from "lib/helpers/copyText";
import { faPaste, faExternalLinkAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "./Tooltip";
import { toSentenceCase } from "lib/helpers/toSentenceCase";

const StyledKeyValue = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: ${props => props.inline ? "0.5rem !important" : "1rem"};
  &:last-child {
    margin-bottom: 0;
  }

  & > .contents {
    flex: 1;
    margin-left: ${props => props.space && "3rem"};
    margin-bottom: ${props => props.inline && "0.3rem"};
    margin-top: ${props => props.inline && "-0.7rem"};

    & > h3 {
      margin: 0;
      color: ${props => props.theme.text};
      font-weight: normal;
      font-size: 1.33rem;
      margin-left: ${props => props.inline && "-3rem"};
      margin-bottom: ${props => props.inline && "0.8rem"};
    }

    & .text,
    & > p,
    & > a,
    .container > p,
    .container > a {
      margin: 0.3rem 1rem 0 0;
      color: ${props => props.theme.text};
      font-size: 1.5rem;
      font-weight: bold;
      overflow: auto hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: ${props => props.lines ? props.lines : "unset"};
      -webkit-box-orient: vertical;
    }
  }

  & > .actions {
    position: absolute;

    .tooltip {
      path {
        fill: ${props => props.theme.text};
      }
    }
  }
`;

const LinkWrap = ({ action, href, children }) => {
  if (!action) return null;
  if (action !== "open" && action !== "download") return children;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export const KeyValue = ({ text, value, href, style, color, action, icon, hoverText, clickText, clickAction, xHoverPos, yHoverPos, space, wrap, size, lineLimit, inlineIcon, capitalize, className, disableTitle }) => {
  if (!value) return null;
  const _lineLimit = lineLimit === undefined ? 1 : lineLimit;
  const _space = action ? true : !!space;
  const _wrap = wrap === undefined ? true : !!wrap;
  const _icon = icon || (action === "open" ? faExternalLinkAlt : action === "copy" ? faPaste : action === "download" ? faDownload : null);
  const _hoverText = hoverText || (action === "open" ? "Open in new tab" : action === "download" ? "Download" : null);
  const _clickText = clickText || (action === "copy" ? "Copied!" : null);
  const _clickAction = clickAction || (action === "copy" ? () => copyText(text) : null);
  const _href = href || value;
  const _displayInline = inlineIcon && _icon;
  const _value = capitalize ? toSentenceCase(value) : value;
  const _className = className ? `key-value ${className}` : "key-value";
  const _title = disableTitle ? null : _value;

  return (
    <StyledKeyValue className={_className} style={style} space={_space} lines={_lineLimit} inline={_displayInline}>
      <div className="actions">
        <LinkWrap action={action} href={_href}>
          <Tooltip xHoverPos={xHoverPos} yHoverPos={yHoverPos} size={size} icon={_icon} hoverText={_hoverText} clickText={_clickText} clickAction={_clickAction} />
        </LinkWrap>
      </div>
      <div className="contents">
        <h3>{text}</h3>
        {_wrap ? (
          <p style={{ color }} title={_title} className="text">
            {_value}
          </p>
        ) : (
          _value
        )}
        {action === "copy" && <textarea id={text} defaultValue={_value} style={{ position: "absolute", left: "-9999px" }} />}
      </div>
    </StyledKeyValue>
  );
};
