import React from "react";
import styled from "styled-components";

import { KeyValue } from "lib/components/KeyValue";
import { displayDate } from "lib/helpers/displayFormats";
import { Redirects } from "./Redirects";
import { Certificate } from "./Certificate";

const StyledContainer = styled.div`
  flex: 1;
  margin: "1.5rem 2rem 0 0";
`;

export const UrlDetails = ({ data = {} }) => {
  const dataProps = { action: "copy", xPos: "right" };

  return (
    <StyledContainer>
      <KeyValue xHoverPos="right" yHoverPos="center" action="open" text="Url" value={data.properties?.url} />
      {data.properties?.redirects?.length ? (
        <KeyValue text={`Redirects (${data.properties?.redirects?.length})`} wrap={false} space value={<Redirects data={data} />} />
      ) : null}
      <KeyValue {...dataProps} text="First Seen" value={displayDate(data.firstSeen)} />
      <KeyValue {...dataProps} text="Last Seen" value={displayDate(data.lastSeen)} />
      {data.properties?.certInfo ? (
        <KeyValue text="Certificate" wrap={false} space value={<Certificate data={data.properties?.certInfo} />} />
      ) : null}
      <KeyValue {...dataProps} text="md5" value={data.md5} />
      <KeyValue {...dataProps} text="sha1" value={data.sha1} />
      <KeyValue {...dataProps} text="sha256" value={data.sha256} />
    </StyledContainer>
  );
};
