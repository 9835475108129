import React, { useState, useRef } from "react";
import styled from "styled-components";
import { fetchData } from "lib/helpers/fetchData";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { Select } from "lib/components/Select";
import { CTAButton } from "lib/components/CTAButton";
import { getFormValues } from "lib/helpers/getFormValues";
import { ConfirmModal } from "lib/components/Modal";
import { MarkdownEditor } from "lib/components/MarkdownEditor";
import { Review } from "apps/pentest/reviews";
import { csvInjectionCheck } from "lib/helpers/csvInjectionCheck";

const upsertKBIssue = async ({ kbIssueId, values }) => {
  const method = kbIssueId ? "PUT" : "POST";
  const updatePath = `/kbissues/${kbIssueId}`;
  const createPath = `/kbissues/create`;
  return fetchData(`${process.env.REACT_APP_PENTEST_API}${kbIssueId ? updatePath : createPath}`, JSON.stringify(values), method);
};

export const UpsertKBIssue = ({ data: kbIssue = {}, reload, hide, isEditMode, setIsEditMode, attachments = [] }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const formRef = useRef(null);

  const handleUpsertKBIssue = async () => {
    const values = getFormValues(formRef.current);
    let newErrors = {};

    Object.keys(values).forEach((inputKey) => {
      if (values[inputKey] === "") newErrors[inputKey] = "This field is required";
    })
    newErrors = csvInjectionCheck(values, newErrors);
    setErrors(newErrors);

    if (!Object.keys(newErrors).length) {
      setIsLoading(true);
      const kbIssueId = kbIssue?.id;
      const newKBIssue = await upsertKBIssue({ kbIssueId, values });
      setIsLoading(false);
      if (!isCreateMode) setIsEditMode(false);
      reload(newKBIssue?.result?.id);
    }
  };

  const handleDeleteKBIssue = async () => {
    setIsDeleting(true);
    await fetchData(`${process.env.REACT_APP_PENTEST_API}/kbissues/${kbIssue.id}`, null, "DELETE");
    setConfirmModal({ showModal: false, callback: null });
    setIsDeleting(false);
    hide();
  };

  const handleDeleteKBIssuePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDeleteKBIssue() });
  };

  const replaceAttachments = (content = "") => {
    const regexp = /\[.*\]\((.*) "(.*)"\)/g;
    const matches = [...content.matchAll(regexp)];
    if (!matches || !matches.length) return content;
    for (const match of matches) {
      const [, url, attachmentId] = match;
      const attachment = attachments.find((a) => a.id === attachmentId);
      if (!attachment) return content;
      content = content.replace(url, attachment.url.replaceAll(" ", "%20"));
    }
    return content;
  };

  const handleCancel = () => {
    reload(kbIssue.id);
    setErrors({});
  };

  const isCreateMode = !kbIssue.id;

  return (
    <StyledContainer>
      <StyledHeader>
        {!isCreateMode && (
          <Review
            entity={kbIssue}
            entityId={kbIssue.id}
            updateUrl={`${process.env.REACT_APP_PENTEST_API}/kbissues/${kbIssue.id}`}
            reload={reload}
            readOnly={!isEditMode}
          />
        )}
        <div style={{ display: "flex", gap: "1rem" }}>
          {isEditMode ? (
            <CTAButton isSecondary onClick={() => (isCreateMode ? handleCancel() : setIsEditMode(false))}>
              Cancel
            </CTAButton>
          ) : (
            <CTAButton isSecondary onClick={handleDeleteKBIssuePrompt} style={{ color: "rgba(200, 40, 40, 0.85)", fontWeight: "bold" }}>
              Delete
            </CTAButton>
          )}
          <CTAButton onClick={() => (isEditMode ? handleUpsertKBIssue() : setIsEditMode(true))} isLoading={isLoading}>
            {isEditMode ? (isCreateMode ? "Create" : "Save") : "Edit"}
          </CTAButton>
        </div>
      </StyledHeader>
      <StyledForm ref={formRef} onChange={(e) => setErrors((err) => ({ ...err, [e.target.name]: null }))} tightSpacing>
        <section style={{ paddingTop: 0 }}>
          <Input name="title" label="Title" defaultValue={kbIssue.title} readOnly={!isEditMode} error={errors.title} />
        </section>
        {!isCreateMode && (
          <>
            <section>
              <Input label="Severity" error={errors.severity}>
                <Select name="severity" defaultValue={kbIssue.severity} readOnly={!isEditMode}>
                  <option value="">Select severity</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
              </Input>
              <Input label="CVSS Score" name="cvssScore" defaultValue={kbIssue.cvssScore} readOnly={!isEditMode} error={errors.cvssScore} />
            </section>
            <section style={{ paddingBottom: 0, border: "none" }}>
              <Input label="Synopsis" error={errors.synopsis}>
                <MarkdownEditor
                  name="synopsis"
                  hideButtons={false}
                  subtle
                  defaultValue={replaceAttachments(kbIssue.synopsis)}
                  readOnly={!isEditMode}
                />
              </Input>
              <Input label="Description" error={errors.description}>
                <MarkdownEditor name="description" subtle defaultValue={replaceAttachments(kbIssue.description)} readOnly={!isEditMode} />
              </Input>
              <Input label="References" error={errors.references}>
                <MarkdownEditor name="references" subtle defaultValue={replaceAttachments(kbIssue.references)} readOnly={!isEditMode} />
              </Input>
              <Input label="Recommendations" error={errors.recommendations}>
                <MarkdownEditor
                  name="recommendations"
                  subtle
                  defaultValue={replaceAttachments(kbIssue.recommendations)}
                  readOnly={!isEditMode}
                />
              </Input>
            </section>
          </>
        )}
      </StyledForm>
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  gap: 5rem;
  margin-bottom: 2rem;
  justify-content: flex-end;
`;

const StyledContainer = styled.div`
  position: relative;
  padding: 0 1rem;
`;
