import React, { useState } from "react";
import styled from "styled-components";
import { Spinner, Error } from "lib/helpers/fetchData";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { truncate } from "lib/helpers/truncate";
import { Checkbox } from "./Checkbox";
import { resolveObject } from "lib/helpers/resolveObject";

const StyledContainer = styled.div`
  margin-right: 1rem;
  height: 100%;
  min-width: 200px;
  max-width: 250px;
  min-height: 200vh;
`;

const StyledSideList = styled.div`
  overflow: hidden;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.cardOutline};
  border-radius: 0.8rem;
  & > div {
    padding: 1rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.headerOutline};
    width: 100%;
    margin: 0 0 1rem;
    padding-bottom: 0.5rem;
    & > span {
      flex: 1;
      display: flex;
      svg {
        margin: 0 0.5rem 0 0;
        color: ${(props) => props.theme.lightText};
        opacity: 0.8;
      }
      h2 {
        font-size: 1.2rem;
        margin: 0;
        color: ${(props) => props.theme.text};
      }
    }
    .clear-button {
      cursor: pointer;
      padding: 0.3rem 1rem;
      margin: -1rem -0.3rem;
      background: ${(props) => props.theme.cardBackground};
      color: ${(props) => props.theme.lightText};
      font-weight: bold;
      border-radius: 0.3rem;
      &:hover {
        color: ${(props) => props.theme.accentText};
        background: ${(props) => props.theme.accentCardBackground};
      }
    }
  }
`;

const StyledValue = styled.div`
  display: flex;
  align-items: center;
  margin: 0.65rem 0;
  cursor: pointer;
  &:hover {
    h3 {
      text-decoration: underline;
    }
    p {
      color: ${(props) => props.theme.accentText};
    }
    .checkbox .icon {
      border-color: ${(props) => props.theme.secondaryButtonColor};
    }
  }
  .checkbox {
    margin: -1rem -0.5rem -1rem -1rem;
  }
  h3 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    color: ${(props) => (props.active ? props.theme.accentText : props.theme.lightText)};
    font-size: 1.2rem;
    font-weight: ${(props) => (props.active ? "bold" : "normal")};
  }
  p {
    margin: 0;
    margin-left: 0.75rem;
    color: ${(props) => (props.active ? props.theme.accentText : props.theme.mediumText)};
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  h3 {
    margin: 0;
    color: ${(props) => props.theme.lightText};
    font-size: 1.2rem;
    font-weight: normal;
  }
  svg {
    color: ${(props) => props.theme.mediumGray};
    margin: 0 0.5rem;
  }
  &:hover {
    h3 {
      text-decoration: underline;
    }
  }
`;

export const SidebarFilterContainer = ({ children }) => <StyledContainer>{children}</StyledContainer>;

export const SidebarFilter = ({
  data: _data,
  isLoading,
  error,
  name,
  icon,
  callback = () => {},
  clear = () => {},
  getName = (d) => d.name,
  getCount = (d) => d.count,
  isActive = () => false,
  max = 6,
  resultField = "result",
  isObject = false,
  sortFn: _sortFn
}) => {
  const [limit, setLimit] = useState(max);

  const data = resolveObject(resultField, _data) || [];

  const normalisedData = isObject ? Object.entries(data) : data;

  if (_data && !normalisedData.length) return null;

  const handleToggleClick = () => {
    if (limit === max) {
      setLimit(undefined);
    } else {
      setLimit(max);
    }
  };

  const handleCallback = (e, cat) => {
    e.stopPropagation();
    e.preventDefault();
    callback(cat);
  };

  const areAnyActive = normalisedData?.some((cat) => isActive(cat));

  const sortFn = _sortFn || ((a, b) => getCount(b) - getCount(a));
  
  const sortedData = normalisedData?.sort(sortFn)
  const slicedData = sortedData?.slice(0, limit);

  return (
    <StyledSideList>
      <ScrollContainer>
        <div className="header" aria-label={`${name} filter`}>
          <span>
            {icon && <FontAwesomeIcon icon={icon} />}
            <h2>{name}</h2>
          </span>
          {areAnyActive && (
            <div className="clear-button" onClick={clear}>
              Clear
            </div>
          )}
        </div>
        {isLoading && <Spinner />}
        {(error || data?.success === false) && <Error />}
        {slicedData.map((cat, i) => (
          <StyledValue key={i} onClick={(e) => handleCallback(e, cat)} active={isActive(cat)}>
            <Checkbox readOnly checked={isActive(cat)} />
            <h3 title={getName(cat)}>{truncate(getName(cat), 25)}</h3>
            <p>{parseInt(getCount(cat)).toLocaleString()}</p>
          </StyledValue>
        ))}
        {normalisedData?.length > max && (
          <StyledToggle onClick={handleToggleClick}>
            <FontAwesomeIcon icon={normalisedData?.length > limit ? faChevronDown : faChevronUp} />
            <h3>{normalisedData?.length > limit ? `${normalisedData.length - limit} more` : "Show less"}</h3>
          </StyledToggle>
        )}
      </ScrollContainer>
    </StyledSideList>
  );
};
