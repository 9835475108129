import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Dashboard } from "./dashboard";
import { Tags } from "./tags";
import { Tickets } from "./tickets";
import { ListRules, SingleRule } from "./rules";
import { ListArtifacts, IOCArtifact } from "./artifacts";
import { fetchData } from "lib/helpers/fetchData";
import { useFetch } from "lib/helpers/fetchData";

const loadTags = async () => {
  return await fetchData(`${process.env.REACT_APP_PHISH_API}/tags`);
};

export const TagsContext = React.createContext({});

export const Phish = () => {
  const tagsData = useFetch(loadTags);

  const routes = [
    { name: "Tickets", link: "/phish/tickets", isWide: true },
    { name: "Artifacts", link: "/phish/artifacts", isWide: true },
    { name: "Rules", link: "/phish/rules" },
    { name: "Tags", link: "/phish/tags" },
  ];

  return (
    <TagsContext.Provider value={tagsData}>
      <PageContainer name="Phish" link="/phish" routes={routes}>
        <ErrorHandledRoute path="/phish/tickets" component={Tickets} />
        <ErrorHandledRoute path="/phish/artifacts/:artifactId" component={IOCArtifact} />
        <ErrorHandledRoute path="/phish/artifacts" component={ListArtifacts} />
        <ErrorHandledRoute path="/phish/rules/:id" component={SingleRule} />
        <ErrorHandledRoute path="/phish/rules" component={ListRules} />
        <ErrorHandledRoute path="/phish/tags" component={Tags} />
        <ErrorHandledRoute path="/phish" component={Dashboard} />
      </PageContainer>
    </TagsContext.Provider>
  );
};
