import moment from "moment";
import { useRef, useState } from "react";
import { getFormValues } from "lib/helpers/getFormValues";
import { useFetch, fetchData, Spinner, Error } from "lib/helpers/fetchData";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CTAButton } from "lib/components/CTAButton";
import { Input } from "lib/components/Input";
import { Modal } from "lib/components/Modal";
import { Table } from "lib/components/Table";
import { Tooltip } from "lib/components/Tooltip";
import { StyledForm } from "lib/styles/general";
import { ConfirmModal } from "lib/components/Modal";

const listOverrides = ({ customerId }) => fetchData(`${process.env.REACT_APP_SOC_API}/overrides/${customerId}`);

const deleteOverride = ({ id, customerId }) =>
  fetchData(`${process.env.REACT_APP_SOC_API}/overrides/${customerId}`, JSON.stringify({ id }), "DELETE");

const createOverride = ({ customerId, offset, date }) =>
  fetchData(`${process.env.REACT_APP_SOC_API}/overrides/${customerId}`, JSON.stringify({ offset, date }), "POST");

export const Overrides = ({ customerId }) => {
  const { data, isLoading, error, reload } = useFetch(listOverrides, { customerId });
  const [addOverrideModal, setAddOverrideModal] = useState({ showModal: false, callback: null });
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isDeleting, setIsDeleting] = useState(false);

  const handleAddOverride = async ({ offset, date }) => {
    await createOverride({ customerId, offset, date });
    reload();
  };

  const handleDeleteOverride = async ({ id }) => {
    setIsDeleting(true);
    await deleteOverride({ id, customerId });
    reload();
    setIsDeleting(false);
    setConfirmModal({ showModal: false })
  };

  const handleShowAddOverride = () => {
    setAddOverrideModal({ showModal: true, callback: handleAddOverride });
  };

  const handleDeletePrompt = (id) => {
    setConfirmModal({ showModal: true, callback: () => handleDeleteOverride({ id }) });
  };

  if (error) return <Error message={error.message} />;
  if (isLoading) return <Spinner />;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Offset</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.result.map((override) => (
            <tr key={override.id}>
              <td>{moment(override.date).format("YYYY-MM-DD")}</td>
              <td>{override.offset}</td>
              <td style={{ overflow: "visible" }}>
                <Tooltip xHoverPos="left" yHoverPos="center" icon={faTrash} hoverText="Delete" clickAction={() => handleDeletePrompt(override.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "1rem" }}>
        <CTAButton onClick={handleShowAddOverride}>Add Override</CTAButton>
      </div>
      <AddOverrideModal {...addOverrideModal} hide={() => setAddOverrideModal({ showModal: false })} />
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
    </>
  );
};

const AddOverrideModal = ({ hide, showModal, callback }) => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    const { offset, date } = getFormValues(formRef.current);
    setIsLoading(true);
    await callback({ offset, date });
    setIsLoading(false);
    hide();
  };

  return (
    <Modal showModal={showModal} hide={hide} width="26rem">
      <StyledForm ref={formRef}>
        <Input name="offset" label="Offset" type="number" />
        <Input name="date" label="Date" type="date" />
      </StyledForm>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem", gap: "1rem" }}>
        <CTAButton isSecondary onClick={hide}>
          Cancel
        </CTAButton>
        <CTAButton onClick={handleSubmit} isLoading={isLoading}>
          Create
        </CTAButton>
      </div>
    </Modal>
  );
};
