import styled from "styled-components";
import { useState } from "react";

import { CheckboxButton } from "lib/components/Checkbox";
import { InfiniteScroll, useInfiniteScroll } from "lib/components/InfiniteScroll";
import { Searchbar } from "lib/components/Searchbar";
import { toSentenceCase } from "lib/helpers/toSentenceCase";
import { StyledFilters } from "lib/styles/general";
import { useDebouncedCallback } from "use-debounce/lib";
import { SingleClient } from "./SingleClient";

const StyledClientsGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(27rem, 1fr));
  grid-auto-rows: 12rem;
  grid-auto-flow: row;
`;

const StyledButtonFilters = styled.div`
  display: flex;
  gap: 1rem;
  & > label {
    padding: 0 0.75rem;
    border-color: ${(props) => props.theme.cardOutline};
  }
`;

const MetricsButton = ({ name, metrics, setMetrics }) => {
  return (
    <CheckboxButton
      onChange={() => setMetrics((m) => ({ ...m, [name]: !m[name] }))}
      defaultChecked={metrics[name]}
      label={toSentenceCase(name)}
    />
  );
};

export const ClientsList = ({ params, setFilter }) => {
  const [data, setData] = useState({ result: [] });
  const [metrics, setMetrics] = useState({ time: true, budget: true, scope: true });

  const url = `${process.env.REACT_APP_SERVICE_DELIVERY_API}/clients`;
  const { loadItems, hasMore, isLoading, reload } = useInfiniteScroll({ url, params, data, setData });

  
  const [searchCallback] = useDebouncedCallback((search) => setFilter({ search }), 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };
  
  const filters = (
    <StyledFilters>
      <Searchbar {...search} placeholder="Search clients" />
      <hr />
      <StyledButtonFilters>
        <MetricsButton name="time" metrics={metrics} setMetrics={setMetrics} />
        <MetricsButton name="budget" metrics={metrics} setMetrics={setMetrics} />
        <MetricsButton name="scope" metrics={metrics} setMetrics={setMetrics} />
      </StyledButtonFilters>
    </StyledFilters>
  );

  return (
    <InfiniteScroll filters={filters} loadItems={loadItems} hasMore={hasMore} isLoading={isLoading} data={data}>
      <StyledClientsGrid>
        {data.result.map((client) => (
          <SingleClient key={client.id} client={client} metrics={metrics} reload={reload} />
        ))}
      </StyledClientsGrid>
    </InfiniteScroll>
  );
};