import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { ExportData } from "./ExportData";
import { Dashboard } from "./Dashboard";
import { RenderQueryEngine } from "./query-engine/components/RenderQueryEngine";

export const Soc = () => {
  const routes = [{ name: "Export Data", link: "/soc/export-data" }];
  if (RenderQueryEngine()) routes.push({ name: "Clarity Query Engine", link: "/soc/query-engine" });

  return (
    <PageContainer name="SOC" link="/soc" routes={routes}>
      <ErrorHandledRoute path="/soc/export-data/" component={ExportData} />
      <ErrorHandledRoute path="/soc/query-engine/" component={RenderQueryEngine} />
      <ErrorHandledRoute path="/soc/" component={Dashboard} />
    </PageContainer>
  );
};
