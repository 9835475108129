import React, { useRef, useState } from "react";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { CTAButton } from "lib/components/CTAButton";
import { fetchData } from "lib/helpers/fetchData";
import { MarkdownEditor } from "lib/components/MarkdownEditor";

export const JumpBox = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    const values = getFormValues(formRef.current);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${data.id}/wiki/jumpbox`, JSON.stringify({ ...values }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };
  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section>
          <Input name="name" label="Name" defaultValue={data.wiki.jumpbox.name} readOnly={!isEditMode} />
          <Input name="ip" label="IP" defaultValue={data.wiki.jumpbox.ip} readOnly={!isEditMode} />
          <Input name="ssh_key" label="SSH Key" defaultValue={data.wiki.jumpbox.ssh_key} readOnly={!isEditMode} />
          <Input label="Notes">
            <MarkdownEditor name="notes" style={{ marginTop: "0.3rem" }} defaultValue={data.wiki.jumpbox.notes || "No notes yet"} readOnly={!isEditMode} subtle />
          </Input>
        </section>
      </StyledForm>
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
      )}
    </div>
  );
};
