import React, { useState, useRef } from "react";
import styled from "styled-components";

import { fetchData } from "lib/helpers/fetchData";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { Select } from "lib/components/Select";
import { CTAButton } from "lib/components/CTAButton";
import { CheckboxButton } from "lib/components/Checkbox";
import { getFormValues } from "lib/helpers/getFormValues";
import { ConfirmModal } from "lib/components/Modal";
import { MarkdownEditor } from "lib/components/MarkdownEditor";
import { Review } from "apps/pentest/reviews";
import { Tooltip } from "lib/components/Tooltip";

import { KBIssueSelection } from "./KBIssueSelection.js";
import { csvInjectionCheck } from "lib/helpers/csvInjectionCheck.js";

const upsertFinding = async ({ findingId, customerId, values }) => {
  const method = findingId ? "PUT" : "POST";
  const updatePath = `/findings/${customerId}/${findingId}`;
  const createPath = `/findings/create`;
  return fetchData(`${process.env.REACT_APP_PENTEST_API}${findingId ? updatePath : createPath}`, JSON.stringify(values), method);
};

const createKbIssue = async ({ values }) => {
  return fetchData(`${process.env.REACT_APP_PENTEST_API}/kbissues/create`, JSON.stringify(values), "POST");
};

export const UpsertFinding = ({ data: finding = {}, endCreation, engagementId, customerId, hide, isEditMode, setIsEditMode, attachments = [] }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showKBIssueList, setShowKBIssueList] = useState(false);
  const [kBIssue, setKBIssue] = useState(null);

  const [errors, setErrors] = useState({});
  const formRef = useRef(null);

  const handleUpsertFinding = async () => {
    const values = getFormValues(formRef.current);
    values.engagementId = engagementId;
    values.customerId = customerId;
    values.affectedSystem = values.affectedSystem.split(" ");
    
    let newErrors = {};
    
    Object.keys(values).forEach((inputKey) => {
      if (values[inputKey] === "") newErrors[inputKey] = "This field is required";
    })
    const { testerDates, ...valuesWithoutTesterDates } = values;
    newErrors = csvInjectionCheck(valuesWithoutTesterDates, newErrors);
    setErrors(newErrors);
    
    if (typeof values.affectedSystem === "string") values.affectedSystem = values.affectedSystem.split(" ");
    if (!Object.keys(newErrors).length) {
      setIsLoading(true);
      const findingId = finding?.id;
      const newFinding = await upsertFinding({ findingId, customerId, values });
      if (values.kbissue) await createKbIssue({ values });
      setIsLoading(false);
      setIsEditMode(false);
      endCreation(newFinding?.result?.id);
    }
  };

  const handleDeleteFinding = async () => {
    setIsDeleting(true);
    await fetchData(`${process.env.REACT_APP_PENTEST_API}/findings/${finding.customerId}/${finding.id}`, null, "DELETE");
    setConfirmModal({ showModal: false, callback: null });
    setIsDeleting(false);
    hide();
  };

  const handleDeleteFindingPrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDeleteFinding() });
  };

  const replaceAttachments = (content = "") => {
    const regexp = /\[.*\]\((.*) "(.*)"\)/g;
    const matches = [...content.matchAll(regexp)];
    if (!matches || !matches.length) return content;
    for (const match of matches) {
      const [, url, attachmentId] = match;
      const attachment = attachments.find((a) => a.id === attachmentId);
      if (!attachment) return content;
      content = content.replace(url, attachment.url.replaceAll(" ", "%20"));
    }
    return content;
  };

  const handleCancel = () => {
    endCreation(finding.id);
    setErrors({});
  };

  const isCreateMode = !finding.id;
  return (
    <StyledContainer>
      <StyledHeader>
        {!isCreateMode && (
          <Review
            entity={finding}
            entityId={finding.id}
            updateUrl={`${process.env.REACT_APP_PENTEST_API}/findings/${finding.customerId}/${finding.id}`}
            reload={endCreation}
            readOnly={!isEditMode}
          />
        )}
        <div style={{ display: "flex", gap: "1rem" }}>
          {isEditMode ? (
            <CTAButton isSecondary onClick={() => (isCreateMode ? handleCancel() : setIsEditMode(false))}>
              Cancel
            </CTAButton>
          ) : (
            <CTAButton isSecondary onClick={handleDeleteFindingPrompt} style={{ color: "rgba(200, 40, 40, 0.85)", fontWeight: "bold" }}>
              Delete
            </CTAButton>
          )}
          <CTAButton onClick={() => (isEditMode ? handleUpsertFinding() : setIsEditMode(true))} isLoading={isLoading}>
            {isEditMode ? (isCreateMode ? "Create" : "Save") : "Edit"}
          </CTAButton>
        </div>
      </StyledHeader>
      <StyledForm ref={formRef} onChange={(e) => setErrors((err) => ({ ...err, [e.target.name]: null }))} tightSpacing>
        {isCreateMode && (
          <div style={{ display: "inline-flex", margin: "-1.5rem 0 1.5rem" }}>
            <CTAButton isSecondary onClick={() => setShowKBIssueList(true)} style={{ marginRight: "1rem" }}>
              Import KB Issue
            </CTAButton>
            <Input name="KBIssue" error={errors.kbissue}>
              <CheckboxButton name="kbissue" subtle label="Create KB Issue" readOnly={!isEditMode} />
            </Input>
          </div>
        )}
        {showKBIssueList && <KBIssueSelection selectKBIssue={setKBIssue} hide={() => setShowKBIssueList(false)} />}
        <section style={{ paddingTop: 0 }}>
          <Input
            name="title"
            label="Title"
            defaultValue={kBIssue ? kBIssue.title : finding.title}
            readOnly={!isEditMode}
            error={errors.title}
          />
        </section>
        <section>
          <Input label="Severity" error={errors.severity}>
            <Select name="severity" defaultValue={kBIssue ? kBIssue.severity : finding.severity} readOnly={!isEditMode}>
              <option value="">Select severity</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </Input>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input label="CVSS Score" name="cvssScore" defaultValue={kBIssue ? kBIssue.cvssScore : finding.cvssScore} readOnly={!isEditMode} error={errors.cvssScore}/>
            <Tooltip
              spacing="1.5"
              style={{ padding: "1rem", marginRight: "-1rem" }}
              hoverText={
                <p>
                  {`A widget is available to construct CVSS v3.1\ncode here: `}
                  <a href="https://www.first.org/cvss/calculator/3.1" target="_blank" rel="noreferrer noopener">
                    https://www.first.org/cvss/calculator/3.1
                  </a>
                </p>
              }
            />
          </div>
        </section>
        <section style={{ paddingBottom: 0, border: "none" }}>
          <Input label="Synopsis" error={errors.synopsis}>
            <MarkdownEditor
              name="synopsis"
              hideButtons={false}
              subtle
              defaultValue={kBIssue ? kBIssue.synopsis : replaceAttachments(finding.synopsis)}
              readOnly={!isEditMode}
            />
          </Input>
          <Input label="Affected System" error={errors.affectedSystem}>
            <MarkdownEditor
              name="affectedSystem"
              hideButtons={false}
              subtle
              defaultValue={finding.affectedSystem?.join(" ")}
              readOnly={!isEditMode}
            />
          </Input>
          <Input label="Recommendations" error={errors.recommendations}>
            <MarkdownEditor
              name="recommendations"
              subtle
              defaultValue={kBIssue ? kBIssue.recommendations : replaceAttachments(finding.recommendations)}
              readOnly={!isEditMode}
            />
          </Input>
          <Input label="Description" error={errors.description}>
            <MarkdownEditor
              name="description"
              subtle
              defaultValue={kBIssue ? kBIssue.description : replaceAttachments(finding.description)}
              readOnly={!isEditMode}
            />
          </Input>
          <Input label="References" error={errors.references}>
            <MarkdownEditor
              name="references"
              subtle
              defaultValue={kBIssue ? kBIssue.references : replaceAttachments(finding.references)}
              readOnly={!isEditMode}
            />
          </Input>
        </section>
      </StyledForm>
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  gap: 5rem;
  margin-bottom: 2rem;
  justify-content: flex-end;
`;

const StyledContainer = styled.div`
  position: relative;
  padding: 0 1rem;
`;
