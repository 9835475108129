import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const StyledSearchbar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.headerOutline};
  border-radius: ${props => props.small ? "0.3rem" : "0.8rem"};
  margin-bottom: 1rem;
  &:focus-within {
    border-color: ${props => props.theme.headerOutline};
  }
  input {
    padding: ${props => props.small ? "0.55rem 0.7rem" : "0.8rem 1.5rem"};
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
    color: ${props => props.theme.text};
    font: inherit;
    font-size: ${props => props.small ? "1.1rem" : "1.33rem"};
    flex: 1;
  }
  .children {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    font-size: 1.2rem;
    color: ${props => props.theme.lightText};
  }
  .clear {
    padding: 0.5rem 1rem 0.5rem 0;
    background: ${props => props.theme.cardBackground};
    color: ${props => props.theme.lightText};
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: ${props => props.theme.text};
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: auto -0.5rem auto 1rem;
  color: ${(props) => props.theme.text};
  opacity: 0.5;
  font-size: 1.5rem;
`;


export const Searchbar = ({ children, style, small, className, setSearch, search, icon, placeholder = "Search", showClear, autoFocus = false }) => (
  <StyledSearchbar style={style} small={small} id="searchbar" className={`searchbar${className ? ` ${className}` : ""}`}>
    {icon && <StyledIcon icon={icon} />}
    <input autoFocus={autoFocus} defaultValue={search} onChange={e => setSearch(e.target.value)} placeholder={placeholder} />
    <div className="children">{children}</div>
    {search && showClear && <FontAwesomeIcon icon={faTimes} className="clear" onClick={() => setSearch("")} />}
  </StyledSearchbar>
);