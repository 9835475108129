import styled from "styled-components";

export const FullWidthContainer = ({ maxWidth = "unset", className, children, ...props }) => {
  return (
    <StyledContainer width={maxWidth} {...props}>
      <div className={`children-container ${className}`}>{children}</div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.cardBackground};
  color: ${(props) => props.theme.text};
  font-size: 1.33rem;
  width: calc(100% + 4rem);
  margin: -2rem -2rem -10rem;
  padding: 2rem;
  box-sizing: border-box;
  min-height: calc(100vh - 4.1rem);
  display: flex;
  flex-direction: column;
  & > .children-container {
    margin: auto;
    width: 100%;
    max-width: ${(props) => props.width};
    display: flex;
    flex-direction: column;
    flex: 1;
    .navbar {
      border-color: ${(props) => props.theme.headerOutline};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
      margin: -1rem -1rem 1rem;
    }
  }
`;
