import styled from "styled-components";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { faMoneyBillWave, faStopwatch, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { VALUE_COLORS } from "../components/constants";
import { Profile } from "../components/Profile";
import { Tags } from "../components/Tags";
import { Flags } from "../components/Flags";
import { toPrecision } from "lib/helpers/toPrecision";
import { calculteWorstPriority } from "../components/helpers";

const StyledProjectBox = styled.div`
  border-radius: 0.5rem;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.headerOutline};
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: inset 0 1rem 3rem 0rem rgba(0, 0, 0, 0.25), 0 0.5rem 1.5rem rgba(0, 0, 0, 0.45);
  overflow: hidden;
  cursor: default;
  transition: all 0.3s;
  cursor: pointer;

  & > .main {
    flex: 1;
    color: ${(props) => props.theme.text};
    font-size: 1.75rem;
    font-weight: bolder;
    text-shadow: 0 0 10px ${(props) => props.theme.pageBackground};
    border-radius: 0.25rem;
    padding: 0.75rem 0.5rem 0;
    position: relative;

    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & > .name {
        overflow: hidden;
        padding: 0.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & > .profile {
        padding: 0.5rem;
        margin: -0.5rem 0;
      }
    }
    & > .client {
      font-size: 1.35rem;
      padding: 0.5rem;
      margin: -0.5rem 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${(props) => props.theme.mediumText};
    }
  }

  & > .details {
    display: flex;
    gap: 1rem;
    max-width: 100%;
    margin: 0.35rem 0.4rem -0.35rem;
    justify-content: space-between;
    align-items: center;
    min-height: 2.65rem;
  }

  & > .statuses {
    display: flex;
    flex-flow: row nowrap;
    height: 6rem;
    margin: 0.75rem;
    background: ${(props) => props.theme.pageBackground};
    border: 1px solid ${(props) => props.theme.headerOutline};
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 3rem 5rem 4rem rgba(0, 0, 0, 0.5);
    opacity: 0.9;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.pageBackground};
    z-index: 99;
    display: ${(props) => (props.archived ? "block" : "none")};
    opacity: 0.4;
  }

  &::after {
    content: "ARCHIVED";
    color: ${(props) => props.theme.mediumText};
    font-size: 3rem;
    font-weight: bold;
    transform: rotate(-20deg);
    text-shadow: 0 0 1rem ${(props) => props.theme.pageBackground};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: ${(props) => (props.archived ? "flex" : "none")};
    justify-content: center;
    align-items: center;
  }
`;

const StyledStatus = styled.div`
  background-color: ${(props) => props.color};
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  color: ${(props) => props.theme.text};
  font-weight: bolder;
  text-shadow: 0 0 10px ${(props) => props.theme.pageBackground};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  transition: all 0.3s;
  position: relative;

  :not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.headerOutline};
  }

  svg {
    filter: drop-shadow(0 0 0.5rem ${(props) => props.theme.pageBackground});
    font-size: 1.75rem;
    flex: 1;
  }

  & > .info {
    flex: 3;
    font-size: 1.1rem;
    & > p {
      margin: 0.2rem 0;
    }
  }
`;

export const ProjectBox = ({ project, metrics, getClient, getProjectManager }) => {
  let { budgetPriority, timePriority, scopePriority, startDate, endDate } = project;

  const history = useHistory();
  
  const worstPriority = calculteWorstPriority({ project, metrics });
  
  const client = getClient(project?.client?.id) || {};
  const projectManager = getProjectManager(project?.projectManager?.id) || {};

  const formatDate = (date) => {
    if (!date) return "NA";
    if (!moment().isSame(date, "year")) return moment(date).format("D MMM 'YY");
    return moment(date).format("D MMM");
  };

  startDate = moment(startDate).startOf("day");
  endDate = moment(endDate).endOf("day");

  // Calc in hours here for better granularity
  const projectLengthInHours = endDate.diff(startDate, "hours") || 1;
  const hoursThroughProject = moment().diff(startDate, "hours");

  // Convert back to days with rounding
  const daysLeft = Math.round((projectLengthInHours - hoursThroughProject) / 24);
  const timeTillStart = -hoursThroughProject;
  const daysTillStart = Math.round(timeTillStart / 24);

  const days = timeTillStart > 0 ? daysTillStart : daysLeft;
  const daysWordage = Math.abs(days) === 1 ? "day" : "days";
  const label = daysTillStart > 0 ? "'til\nstart" : daysLeft < 0 ? "over" : "left";

  const loggedUsage = typeof project.budget?.loggedUsage === "number" && `${toPrecision(project.budget?.loggedUsage * 100, 4)}%`;
  const scheduledUsage = typeof project.budget?.scheduledUsage === "number" && `${toPrecision(project.budget?.scheduledUsage * 100, 4)}%`;

  const handleProjectClick = () => history.push(`/service-delivery-portfolio/projects/${project.id}`);
  
  return (
    <StyledProjectBox style={{ background: VALUE_COLORS[worstPriority] }} onClick={handleProjectClick} archived={project.isArchived}>
      <div className="main">
        <div className="header">
          <div className="name" title={project.title}>
            {project.title}
          </div>
          <Profile className="profile" alt="Avatar" title={projectManager.name} name={projectManager.name} />
        </div>
        <div className="client" title={client.name}>
          {client.name}
        </div>
      </div>
      <div className="details">
        <Tags tags={project.tags} />
        <Flags project={project} />
      </div>
      <div className="statuses">
        <StyledStatus color={VALUE_COLORS[timePriority]} disabled={!metrics.time} title="Time RAG">
          <FontAwesomeIcon icon={faStopwatch} />
          <div className="info">
            <p>
              {formatDate(project.startDate)} - {formatDate(project.endDate)}
            </p>
            {project.endDate && <p>{`${Math.abs(days)} ${daysWordage} ${label}`}</p>}
          </div>
        </StyledStatus>
        <StyledStatus color={VALUE_COLORS[budgetPriority]} disabled={!metrics.budget} title="Budget RAG">
          <FontAwesomeIcon icon={faMoneyBillWave} />
          {loggedUsage || scheduledUsage ? (
            <div className="info">
              <p>{loggedUsage || "?? %"} / logged</p>
              <p>{scheduledUsage || "?? %"} / scheduled</p>
            </div>
          ) : null}
        </StyledStatus>
        <StyledStatus style={{ flex: 0.5 }} color={VALUE_COLORS[scopePriority]} disabled={!metrics.scope} title="Scope RAG">
          <FontAwesomeIcon icon={faTasks} />
        </StyledStatus>
      </div>
    </StyledProjectBox>
  );
};
