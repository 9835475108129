import React, { useRef } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountUp, faSortAmountDown } from "@fortawesome/free-solid-svg-icons";
import { useId } from "lib/helpers/useId";

const StyledHeader = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.6rem 0.4rem;
  margin-left: -0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  transition: background 0.3s;
  &:hover {
    background: ${props => props.theme.accentCardBackground};
  }
  & * {
    cursor: pointer;
  }
  input {
    display: none;
    &:not(:checked) + * {
      display: none;
    }
  }
`;

export const SortableHeader = ({ field, setSort, sort, style, headerStyle, children }) => {
  const id = useId();
  const reverse = useRef(sort.orderBy === field ? sort.reverse : false);

  const handleSort = () => {
    reverse.current = !reverse.current;
    setSort({ orderBy: field, reverse: reverse.current });
  };

  if (style?.justifyContent === "flex-end" && sort.orderBy === field) {
    style = { ...style, justifyContent: "space-between", flexDirection: "row-reverse" };
  }

  return (
    <th style={headerStyle}>
      <StyledHeader style={style} htmlFor={id}>
        {children}
        <input type="checkbox" checked={sort.orderBy === field} readOnly />
        <FontAwesomeIcon icon={reverse.current ? faSortAmountUp : faSortAmountDown} />
        <input id={id} type="checkbox" checked={reverse.current} onClick={handleSort} readOnly />
      </StyledHeader>
    </th>
  );
};
