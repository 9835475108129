import React, { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useAsync } from "react-async";
import { fetchData } from "lib/helpers/fetchData";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";

import { CTAButton } from "lib/components/CTAButton";
import { TextArea } from "lib/components/TextArea";
import { Link } from "react-router-dom";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { getArtifactContent } from "lib/helpers/getArtifactContent";
import { trimWord } from "lib/helpers/trimWord";
import { useUser } from "auth";
import { getAnalystName } from "lib/helpers/getAnalystName";

const createComment = async ([id, text]) => {
  return await fetchData(`${process.env.REACT_APP_PHISH_API}/comments/${id}`, JSON.stringify({ text }), "POST");
};

const updateComment = async ([id, text]) => {
  return await fetchData(`${process.env.REACT_APP_PHISH_API}/comments/${id}`, JSON.stringify({ text }), "PUT");
};

const deleteComment = async ([id]) => {
  return await fetchData(`${process.env.REACT_APP_PHISH_API}/comments/${id}`, null, "DELETE");
};

const StyledComment = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
  opacity: ${(props) => (props.recben ? 0.4 : 1)};
  transition: all 0.2s;
  &:hover {
    opacity: 1;
  }

  .target-link {
    position: absolute;
    white-space: nowrap;
    right: 0;
    margin-top: -2.25rem;
    opacity: 1;
    transition: all 0.3s;
  }

  &:hover {
    .target-link {
      opacity: 1;
    }
  }

  & > div:first-child {
    flex: 1;
    & > p {
      word-break: break-word;
      margin-left: 0.5rem;
      padding-left: 0.75rem;
      border-left: 2px solid ${(props) => props.theme.headerOutline};
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -10rem;
  }

  textarea {
    width: 100% !important;
    height: ${(props) => (props.create ? "75px" : "50px")};
    min-height: ${(props) => (props.create ? "75px" : "50px")};
    padding: 0.5rem;
    background: transparent;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.headerOutline};
    color: inherit;
    font: inherit;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    outline: none;
    resize: none;
    margin-right: 0.5rem;
    transition: border 0.3s;
    &:hover,
    &:focus-within {
      border: 1px solid ${(props) => props.theme.secondaryButtonColor};
      resize: auto;
    }
  }

  :first-child {
    flex-flow: column;
    align-items: flex-end;
    textarea {
      margin: 0;
    }
    button {
      position: unset;
      margin: 1rem 0 0 0;
    }
  }

  svg {
    cursor: pointer;
    margin: 0 0 0 6px;
    font-size: 1rem;
    border: 1px solid ${(props) => props.theme.headerOutline};
    border-radius: 0.25rem;
    padding: 0.45rem;
    background: ${(props) => props.theme.cardBackground};
    transition: background 0.3s;
    &:hover {
      background: ${(props) => props.theme.accentCardBackground};
    }
  }

  p {
    margin: 4px 0;
    font-size: 1.5rem;
  }
`;

const StyledCommentHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;

  h3 {
    font-size: 1.33rem;
    margin: 0 0.5rem 0 0.5rem;
    :first-child {
      margin-left: 0;
    }
  }
  p {
    margin: 0;
  }
  section {
    flex: 1;
  }
  div {
    display: flex;
  }
`;

const StyledContainer = styled.div`
  max-width: 90%;
  width: 800px;
  min-height: 150px;
  margin: auto;
  hr {
    border: none;
    background: ${(props) => props.theme.headerOutline};
    height: 1px;
    margin: 1.5rem 0.5rem;
  }
  p {
    font-size: 1.5rem;
    color: ${(props) => props.theme.text};
    &.no-results {
      text-align: center;
    }
  }
`;

const StyledCommentsSectionHeader = styled.h3`
  font-size: 1.75rem;
  margin: 0.3rem 0 1.5rem;
  color: ${(props) => props.theme.lightText};
`;

export const Comments = ({ data, refresh }) => {
  let comments = data.comments?.sort((a, b) => b.created - a.created);

  const ownObjectComments = comments.filter((comment) => !comment.isFromArtifact);
  const foreignComments = comments.filter((comment) => comment.isFromArtifact);

  const recBenForeignComments = foreignComments.filter((comment) => comment.target.isRecBen);
  const nonRecBenForeignComments = foreignComments.filter((comment) => !comment.target.isRecBen);

  const renderComments = () => {
    // Check if there are no comments
    if (!comments?.length) return <p className="no-results">No comments yet</p>;
    // Check if all the comments are from this object (i.e. no inherited comments, like on a ticket)
    if (ownObjectComments.length === comments.length) {
      return comments.map((comment) => <Comment key={comment.id} id={data.id} {...comment} refresh={refresh} />);
    }

    return (
      <>
        {ownObjectComments.length ? (
          <>
            <StyledCommentsSectionHeader>Direct Comments</StyledCommentsSectionHeader>
            {ownObjectComments.map((comment) => (
              <Comment key={comment.id} id={data.id} {...comment} refresh={refresh} />
            ))}
            <hr />
            <StyledCommentsSectionHeader>Other Comments</StyledCommentsSectionHeader>
          </>
        ) : null}
        {nonRecBenForeignComments.map((comment) => (
          <Comment recBen={false} key={comment.id} id={data.id} {...comment} refresh={refresh} />
        ))}
        {recBenForeignComments.map((comment) => (
          <Comment recBen={true} key={comment.id} id={data.id} {...comment} refresh={refresh} />
        ))}
      </>
    );
  };

  return (
    <StyledContainer>
      <CreateComment id={data.id} refresh={refresh} />
      <hr />
      {renderComments()}
    </StyledContainer>
  );
};

const CreateComment = ({ id, refresh }) => {
  const textRef = useRef(null);
  const defaultValue = "Enter comment";

  const { run: runCreate } = useAsync({ deferFn: createComment, onResolve: () => refresh() });

  const handleChange = (e) => {
    e.target.style.height = `${e.target.scrollHeight || 5}px`;
  };

  const handleFocus = () => {
    if (textRef.current.value === defaultValue) {
      textRef.current.value = "";
    } else if (textRef.current.value === "") {
      textRef.current.value = defaultValue;
    }
  };

  const handleSubmit = () => {
    const text = textRef.current.value;
    runCreate(id, text);
    textRef.current.value = defaultValue;
  };

  return (
    <StyledComment create={true}>
      <textarea ref={textRef} defaultValue={defaultValue} onChange={handleChange} onFocus={handleFocus} onBlur={handleFocus} />
      <CTAButton onClick={handleSubmit}>Post</CTAButton>
    </StyledComment>
  );
};

const Comment = ({ id, user, created, didEdit, text, isFromArtifact, target, refresh, recBen }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [localText, setLocalText] = useState(text);

  const textareaRef = useRef(null);

  const { run: runUpdate } = useAsync({ deferFn: updateComment, onResolve: () => refresh() });
  const { run: runDelete } = useAsync({ deferFn: deleteComment, onResolve: () => refresh() });

  const { user: currentUser } = useUser();
  const isOwnComment = currentUser?.email?.toLowerCase() === user.email.toLowerCase();

  console.log({ user, currentUser, isOwnComment });

  const handleChange = (e) => {
    setLocalText(e.target.innerText || e.target.value);
    e.target.style.height = `${e.target.scrollHeight || 10}px`;
  };

  const handleSubmit = () => {
    setIsEditMode(false);
    runUpdate(id, localText);
  };

  useLayoutEffect(() => {
    if (isOwnComment) handleChange({ target: textareaRef.current });
  }, [isOwnComment]);

  return (
    <StyledComment own={isOwnComment} recben={recBen}>
      <div>
        <StyledCommentHeader>
          <h3>{getAnalystName(user)}</h3>
          <p>-</p>
          <h3>{`${moment(created).fromNow()} (${moment(created).format("DD/MM/YYYY - HH:mm")})`}</h3>
          {didEdit && (
            <>
              {" - "}
              <h3>Edited</h3>
            </>
          )}
          <section />
          <div>
            {isFromArtifact ? (
              <Link className="target-link" to={`/phish/tickets/${target.customer.id}/${target.ticketId}/artifacts/${target.id}`}>
                <div style={{ display: "flex", flexFlow: "column nowrap", alignItems: "flex-end", fontSize: "1.15rem" }}>
                  <span>{trimWord(getArtifactContent(target), 60)}</span>
                  <span>
                    <span>{`From artifact ${target.fid} - `}</span>
                    <b style={{ color: getColorFromSeverity(target?.severity) }}>{`${target.severity.text} (${target.confidence.text})`}</b>
                  </span>
                </div>
              </Link>
            ) : (
              <>
                {!isEditMode && isOwnComment && <FontAwesomeIcon icon={faEdit} onClick={() => setIsEditMode(true)} />}
                {localText !== text && <FontAwesomeIcon icon={faSave} onClick={handleSubmit} />}
                {isOwnComment && <FontAwesomeIcon icon={faTrash} onClick={() => runDelete(id)} />}
              </>
            )}
          </div>
        </StyledCommentHeader>
        <TextArea ref={textareaRef} readOnly={!isEditMode} defaultValue={text} onChange={handleChange} />
      </div>
    </StyledComment>
  );
};
