export const toPrecision = (value, precision = 2) => {
  if (typeof value !== "number") {
    value = Number(value);
    if (isNaN(value)) return null;
  }
  return parseFloat(Number(value).toPrecision(precision));
};

export const toFixed = (value, precision = 2) => {
  if (typeof value !== "number") {
    value = Number(value);
    if (isNaN(value)) return null;
  }
  return Number(value).toFixed(precision);
};

export const round = (value, precision = 2) => {
  if (typeof value !== "number") {
    value = Number(value);
    if (isNaN(value)) return null;
  }
  const magnitude = 10 ** precision;
  return Math.round((value + Number.EPSILON) * magnitude) / magnitude;
};

export const toCurrency = (value) => {
  if (typeof value !== "number") {
    value = Number(value);
    if (isNaN(value)) return null;
  }
  return Number(Number(value).toFixed(2)).toLocaleString("en-uk", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
