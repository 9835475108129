import styled from "styled-components";

import { Flags } from "../components/Flags";
import { Profile } from "../components/Profile";
import { Tags } from "../components/Tags";

const StyledTitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.text};
  font-size: 1.75rem;
  font-weight: bolder;
  text-shadow: 0 0 10px ${(props) => props.theme.pageBackground};
  border-radius: 0.25rem;
  position: relative;

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    & > .name {
      overflow: hidden;
      padding: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > .profile-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.5rem;
      & > p {
        font-size: 1.33rem;
        margin: 0 0.7rem;
      }
      & > .profile {
        padding: 0.5rem;
        margin: -0.5rem 0;
      }
    }
  }
  & > .client {
    font-size: 1.35rem;
    padding: 0.5rem;
    margin: -0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => props.theme.mediumText};
  }
`;

const StyledInfo = styled.div`
  display: flex;
  gap: 1rem;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0.75rem 0;
  z-index: 99;

  & > .tags {
    padding: 0.35rem;
    & > div {
      padding: 0.6rem 0.8rem;
      &::after {
        opacity: 0.85;
      }
    }
  }

  & > .flags {
    margin: 0 1rem;
  }
`;

export const Header = ({ project, projectManager, client }) => {
  return (
    <>
      <StyledTitle>
        <div className="header">
          <div className="name" title={project.title}>
            {project.title}
          </div>
          <div className="profile-container">
            <p>{projectManager.name}</p>
            <Profile className="profile" alt="Avatar" title={projectManager.name} name={projectManager.name} />
          </div>
        </div>
        <div className="client" title={client.name}>
          {client.name}
        </div>
      </StyledTitle>
      <StyledInfo>
        <Tags tags={project.tags} />
        <Flags project={project} />
      </StyledInfo>
    </>
  );
};
