import moment from "moment";
import { useDebouncedCallback } from "use-debounce";

import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { LinkRow } from "lib/components/LinkRow";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { Searchbar } from "lib/components/Searchbar";
import { SortableHeader } from "lib/components/SortableHeader";
import { Table } from "lib/components/Table";
import { useDataList } from "lib/helpers/useDataList";
import { StyledFilters } from "lib/styles/general";

import { ReviewBox, REVIEW_STATUSES } from "../reviews";

export const FindingsList = () => {
  const url = `${process.env.REACT_APP_PENTEST_API}/findings`;

  const { params, setParams, ...findings } = useDataList({
    url,
    orderBy: "createdAt",
    reverse: false,
    status: REVIEW_STATUSES.PENDING_REVIEW,
  });

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: (sort) => setParams((p) => ({ ...p, ...sort })),
  };

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  return (
    <>
      <StyledFilters>
        <Searchbar {...search} placeholder="Search findings" />
      </StyledFilters>
      <ScrollContainer className="scroll">
        <InfiniteScroll {...findings} useWindow={false} noDataText="No pending findings found">
          <Table tableStyle={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "8rem" }}></th>
                <SortableHeader field="title" {...sort}>
                  Finding Title
                </SortableHeader>
                <SortableHeader field="severity" {...sort} headerStyle={{ width: "5rem" }}>
                  Severity
                </SortableHeader>
                <SortableHeader field="cvssScore" {...sort} headerStyle={{ width: "5rem" }}>
                  CVSS
                </SortableHeader>
                <SortableHeader field="createdAt" {...sort} headerStyle={{ width: "10rem" }} style={{ justifyContent: "flex-end" }}>
                  Created at
                </SortableHeader>
              </tr>
            </thead>
            <tbody>
              {findings.data.result.map((finding, i) => (
                <LinkRow key={i} path={`/pentest/engagements/engagement/${finding.customerId}/${finding.engagementId}?findingId=${finding.id}`}>
                  <td style={{ padding: 0, paddingLeft: "0.2rem" }}>
                    <ReviewBox status={finding.status} />
                  </td>
                  <td>{finding.title}</td>
                  <td>{finding.severity}</td>
                  <td>{finding.cvssScore}</td>
                  <td>{moment(finding.createdAt).fromNow()}</td>
                </LinkRow>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </ScrollContainer>
    </>
  );
};
