import { useState, useRef } from "react";
import moment from "moment";
import { fetchData } from "lib/helpers/fetchData";

import { generateUUID } from "lib/helpers/generateUUID";
import { getFormValues } from "lib/helpers/getFormValues";
import { StyledFilters } from "lib/styles/general";
import { StyledForm } from "lib/styles/general";

import { Modal, ConfirmModal } from "lib/components/Modal";
import { Searchbar } from "lib/components/Searchbar";
import { CTAButton } from "lib/components/CTAButton";
import { Table } from "lib/components/Table";
import { Input } from "lib/components/Input";
import { RadioButtonGroup, RadioButton } from "lib/components/RadioButton";
import { MarkdownEditor } from "lib/components/MarkdownEditor";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { CheckboxBorderedButton } from "lib/components/Checkbox";
import { toSentenceCase } from "lib/helpers/toSentenceCase";
import { useFetch } from "lib/helpers/fetchData";
import styled from "styled-components";

export const Contacts = ({ customer, reload }) => {
  const [search, setSearch] = useState("");
  const [contactModal, setContactModal] = useState({ showModal: false, contactId: null });
  const formRef = useRef(null);

  const handleShowAddContact = () => setContactModal({ showModal: true, contactId: null, isCreate: true });

  const contacts = customer?.wiki?.contacts || [];
  const list = contacts?.filter((c) => [c.name.toLowerCase(), c.email.toLowerCase()].join("").includes(search.toLowerCase()));

  const contact = contacts.find((c) => c.id === contactModal.contactId);

  const priorities = [
    {"name": "first", "value": 1},
    {"name": "second", "value": 2},
    {"name": "third", "value": 3},
    {"name": "temp", "value": 4},
    {"name": "other", "value": 5},
  ]

  const contactList = list.map(obj => ({...obj, priority: priorities.find(item => item.name === obj.priority.toLowerCase() ? item.value : null)}));

  const sortByPriorityAndName =((a, b) => {
    if (a.priority.value === b.priority.value) {
      return a.name.localeCompare(b.name);
    }
    return a.priority.value - b.priority.value;
  });

  return (
    <div>
      <StyledForm ref={formRef}>
        <section>
      <Passphrase customerId={customer.id}/>
      </section>
      <section>
      <StyledFilters style={{ display: "flex" }}>
        <Searchbar style={{ margin: 0 }} setSearch={setSearch}>{`${list.length} contact${list.length !== 1 ? "s" : ""}`}</Searchbar>
        <hr />
        <CTAButton onClick={handleShowAddContact}>Add Contact</CTAButton>
      </StyledFilters>
      {list.length ? (
        <ScrollContainer scrollY="hidden" scrollX="auto">
          <Table isFixed singleLine>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Job Role</th>
                <th style={{ textAlign: "right", width: "5rem" }}>Priority</th>
              </tr>
            </thead>
            <tbody>
              {contactList.sort(sortByPriorityAndName).map((c) => (
                <tr key={c.id} style={{ cursor: "pointer" }} onClick={() => setContactModal({ showModal: true, contactId: c.id })}>
                  <td>{c.name}</td>
                  <td>{c.phone}</td>
                  <td>{c.role}</td>
                  <td style={{ textAlign: "right", width: "5rem" }}>
                    <b>{toSentenceCase(c.priority.name)}</b>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollContainer>
      ) : (
        <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No contacts</p>
      )}
      <ContactModal
        key={JSON.stringify({ contactModal, contact })}
        {...contactModal}
        setModal={setContactModal}
        contact={contact}
        reload={reload}
        contacts={contacts}
        customerId={customer.id}
      />
      </section>
      </StyledForm>
    </div>
  );
};

const loadChangelog = ({ contactId }) => {
  if (!contactId) return null;
  return fetchData(
    `${process.env.REACT_APP_ADMIN_API}/activities?targetId=${contactId}&types=contact-update-success,passphrase-update-success`
  );
};

const ContactModal = ({ showModal, setModal, contactId, contact = {}, contacts = [], isCreate, reload, customerId }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isEditMode, setIsEditMode] = useState(isCreate ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [priority, setPriority] = useState(contact?.priority || "third");
  const formRef = useRef(null);
  const {
    data: changelog,
    isLoading: isLoadingChangelog,
    error: changelogError,
    reload: reloadChangelog,
  } = useFetch(loadChangelog, { contactId });

  const handleSubmit = async () => {
    const newUser = getFormValues(formRef.current);
    newUser.id = contact.id || generateUUID();
    const otherContacts = contacts.filter((c) => c.id !== newUser.id);
    const newContacts = [...otherContacts, newUser];
    setIsLoading(true);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/contacts`, JSON.stringify(newContacts), "PUT");
    setIsLoading(false);
    setModal((m) => ({ ...m, isCreate: false, contactId: newUser.id }));
    setIsEditMode(false);
    reload();
    reloadChangelog();
  };

  const hide = () => setModal({ showModal: false });

  const handleCancel = () => (isCreate ? hide() : setIsEditMode(false));

  const handleDelete = async () => {
    const newContacts = contacts.filter((c) => c.id !== contact.id);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${customerId}/wiki/contacts`, JSON.stringify(newContacts), "PUT");
    setConfirmModal({ showModal: false, callback: null });
    reload();
    hide();
  };

  const handleDeletePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDelete() });
  };

  const getPriority = (field) => (!contact.priority && field === "third" ? true : contact.priority === field);
  const getTriage = () => (contact.includeTriage === undefined && priority !== "other" ? true : !!contact.includeTriage);

  return (
    <>
      <Modal showModal={showModal} hide={hide} width="65rem">
        <div style={{ position: "relative", marginTop: "1rem" }} key={JSON.stringify(contact)}>
          <div style={{ position: "absolute", right: 0, top: 0, zIndex: 99, display: "flex", gap: "1rem", alignItems: "center" }}>
            {!isEditMode && <CTAButton onClick={() => setIsEditMode(!isEditMode)}>Edit</CTAButton>}
          </div>
          <StyledForm ref={formRef}>
            <section style={{ padding: 0, border: "none" }}>
              <Input label="Priority" labelStyle={{ margin: 0 }}>
                <RadioButtonGroup readOnly={!isEditMode} onChange={setPriority}>
                  <RadioButton name="priority" label="First" defaultChecked={getPriority("first")} />
                  <RadioButton name="priority" label="Second" defaultChecked={getPriority("second")} />
                  <RadioButton name="priority" label="Third" defaultChecked={getPriority("third")} />
                  <RadioButton name="priority" label="Temp" defaultChecked={getPriority("temp")} />
                  <RadioButton name="priority" label="Other" defaultChecked={getPriority("other")} />
                </RadioButtonGroup>
              </Input>
              <span>
                <Input name="name" label="Name" defaultValue={contact.name} readOnly={!isEditMode} />
                <Input name="email" label="Email" defaultValue={contact.email} readOnly={!isEditMode} />
              </span>
              <span>
                <Input name="phone" label="Phone Number" defaultValue={contact.phone} readOnly={!isEditMode} />
                <Input name="role" label="Job Role" defaultValue={contact.role} readOnly={!isEditMode} />
              </span>
              {(isEditMode ? priority === "temp" : contact.priority === "temp") && (
                <span>
                  <Input
                    name="tempStart"
                    type="date"
                    label="Temp Start Date"
                    defaultValue={moment(contact.tempStart).format("YYYY-MM-DD")}
                    readOnly={!isEditMode}
                  />
                  <Input
                    name="tempEnd"
                    type="date"
                    label="Temp End Date"
                    defaultValue={moment(contact.tempEnd).format("YYYY-MM-DD")}
                    readOnly={!isEditMode}
                  />
                </span>
              )}
              <Input label="Notes">
                <MarkdownEditor
                  name="notes"
                  style={{ marginTop: "0.3rem", marginBottom: isEditMode && "1rem" }}
                  defaultValue={contact.notes}
                  readOnly={!isEditMode}
                  subtle
                />
              </Input>
              {!isEditMode && contact.includeTriage && (
                <p>
                  📧
                  <i>This contact is included in triage communication emails.</i>
                </p>
              )}
              {isEditMode && (
                <CheckboxBorderedButton
                  name="includeTriage"
                  label="Copy into Triage Emails"
                  defaultChecked={getTriage()}
                  labelStyle={{ position: "absolute", left: 0 }}
                  key={isEditMode}
                />
              )}
            </section>
            {!isEditMode && (
              <section style={{ paddingTop: 0, paddingBottom: "1.5rem", display: "flex", gap: "1rem", alignItems: "flex-start" }}>
                <Changelog data={changelog} isLoading={isLoadingChangelog} error={changelogError} />
                <div className="box-container" style={{ borderColor: "rgba(255, 0, 0, 0.6)", flex: 1 }}>
                  <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this contact permanently</p>
                  <CTAButton onClick={handleDeletePrompt} style={{ color: "rgba(255, 0, 0, 0.7)", fontWeight: "bold" }} isSecondary>
                    Delete
                  </CTAButton>
                </div>
              </section>
            )}
          </StyledForm>
          {isEditMode && (
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem", gap: "1rem" }}>
              <CTAButton isSecondary onClick={handleCancel}>
                Cancel
              </CTAButton>
              <CTAButton onClick={handleSubmit} isLoading={isLoading}>
                {isCreate ? "Create" : "Save"}
              </CTAButton>
            </div>
          )}
        </div>
      </Modal>
      <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
    </>
  );
};

const StyledMessage = styled.p`
  margin: 0;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0.5rem;
  bottom: 0.5rem;
  left: -0.4rem;
  right: 0;
  font-style: italic;
`;

const Changelog = ({ data: _data, isLoading, error }) => {
  const [showChangelogModal, setShowChangelogModal] = useState(false);

  const data = _data && _data.result.sort((a, b) => b.created - a.created);
  const lastUpdate = data && data[0];

  return (
    <>
      <div
        style={{ marginBottom: lastUpdate && "2rem", minHeight: "2.9rem" }}
        className="box-container interactive"
        onClick={() => setShowChangelogModal(true)}
      >
        View Changelog
      </div>
      {lastUpdate && <StyledMessage title={lastUpdate.message}>Latest change: {lastUpdate.message}</StyledMessage>}
      <Modal
        subtitle="Changelog"
        showModal={showChangelogModal}
        hide={() => setShowChangelogModal(false)}
        isModalLoading={isLoading}
        modalError={error}
        style={{ width: "80rem", paddingBottom: "1.5rem" }}
      >
        {data?.length ? (
          <ScrollContainer scrollX="auto">
            <Table isFixed singleLine>
              <thead>
                <tr>
                  <th style={{ width: "60%" }}>Activity</th>
                  <th style={{ width: "20%" }}>Actioned by</th>
                  <th style={{ width: "20%" }}>Date</th>
                </tr>
              </thead>
              <tbody>
                {data.map((a) => (
                  <tr key={a.id} style={{ cursor: "pointer" }}>
                    <td style={{ width: "60%" }}>{a.message}</td>
                    <td style={{ width: "20%" }}>{a.user.displayName}</td>
                    <td style={{ width: "20%" }}>{moment.unix(a.created).format("YYYY-MM-DD HH:mm")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollContainer>
        ) : (
          <p style={{ width: "100%", textAlign: "center", fontSize: "1.5rem" }}>No activities</p>
        )}
      </Modal>
    </>
  );
};

const loadPassphrase = ({ customerId }) => {
  return fetchData(`${process.env.REACT_APP_ADMIN_API}/phrases/${customerId}`);
};

const Passphrase = ({ customerId }) => {
  const { data, isLoading } = useFetch(loadPassphrase, { customerId: customerId });
  const phrase = isLoading ? "......" : data?.result?.phrase;
  return <Input readOnly label="Customer Passphrase" value={phrase || ""} />;
};
