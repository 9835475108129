import React, { useState } from "react";

import { CTAButton } from "lib/components/CTAButton";

import { fetchData } from "lib/helpers/fetchData";
import { ConfirmModal } from "lib/components/Modal";
import { useHistory } from "react-router-dom";

export const DeleteCampaign = ({ campaign }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });

  const history = useHistory();
  
  const handleDelete = () => {
    const customerId = campaign.customer.id;
    const campaignId = campaign.id;
    setIsLoading(true);
    setError(null);
    fetchData(`${process.env.REACT_APP_REPORTING_API}/phish-sim/manage/reports/delete/${customerId}/${campaignId}`, null, "DELETE")
      .then(() => {
        setIsLoading(false);
        history.push("/phish-sim/campaigns");
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e?.error?.message || e?.error || e);
      });
  };

  const handleDeletePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDelete() });
  };

  return (
    <section>
      {/* eslint-disable-next-line */}
      <h3 style={{ fontSize: "1.5rem" }}>Danger Zone 🚨🚨</h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid rgba(255, 0, 0, 0.6)",
          borderRadius: "0.8rem",
          padding: "1rem",
          marginTop: "1rem",
        }}
      >
        <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this campaign permanently</p>
        <CTAButton onClick={handleDeletePrompt} style={{ borderColor: "rgba(255, 0, 0, 0.3)" }} isSecondary isLoading={isLoading} error={error}>
          Delete
        </CTAButton>
        <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
      </div>
    </section>
  );
};
