import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchData, useFetch } from "lib/helpers/fetchData";

import { UpsertFinding } from "./UpsertFinding";
import { CommentsList } from "../../comments/CommentsList";

const loadComments = async ({ findingId }) => {
  return await fetchData(`${process.env.REACT_APP_PENTEST_API}/comments/${findingId}`);
};
export const SingleFinding = ({ reload: reloadList, engagementId, finding, findingId, attachments, customerId }) => {
  const { data, isLoading, error, reload: reloadComments } = useFetch(loadComments, { findingId });

  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const scrollingElement = document.getElementById("scrolling-element");
    if (scrollingElement) {
      const left = isEditMode ? scrollingElement.clientWidth : 0;
      scrollingElement.scrollTo({ behavior: "smooth", left });
    }
  }, [isEditMode]);

  const handleHide = () => {
    history.push(`/pentest/engagements/engagement/${customerId}/${engagementId}`);
    reloadList();
  };

  const reloadAll = () => {
    reloadList();
    reloadComments();
  };

  return (
    <>
      <UpsertFinding
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        data={finding}
        endCreation={reloadAll}
        engagementId={engagementId}
        customerId={customerId}
        hide={handleHide}
        attachments={attachments}
      />
      {!isEditMode && <CommentsList targetId={findingId} data={data} isLoading={isLoading} error={error} reload={reloadComments} />}
    </>
  );
};
