import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const StyledCheckbox = styled.label`
  cursor: pointer;
  display: ${(props) => props.hide && "none"};

  .checkbox {
    padding: ${(props) => (props.small ? "0.5rem 0.75rem" : "0.75rem 1rem")};
    margin: ${(props) => (props.small ? "0 -0.5rem" : "-0.85rem -1.25rem")};
    width: 100%;
    transition: all 0.2s;

    &:hover {
      .icon {
        border-color: ${(props) => props.theme.secondaryButtonColor};
      }
    }
  }

  .icon {
    cursor: pointer;
    border: 1px solid ${(props) => (props.highlight ? props.theme.lightText : props.theme.headerOutline)};
    background: ${(props) => props.theme.cardBackground};
    border-radius: 0.3rem;
    width: ${(props) => (props.small ? "1rem" : "1.3rem")};
    height: ${(props) => (props.small ? "1rem" : "1.3rem")};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => (props.small ? "0.5rem" : "0.7rem")};
    path {
      color: transparent;
    }
    transition: all 0.2s ease-out;
    &:hover {
      background: ${(props) => props.theme.accentCardBackground};
    }
  }

  input {
    display: none;
    &:checked + .checkbox > .icon {
      path {
        color: ${(props) => props.theme.buttonText || props.theme.text};
      }
      background: ${(props) => props.theme.secondaryButtonColor};
      border-color: ${(props) => props.theme.secondaryButtonColor};
      &:hover {
        background: ${(props) => props.theme.secondaryButtonHover};
      }
    }
  }
`;

export const Checkbox = React.forwardRef(({ style, small, className, highlight, hide, ...props }, ref) => (
  <StyledCheckbox style={style} className={`${className} checkbox-container`} small={small} highlight={highlight} hide={hide}>
    <input type="checkbox" {...props} ref={ref} key={props.defaultChecked} />
    <div className="checkbox">
      <div className="icon">
        <FontAwesomeIcon icon={faCheck} />
      </div>
    </div>
  </StyledCheckbox>
));

const StyledCheckboxButton = styled.label`
  && {
    user-select: none;
    outline-color: ${(props) => props.theme.secondaryButtonColor};
    cursor: ${(props) => !props.readOnly && !props.subtle && "pointer"};
    width: fit-content;
    min-height: ${(props) => (props.subtle && props.readOnly ? "unset" : "2.5rem")};
    color: ${(props) => props.theme.text};
    display: ${(props) => (props.hide ? "none" : "flex")};
    align-items: center;
    padding: ${(props) => (props.subtle || (props.readOnly && !props.small) ? "0.225rem 0" : "0.225rem 1rem")};
    font-size: ${(props) => (props.readOnly && !props.small ? "1.5rem" : "1.33rem")};
    font-weight: ${(props) => (props.readOnly && !props.small ? "bold" : "normal")};
    font-style: ${(props) => props.small && "italic"};
    border: ${(props) => (props.subtle || (props.readOnly && !props.small) ? "none" : `1px solid ${props.theme.headerOutline}`)};
    border-radius: 0.8rem;
    background: ${(props) => !props.readOnly && !props.subtle && props.theme.cardBackground};
    flex: ${(props) => (props.subtle || (props.readOnly && !props.small) ? "none" : 1)};
    transition: background 0.3s;
    &:hover {
      background: ${(props) => !props.readOnly && !props.subtle && props.theme.accentCardBackground};
    }
    & > label {
      margin: 0 0.75rem 0 0;
    }
  }
`;

export const CheckboxButton = ({ label, readOnly, showWhenNull = false, smallWhenNull = false, subtle = false, ...props }) => {
  const hide = readOnly && !props.defaultChecked && !showWhenNull;
  if (hide) return null;
  return (
    <StyledCheckboxButton tabIndex={0} readOnly={readOnly} subtle={subtle} small={readOnly && smallWhenNull} hide={hide}>
      {<Checkbox {...props} hide={hide || readOnly} />}
      {label}
    </StyledCheckboxButton>
  );
};

const StyledCheckboxButtonGroup = styled.label`
  && {
    font-size: 1.25rem;
    font-weight: normal;
    text-transform: capitalize;
    margin: 1rem 0;
    & > .hidden-input {
      display: none;
    }

    & > span {
      margin-bottom: 0 !important;
      position: relative;
      padding-top: 0.3rem;

      & > label {
        padding: ${(props) => props.readOnly && "0 1rem"};
        margin-right: ${(props) => props.readOnly && 0};
        border-radius: ${(props) => props.readOnly && 0};
        &:first-of-type {
          padding-left: ${(props) => props.readOnly && 0};
        }
        &:not(:last-child) {
          border-right: ${(props) => props.readOnly && props.length > 1 && `1px solid ${props.theme.headerOutline}`};
        }
      }

      &:empty {
        padding-bottom: 2rem;
        padding-left: 0.15rem;
        &::after {
          position: absolute;
          content: "Not given";
          text-transform: initial;
          opacity: 0.75;
          width: 100%;
          box-sizing: border-box;
          font: inherit;
          font-size: 1.5rem;
          font-weight: bold;
          color: ${(props) => props.theme.text};
          border-radius: 0.25rem;
          transition: all 0.3s;
          cursor: default;
        }
      }
    }
  }
`;

export const CheckboxButtonGroup = ({ label, name, readOnly, children, ...props }) => {
  let initialValues = [];

  React.Children.forEach(children, (child) => child.props.defaultChecked && initialValues.push(child.props.name));

  const [values, setValues] = useState([...initialValues]);

  const onChange = (e = {}) => {
    let newValues = [...values];
    const { checked, name } = e.target || {};
    newValues = checked ? [...newValues, name] : values.filter((v) => v !== name);
    setValues(newValues);
  };

  return (
    <StyledCheckboxButtonGroup readOnly={readOnly} length={children.length}>
      {label}
      <span {...props}>{React.Children.map(children, (child) => React.cloneElement(child, { readOnly, onChange }))}</span>
      <input tabIndex={-1} className="hidden-input" value={values.join(", ")} name={name} readOnly />
    </StyledCheckboxButtonGroup>
  );
};

const StyledCheckboxBorderedButton = styled.label`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  font-size: 1.3rem;
  height: auto;
  width: fit-content;
  border: 1px solid ${(props) => props.theme.headerOutline};
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 0.8rem;
  background: ${(props) => props.theme.cardBackground};
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    background: ${(props) => props.theme.accentCardBackground};
    .icon {
      background: ${(props) => props.theme.accentCardBackground};
    }
  }
  & > label {
    margin-left: 0.33rem;
  }
`;

export const CheckboxBorderedButton = ({ label, name, labelStyle = {}, style = {}, defaultChecked, disabled, ...props }) => {
  return (
    <StyledCheckboxBorderedButton style={labelStyle}>
      {label}
      <Checkbox name={name} highlight disabled={disabled} defaultChecked={defaultChecked} {...props} />
    </StyledCheckboxBorderedButton>
  );
};