import { redirectToEntry } from "./helpers";

export const REDIRECT_URI = `${window.location.origin}/callback`;
export const LOGOUT_URI = `${window.location.origin}/logout`;
export const SIGN_IN_POLICY_NAME = "B2C_1_sign_on_sign_in";

export const AUTH_STATE = {
  UNDETERMINED: "undetermined",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
};

export const AUTH_ERRORS = [
  {
    code: "AADB2C90075",
    message: "Unable to validate user account. Please check you entered the correct credentials",
    handler: redirectToEntry,
    skipModal: true,
  },
  {
    code: "AADB2C90287",
    message: "An incorrect redirect uri has been specified",
    handler: redirectToEntry,
    skipModal: true,
  },
  {
    code: "AADB2C90006",
    message: "An incorrect redirect uri has been specified",
    handler: redirectToEntry,
    skipModal: true,
  },
  {
    code: "AADB2C90091",
    message: "The user has cancelled their flow, so bring them back to login",
    handler: redirectToEntry,
    skipModal: true,
  },
];
