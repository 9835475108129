import React from "react";
import moment from "moment";

import { StandardChart } from "lib/components/StandardChart";

export const ActivitiesChart = ({ data, index, setIndex, type, hasClick = true }) => {
  const handleClick = (e, el, ctx) => {
    const canvas = document.getElementById("activityGraph");
    const x = e.clientX - canvas.offsetLeft;

    const legendFilterHeight = 125;
    const xAxisLabelWidth = 16;
    if (e.clientY < legendFilterHeight) return;

    const meta = ctx.config.data.datasets[0]._meta;
    const metaData = meta[Object.keys(meta)[0]];
    const bars = metaData.data.map((d) => d._model.x);

    const mouseX = x - metaData.data[0]._model.x + metaData.data[0]._model.width + xAxisLabelWidth;
    const index = bars.findIndex((b) => b >= mouseX);

    setIndex(index);
  };

  const dataLabels = [type.name];
  const dataLabelIds = [type.id];
  const colors = [type.color];
  const dataPoints = dataLabelIds.map((label) => data.map((d) => d[label.toLowerCase()]));
  const axisLabels = data.map((d) => moment(d.date).format("MMM D"));

  const id = "activityGraph";
  const stacked = true;
  const displayTooltip = true;
  const displayLegend = false;
  const hasHover = hasClick;
  const padding = 20;
  const config = {
    colors,
    dataLabels,
    displayTooltip,
    displayLegend,
    dataPoints,
    axisLabels,
    stacked,
    id,
    hasHover,
    padding,
  };

  return (
    <StandardChart
      key={index}
      drawBoxIndex={index}
      setBoxIndex={setIndex}
      xAxisType="category"
      {...config}
      handleClick={hasClick && handleClick}
    />
  );
};
