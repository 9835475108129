import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { StyledFilters } from "lib/styles/general";
import { Searchbar } from "lib/components/Searchbar";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { InfiniteScroll } from "lib/components/InfiniteScroll";
import { Table } from "lib/components/Table";
import { SortableHeader } from "lib/components/SortableHeader";
import { LinkRow } from "lib/components/LinkRow";
import { ReviewBox } from "../reviews";
import { useQuery } from "lib/helpers/useQuery";
import { CTAButton } from "lib/components/CTAButton";
import { Select } from "lib/components/Select";
import styled from "styled-components";

const STATUSES = [
  { key: "Draft", value: 1 },
  { key: "Approved", value: 2 },
  { key: "Requires Changes", value: 3 },
  { key: "Pending Review", value: 4 },
];

export const KBIssuesList = ({ kbIssuesData }) => {
  const history = useHistory();
  // We may have an optional finding id param to represent the current finding
  const { kbIssueId } = useQuery();
  const { params, setParams, ...kbIssues } = kbIssuesData;

  const handleSubmitNew = () => {
    history.push(`/pentest/kb-issues?isCreateKBIssue=true`);
  };

  const sort = {
    sort: { orderBy: params.orderBy, reverse: params.reverse },
    setSort: (sort) => setParams((p) => ({ ...p, ...sort })),
  };

  const [searchCallback] = useDebouncedCallback((search) => {
    setParams((f) => ({ ...f, search }));
  }, 500);

  const search = {
    search: params.search,
    setSearch: (search) => searchCallback(search),
  };

  return (
    <StyledContainer>
      <StyledFilters>
        <Searchbar {...search} placeholder="Search KB Issues" small />
        <hr />
        <CTAButton onClick={handleSubmitNew} isSecondary>
          Add KB Issue
        </CTAButton>
      </StyledFilters>
      <ScrollContainer className="scroll">
        <InfiniteScroll {...kbIssues} useWindow={false} disableNoResults>
          <Table tableStyle={{ tableLayout: kbIssues.data.result.length ? "fixed" : "auto" }} small>
            <thead>
              <tr>
                <th style={{ width: "10rem" }}>
                  <Select
                    key={params.status}
                    defaultValue={params.status}
                    onChange={(e) => setParams({ status: e.target.value })}
                    style={{ width: "100%", fontSize: "0.9rem" }}
                  >
                    <option value="">Filter status</option>
                    {STATUSES.map((statuses, i) => (
                      <option key={i} value={statuses.value}>
                        {statuses.key}
                      </option>
                    ))}
                  </Select>
                </th>
                <SortableHeader field="title" {...sort} headerStyle={{ paddingLeft: "0.3rem" }}>
                  Issue Title
                </SortableHeader>
                <SortableHeader field="severity" {...sort} style={{ justifyContent: "flex-end" }} headerStyle={{ width: "3rem" }}>
                  Severity
                </SortableHeader>
                <SortableHeader field="cvssScore" {...sort} style={{ justifyContent: "flex-end" }} headerStyle={{ width: "2rem" }}>
                  CVSS
                </SortableHeader>
              </tr>
            </thead>
            <tbody>
              {kbIssues.data.result.length ? (
                kbIssues.data.result.map((issue) => (
                  <LinkRow key={issue.id} active={issue.id === kbIssueId} path={`/pentest/kb-issues?kbIssueId=${issue.id}`}>
                    <td style={{ padding: 0, paddingLeft: "0.2rem" }}>{<ReviewBox status={issue.status} />}</td>
                    <td style={{ padding: 0, paddingLeft: "0.2rem" }}>{issue.title}</td>
                    <td style={{ paddingLeft: 0, textAlign: "right" }}>{issue.severity}</td>
                    <td style={{ paddingLeft: 0, textAlign: "right" }}>{issue.cvssScore}</td>
                  </LinkRow>
                ))
              ) : (
                <tr className="no-results-row">
                  <td colSpan={100} className="no-results-text">
                    No KB issues found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </InfiniteScroll>
      </ScrollContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100%;
  position: relative;
  & > .scroll {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 4rem;
    & > div {
      justify-content: flex-start;
    }
  }
`;
