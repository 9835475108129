import React from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledTag = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  border: 1px solid ${(props) => props.theme.headerOutline};
  border-radius: 0.25rem;
  margin: 5px 7px 2px 0;
  font-size: 1.33rem;
  font-weight: bold;
  padding: 0.4rem 1rem;
  background: ${(props) => props.theme.cardBackground};
  transition: background 0.3s;
  &:hover {
    background: ${(props) => props.theme.accentCardBackground};
  }
  svg {
    font-size: 0.8rem;
    margin-left: 0.8rem;
  }
`;

export const Tag = ({ callback, name, label }) => {
  return (
    <StyledTag onClick={callback} aria-label={label}>
      {name}
      <FontAwesomeIcon icon={faTimes} />
    </StyledTag>
  );
};
