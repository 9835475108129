import React from "react";
import styled from "styled-components";
import { KeyValue } from "lib/components/KeyValue";
import { displayDate, displayDataAmount } from "lib/helpers/displayFormats";

const StyledContainer = styled.div`
  flex: 1;
  margin: "1.5rem 2rem 0 0";
`;

export const FileDetails = ({ data = {} }) => {
  const dataProps = { action: "copy", xPos: "right" };

  return (
    <StyledContainer>
      <KeyValue xHoverPos="right" yHoverPos="center" action="download" text="File Name" value={data.properties?.filename} href={data.properties?.location?.url} />
      <KeyValue {...dataProps} text="File Size" value={displayDataAmount(data.properties?.size, true)} />
      <KeyValue {...dataProps} text="Content Type" value={data.properties?.contentType} />
      <KeyValue {...dataProps} text="First Seen" value={displayDate(data.firstSeen)} />
      <KeyValue {...dataProps} text="Last Seen" value={displayDate(data.lastSeen)} />
      <KeyValue {...dataProps} text="md5" value={data.md5} />
      <KeyValue {...dataProps} text="sha1" value={data.sha1} />
      <KeyValue {...dataProps} text="sha256" value={data.sha256} />
    </StyledContainer>
  );
};
