import { CTAButton } from "lib/components/CTAButton";
import { CheckboxButton } from "lib/components/Checkbox";
import { KeyValue } from "lib/components/KeyValue";
import { fetchData } from "lib/helpers/fetchData";
import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { useRef, useState } from "react";

export const Tracking = ({ data, reload }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    let { enabled } = getFormValues(formRef.current);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/customers/${data?.id}/tracking`, JSON.stringify({ enabled }), "PUT");
    setIsLoading(false);
    setIsEditMode(false);
    reload();
  };

  const handleExit = () => setIsEditMode(false);

  return (
    <div style={{ position: "relative" }}>
      {!isEditMode && (
        <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
          Edit
        </CTAButton>
      )}
      <StyledForm ref={formRef}>
        <section style={{ paddingTop: 0 }}>
          <KeyValue
            text="Service Status"
            wrap={false}
            value={
              <CheckboxButton
                name="enabled"
                subtle
                showWhenNull
                label={!isEditMode ? (data?.tracking?.enabled ? "Enabled" : "Disabled") : "Is Enabled?"}
                defaultChecked={!!data?.tracking?.enabled}
                readOnly={!isEditMode}
              />
            }
          />
        </section>
      </StyledForm>
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "1rem" }}>
          <CTAButton isSecondary onClick={handleExit}>
            Cancel
          </CTAButton>
          <CTAButton isLoading={isLoading} onClick={handleSubmit}>
            Save
          </CTAButton>
        </div>
      )}
    </div>
  );
};
