import React from 'react'
import styled from "styled-components";
import moment from 'moment';

import { KeyValue } from 'lib/components/KeyValue'
import { getTimeTillDate } from 'lib/helpers/getTimeTillDate';

const StyledContainer = styled.div`
  flex: 1;
  margin: 1.5rem 2rem 0 0;
  min-width: 30rem;
`;

export const Details = ({ data }) => {
  const details = [
    { key: "From",            value: data.mail?.from?.value[0].address },
    { key: "Received",        value: moment(data.created).format("HH:mm - MMM D") },
    { key: "Due Time",        value: getTimeTillDate(data.due) },
    { key: "Subject",         value: data.mail?.subject },
    { key: "Email Receipt",   value: data.receiptMailSent ? "Sent" : "Not Sent" },
    { key: "Email Results",   value: data.resultMailSent ? "Sent" : "Not Sent" },
    { key: "Response Time",   value: getTimeTillDate(data.ttfr, data.created, true) },
    { key: "Resolution Time", value: getTimeTillDate(data.ttr, data.created, true) },
  ]
  
  return (
    <StyledContainer>
      {details.map(({ key, value }) => <KeyValue key={key} text={key} value={value} />)}
    </StyledContainer>
  )
}