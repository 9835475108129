import React, { useState, useRef } from "react";
import moment from "moment";
import { fetchData } from "lib/helpers/fetchData";

import { getFormValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";

import { Modal, ConfirmModal } from "lib/components/Modal";
import { CTAButton } from "lib/components/CTAButton";
import { Input } from "lib/components/Input";
import { UserCustomers } from "./UserCustomers";

export const UserModal = ({ showModal, hide, user = {}, customerId, reload }) => {
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async () => {
    const newUser = getFormValues(formRef.current);
    setIsLoading(true);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/users/${customerId}/${user.id}`, JSON.stringify(newUser), "PUT");
    setIsLoading(false);
    reload();
    hide();
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    await fetchData(`${process.env.REACT_APP_ADMIN_API}/users/${customerId}/${user?.id}`, null, "DELETE");
    setIsDeleting(false);
    setConfirmModal({ showModal: false, callback: null });
    reload();
    hide();
  };

  const handleDeletePrompt = () => {
    setConfirmModal({ showModal: true, callback: () => handleDelete() });
  };

  return (
    <Modal showModal={showModal} hide={hide} width="50rem">
      <div style={{ position: "relative", marginTop: "1rem" }}>
        {!isEditMode && setIsEditMode && (
          <CTAButton style={{ position: "absolute", right: 0, top: 0, zIndex: 99 }} onClick={() => setIsEditMode(!isEditMode)}>
            Edit
          </CTAButton>
        )}
        <StyledForm ref={formRef}>
          <section style={{ paddingTop: 0 }}>
            <Input name="email" label="Email" defaultValue={user.email} readOnly={true} />
            <Input name="displayName" label="Display Name" defaultValue={user.displayName} readOnly={!isEditMode} />
            {!isEditMode && (
              <Input
                name="invited"
                label="Invited"
                defaultValue={user.invited?.value && moment(user.invited.ts).format("YYYY-MM-DD HH:mm:ss")}
                readOnly={true}
              />
            )}
            {!isEditMode && (
              <Input
                name="joined"
                label="Joined"
                defaultValue={user.joined?.value && moment(user.joined.ts).format("YYYY-MM-DD HH:mm:ss")}
                readOnly={true}
              />
            )}
          </section>
          {!isEditMode && (
            <section>
              <h3>Customers</h3>
              <UserCustomers user={user} reload={reload} customerId={customerId} />
            </section>
          )}
          {!isEditMode && (
            <section>
              {/* eslint-disable-next-line */}
              <h3>Danger Zone 🚨🚨</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid rgba(255, 0, 0, 0.6)",
                  borderRadius: "0.8rem",
                  padding: "1rem",
                }}
              >
                <p style={{ flex: 1, fontSize: "1.35rem", margin: 0 }}>Delete this user permanently</p>
                <CTAButton onClick={handleDeletePrompt} style={{ color: "rgba(255, 0, 0, 0.7)", fontWeight: "bold" }} isSecondary>
                  Delete
                </CTAButton>
              </div>
            </section>
          )}
        </StyledForm>
        {isEditMode && (
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem" }}>
            <CTAButton isSecondary onClick={() => setIsEditMode(false)}>
              Cancel
            </CTAButton>
            <CTAButton onClick={handleSubmit} isLoading={isLoading}>
              {setIsEditMode ? "Save" : "Create"}
            </CTAButton>
          </div>
        )}
      </div>
      <ConfirmModal {...confirmModal} isLoading={isDeleting} hide={() => setConfirmModal({ showModal: false })} />
    </Modal>
  );
};
