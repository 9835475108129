import styled from "styled-components";

const StyledProfile = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  border-radius: 50%;
  color: ${(props) => props.theme.text};
  font-size: 1.2rem;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 10;

  &::before {
    z-index: -1;
    position: absolute;
    content: "";
    background: red;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.cardBackground};
    opacity: 0.5;
  }
`;

export const Profile = ({ name, size = "1.7rem", ...props }) => {
  const initials = name.split(" ").map((part) => part[0].toUpperCase());
  return <StyledProfile {...props} size={size}>{initials}</StyledProfile>;
};
