import { useState } from "react";
import { UpsertFinding } from "./UpsertFinding";
import { useHistory } from "react-router-dom";

export const CreateFinding = ({ reload, engagementId, customerId }) => {
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(true);

  const handleHide = (findingId) => {
    reload();
    if (findingId) {
      history.push(`/pentest/engagements/engagement/${customerId}/${engagementId}?findingId=${findingId}`);
    } else {
      history.goBack();
    }
  };

  return <UpsertFinding isEditMode={isEditMode} setIsEditMode={setIsEditMode} customerId={customerId} endCreation={handleHide} engagementId={engagementId} />;
};
