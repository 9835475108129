import React, { useRef, useState } from "react";
import moment from "moment";

import { getNamedValues } from "lib/helpers/getFormValues";
import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { Select } from "lib/components/Select";
import { CTAButton } from "lib/components/CTAButton";
import { ErrorsList } from "lib/components/ErrorsList";

import { getEventKey } from "./Events";

export const CreateEvent = ({ setEvents, targets }) => {
  const [errors, setErrors] = useState([]);
  const newEventFormRef = useRef(null);

  const addEvent = () => {
    let event = getNamedValues(newEventFormRef.current);
    event.date = moment(`${event.date} ${event.time}`).toISOString();
    event.id = getEventKey(event);

    let errors = [];
    Object.entries(event).forEach(([key, value]) => {
      if (!value) errors.push(key);
    });

    setErrors(errors);
    if (errors.length) return;

    setEvents((e) => [...e, event]);
  };

  return (
    <StyledForm ref={newEventFormRef} style={{ paddingTop: "0.33rem" }}>
      <Input label="User">
        <Select tabIndex={-1} name="user" style={{ width: "100%"  }}>
          <option value="">Select User</option>
          {targets.length ? targets.map((t) => <option value={t}>{t}</option>) : <option value="">Please input targets first</option>}
        </Select>
      </Input>
      <Input label="Event type">
        <Select name="type" style={{ width: "100%" }}>
          <option value="">Select Type</option>
          <option value="opened">Opened</option>
          <option value="clicked">Clicked</option>
          <option value="submitted">Submitted Data</option>
          <option value="reported">Reported Email</option>
        </Select>
      </Input>
      <Input label="Date" type="date" name="date" />
      <Input label="Time" type="time" name="time" />
      <ErrorsList errors={errors} />
      <CTAButton onClick={addEvent}>Add</CTAButton>
    </StyledForm>
  );
};
