import React from "react";
import styled from "styled-components";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { KeyValue } from "lib/components/KeyValue";
import { PreviewBox } from "lib/components/PreviewBox";
import { getRemValue } from "lib/helpers/getRemValue";

const StyledCertificate = styled(PreviewBox)`
  position: relative;
  height: 100%;
  width: fit-content;
  padding: 1rem;
  background: ${props => props.theme.cardBackground};
  & > svg {
    position: absolute;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.iconBackground};
    top: 0;
    right: 0;
    padding: 0.4rem;
    margin: 0.3rem;
    border: 1px solid ${props => props.theme.headerOutline};
    border-radius: 50%;
  }
`;

export const Certificate = ({ data }) => {
  const getCompanyText = d => d.CN + (d.OU ? ` (${d.OU})` : "");
  const getLocationText = d => [d.O, d.L, d.ST, d.C].filter(Boolean).join(", ");
  const getValidity = d => `Currently ${moment(d.valid_to).isAfter() ? "valid" : "invalid"}`;
  const getValidDates = d => `${moment(d.valid_from).format("YYYY-MM-DD")} - ${moment(d.valid_to).format("YYYY-MM-DD")}`;

  return (
    <StyledCertificate maxHeight={getRemValue(9.5)} showShadow={false} id="ticket-cert">
      <KeyValue text="Subject" value={[getCompanyText(data.subject), getLocationText(data.subject)].filter(Boolean).join(" - ")} />
      <KeyValue text="Issuer" value={[getCompanyText(data.issuer), getLocationText(data.issuer)].filter(Boolean).join(" - ")} />
      <KeyValue text="Validity" value={`${getValidity(data)} (${getValidDates(data)})`} />
      <KeyValue text="Subject Alt Name" value={data.subjectaltname} />
      <FontAwesomeIcon icon={faShieldAlt} />
    </StyledCertificate>
  );
};