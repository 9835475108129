import styled from "styled-components";
import { CircleIndicator } from "../../components/CircleIndicator";
import { COLORS, VALUE_COLORS } from "../../components/constants";
import { KeyValue } from "lib/components/KeyValue";
import { round } from "lib/helpers/toPrecision";
import { toCurrency } from "lib/helpers/toPrecision";

const StyledContainer = styled.div`
  padding-left: 2rem;
  display: flex;
  align-items: flex-start;
  gap: 2rem;

  & > .logged-info {
    gap: 2rem;
    padding-top: 1rem;
    display: flex;
    justify-content: center;

    & > .logged {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      & > h2 {
        margin: 0;
        color: ${(props) => props.theme.text};
        font-size: 1.5rem;
      }

      & > h3 {
        margin: 0;
        color: ${(props) => props.theme.lightText};
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }
    }
  }

  & > .budget-info {
    padding-top: 1rem;
    padding-right: 2rem;
    border-right: 1px solid ${(props) => props.theme.headerOutline};
  }

  & > .scheduled-info {
    border: 1px dashed ${(props) => props.theme.headerOutline};
    padding: 1rem;
    border-radius: 0.8rem;
    display: flex;
    gap: 1.5rem;

    & > .scheduled {
      width: 10rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      & > h2 {
        margin: 0;
        color: ${(props) => props.theme.text};
        font-size: 1.5rem;
      }

      & > h3 {
        margin: 0;
        color: ${(props) => props.theme.lightText};
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }
    }
  }
`;

const StyledCircle = styled(CircleIndicator)`
  && {
    width: ${(props) => props.size || "8.5rem"};
    height: ${(props) => props.size || "8.5rem"};

    .text {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.mediumText};

      & > h2 {
        font-size: 1.33rem;
        margin: 0.5rem 0 0;
        text-align: center;
      }

      & > h3 {
        font-size: 1.1rem;
        color: ${(props) => props.theme.lightText};
        margin: 0;
        white-space: pre-wrap;
        text-align: center;
      }
    }
  }
`;

export const Budget = ({ project }) => {
  const { hourlyRate, logged, scheduled, budget } = project;

  const hasHourlyRate = typeof hourlyRate === "number";

  const hourlyRateLabel = hasHourlyRate ? `£${toCurrency(hourlyRate)}` : "Unknown";

  const loggedNonBillableHours = `${round(logged?.nonBillableHours) || 0} hrs`;
  const loggedBillableHours = `${round(logged?.billableHours) || 0} hrs`;
  const loggedBillableFee = `£${toCurrency(logged?.billableFee || 0)}`;

  const scheduledNonBillableHours = `${round(scheduled?.nonBillableHours) || 0} hrs`;
  const scheduledBillableHours = `${round(scheduled?.billableHours) || 0} hrs`;
  const scheduledBillableFee = `£${toCurrency(scheduled?.billableFee || 0)}`;

  const budgetHours = `${round(budget?.hours) || 0} hrs`;
  const budgetFee = `£${toCurrency(budget?.fee || 0)}`;

  return (
    <StyledContainer>
      <div className="budget-info">
        <KeyValue text="Hourly rate" value={hourlyRateLabel} />
        <KeyValue text="Budget hours" value={budgetHours} />
        <KeyValue text="Budget Fee" value={budgetFee} />
      </div>

      <div className="logged-info">
        <div className="logged">
          <h2>{round(budget.loggedUsage * 100)}%</h2>
          <h3>used</h3>
          <LoggedCircle project={project} />
        </div>
        <div className="details">
          <KeyValue text="Billable Hours" value={loggedBillableHours} />
          <KeyValue text="Non-billable hours" value={loggedNonBillableHours} />
          <KeyValue text="Billable Fee" value={loggedBillableFee} />
        </div>
      </div>

      <div className="scheduled-info">
        <div className="scheduled">
          <h2>{round(budget.scheduledUsage * 100)}%</h2>
          <h3>scheduled</h3>
          <ScheduledCircle project={project} />
        </div>
        <div className="details">
          <KeyValue text="Billable Hours" value={scheduledBillableHours} />
          <KeyValue text="Non-billable hours" value={scheduledNonBillableHours} />
          <KeyValue text="Billable Fee" value={scheduledBillableFee} />
        </div>
      </div>
    </StyledContainer>
  );
};

const LoggedCircle = ({ project }) => {
  const { logged, budget, budgetPriority, isBillable, hourlyRate } = project;
  const { loggedUsage } = budget;

  const hasData = typeof loggedUsage === "number" && !isNaN(loggedUsage);
  const color = hasData ? VALUE_COLORS[budgetPriority] : "gray";

  const renderLabel = () => {
    if (!hasData) return <h3>No data</h3>;

    if (isBillable || hourlyRate) {
      return (
        <>
          <h2>{`£${toCurrency((isBillable ? logged?.billableFee : logged?.nonBillableHours * hourlyRate) || 0)}`}</h2>
          <h3>{`/ £${toCurrency(budget?.fee || 0)}`}</h3>
        </>
      );
    } else {
      return <h2>{isBillable && `${logged.nonBillableHours} hrs`}</h2>;
    }
  };

  return (
    <StyledCircle className="logged" percentageComplete={loggedUsage} color={color}>
      <span className="text">{renderLabel()}</span>
    </StyledCircle>
  );
};

const ScheduledCircle = ({ project }) => {
  const { scheduled, budget, isBillable, hourlyRate } = project;
  const { scheduledUsage } = budget;

  const hasData = typeof scheduledUsage === "number" && !isNaN(scheduledUsage);
  const scheduledColor = scheduledUsage < 0.8 ? COLORS.GREEN : scheduledUsage < 1 ? COLORS.AMBER : COLORS.RED;

  const color = hasData ? scheduledColor : "gray";

  const renderLabel = () => {
    if (!hasData) return <h3>No data</h3>;
    if (isBillable || hourlyRate) {
      return (
        <h2 style={{ fontSize: "1rem" }}>{`£${toCurrency(
          (isBillable ? scheduled?.billableFee : scheduled?.nonBillableHours * hourlyRate) || 0
        )}`}</h2>
      );
    } else {
      return <h2>{isBillable && `${scheduled.nonBillableHours} hrs`}</h2>;
    }
  };

  return (
    <StyledCircle className="scheduled" percentageComplete={scheduledUsage} color={color} size="7rem">
      <span className="text">{renderLabel()}</span>
    </StyledCircle>
  );
};
