import React from "react";
import { PageContainer } from "lib/components/PageContainer";
import { ErrorHandledRoute } from "lib/components/ErrorHandledRoute";

import { Brain as BrainPage } from "./Brain";

export const Brain = () => {
  const routes = [{ name: "Brain", link: "/brain/" }];

  return (
    <PageContainer name="Brain" link="/brain" routes={routes}>
      <ErrorHandledRoute path="/brain/" component={BrainPage} />
    </PageContainer>
  );
};