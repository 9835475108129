import React from "react";
import { Spinner, Error } from "lib/helpers/fetchData";

import { Card } from "lib/components/Card";
import { Tabs } from "lib/components/Tabs";

import { Rules } from "./Rules";
import { Evaluate } from "./Evaluate";
import { Comments } from "./Comments";
import { History } from "./History";
import { RelatedTickets } from "./RelatedTickets";
import { UrlDetails } from "./UrlDetails";
import { FileDetails } from "./FileDetails";
import { Preview } from "./Preview";

export const ArtifactContent = ({ data: _data, error, reload, setData, artifactId, ticketId, customerId }) => {
  if (error || _data?.[0]?.success === false || _data?.[1]?.success === false) return <Error message={error} />;
  if (!_data || !_data?.[0] || !_data?.[1]) return <Spinner />;

  const [data, relatedStats] = _data;

  const optimiseChanges = changedData => {
    let newData = data;
    newData.result = changedData;
    setData([newData, relatedStats]);
  };

  const counts = [`${data?.result?.rules?.filter(r => r?.didFindEvil)?.length}/${data?.result?.rules?.length}`, relatedStats.result, data.result.comments.length, data.result.history.length];

  const details = data.result.type === "url" ? <UrlDetails data={data.result} /> : <FileDetails data={data.result} />

  return (
    <>
      <Card childrenStyle={{ display: "flex", flexFlow: "row wrap-reverse", alignItems: "flex-end" }}>
        {details}
        <Evaluate data={data.result} setData={optimiseChanges} ticketId={ticketId} customerId={customerId} artifactId={artifactId} refresh={reload} />
      </Card>
      <Tabs counts={counts}>
        <Rules name="Detection" id={artifactId} data={data.result} />
        <RelatedTickets name="Related Tickets" id={artifactId} />
        <Comments name="Comments" id={artifactId} data={data.result} refresh={reload} />
        <History name="Change Log" id={artifactId} data={data.result} />
        {data.result?.properties?.location && <Preview name="Preview" data={data.result} />}
      </Tabs>
    </>
  );
};
