import styled from "styled-components";
import { FindingsList } from "./FindingsList";
import { KbIssuesList } from "./KbIssuesList";

export const PendingItems = () => {
  return (
    <StyledContainer>
      <div className="findings-container">
        <FindingsList />
      </div>
      <div className="kbissues-container">
        <KbIssuesList />
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  & > div {
    flex: 1;
    height: calc(100vh - 8rem);
    position: relative;
    & > .scroll {
      position: absolute;
      top: 4.5rem;
      left: 0px;
      bottom: 0px;
      right: 0px;
      & > div {
        justify-content: flex-start;
        height: unset;
      }
    }
  }
`;
