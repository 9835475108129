import styled from "styled-components";
import { fetchData, useFetch, Spinner } from "lib/helpers/fetchData";
import { useQuery } from "lib/helpers/useQuery";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ProjectFilters } from "./ProjectFilters";
import { ProjectsList } from "./ProjectsList";
import { useInfiniteScroll } from "lib/components/InfiniteScroll";
import { useEffectExceptOnMount } from "lib/helpers/useEffectExceptOnMount";

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`;

const projectsUrl = `${process.env.REACT_APP_SERVICE_DELIVERY_API}/projects`;
const projectFiltersUrl = `${process.env.REACT_APP_SERVICE_DELIVERY_API}/project-stats`;
const clientsUrl = `${process.env.REACT_APP_SERVICE_DELIVERY_API}/clients`;
const projectManagersUrl = `${process.env.REACT_APP_SERVICE_DELIVERY_API}/project-managers`;

const fetchClients = () => fetchData(`${clientsUrl}?pageSize=999`);
const fetchProjectManagers = () => fetchData(`${projectManagersUrl}?pageSize=999`);
const fetchProjectFilters = ({ clientId = "" }) => fetchData(`${projectFiltersUrl}/${clientId}`);

export const Projects = () => {
  const history = useHistory();
  const { clientId } = useQuery();

  const [data, setData] = useState({ result: [] });
  const [filters, setFilters] = useState({ clientId: clientId, isArchived: false, pageSize: 100 });

  const clientsData = useFetch(fetchClients);
  const projectManagersData = useFetch(fetchProjectManagers);

  const filtersData = useFetch(fetchProjectFilters, { clientId: filters.clientId });
  const projectsData = useInfiniteScroll({ url: projectsUrl, params: filters, data, setData });

  const refreshFiltersData = filtersData.reload;
  const refreshProjectsData = projectsData.reload;

  const refresh = () => {
    refreshFiltersData();
    refreshProjectsData();
  };

  // Remove the client id from the url if it exists
  useEffect(() => {
    if (clientId) history.replace("/service-delivery-portfolio/projects");
  }, [clientId, history]);

  // Refresh the filters data whenever a filter changes
  useEffectExceptOnMount(() => {
    refreshFiltersData();
  }, [filters, refreshFiltersData]);

  const setFilter = (filter) => {
    setFilters((f) => ({ ...f, ...filter }));
  };

  const getClient = (id) => clientsData?.data?.result?.find((c) => c.id === id);
  const getProjectManager = (id) => projectManagersData?.data?.result?.find((pm) => pm.id === id);

  if (clientsData?.isLoading || projectManagersData?.isLoading) return <Spinner />;

  return (
    <StyledContainer>
      <ProjectFilters
        filtersData={filtersData}
        filters={filters}
        setFilter={setFilter}
        getClient={getClient}
        getProjectManager={getProjectManager}
      />
      <ProjectsList
        projectsData={projectsData}
        filters={filters}
        setFilter={setFilter}
        getClient={getClient}
        getProjectManager={getProjectManager}
        refresh={refresh}
      />
    </StyledContainer>
  );
};
