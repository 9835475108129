import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes, ThemeContext } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CTAButton } from "./CTAButton";
import { ScrollContainer } from "./ScrollContainer";
import { useHistory } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import { Error, Spinner } from "lib/helpers/fetchData";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateZ(1px);
  }
  100% {
    opacity: 1;
    transform: translateZ(1px);
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateZ(1px) translateY(-15px) scale(0.95);
  }
  100% {
    transform: translateZ(1px) translateY(0) scale(1);
  }
`;

const StyledModalContainer = styled(ScrollContainer)`
  z-index: 200;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw !important;
  height: 100vh;
  contain: strict;
  will-change: transform, opacity;
  background: ${(props) => (props.blur ? props.theme.modalBlurBackground : props.theme.modalBackground)};
  animation: ${fadeIn} ${(props) => `${props.animation}s`} ease-out forwards;
  backdrop-filter: ${(props) => (props.blur ? "blur(28px)" : "none")};

  .modal {
    left: ${(props) => `calc(calc(-100vw + ${props.width}) / 2)`};
    top: -10rem;
    height: calc(100vh + 10rem);
    width: calc(100vw - 6px) !important;
    .modal-content {
      margin-top: 10rem;
      margin-bottom: 10rem;
    }
  }
`;

const StyledModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledExit = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 1.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.lightText};
`;

const StyledModal = styled.div`
  position: relative;
  width: 50rem;
  max-width: 95%;
  min-height: 10rem;
  margin: 7vh auto;
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => (props.blur ? props.theme.headerOutline : props.theme.cardOutline)};
  border-radius: 0.8rem;
  display: flex;
  flex-flow: column nowrap;
  will-change: transform;
  animation: ${slideIn} ${(props) => `${props.animation}s`} ease-out forwards;
  box-shadow: 0 10px 50px 1px rgba(0, 0, 0, 0.1);
`;

const StyledActionsContainer = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: flex-end;
  align-items: center;
`;

const StyledChildren = styled.div`
  flex: 1;
  font-size: 1.3rem;
  padding: 0 1.5rem;
  color: ${(props) => props.theme.text};
`;

const StyledHeader = styled.div`
  padding: 1.5rem 1.5rem 0.5rem;
  h1 {
    color: ${(props) => props.theme.text};
    font-size: 1.75rem;
    font-weight: normal;
    margin: 0 0 1rem;
    text-transform: uppercase;
  }
  h3 {
    margin: 0.5rem 0;
    color: ${(props) => props.theme.mediumText};
    font-size: 1.33rem;
  }
`;

export const Modal = ({
  hide,
  showModal = true,
  children,
  showExit = true,
  animation = 0.175,
  actionText = "Confirm",
  actionCommand,
  showCancel = true,
  isLoading,
  isModalLoading,
  modalError,
  title = "",
  subtitle = "",
  enterKeySubmits = false,
  modalStyle = {},
  childrenStyle = {},
  exitStyle = {},
  width = "50rem",
  testId = "modal",
  blur: _blur = true,
  ...props
}) => {
  const { blur: globalBlurEnabled } = useContext(ThemeContext);
  const blur = globalBlurEnabled ? _blur : false;

  useEffect(() => {
    const handleKeyPress = ({ key }) => {
      if (key === "Escape") {
        hide && hide();
      }
      if (key === "Enter" && enterKeySubmits) {
        actionCommand ? actionCommand() : hide && hide();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [hide, actionCommand, enterKeySubmits]);

  if (!showModal) return null;

  const renderModal = () => {
    if (isModalLoading) return <Spinner />;
    if (modalError) return <Error message={modalError} />;

    return (
      <>
        <StyledHeader className="header">
          <h3>{subtitle}</h3>
          <h1>{title}</h1>
        </StyledHeader>
        <StyledChildren className="children" style={childrenStyle}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </StyledChildren>
        {actionCommand && (
          <StyledActionsContainer>
            {showCancel && (
              <CTAButton onClick={hide} isSecondary style={{ marginRight: "1rem" }}>
                Cancel
              </CTAButton>
            )}
            <CTAButton isLoading={isLoading} onClick={() => (actionCommand ? actionCommand() : hide())}>
              {actionText}
            </CTAButton>
          </StyledActionsContainer>
        )}
      </>
    );
  };

  return (
    <StyledModalContainer animation={animation} style={modalStyle} className="modal" width={width} blur={blur}>
      <StyledModalBackground onClick={hide} />
      <StyledModal
        {...props}
        style={{ width, ...(props.style || {}) }}
        className={`modal-content ${props.className}`}
        blur={blur}
        animation={animation}
        data-testid={testId}
      >
        {showExit && <StyledExit icon={faTimes} onClick={hide} style={exitStyle} />}
        {renderModal()}
      </StyledModal>
    </StyledModalContainer>
  );
};

export const LoadingModal = () => {
  const history = useHistory();
  return (
    <Modal animation={0.3} showModal={true} hide={() => history.goBack()}>
      <Spinner />
    </Modal>
  );
};

export const AutoHideModal = ({ children, actionCommand, actionText = "Dismiss", ...props }) => {
  const [showModal, setShowModal] = useState(true);
  const hide = () => {
    actionCommand && actionCommand();
    setShowModal(false);
  };
  return (
    <Modal showModal={showModal} hide={hide} actionText={actionText} actionCommand={hide} showCancel={false} showExit={false} {...props}>
      {children}
    </Modal>
  );
};

export const ErrorModal = ({ error, hide }) => {
  const [showModal, setShowModal] = useState(true);
  if (error === "" || error === false) error = "An error occurred, please try again!";

  const handleHide = () => {
    hide && hide();
    setShowModal(false);
  };

  return (
    <Modal title="Error" style={{ alignItems: "center" }} showModal={showModal} hide={handleHide} actionText="Okay" testId="error">
      <p style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{error}</p>
    </Modal>
  );
};

export const ConfirmModal = ({ showModal, hide, callback, message = "Are you sure you wish to execute this action?", ...props }) => {
  return (
    <Modal title="Are you sure?" showModal={showModal} hide={hide} actionText="Confirm" actionCommand={callback} {...props} width="35rem">
      <p>{message}</p>
    </Modal>
  );
};
