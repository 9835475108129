import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAsync } from "react-async";
import { fetchData } from "lib/helpers/fetchData";

import { Tag } from "lib/components/Tag";
import { KeyValue } from "lib/components/KeyValue";
import { Dropdown } from "lib/components/Dropdown";
import { getColorFromSeverity } from "lib/helpers/getColorFromSeverity";
import { PreviewBox } from "lib/components/PreviewBox";
import { getImageSource } from "lib/helpers/getImageSource";
import { hasEvaluationOrigin, getEvaluationOriginIcon, getEvaluationText } from "lib/helpers/getEvaluation";
import { getSeverityText } from "lib/helpers/getSeverityText";
import { TagsContext } from "apps/phish";

const updateArtifact = async ([ticketId, customerId, artifactId, severity, tags]) => {
  const path = ticketId ? `/tickets/${customerId}/${ticketId}/artifacts/${artifactId}/update` : `/artifacts/${artifactId}/update`;
  return await fetchData(`${process.env.REACT_APP_PHISH_API}${path}`, JSON.stringify({ severity, tags }), "POST");
};

const StyledContainer = styled.div`
  flex: 1;
  max-width: 55rem;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.headerOutline};
  border-radius: 5px;
  padding: 1.5rem;
  margin: 0 0 2rem 2rem;
  @media (max-width: ${props => props.theme.mobileWidth}) {
    padding: 0;
    padding-bottom: 1.5rem;
    margin-left: 0;
    max-width: unset;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${props => props.theme.headerOutline};
    & > div {
      min-width: unset !important;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 41rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const Evaluate = ({ data, setData, ticketId = null, customerId = null, artifactId, refresh }) => {
  const { run: update, isLoading: isUpdating } = useAsync({ deferFn: updateArtifact, onResolve: () => refresh() });
  const { data: tags, isLoading: isFetchingTags } = useContext(TagsContext);

  const history = useHistory();
  const imageSource = getImageSource(data);

  const handleEvaluation = e => update(ticketId, customerId, artifactId, { score: parseInt(e) }, data.tags);

  const handleAddTag = e => {
    const newTags = [...(data?.tags || []), JSON.parse(e)];
    setData({ ...data, tags: newTags });
    update(ticketId, customerId, artifactId, data.severity, newTags);
  };

  const handleDeleteTag = id => {
    const newTags = data.tags.filter(tag => tag.id !== id);
    setData({ ...data, tags: newTags });
    update(ticketId, customerId, artifactId, data.severity, newTags);
  };

  const handleEvaluationClick = ({ target } = {}) => {
    if (!target?.type || target?.type?.toLowerCase() === data.type) return;
    if (target.type === "ticket") {
      history.push(`/phish/tickets/${ticketId}`);
    } else if (target.type === "url" || target.type === "file") {
      history.push(`/phish/tickets/${customerId}/${ticketId}/artifacts/${target.id}`);
    }
  };

  return (
    <StyledContainer>
      <div>
        <KeyValue
          text="Current Evaluation"
          xHoverPos="left"
          yHoverPos="center"
          inlineIcon
          action={hasEvaluationOrigin(data) && "origin"}
          icon={getEvaluationOriginIcon(data.evaluationOrigin)}
          hoverText={getEvaluationText(data.evaluationOrigin, data)}
          clickAction={() => handleEvaluationClick(data.evaluationOrigin)}
          color={getColorFromSeverity(data.severity)}
          value={getSeverityText(data)}
        />
        <Dropdown label={!data.severity.score ? "Evaluate" : "Re-Evaluate"} callback={handleEvaluation} isLoading={isUpdating}>
          <option value="1">Benign</option>
          <option value="2">Suspicious</option>
          <option value="3">Malicious</option>
        </Dropdown>
      </div>
      <div>
        <KeyValue
          text="Tags"
          wrap={false}
          value={
            <div className="container" style={{ display: "flex", flexWrap: "wrap" }}>
              {data.tags.length > 0 ? (
                data.tags.map(tag => <Tag key={tag.id} name={tag.name} callback={() => handleDeleteTag(tag.id)} />)
              ) : (
                <p>None</p>
              )}
            </div>
          }
        />
        <Dropdown label="+ Tags" callback={handleAddTag} isLoading={isFetchingTags}>
          {tags?.result
            .filter(tag => !data.tags.map(tag => tag.id).includes(tag.id))
            .map(({ id, name }) => (
              <option key={id} value={JSON.stringify({ id, name })}>
                {name}
              </option>
            ))}
        </Dropdown>
      </div>
      {imageSource && (
        <div>
          <KeyValue
            text="Preview"
            wrap={false}
            value={
              <PreviewBox maxHeight={500} id="artifact-eval">
                <img src={imageSource} alt="Evaluate Preview" style={{ width: "100%", height: "auto" }} />
              </PreviewBox>
            }
            style={{ margin: 0 }}
          />
        </div>
      )}
    </StyledContainer>
  );
};
