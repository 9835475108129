import React from "react";
import styled from "styled-components";

import { Spinner, Error } from "lib/helpers/fetchData";
import { color } from "d3";

export const CTAButton = React.forwardRef(
  ({ as = "button", children, style, disabled = false, isSecondary = false, small, onClick, className, isLoading, error }, ref) => (
    <StyledCTAButton
      as={as}
      className={className}
      type="button"
      style={style}
      onClick={onClick}
      small={small}
      secondary={isSecondary}
      disabled={disabled}
      ref={ref}
    >
      <>
        {isLoading && <Spinner />}
        {error || isLoading ? null : children}
        {error && <Error />}
      </>
    </StyledCTAButton>
  )
);

export const getColor = (props) => {
  if (props.disabled) return props.theme.pageBackground;
  if (props.secondary) return props.theme.accentCardBackground;
  return props.theme.secondaryButtonColor;
};

export const getHoverColor = (props) => {
  if (props.disabled) return props.theme.pageBackground;
  if (props.secondary) return color(props.theme.accentCardBackground).darker(0.15);
  return color(props.theme.secondaryButtonColor).darker(0.4);
};

export const getActiveColor = (props) => {
  if (props.disabled) return props.theme.pageBackground;
  if (props.secondary) return color(props.theme.accentCardBackground).darker(0.35);
  return color(props.theme.secondaryButtonColor).darker(0.8);
};

export const getBorderColor = (props) => {
  if (props.disabled) return props.theme.pageBackground;
  if (props.secondary) return color(props.theme.accentCardBackground).darker(0.4);
  return color(props.theme.secondaryButtonColor).darker(0.4);
};

export const getBorderHoverColor = (props) => {
  if (props.disabled) return props.theme.pageBackground;
  if (props.secondary) return color(props.theme.accentCardBackground).darker(0.6);
  return color(props.theme.secondaryButtonColor).darker(0.8);
};

export const getBorderActiveColor = (props) => {
  if (props.disabled) return props.theme.pageBackground;
  if (props.secondary) return color(props.theme.accentCardBackground).darker(0.8);
  return color(props.theme.secondaryButtonColor).darker(1.4);
};

const StyledCTAButton = styled.button`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  outline-color: ${getBorderActiveColor};
  border-radius: 0.33rem;
  height: ${(props) => (props.small ? "2.4rem" : "2.8rem")};
  min-height: ${(props) => (props.small ? "2.4rem" : "2.8rem")};
  width: ${(props) => (props.small ? "10rem" : "10rem")};
  min-width: ${(props) => (props.small ? "10rem" : "10rem")};
  font: inherit;
  font-size: ${(props) => (props.small ? "1.15rem" : "1.33rem")};
  background: ${getColor};
  border: 1px solid ${getBorderColor};
  color: ${(props) => (props.secondary || props.disabled ? props.theme.mediumText : props.theme.buttonText || props.theme.text)};
  transition: all 0.25s;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  &:hover {
    background: ${getHoverColor};
    border-color: ${getBorderHoverColor};
  }
  &:active {
    background: ${getActiveColor};
    border-color: ${getBorderActiveColor};
  }
  div {
    transform: scale(0.4);
    position: absolute;
    svg {
      margin: 1.2rem;
      circle {
        stroke-opacity: 1;
      }
    }
  }
  img {
    margin: 2px 0 !important;
    height: 1.9rem;
  }
`;
