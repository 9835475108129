import React, { useState, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { trimWord } from "lib/helpers/trimWord";
import { ScrollContainer } from "lib/components/ScrollContainer";
import { KeyValue } from "lib/components/KeyValue";
import { Tooltip } from "lib/components/Tooltip";

export const Rules = ({ data }) => (
  <StyledRulesContainer scrollX="auto" scrollY="unset">
    <header>
      <div className="small" />
      <div className="medium">Type</div>
      <div className="large">Description</div>
      <div className="smaller">Last Run</div>
      <div className="smaller center">Is Running</div>
      <div className="smaller center">Did Execute</div>
      <div className="smaller center">Did Find Evil</div>
    </header>
    {data.rules.length > 0 ? (
      data.rules.map((result, i) => <Rule key={result.id} {...result} isLast={i === data.rules.length - 1} />)
    ) : (
      <p>No rules ran against this object</p>
    )}
  </StyledRulesContainer>
);

const Rule = ({ data, message, rule, started, didExecute, didFindEvil, isRunning, isLast }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  const handleToggle = () => {
    setHeight(isOpen ? 0 : contentRef.current.clientHeight);
    setIsOpen(!isOpen);
  };

  let parsedData = data;
  try {
    parsedData = JSON.parse(data);
  } catch (error) {}

  return (
    <StyledRule open={isOpen} height={height}>
      <rule-header onClick={handleToggle}>
        <div className="small">
          <FontAwesomeIcon icon={faChevronDown} className="arrow" />
        </div>
        <div className="medium">
          {rule.type || (message && message.indexOf(":") > -1 ? message.split(":")[1] : "") || ""}
          {rule.type === "VirusTotal" && (
            <Tooltip
              xHoverPos="right"
              yHoverPos={isLast ? "top" : "center"}
              hoverText={
                <p>
                  VT hits threshold <b>>= 10</b> for artifact <b>severity inheritance</b>,{"\n"} but <b>>= 1</b> for <b>Did Find Evil</b>{" "}
                  light
                </p>
              }
            />
          )}
          {rule.type === "MispLookup" && parsedData?.[0]?.["event_id"] && (
            <a
              href={`${process.env.REACT_APP_MISP_URL}/events/view/${parsedData?.[0]?.["event_id"]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip xHoverPos="right" yHoverPos={isLast ? "top" : "center"} hoverText="View in MISP" icon={faExternalLinkAlt} />
            </a>
          )}
        </div>
        <div className="large">{trimWord(rule.description, 200)}</div>
        <div className="smaller" style={{ zIndex: 99 }}>
          {started && moment(started).fromNow()}
        </div>
        <div className="smaller">
          <Ball color={isRunning ? "green" : "red"} />
        </div>
        <div className="smaller">
          <Ball color={didExecute ? "green" : "red"} />
        </div>
        <div className="smaller">
          <Ball color={didFindEvil ? "green" : "red"} />
        </div>
      </rule-header>
      <rule-body>
        <rule-content ref={contentRef}>
          <KeyValue text="Response Message" value={message || "No message provided"} />
          <KeyValue
            text="Response Data"
            value={parsedData ? JSON.stringify(parsedData, null, 4) : "No data provided"}
            lineLimit={null}
            disableTitle
          />
        </rule-content>
      </rule-body>
    </StyledRule>
  );
};

const Ball = ({ color }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
      <div style={{ height: "10px", width: "10px", borderRadius: "5px", background: color }} />
    </div>
  );
};

const StyledRulesContainer = styled(ScrollContainer)`
  margin: -1.8rem;
  width: calc(100% + 3.6rem);
  p {
    font-size: 1.5rem;
    color: ${(props) => props.theme.text};
    white-space: pre-wrap;
  }
  & > p {
    text-align: center;
  }
  header {
    font-size: 1.25rem;
    display: flex;
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      padding: 1.25rem 0.8rem;
    }
  }
  .small {
    min-width: 20px;
    max-width: 20px;
    display: flex;
    justify-content: center;
  }
  .smaller {
    min-width: 85px;
    max-width: 85px;
    overflow: unset;
  }
  .medium {
    min-width: 20%;
    max-width: 120px;
    padding: 0 0.8rem;
    display: flex;
    align-items: center;
    min-height: 4rem;
    max-height: 4rem;
    overflow: unset;
    .tooltip {
      margin-left: 1rem;
    }
  }
  .large {
    flex: 1;
    min-width: 40%;
  }
  .center {
    text-align: center;
  }
`;

const StyledRule = styled.div`
  &:nth-child(even) rule-header > div {
    background: ${(props) => props.theme.accentCardBackground};
  }
  rule-header {
    cursor: pointer;
    display: flex;
    align-items: stretch;
    color: ${(props) => props.theme.mediumText};
    font-size: 1.45rem;
    & > div {
      padding: 1.25rem 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  rule-body {
    transition: height 0.3s;
    height: ${(props) => `${props.height}px`};
    overflow: hidden;
    display: block;
    box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.2);
  }

  rule-content {
    display: block;
    margin-left: calc(20px + (3 * 0.8rem) - 1.25rem);
    padding: 1.25rem;
  }

  .arrow {
    transition: transform 0.3s;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(360deg)")};
  }
`;
