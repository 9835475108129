const { useLocation } = require("react-router-dom");

export const useQuery = () => {
  const search = new URLSearchParams(useLocation().search);

  let params = {};

  search.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};
