import React from "react";
import moment from "moment";

import { StyledForm } from "lib/styles/general";
import { Input } from "lib/components/Input";
import { MarkdownEditor } from "lib/components/MarkdownEditor";
import { CTAButton } from "lib/components/CTAButton";
import { ErrorsList } from "lib/components/ErrorsList";

export const ReleaseForm = React.forwardRef(({ release = {}, errors = [], isEditMode = false, hide, handleSubmit }, formRef) => {
  if (release.date) release.date = moment(release.date).format("YYYY-MM-DD");
  
  return (
    <StyledForm ref={formRef}>
      <section>
        <Input name="title" label="Title" defaultValue={release.title} readOnly={!isEditMode} />
        <Input name="version" label="Version" type="number" step="0.1" defaultValue={release.version} readOnly={!isEditMode} />
        <Input name="date" label="Date" type="date" readOnly={!isEditMode} defaultValue={release.date} />
      </section>
      <section>
        <Input label="Clarity Content">
          <MarkdownEditor name="clarityContent" style={{ margin: "0.5rem 0" }} subtle defaultValue={release.clarityContent} readOnly={!isEditMode} />
        </Input>
      </section>
      <section>
        <Input label="Slack Content">
          <MarkdownEditor name="slackContent" style={{ margin: "0.5rem 0" }} subtle defaultValue={release.slackContent} readOnly={!isEditMode} />
        </Input>
      </section>
      <section>
        <ErrorsList errors={errors} />
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "2rem 0 0" }}>
          <CTAButton isSecondary onClick={hide}>
            Cancel
          </CTAButton>
          <CTAButton onClick={handleSubmit}>Submit</CTAButton>
        </div>
      </section>
    </StyledForm>
  );
});
