import React from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  width: auto;
  height: auto;
  margin: auto;
  max-width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Preview = ({ data }) => {
  return (
    <StyledContainer>
      <StyledImage alt="Email Preview" src={data?.mail?.preview?.url} />
    </StyledContainer>
  );
};
