import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { getRemValue } from "lib/helpers/getRemValue";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const spinAnimation = (props) => {
  return css`
    ${spin} 1s ease-in-out infinite
  `;
};

const StyledIcon = styled(FontAwesomeIcon)`
  & > path {
    animation: ${(props) => props.rotate && spinAnimation()};
    transform-origin: center;
  }
`;

const StyledTooltip = styled.div`
  position: relative;
  display: inline-flex;
  & + p {
    display: inline-block;
    margin: 8px 6px 2px !important;
  }
  & > span > svg {
    cursor: pointer;
    z-index: 99;
    min-width: 1rem;
    font-size: 1rem;
    border: 1px solid ${(props) => props.theme.headerOutline};
    border-radius: 0.25rem;
    padding: 0.65rem;
    background: ${(props) => props.theme.cardBackground};
    transition: background 0.3s;
    color: ${(props) => props.theme.text};
    &:hover {
      background: ${(props) => props.theme.accentCardBackground};
    }
  }
  & > div {
    position: absolute;
    box-sizing: border-box;
    opacity: 0;
    animation: ${fadeIn} 0.2s forwards;
    color: ${(props) => props.theme.mediumText};
    box-shadow: rgba(0, 0, 0, 0.06) 0px 10px 22px, rgba(0, 0, 0, 0.06) 0px 7px 15px 8px, rgba(0, 0, 0, 0.06) 0px 5px 10px;
    background: ${(props) => props.theme.cardBackground};
    border: 1px solid ${(props) => props.theme.cardOutline};
    border-radius: 0.33rem;
    padding: 0.2rem 1rem;
    z-index: 999;
    width: unset;
    width: max-content;
    max-width: 70vw;
    display: flex;
    flex-flow: column nowrap;

    & > .content {
      margin: 0.3rem;
      font-size: 1.33rem;
      white-space: nowrap;
      & > p {
        margin: 0.7rem 0.5rem;
        line-height: 1.5;
        font-size: 1.5rem;
        white-space: pre-wrap;
        text-align: center;
      }
    }
  }
`;

export const Tooltip = ({
  icon = faInfo,
  iconProps = {},
  tooltipProps = {},
  hoverText,
  clickText,
  clickAction,
  xHoverPos = "center",
  yHoverPos = "top",
  xClickPos = "center",
  yClickPos = "top",
  size = "small",
  persist = false,
  spacing = 1,
  ...props
}) => {
  const [tooltipText, setTooltipText] = useState();
  const [tooltipSize, setTooltipSize] = useState({ width: null, height: null });

  const tooltipRef = useRef(null);
  const iconRef = useRef(null);

  useLayoutEffect(() => {
    setTooltipSize({ width: tooltipRef?.current?.offsetWidth, height: tooltipRef?.current?.offsetHeight });
  }, [tooltipText]);

  const iconWidth = iconRef?.current?.offsetWidth;
  const iconHeight = (iconRef?.current?.offsetHeight || 2) - 2;

  const handleTooltipMouseEnter = () => tooltipText == null && setTooltipText(hoverText);
  const handleTooltipMouseLeave = () => tooltipText === hoverText && setTooltipText(null);

  const handleTooltipClick = () => {
    clickAction && clickAction();
    setTooltipText(clickText);
    if (!persist) setTimeout(() => setTooltipText(null), 700);
  };

  useEffect(() => {
    const handleClose = () => setTooltipText(null);
    window.addEventListener("mousedown", handleClose);
    return () => window.removeEventListener("mousedown", handleClose);
  }, []);

  const getSizeStyling = (size) => {
    switch (size) {
      case "small":
        return { padding: "0.45rem", fontSize: "1rem" };
      case "medium":
        return { padding: "0.65rem", fontSize: "1.1rem" };
      case "large":
        return { padding: "0.85rem", fontSize: "1.2rem" };
      default:
        return null;
    }
  };

  const getPositionStyling = (x, y) => {
    let left, right, bottom, top;
    const padding = getRemValue(spacing);

    if (y === "top") bottom = iconHeight + padding;
    if (y === "center") top = (iconHeight - tooltipSize.height) / 2;
    if (y === "bottom") top = iconHeight + padding;

    if (x === "left") right = y !== "center" ? 0 : iconWidth + padding;
    if (x === "center") left = (iconWidth - tooltipSize.width) / 2;
    if (x === "right") left = y !== "center" ? 0 : iconWidth + padding;

    return { left, right, bottom, top };
  };

  const sizeStyling = getSizeStyling(size);
  const positionStyling = tooltipText === hoverText ? getPositionStyling(xHoverPos, yHoverPos) : getPositionStyling(xClickPos, yClickPos);

  return (
    <StyledTooltip
      {...props}
      className={`tooltip${props.className ? ` ${props.className}` : ""}`}
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <span ref={iconRef}>
        <StyledIcon icon={icon} style={{ ...sizeStyling }} onMouseUp={handleTooltipClick} {...iconProps} />
      </span>
      {tooltipText && (
        <div style={{ ...positionStyling }} {...tooltipProps}>
          <div className="content">{tooltipText}</div>
        </div>
      )}

      {/* hack: duplicate div offscreen to get display-time width */}
      <div ref={tooltipRef} style={{ left: "-10000px" }}>
        <div className="content">{tooltipText}</div>
      </div>
    </StyledTooltip>
  );
};
